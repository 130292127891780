import React from "react";
import styled from "styled-components";
import PrimaryMiniBtn from "./../atoms/buttons/PrimaryMiniBtn";
import SecondaryMiniBtn from "../atoms/buttons/SecondaryMiniBtn";
import PretendardText from "../atoms/PretendardText";

const Confirm = (props) => {
  return (
    <ConfirmContainer {...props}>
      <ConfirmText>{props.children}</ConfirmText>
      <ConfirmBtnWrapper>
        <SecondaryMiniBtn
          style={
            {
              // width: 68,
            }
          }
          onClick={props.cancelEvent}
        >
          {props.cancelText ? props.cancelText : "취소"}
        </SecondaryMiniBtn>
        <PrimaryMiniBtn
          style={{
            width: 68,
          }}
          onClick={props.confirmEvent}
        >
          {props.confirmText ? props.confirmText : "확인"}
        </PrimaryMiniBtn>
      </ConfirmBtnWrapper>
    </ConfirmContainer>
  );
};

export default Confirm;

const ConfirmContainer = styled.div`
  min-width: 344px;
  min-height: 185px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  z-index: 100;
`;

const ConfirmText = styled(PretendardText)`
  font-size: 16px;
  line-height: 145%;
  font-weight: 400;
  color: #282828;
  text-align: center;
`;

const ConfirmBtnWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  align-self: center;
`;
