export const formatBusinessNumber = (number) => {
  const cleaned = ("" + number).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{2})(\d{5,})$/);

  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  } else {
    return cleaned;
  }
};

export const formatPhoneNumber = (number) => {
  const cleaned = ("" + number).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{4})(\d{4,})$/);

  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  } else {
    return cleaned;
  }
};

export const formatTelNumber = (number) => {
  const cleaned = ("" + number).replace(/\D/g, "");
  let match;

  if (cleaned.startsWith("02")) {
    // 서울 지역번호(02)의 경우
    match = cleaned.match(/^(02)(\d{3,4})(\d{4})$/);
  } else {
    // 나머지 지역번호(031 등)의 경우
    match = cleaned.match(/^(\d{3})(\d{3,4})(\d{4})$/);
  }

  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  } else {
    return cleaned;
  }
};

export const formatReportNumber = (number) => {
  const cleaned = ("" + number).replace(/\s/g, "");
  const match = cleaned.match(/^(\d{4})-?([가-힣]+)-?(\d{4})(호)?$/);

  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}${match[4] || ""}`;
  } else {
    return cleaned;
  }
};

export const formatNumberWithCommas = (data) => {
  let cleanedData = data;
  // 숫자 외 나머지 값 삭제
  if (typeof data === "string") {
    cleanedData = data.toString().replace(/[^\d]/g, "");
  }

  // 다시 숫자로 변환
  let number = parseFloat(cleanedData);

  number = Math.floor(number);

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// 숫자를 소수점 1자리까지 표시
export const formatRatingNumber = (rating) => {
  if (!rating.includes(".")) {
    return rating + ".0";
  }
  return rating;
};

// 숫자만 추출
export const removeNonNumeric = (data) => {
  return data.replace(/[^0-9]/g, "");
};
