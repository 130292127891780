import React from "react";
import styled from "styled-components";

const XCircleIcon = (props) => {
  return (
    <XCircleIconSvg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/XCircleIconSvg"
      {...props}
    >
      <circle
        cx="8"
        cy="8"
        r="7.5"
        fill="white"
        stroke="#C0C0C0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 4.5L11.5 11"
        stroke="#C0C0C0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 4.5L5 11"
        stroke="#C0C0C0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </XCircleIconSvg>
  );
};

export default XCircleIcon;

const XCircleIconSvg = styled.svg`
  cursor: pointer;
`;
