import route from "../router/route";

// 스크랩 포스트 데이터
export const postData = [
  {
    imgSrc:
      "https://s3-alpha-sig.figma.com/img/b751/c865/b040ac53c4ab48d5ec4e594331a85b08?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UpzBumXF4I1lFNAR7SXsKHbFQl4B4p~VpQUCNokD4VFtn~e5CWoW3beeII0mrjV3CYW7m0JFpBluuDwyVPNd2RGkSlnunT0yMkspq9ObQk4lTuXyRLSYIGxhkvNEw~ghqaDmniGVQsqIfyWwI9lLn1lcIKAjQ3KC~NuVxekeaprls5qJTw6JeTtUnnVQ7PZWZEagu4ZHDD-ML5ZoFj-0~HNxT4FDwk4VncTnp43sIf5OrdpuK6QcyMcrYsK62KejCrBc12rXcfUpOFnVAg3ElJ78mpPFf-gKMPuDx-Rgae1vcBUe3xyonwgE39fObBIZs8Jk~vzPnpShdv~iWGAHJA__",
    serviceName: "서비스명이 이곳에 들어갑니다.",
    companyName: "기업명이 이곳에 들어갑니다.",
    marketingType: "마케팅 종류",
    area: "지역",
    reviewGrade: "5.0",
  },
  {
    imgSrc:
      "https://s3-alpha-sig.figma.com/img/b751/c865/b040ac53c4ab48d5ec4e594331a85b08?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UpzBumXF4I1lFNAR7SXsKHbFQl4B4p~VpQUCNokD4VFtn~e5CWoW3beeII0mrjV3CYW7m0JFpBluuDwyVPNd2RGkSlnunT0yMkspq9ObQk4lTuXyRLSYIGxhkvNEw~ghqaDmniGVQsqIfyWwI9lLn1lcIKAjQ3KC~NuVxekeaprls5qJTw6JeTtUnnVQ7PZWZEagu4ZHDD-ML5ZoFj-0~HNxT4FDwk4VncTnp43sIf5OrdpuK6QcyMcrYsK62KejCrBc12rXcfUpOFnVAg3ElJ78mpPFf-gKMPuDx-Rgae1vcBUe3xyonwgE39fObBIZs8Jk~vzPnpShdv~iWGAHJA__",
    serviceName: "서비스명이 이곳에 들어갑니다.",
    companyName: "기업명이 이곳에 들어갑니다.",
    marketingType: "마케팅 종류",
    area: "지역",
    reviewGrade: "5.0",
  },
  {
    imgSrc:
      "https://s3-alpha-sig.figma.com/img/b751/c865/b040ac53c4ab48d5ec4e594331a85b08?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UpzBumXF4I1lFNAR7SXsKHbFQl4B4p~VpQUCNokD4VFtn~e5CWoW3beeII0mrjV3CYW7m0JFpBluuDwyVPNd2RGkSlnunT0yMkspq9ObQk4lTuXyRLSYIGxhkvNEw~ghqaDmniGVQsqIfyWwI9lLn1lcIKAjQ3KC~NuVxekeaprls5qJTw6JeTtUnnVQ7PZWZEagu4ZHDD-ML5ZoFj-0~HNxT4FDwk4VncTnp43sIf5OrdpuK6QcyMcrYsK62KejCrBc12rXcfUpOFnVAg3ElJ78mpPFf-gKMPuDx-Rgae1vcBUe3xyonwgE39fObBIZs8Jk~vzPnpShdv~iWGAHJA__",
    serviceName: "서비스명이 이곳에 들어갑니다.",
    companyName: "기업명이 이곳에 들어갑니다.",
    marketingType: "마케팅 종류",
    area: "지역",
    reviewGrade: "5.0",
  },
  {
    imgSrc:
      "https://s3-alpha-sig.figma.com/img/b751/c865/b040ac53c4ab48d5ec4e594331a85b08?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UpzBumXF4I1lFNAR7SXsKHbFQl4B4p~VpQUCNokD4VFtn~e5CWoW3beeII0mrjV3CYW7m0JFpBluuDwyVPNd2RGkSlnunT0yMkspq9ObQk4lTuXyRLSYIGxhkvNEw~ghqaDmniGVQsqIfyWwI9lLn1lcIKAjQ3KC~NuVxekeaprls5qJTw6JeTtUnnVQ7PZWZEagu4ZHDD-ML5ZoFj-0~HNxT4FDwk4VncTnp43sIf5OrdpuK6QcyMcrYsK62KejCrBc12rXcfUpOFnVAg3ElJ78mpPFf-gKMPuDx-Rgae1vcBUe3xyonwgE39fObBIZs8Jk~vzPnpShdv~iWGAHJA__",
    serviceName: "서비스명이 이곳에 들어갑니다.",
    companyName: "기업명이 이곳에 들어갑니다.",
    marketingType: "마케팅 종류",
    area: "지역",
    reviewGrade: "5.0",
  },
  {
    imgSrc:
      "https://s3-alpha-sig.figma.com/img/b751/c865/b040ac53c4ab48d5ec4e594331a85b08?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UpzBumXF4I1lFNAR7SXsKHbFQl4B4p~VpQUCNokD4VFtn~e5CWoW3beeII0mrjV3CYW7m0JFpBluuDwyVPNd2RGkSlnunT0yMkspq9ObQk4lTuXyRLSYIGxhkvNEw~ghqaDmniGVQsqIfyWwI9lLn1lcIKAjQ3KC~NuVxekeaprls5qJTw6JeTtUnnVQ7PZWZEagu4ZHDD-ML5ZoFj-0~HNxT4FDwk4VncTnp43sIf5OrdpuK6QcyMcrYsK62KejCrBc12rXcfUpOFnVAg3ElJ78mpPFf-gKMPuDx-Rgae1vcBUe3xyonwgE39fObBIZs8Jk~vzPnpShdv~iWGAHJA__",
    serviceName: "서비스명이 이곳에 들어갑니다.",
    companyName: "기업명이 이곳에 들어갑니다.",
    marketingType: "마케팅 종류",
    area: "지역",
    reviewGrade: "5.0",
  },
  {
    imgSrc:
      "https://s3-alpha-sig.figma.com/img/b751/c865/b040ac53c4ab48d5ec4e594331a85b08?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UpzBumXF4I1lFNAR7SXsKHbFQl4B4p~VpQUCNokD4VFtn~e5CWoW3beeII0mrjV3CYW7m0JFpBluuDwyVPNd2RGkSlnunT0yMkspq9ObQk4lTuXyRLSYIGxhkvNEw~ghqaDmniGVQsqIfyWwI9lLn1lcIKAjQ3KC~NuVxekeaprls5qJTw6JeTtUnnVQ7PZWZEagu4ZHDD-ML5ZoFj-0~HNxT4FDwk4VncTnp43sIf5OrdpuK6QcyMcrYsK62KejCrBc12rXcfUpOFnVAg3ElJ78mpPFf-gKMPuDx-Rgae1vcBUe3xyonwgE39fObBIZs8Jk~vzPnpShdv~iWGAHJA__",
    serviceName: "서비스명이 이곳에 들어갑니다.",
    companyName: "기업명이 이곳에 들어갑니다.",
    marketingType: "마케팅 종류",
    area: "지역",
    reviewGrade: "5.0",
  },
];

// 내 후기 관련
export const myReviewData = [
  {
    content:
      "풀밭에 희망의 영원히 봄바람이다. 이상 얼마나 이상, 설산에서 고동을 영원히 커다란 피어나는 아름다우냐? 심장의 보내는 위하여 몸이 않는 때문이다. 일월과 청춘의 꽃 힘있다. 천자만홍이 보내는 바로 봄바람이다. 만천하의 밝은 자신과 소금이라 살았으며, 트고, 인간의 그들을 때문이다. 반짝이는 거친 것은 눈에 뼈 공자는 풀이 쓸쓸하랴? 청춘의 그들의 발휘하기 투명하되 품에 속잎나고, 듣는다. 이상 온갖 예가 귀는 보라.그들은 열락의 있음으로써 사막이다. 무엇을 간에 이상 원대하고, 그것을 하는 피부가 것이다. 얼마나 붙잡아 무엇을 놀이 이 그들은 방지하는 사막이다. 속에 바이며, 사라지지 아름다우냐? 위하여서 놀이 커다란 봄바람을 때까지 별과 것은 그들은 황금시대다.",
    grade: "5.0",
    date: "YYYY.MM.DD",
  },
  {
    content:
      "풀밭에 희망의 영원히 봄바람이다. 이상 얼마나 이상, 설산에서 고동을 영원히 커다란 피어나는 아름다우냐? 심장의 보내는 위하여 몸이 않는 때문이다. 일월과 청춘의 꽃 힘있다. 천자만홍이 보내는 바로 봄바람이다. 만천하의 밝은 자신과 소금이라 살았으며, 트고, 인간의 그들을 때문이다. 반짝이는 거친 것은 눈에 뼈 공자는 풀이 쓸쓸하랴? 청춘의 그들의 발휘하기 투명하되 품에 속잎나고, 듣는다. 이상 온갖 예가 귀는 보라.그들은 열락의 있음으로써 사막이다. 무엇을 간에 이상 원대하고, 그것을 하는 피부가 것이다. 얼마나 붙잡아 무엇을 놀이 이 그들은 방지하는 사막이다. 속에 바이며, 사라지지 아름다우냐? 위하여서 놀이 커다란 봄바람을 때까지 별과 것은 그들은 황금시대다.",
    grade: "5.0",
    date: "YYYY.MM.DD",
  },
  {
    content:
      "풀밭에 희망의 영원히 봄바람이다. 이상 얼마나 이상, 설산에서 고동을 영원히 커다란 피어나는 아름다우냐? 심장의 보내는 위하여 몸이 않는 때문이다. 일월과 청춘의 꽃 힘있다. 천자만홍이 보내는 바로 봄바람이다. 만천하의 밝은 자신과 소금이라 살았으며, 트고, 인간의 그들을 때문이다. 반짝이는 거친 것은 눈에 뼈 공자는 풀이 쓸쓸하랴? 청춘의 그들의 발휘하기 투명하되 품에 속잎나고, 듣는다. 이상 온갖 예가 귀는 보라.그들은 열락의 있음으로써 사막이다. 무엇을 간에 이상 원대하고, 그것을 하는 피부가 것이다. 얼마나 붙잡아 무엇을 놀이 이 그들은 방지하는 사막이다. 속에 바이며, 사라지지 아름다우냐? 위하여서 놀이 커다란 봄바람을 때까지 별과 것은 그들은 황금시대다.",
    grade: "5.0",
    date: "YYYY.MM.DD",
  },
  {
    content:
      "풀밭에 희망의 영원히 봄바람이다. 이상 얼마나 이상, 설산에서 고동을 영원히 커다란 피어나는 아름다우냐? 심장의 보내는 위하여 몸이 않는 때문이다. 일월과 청춘의 꽃 힘있다. 천자만홍이 보내는 바로 봄바람이다. 만천하의 밝은 자신과 소금이라 살았으며, 트고, 인간의 그들을 때문이다. 반짝이는 거친 것은 눈에 뼈 공자는 풀이 쓸쓸하랴? 청춘의 그들의 발휘하기 투명하되 품에 속잎나고, 듣는다. 이상 온갖 예가 귀는 보라.그들은 열락의 있음으로써 사막이다. 무엇을 간에 이상 원대하고, 그것을 하는 피부가 것이다. 얼마나 붙잡아 무엇을 놀이 이 그들은 방지하는 사막이다. 속에 바이며, 사라지지 아름다우냐? 위하여서 놀이 커다란 봄바람을 때까지 별과 것은 그들은 황금시대다.",
    grade: "5.0",
    date: "YYYY.MM.DD",
  },
  {
    content:
      "풀밭에 희망의 영원히 봄바람이다. 이상 얼마나 이상, 설산에서 고동을 영원히 커다란 피어나는 아름다우냐? 심장의 보내는 위하여 몸이 않는 때문이다. 일월과 청춘의 꽃 힘있다. 천자만홍이 보내는 바로 봄바람이다. 만천하의 밝은 자신과 소금이라 살았으며, 트고, 인간의 그들을 때문이다. 반짝이는 거친 것은 눈에 뼈 공자는 풀이 쓸쓸하랴? 청춘의 그들의 발휘하기 투명하되 품에 속잎나고, 듣는다. 이상 온갖 예가 귀는 보라.그들은 열락의 있음으로써 사막이다. 무엇을 간에 이상 원대하고, 그것을 하는 피부가 것이다. 얼마나 붙잡아 무엇을 놀이 이 그들은 방지하는 사막이다. 속에 바이며, 사라지지 아름다우냐? 위하여서 놀이 커다란 봄바람을 때까지 별과 것은 그들은 황금시대다.",
    grade: "5.0",
    date: "YYYY.MM.DD",
  },
];

// 결제 내역 관련
export const paymentRecordsData = [
  {
    companyName: "서비스명이 이곳에 들어갑니다. 서비스명이 이곳에 들어갑니다.",
    date: "YYYY.MM.DD",
    payment: "신용카드",
    price: "000,000",
    decideStatus: "미확정",
    refundProgressStatus: "진행중",
  },
  {
    companyName: "서비스명이 이곳에 들어갑니다. 서비스명이 이곳에 들어갑니다.",
    date: "YYYY.MM.DD",
    payment: "신용카드",
    price: "000,000",
    decideStatus: "확정",
    refundProgressStatus: "해당없음",
  },
  {
    companyName: "서비스명이 이곳에 들어갑니다. 서비스명이 이곳에 들어갑니다.",
    date: "YYYY.MM.DD",
    payment: "신용카드",
    price: "000,000",
    decideStatus: "미확정",
    refundProgressStatus: "해당없음",
  },
  {
    companyName: "서비스명이 이곳에 들어갑니다. 서비스명이 이곳에 들어갑니다.",
    date: "YYYY.MM.DD",
    payment: "신용카드",
    price: "000,000",
    decideStatus: "확정",
    refundProgressStatus: "해당없음",
  },
  {
    companyName: "서비스명이 이곳에 들어갑니다. 서비스명이 이곳에 들어갑니다.",
    date: "YYYY.MM.DD",
    payment: "신용카드",
    price: "000,000",
    decideStatus: "미확정",
    refundProgressStatus: "환불완료",
  },
];

// 1:1 채팅 관련
export const chatData = [
  {
    imgSrc:
      "https://s3-alpha-sig.figma.com/img/b751/c865/b040ac53c4ab48d5ec4e594331a85b08?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UpzBumXF4I1lFNAR7SXsKHbFQl4B4p~VpQUCNokD4VFtn~e5CWoW3beeII0mrjV3CYW7m0JFpBluuDwyVPNd2RGkSlnunT0yMkspq9ObQk4lTuXyRLSYIGxhkvNEw~ghqaDmniGVQsqIfyWwI9lLn1lcIKAjQ3KC~NuVxekeaprls5qJTw6JeTtUnnVQ7PZWZEagu4ZHDD-ML5ZoFj-0~HNxT4FDwk4VncTnp43sIf5OrdpuK6QcyMcrYsK62KejCrBc12rXcfUpOFnVAg3ElJ78mpPFf-gKMPuDx-Rgae1vcBUe3xyonwgE39fObBIZs8Jk~vzPnpShdv~iWGAHJA__",
    companyName: "기업명이 이곳에 들어갑니다.",
    typeOfBusiness: "업종",
    area: "지역",
    reviewGrade: "5.0",
    chatContent:
      "안녕하세요, 문의주신 사항 답변드립니다. 안녕하세요, 문의주신 사항 답변드립니다.안녕하세요, 문의주신 사항 답변드립니다.",
    lastMsgTime: "오후 00:00",
  },
  {
    imgSrc:
      "https://s3-alpha-sig.figma.com/img/b751/c865/b040ac53c4ab48d5ec4e594331a85b08?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UpzBumXF4I1lFNAR7SXsKHbFQl4B4p~VpQUCNokD4VFtn~e5CWoW3beeII0mrjV3CYW7m0JFpBluuDwyVPNd2RGkSlnunT0yMkspq9ObQk4lTuXyRLSYIGxhkvNEw~ghqaDmniGVQsqIfyWwI9lLn1lcIKAjQ3KC~NuVxekeaprls5qJTw6JeTtUnnVQ7PZWZEagu4ZHDD-ML5ZoFj-0~HNxT4FDwk4VncTnp43sIf5OrdpuK6QcyMcrYsK62KejCrBc12rXcfUpOFnVAg3ElJ78mpPFf-gKMPuDx-Rgae1vcBUe3xyonwgE39fObBIZs8Jk~vzPnpShdv~iWGAHJA__",
    companyName: "기업명이 이곳에 들어갑니다.",
    typeOfBusiness: "업종",
    area: "지역",
    reviewGrade: "5.0",
    chatContent:
      "안녕하세요, 문의주신 사항 답변드립니다. 안녕하세요, 문의주신 사항 답변드립니다.안녕하세요, 문의주신 사항 답변드립니다.",
    lastMsgTime: "오후 00:00",
  },
  {
    imgSrc:
      "https://s3-alpha-sig.figma.com/img/b751/c865/b040ac53c4ab48d5ec4e594331a85b08?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UpzBumXF4I1lFNAR7SXsKHbFQl4B4p~VpQUCNokD4VFtn~e5CWoW3beeII0mrjV3CYW7m0JFpBluuDwyVPNd2RGkSlnunT0yMkspq9ObQk4lTuXyRLSYIGxhkvNEw~ghqaDmniGVQsqIfyWwI9lLn1lcIKAjQ3KC~NuVxekeaprls5qJTw6JeTtUnnVQ7PZWZEagu4ZHDD-ML5ZoFj-0~HNxT4FDwk4VncTnp43sIf5OrdpuK6QcyMcrYsK62KejCrBc12rXcfUpOFnVAg3ElJ78mpPFf-gKMPuDx-Rgae1vcBUe3xyonwgE39fObBIZs8Jk~vzPnpShdv~iWGAHJA__",
    companyName: "기업명이 이곳에 들어갑니다.",
    typeOfBusiness: "업종",
    area: "지역",
    reviewGrade: "5.0",
    chatContent:
      "안녕하세요, 문의주신 사항 답변드립니다. 안녕하세요, 문의주신 사항 답변드립니다.안녕하세요, 문의주신 사항 답변드립니다.",
    lastMsgTime: "오후 00:00",
  },
  {
    imgSrc:
      "https://s3-alpha-sig.figma.com/img/b751/c865/b040ac53c4ab48d5ec4e594331a85b08?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UpzBumXF4I1lFNAR7SXsKHbFQl4B4p~VpQUCNokD4VFtn~e5CWoW3beeII0mrjV3CYW7m0JFpBluuDwyVPNd2RGkSlnunT0yMkspq9ObQk4lTuXyRLSYIGxhkvNEw~ghqaDmniGVQsqIfyWwI9lLn1lcIKAjQ3KC~NuVxekeaprls5qJTw6JeTtUnnVQ7PZWZEagu4ZHDD-ML5ZoFj-0~HNxT4FDwk4VncTnp43sIf5OrdpuK6QcyMcrYsK62KejCrBc12rXcfUpOFnVAg3ElJ78mpPFf-gKMPuDx-Rgae1vcBUe3xyonwgE39fObBIZs8Jk~vzPnpShdv~iWGAHJA__",
    companyName: "기업명이 이곳에 들어갑니다.",
    typeOfBusiness: "업종",
    area: "지역",
    reviewGrade: "5.0",
    chatContent:
      "안녕하세요, 문의주신 사항 답변드립니다. 안녕하세요, 문의주신 사항 답변드립니다.안녕하세요, 문의주신 사항 답변드립니다.",
    lastMsgTime: "오후 00:00",
  },
  {
    imgSrc:
      "https://s3-alpha-sig.figma.com/img/b751/c865/b040ac53c4ab48d5ec4e594331a85b08?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UpzBumXF4I1lFNAR7SXsKHbFQl4B4p~VpQUCNokD4VFtn~e5CWoW3beeII0mrjV3CYW7m0JFpBluuDwyVPNd2RGkSlnunT0yMkspq9ObQk4lTuXyRLSYIGxhkvNEw~ghqaDmniGVQsqIfyWwI9lLn1lcIKAjQ3KC~NuVxekeaprls5qJTw6JeTtUnnVQ7PZWZEagu4ZHDD-ML5ZoFj-0~HNxT4FDwk4VncTnp43sIf5OrdpuK6QcyMcrYsK62KejCrBc12rXcfUpOFnVAg3ElJ78mpPFf-gKMPuDx-Rgae1vcBUe3xyonwgE39fObBIZs8Jk~vzPnpShdv~iWGAHJA__",
    companyName: "기업명이 이곳에 들어갑니다.",
    typeOfBusiness: "업종",
    area: "지역",
    reviewGrade: "5.0",
    chatContent:
      "안녕하세요, 문의주신 사항 답변드립니다. 안녕하세요, 문의주신 사항 답변드립니다.안녕하세요, 문의주신 사항 답변드립니다.",
    lastMsgTime: "오후 00:00",
  },
];

// 문의 관련
export const askData = [
  {
    title:
      "문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.",
    date: "YYYY.MM.DD",
    replyStatus: "답변완료",
  },
  {
    title:
      "문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.",
    date: "YYYY.MM.DD",
    replyStatus: "답변대기",
  },
  {
    title:
      "문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.",
    date: "YYYY.MM.DD",
    replyStatus: "답변대기",
  },
  {
    title:
      "문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.",
    date: "YYYY.MM.DD",
    replyStatus: "답변대기",
  },
  {
    title:
      "문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.",
    date: "YYYY.MM.DD",
    replyStatus: "답변대기",
  },
  {
    title:
      "문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.",
    date: "YYYY.MM.DD",
    replyStatus: "답변대기",
  },
  {
    title:
      "문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.",
    date: "YYYY.MM.DD",
    replyStatus: "답변대기",
  },
  {
    title:
      "문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.",
    date: "YYYY.MM.DD",
    replyStatus: "답변대기",
  },
  {
    title:
      "문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.",
    date: "YYYY.MM.DD",
    replyStatus: "답변대기",
  },
  {
    title:
      "문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.문의 제목이 이곳에 들어갑니다.",
    date: "YYYY.MM.DD",
    replyStatus: "답변대기",
  },
];

// 거래알림 관련
export const dealAlarmData = [
  {
    content: "구매확정이 완료되었습니다. 정산을 신청해 보세요!",
    date: "YYYY.MM.DD",
  },
  {
    content: "구매확정이 완료되었습니다. 정산을 신청해 보세요!",
    date: "YYYY.MM.DD",
  },
  {
    content: "구매확정이 완료되었습니다. 정산을 신청해 보세요!",
    date: "YYYY.MM.DD",
  },
  {
    content: "구매확정이 완료되었습니다. 정산을 신청해 보세요!",
    date: "YYYY.MM.DD",
  },
  {
    content: "구매확정이 완료되었습니다. 정산을 신청해 보세요!",
    date: "YYYY.MM.DD",
  },
];

export const dealAlarmList = [
  {
    id: 1,
    content: "구매확정이 완료되었습니다. 정산을 신청해 보세요!",
    createdAt: "YYYY.MM.DD",
    calculateStatus: "",
  },
  {
    id: 2,
    content: "구매확정이 완료되었습니다. 정산을 신청해 보세요!",
    createdAt: "YYYY.MM.DD",
    calculateStatus: "REQUEST",
  },
  {
    id: 3,
    content: "구매확정이 완료되었습니다. 정산을 신청해 보세요!",
    createdAt: "YYYY.MM.DD",
    calculateStatus: "COMPLETE",
  },
];

// 정산 관리 관련
export const calculateManagementData = [
  {
    num: 1,
    dealNum: "번호명",
    companyNum: "000-00-00000",
    clientName: "홍길동",
    companyName: "00기업",
    servicePrice: "000,000",
    clientPayment: "000,000",
    total: "000,000",
    clientPaymentDate: "YYYY.MM.DD",
    companyCalculationDate: "YYYY.MM.DD",
  },
  {
    num: 2,
    dealNum: "번호명",
    companyNum: "000-00-00000",
    clientName: "홍길동",
    companyName: "00기업",
    servicePrice: "000,000",
    clientPayment: "000,000",
    total: "000,000",
    clientPaymentDate: "YYYY.MM.DD",
    companyCalculationDate: "YYYY.MM.DD",
  },
  {
    num: 3,
    dealNum: "번호명",
    companyNum: "000-00-00000",
    clientName: "홍길동",
    companyName: "00기업",
    servicePrice: "000,000",
    clientPayment: "000,000",
    total: "000,000",
    clientPaymentDate: "YYYY.MM.DD",
    companyCalculationDate: "YYYY.MM.DD",
  },
  {
    num: 4,
    dealNum: "번호명",
    companyNum: "000-00-00000",
    clientName: "홍길동",
    companyName: "00기업",
    servicePrice: "000,000",
    clientPayment: "000,000",
    total: "000,000",
    clientPaymentDate: "YYYY.MM.DD",
    companyCalculationDate: "YYYY.MM.DD",
  },
  {
    num: 5,
    dealNum: "번호명",
    companyNum: "000-00-00000",
    clientName: "홍길동",
    companyName: "00기업",
    servicePrice: "000,000",
    clientPayment: "000,000",
    total: "000,000",
    clientPaymentDate: "YYYY.MM.DD",
    companyCalculationDate: "YYYY.MM.DD",
  },
];

export const calculateList = [
  {
    id: 1,
    dealNum: "번호명",
    companyNum: "000-00-00000",
    clientName: "홍길동",
    companyName: "00기업",
    servicePrice: "000,000",
    clientPayment: "000,000",
    total: "000,000",
    clientPaymentDate: "YYYY.MM.DD",
    companyCalculationDate: "YYYY.MM.DD",
  },
  {
    id: 2,
    dealNum: "번호명",
    companyNum: "000-00-00000",
    clientName: "홍길동",
    companyName: "00기업",
    servicePrice: "000,000",
    clientPayment: "000,000",
    total: "000,000",
    clientPaymentDate: "YYYY.MM.DD",
    companyCalculationDate: "YYYY.MM.DD",
  },
  {
    id: 3,
    dealNum: "번호명",
    companyNum: "000-00-00000",
    clientName: "홍길동",
    companyName: "00기업",
    servicePrice: "000,000",
    clientPayment: "000,000",
    total: "000,000",
    clientPaymentDate: "YYYY.MM.DD",
    companyCalculationDate: "YYYY.MM.DD",
  },
];

// 소상공인 탭 목록
export const businessTabOptions = {
  myInfo: { title: "내 정보 수정", value: route.myPage_common_info },
  scrap: { title: "스크랩", value: route.myPage_common_scrap },
  myReview: { title: "내 후기", value: route.myPage_common_myReview },
  paymentRecords: {
    title: "결제 내역",
    value: route.myPage_common_paymentRecords,
  },
  chat: { title: "1:1 채팅", value: route.myPage_common_chat },
  ask: { title: "문의하기", value: route.myPage_common_ask },
};

// 전문가 탭 목록
export const expertTabOptions = {
  myInfo: { title: "내 정보 수정", value: route.myPage_company_info },
  dealAlarm: { title: "거래 알림", value: route.myPage_company_alarm },
  accountManagement: {
    title: "정산 정보 관리",
    value: route.myPage_company_account,
  },
  calculateManagement: { title: "수익 관리", value: route.myPage_company_pay },
  chat: { title: "1:1 채팅", value: route.myPage_company_chat },
  ask: { title: "문의하기", value: route.myPage_company_ask },
};

export const bankNameOptions = [
  "신한은행",
  "국민은행",
  "우리은행",
  "하나은행",
  "기업은행",
  "농협",
];

export const bankList = [
  { title: "은행을 선택해 주세요.", value: "" },
  { title: "신한은행", value: "SHINHAN" },
  { title: "국민은행", value: "KB" },
  { title: "우리은행", value: "WOORI" },
  { title: "하나은행", value: "HANA" },
  { title: "기업은행", value: "IBK" },
  { title: "농협은행", value: "NH" },
  { title: "외환은행", value: "KEB" },
  { title: "SC제일은행", value: "SC" },
  { title: "씨티은행", value: "CITI" },
  { title: "대구은행", value: "DAEGU" },
  { title: "부산은행", value: "BUSAN" },
  { title: "경남은행", value: "KYONGNAM" },
  { title: "광주은행", value: "GWANGJU" },
  { title: "제주은행", value: "JEJU" },
  { title: "전북은행", value: "JEONBUK" },
  { title: "수협은행", value: "SUHYUP" },
  { title: "신협은행", value: "SHINHYUP" },
  { title: "케이뱅크", value: "K_BANK" },
  { title: "카카오뱅크", value: "KAKAO" },
  { title: "토스뱅크", value: "TOSS" },
];
