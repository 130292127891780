import React from "react";
import DaumPostcode from "react-daum-postcode";
import XBtnIcon from "./../atoms/icons/XBtnIcon";

const Address = (props) => {
  const complete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    // console.log(data);
    // console.log(fullAddress);
    // console.log(data.zonecode);

    props.setAddress({
      ...props.address,
      address: fullAddress,
    });

    props.onClose();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        gap: 20,
        width: 520,
        height: 550,
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <XBtnIcon
        style={{ position: "absolute", top: 30, right: 30 }}
        onClick={props.onClose}
      />
      <DaumPostcode
        style={{
          position: "absolute",
          top: 100,
          width: "100%",
          height: 450,
          zIndex: 1000,
          borderRadius: 10,
        }}
        autoClose
        onComplete={complete}
      />
    </div>
  );
};

export default Address;
