const qnaData = [
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
  {
    title:
      "자주 묻는 질문 제목이 이곳에 들어갑니다. 게시글은 관리자 페이지에서 최고관리자가 등록합니다.",
    content:
      "제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다. 제목에 대한 내용이 이곳에 들어갑니다.",
  },
];

export default qnaData;
