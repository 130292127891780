import React from "react";
import styled from "styled-components";

const ModalContentWrapper = (props) => {
  return (
    <StyledModalContentWrapper {...props}>
      {props.children}
    </StyledModalContentWrapper>
  );
};

export default ModalContentWrapper;

const StyledModalContentWrapper = styled.div`
  width: 640px;
  margin-top: 23px;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
`;
