import React, { useEffect, useRef, useState } from "react";
import PageContainer from "../../components/molecules/layout/PageContainer";
import BodyContainer from "./../../components/molecules/layout/BodyContainer";
import Header from "./../../components/organisms/Header";
import Title from "./../../components/atoms/Title";
import SubTitle from "./../../components/atoms/SubTitle";
import Footer from "./../../components/organisms/Footer";
import { useRecoilState } from "recoil";
import { initialLoginValues, loginValueState } from "../../store/loginStore";
import styled from "styled-components";
import { deleteStorage, setStorage } from "../../lib/utils/sessionStorage";
import useResizeHeight from "../../hooks/useResizeHeight";
import InputBox from "../../components/molecules/InputBox";
import PrimaryBtn from "./../../components/atoms/buttons/PrimaryBtn";
import regex from "./../../constants/regex";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Alert from "../../components/molecules/Alert";
import { url } from "../../lib/api/api";
import { removeNonNumeric } from "../../lib/utils/formatNumber";

const FindPw = () => {
  const navigate = useNavigate();

  const form1 = useRef();
  const form2 = useRef();

  // 전역 상태
  const [loginValue, setLoginValue] = useRecoilState(loginValueState);

  // 유효성 검사 결과 상태
  const [phoneNumberRegexTest, setPhoneNumberRegexTest] = useState(true); // 전화번호 정규식 테스트 결과 상태
  const [certNumRegexTest, setCertNumRegexTest] = useState(true); // 인증번호 정규식 테스트 결과 상태
  const [certNumSent, setCertNumSent] = useState(false); // 인증번호 발송 여부 상태
  const [verifyCertNum, setVerifyCertNum] = useState(false); // 인증번호 확인 상태
  const [temporaryPw, setTemporaryPw] = useState(""); // 임시 비밀번호 상태
  const [comparisonPw, setComparisonPw] = useState(true); // 비밀번호 비교 상태
  const [pwRegexTest, setPwRegexTest] = useState(true); // 비밀번호 정규식 테스트 결과 상태

  // 서버 응답 메시지 상태
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // 비밀번호 재설정 화면 표시 상태
  const [isShowResetPw, setIsShowResetPw] = useState(false);

  // 비밀번호 재설정 완료 화면 표시 상태
  const [isShowCompleteResetPw, setIsShowCompleteResetPw] = useState(false);

  // 정규식 검사 함수
  const validateInput = (regex, value) => {
    return regex.test(value); // 통과하면 true, 실패하면 false 반환
  };

  // 입력 필드 핸들러
  const handleInputValueChange = (e) => {
    const { name, value } = e.target;

    setLoginValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    const storageCertNum = sessionStorage.getItem("certNum");

    const validateField = (name, value) => {
      switch (name) {
        case "phoneNumber":
          if (value !== "") {
            setPhoneNumberRegexTest(validateInput(regex.tel, value));
          } else {
            setPhoneNumberRegexTest(true);
          }
          break;
        case "certNum":
          if (value !== "") {
            setCertNumRegexTest(validateInput(regex.certNum, value));
            if (value === storageCertNum) {
              setVerifyCertNum(true);
            } else {
              setVerifyCertNum(false);
            }
          } else {
            setCertNumRegexTest(true);
          }
          break;
        case "currentPw":
          if (value !== "") {
            if (value === temporaryPw) {
              setComparisonPw(true);
            } else {
              setComparisonPw(false);
            }
          } else {
            setComparisonPw(false);
          }
          break;
        case "newPw":
          if (value !== "") {
            setPwRegexTest(validateInput(regex.pw, value));
          } else {
            setPwRegexTest(true);
          }
          break;
        default:
          console.error("Invalid field name");
      }
    };

    validateField(name, value);
  };

  // 인증번호 발송 함수
  const handleCertNumSent = async () => {
    try {
      // 존재하는 전화번호인지 확인
      await axios.post(`${url}auth/find/email`, {
        phoneNumber: removeNonNumeric(loginValue.phoneNumber),
      });

      // 존재한다면 인증번호 생성
      const response = await axios.post(`${url}auth/sms`, {
        phoneNumber: removeNonNumeric(loginValue.phoneNumber),
        content: "",
      });
      let certNum = response.data.data.authNumber;
      setStorage("certNum", certNum);
      setIsAlertOpen(true);
      setCertNumSent(true);
      setResponseMessage("인증번호가 발송되었습니다.");
      // 이전 입력된 인증번호 초기화
      setLoginValue((prevState) => ({
        ...prevState,
        certNum: "",
      }));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 500 || 401) {
          setIsAlertOpen(true); // 전송 실패 알림창 열기
          setResponseMessage("등록되지 않은 전화번호입니다.");
        } else {
          setIsAlertOpen(true); // 전송 실패 알림창 열기
          setResponseMessage("잘못된 요청입니다.");
        }
      } else {
        // 서버 응답이 없을 때 처리
        setIsAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // 사용자의 전화번호 입력란 변경으로 인하여 인증번호 발송 상태 false로 변경
  useEffect(() => {
    if (!phoneNumberRegexTest || loginValue.phoneNumber === "") {
      setCertNumSent(false);
      setVerifyCertNum(false);
    }
  }, [phoneNumberRegexTest, loginValue.phoneNumber]);

  // 인증 이후 사용자의 전화번호 입력란 변경으로 인하여 인증번호 확인 상태 false로 변경
  useEffect(() => {
    if (!certNumRegexTest || loginValue.certNum === "") {
      setVerifyCertNum(false);
    }
  }, [certNumRegexTest, loginValue.certNum]);

  // 전화번호 저장 상태
  const [savingPhoneNumber, setSavingPhoneNumber] = useState(false);

  // 비밀번호 찾기 전송 함수
  const handleFindPw = async (e) => {
    e.preventDefault();
    setSavingPhoneNumber(loginValue.phoneNumber);
    try {
      const response = await axios.post(`${url}auth/sms/password`, {
        phoneNumber: loginValue.phoneNumber,
      });
      setTemporaryPw(response.data.data.password);
      setLoginValue(initialLoginValues); // 입력값 초기화
      setIsShowResetPw(true); // 비밀번호 재설정 화면 표시
      setIsShowCompleteResetPw(false); // 비밀번호 재설정 완료 화면 비표시
      deleteStorage("certNum"); // 인증번호 세션 삭제
    } catch (error) {
      if (error.response.data.code === 40002) {
        setResponseMessage(error.response.data.message);
        setIsAlertOpen(true);
      } else {
        console.error(error);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
        setIsAlertOpen(true);
      }
    }
  };

  // 비밀번호 재설정 전송 함수
  const handleResetPw = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${url}auth/change/password`, {
        phoneNumber: String(savingPhoneNumber),
        oldPassword: loginValue.currentPw,
        newPassword: loginValue.newPw,
      });
      setLoginValue(initialLoginValues); // 입력값 초기화
      setSavingPhoneNumber(""); // 저장된 전화번호 초기화
      setIsShowCompleteResetPw(true); // 비밀번호 재설정 완료 화면 표시
    } catch (error) {
      console.error("에러 발생:", error);
      if (error.response) {
        console.error("응답 데이터: ", error.response.data);
        console.error("응답 상태 코드: ", error.response.status);
        console.error("응답 메시지: ", error.response.data.message);
      } else {
        console.error("서버에 연결할 수 없습니다.");
      }
    }
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 900 + elementHeight,
      }}
    >
      <Header />
      <BodyContainer
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        {!isShowResetPw && !isShowCompleteResetPw && (
          <FindPwContainer ref={elementRef} style={{ top: 302 }}>
            <FindPwTitleWrapper>
              <Title>비밀번호 찾기</Title>
              <SubTitle>
                가입한 전화번호를 입력해 주세요.
                <br />
                전화번호로 임시 비밀번호가 전송됩니다.
              </SubTitle>
            </FindPwTitleWrapper>
            <FindPwForm
              ref={form1}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {/* 전화번호 입력 필드 영역 */}
              <InputBox
                $uniqueKey="phoneNumber"
                label="전화번호"
                type="number"
                placeholder="전화번호를 입력해 주세요."
                $isRequired
                value={loginValue.phoneNumber}
                onChange={handleInputValueChange}
                $isError={!phoneNumberRegexTest}
                $errorMsg="전화번호 형식이 올바르지 않습니다."
                $isShowBtn={true}
                $btnText={certNumSent ? "재발송" : "인증하기"}
                $btnDisabled={
                  (!phoneNumberRegexTest && loginValue.phoneNumber !== "") ||
                  loginValue.phoneNumber === "" ||
                  verifyCertNum
                }
                $btnHandler={handleCertNumSent}
              />

              {/* 인증번호 입력 필드 영역 */}
              {certNumSent && phoneNumberRegexTest && (
                <InputBox
                  isNoLabel
                  $uniqueKey="certNum"
                  type="number"
                  placeholder="인증번호를 입력해 주세요."
                  value={loginValue.certNum}
                  onChange={handleInputValueChange}
                  $isError={!certNumRegexTest}
                  $errorMsg="인증번호 6자리를 올바르게 입력해 주세요."
                  $isHelp={
                    verifyCertNum ||
                    (loginValue.certNum !== "" &&
                      certNumRegexTest &&
                      !verifyCertNum)
                  }
                  $helpMsg={
                    certNumRegexTest && !verifyCertNum
                      ? "인증번호가 불일치합니다."
                      : "인증번호가 일치합니다."
                  }
                  readOnly={verifyCertNum}
                  disabled={verifyCertNum}
                />
              )}

              {/* 알림창 */}
              {isAlertOpen && (
                <Alert
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  onClick={() => {
                    setIsAlertOpen(false);
                  }}
                >
                  {responseMessage}
                </Alert>
              )}
            </FindPwForm>
            {/* 이메일 전송 버튼 */}
            <PrimaryBtn
              disabled={!verifyCertNum}
              type="submit"
              onClick={handleFindPw}
            >
              임시 비밀번호 전송하기
            </PrimaryBtn>
          </FindPwContainer>
        )}

        {/* 이메일 찾기 결과 화면 */}
        {isShowResetPw && !isShowCompleteResetPw && (
          <FindPwContainer ref={elementRef} style={{ top: 199 }}>
            <FindPwTitleWrapper>
              <Title>비밀번호 재설정</Title>
              <SubTitle>
                비밀번호는 8자 이상 16자 이하의 문자, 숫자, 특수기호중
                <br />
                2가지 이상을 조합하여 만들어야 합니다.
              </SubTitle>
            </FindPwTitleWrapper>
            <FindPwForm ref={form2} onSubmit={handleResetPw}>
              {/* Input 영역 */}
              <InputBox
                $uniqueKey="currentPw"
                label="비밀번호"
                type="password"
                placeholder="현재 비밀번호를 입력해 주세요."
                $isRequired
                value={loginValue.currentPw}
                onChange={handleInputValueChange}
                $isError={!comparisonPw}
                $errorMsg="비밀번호가 일치하지 않습니다."
              />

              <InputBox
                $uniqueKey="newPw"
                label="새 비밀번호"
                type="password"
                placeholder="새 비밀번호를 입력해 주세요."
                $isRequired
                value={loginValue.newPw}
                onChange={handleInputValueChange}
                $isError={
                  (!pwRegexTest && loginValue.newPw !== "") ||
                  (loginValue.newPw !== "" &&
                    loginValue.currentPw === loginValue.newPw)
                }
                $errorMsg={
                  loginValue.newPw !== "" &&
                  loginValue.currentPw === loginValue.newPw
                    ? "이전 비밀번호와 다른 비밀번호를 사용해 주세요."
                    : "비밀번호는 8자 이상 16자 이내 문자/영문/특수기호를 사용하여야 합니다."
                }
              />

              <InputBox
                $uniqueKey="newPwCheck"
                label="새 비밀번호 확인"
                type="password"
                placeholder="새 비밀번호를 한 번 더 입력해 주세요."
                $isRequired
                value={loginValue.newPwCheck}
                onChange={handleInputValueChange}
                $isError={
                  loginValue.newPwCheck !== "" &&
                  loginValue.newPw !== loginValue.newPwCheck
                }
                $errorMsg="비밀번호가 일치하지 않습니다."
              />

              {/* 알림창 */}
              {isAlertOpen && (
                <Alert
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  onClick={() => {
                    setIsAlertOpen(false);
                  }}
                >
                  {responseMessage}
                </Alert>
              )}
            </FindPwForm>
            <PrimaryBtn
              disabled={
                !(
                  comparisonPw === true &&
                  pwRegexTest === true &&
                  loginValue.currentPw !== "" &&
                  loginValue.newPw !== "" &&
                  loginValue.newPwCheck !== "" &&
                  loginValue.currentPw !== loginValue.newPw &&
                  loginValue.newPw === loginValue.newPwCheck
                )
              }
              type="submit"
              onClick={handleResetPw}
            >
              비밀번호 재설정하기
            </PrimaryBtn>
          </FindPwContainer>
        )}

        {/* 비밀번호 재설정 완료 화면 */}
        {isShowCompleteResetPw && (
          <FindPwContainer ref={elementRef} style={{ top: 373 }}>
            <FindPwTitleWrapper>
              <Title>비밀번호 재설정 완료</Title>
              <SubTitle>
                회원님의 비밀번호가 정상적으로 변경되었습니다.
              </SubTitle>
            </FindPwTitleWrapper>
            <PrimaryBtn
              type="button"
              onClick={() => {
                navigate("/login");
              }}
            >
              로그인 하기
            </PrimaryBtn>
          </FindPwContainer>
        )}

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default FindPw;

const FindPwContainer = styled.section`
  position: absolute;
  width: 430px;
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

const FindPwTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FindPwForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;
