import axios from "axios";
import { atom, selector } from "recoil";
import { url } from "../lib/api/api";

export const baseInfoState = atom({
  key: "baseInfoState",
  default: selector({
    key: "baseInfoState/Default",
    get: async () => {
      try {
        const response = await axios.get(`${url}base-info`);

        return response.data.data.content;
      } catch (error) {
        console.error(error);
        return {};
      }
    },
  }),
});
