import React from "react";
import styled from "styled-components";

const SecondaryMiniBtn = (props) => {
  return (
    <StyledSecondaryMiniBtn {...props}>{props.children}</StyledSecondaryMiniBtn>
  );
};

export default SecondaryMiniBtn;

const StyledSecondaryMiniBtn = styled.button`
  width: fit-content;
  padding: ${(props) => (props.$isSmall ? "5px 10px" : "8px 20px")};
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-size: ${(props) => (props.$isSmall ? "14px" : "16px")};
  font-weight: 600;
  line-height: 145%;
  background-color: #fff;
  color: #282828;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  cursor: pointer;

  &:disabled {
    background-color: #c0c0c0;
    color: #939393;
    cursor: not-allowed;
  }
`;
