const callChatGPT = async (prompt) => {
  const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiKey}`,
    },
    body: JSON.stringify({
      model: "gpt-3.5-turbo",
      messages: [{ role: "user", content: `${prompt}` }],
      temperature: 0.5,
      max_tokens: 1000,
    }),
  });

  const responseData = await response.json();

  return responseData;
};

export default callChatGPT;
