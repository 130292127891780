import api from "./api";

export default class ServiceApi {
  static async getServiceList(data) {
    return await api.get("merches", data);
  }
  static async getById(merchId) {
    return await api.get(`merch/${merchId}`);
  }
}
