import styled from "styled-components";
import Title from "../../../../components/atoms/Title";
import BodyContainer from "../../../../components/molecules/layout/BodyContainer";
import Header from "../../../../components/organisms/Header";
import useResizeHeight from "../../../../hooks/useResizeHeight";
import Footer from "../../../../components/organisms/Footer";
import PretendardText from "../../../../components/atoms/PretendardText";
import { useRecoilValue } from "recoil";
import React, { useEffect, useState } from "react";
import {
  deleteStorage,
  getStorage,
} from "../../../../lib/utils/sessionStorage";
import axios from "axios";
import { url } from "../../../../lib/api/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { myInfoValueState } from "../../../../store/myInfo";
import CompanySideBar from "../_components/CompanySideBar";
import PageContainer from "../../../../components/molecules/layout/PageContainer";
import scrollToTop from "../../../../lib/utils/scrollToTop";
import SecondaryMiniBtn from "../../../../components/atoms/buttons/SecondaryMiniBtn";
import Confirm from "../../../../components/molecules/Confirm";
import { formatDate } from "../../../../lib/utils/formatDate";
import Alert from "../../../../components/molecules/Alert";
import route from "../../../../router/route";

const CompanyAskDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    if (myInfo.id === 0 || !myInfo) {
      return;
    } else {
      if (myInfo.profile) {
        getTokens();
      } else {
        navigate("/");
      }
    }
  }, [myInfo]); // eslint-disable-line

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    scrollToTop();
    if (token !== "") {
      getAskData();
    }
  }, [token, location]); // eslint-disable-line

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // 알림창 관련 상태
  const [alertOpen, setAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // ------------------------------------ //
  // ---------- 문의하기 영역 ---------- //
  // ---------------------------------- //

  // 단일 문의하기 데이터 가져오기
  const [askData, setAskData] = useState({});

  const getAskData = async () => {
    try {
      const response = await axios.get(`${url}member/ask/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setAskData(response.data.data.content);
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // 문의 삭제하기
  const [delAskBtnClicked, setDelAskBtnClicked] = useState(false);

  const deleteAsk = async (delAskId) => {
    try {
      let data = {
        ids: [delAskId],
      };
      await axios.delete(`${url}member/asks`, {
        data: data,
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      navigate(`${route.myPage_company_ask}`);
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 550 + elementHeight,
      }}
    >
      <Header $isBgWhite={true} />
      <BodyContainer
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        <MyPageContainer ref={elementRef}>
          <Title>마이페이지</Title>
          <MyPageInnerContainerWrapper>
            <CompanySideBar />
            <MyPageContentsContainer>
              <AskDetailContainer>
                <AskDetailTitleWrapper>
                  <AskDetailTitleText>{askData.title}</AskDetailTitleText>
                  <AskDetailDateText>
                    {formatDate(askData.createdAt)}
                  </AskDetailDateText>
                </AskDetailTitleWrapper>

                <AskDetailContentTextWrapper>
                  <AskDetailContentText>{askData.content}</AskDetailContentText>
                </AskDetailContentTextWrapper>

                <AskDetailReplyWrapper>
                  <AskDetailReplyTitle>
                    {askData.answer !== "" ? "답변 내용" : "답변 대기"}
                  </AskDetailReplyTitle>
                  <AskDetailReplyContent>
                    {askData.answer !== "" ? askData.answer : ""}
                  </AskDetailReplyContent>
                </AskDetailReplyWrapper>
                <AskDetailBtnWrapper>
                  <SecondaryMiniBtn
                    onClick={() => {
                      setDelAskBtnClicked(true);
                    }}
                  >
                    삭제하기
                  </SecondaryMiniBtn>
                  {delAskBtnClicked && (
                    <Confirm
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                      cancelText="아니요"
                      cancelEvent={() => {
                        setDelAskBtnClicked(false);
                      }}
                      confirmText="예"
                      confirmEvent={() => {
                        deleteAsk(askData.id);
                      }}
                    >
                      삭제하시겠습니까?
                      <br />
                      삭제한 내용은 복구가 불가능합니다.
                    </Confirm>
                  )}
                  <SecondaryMiniBtn
                    type="button"
                    onClick={() => {
                      navigate(`${route.myPage_company_ask}`);
                      scrollToTop();
                    }}
                  >
                    목록으로
                  </SecondaryMiniBtn>
                </AskDetailBtnWrapper>
              </AskDetailContainer>

              {alertOpen && (
                <Alert
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAlertOpen(false);
                  }}
                >
                  {responseMessage}
                </Alert>
              )}
            </MyPageContentsContainer>
          </MyPageInnerContainerWrapper>
        </MyPageContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default CompanyAskDetail;

const MyPageContainer = styled.div`
  position: absolute;
  top: 165px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MyPageInnerContainerWrapper = styled.div`
  display: flex;
  gap: 31px;
  width: 100%;
  margin-top: 30px;
`;

const MyPageContentsContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MyInfoEditBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
`;

const AskDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 892px;
`;

const AskDetailTitleWrapper = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #edeff1;
`;

const AskDetailTitleText = styled(PretendardText)`
  font-size: 16px;
  font-weight: 700;
  line-height: 145%;
  color: #282828;
`;

const AskDetailDateText = styled(PretendardText)`
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: #939393;
`;

const AskDetailContentTextWrapper = styled.div`
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 23px;
`;

const AskDetailContentText = styled(PretendardText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #383838;
`;

const AskDetailReplyWrapper = styled.div`
  background-color: #edeff1;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AskDetailReplyTitle = styled(PretendardText)`
  font-size: 16px;
  font-weight: 600;
  line-height: 145%;
  color: #282828;
`;

const AskDetailReplyContent = styled(PretendardText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: #6f6f6f;
`;

const AskDetailBtnWrapper = styled(MyInfoEditBtnWrapper)`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;
