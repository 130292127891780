import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Title from "../../../../components/atoms/Title";
import BodyContainer from "../../../../components/molecules/layout/BodyContainer";
import Header from "../../../../components/organisms/Header";
import useResizeHeight from "../../../../hooks/useResizeHeight";
import Footer from "../../../../components/organisms/Footer";
import PretendardText from "../../../../components/atoms/PretendardText";
import { useRecoilValue } from "recoil";
import {
  deleteStorage,
  getStorage,
} from "../../../../lib/utils/sessionStorage";
import axios from "axios";
import { url } from "../../../../lib/api/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { myInfoValueState } from "../../../../store/myInfo";
import PageContainer from "../../../../components/molecules/layout/PageContainer";
import Alert from "../../../../components/molecules/Alert";
import { formatRatingNumber } from "../../../../lib/utils/formatNumber";
import CommonSideBar from "../_components/CommonSideBar";
import { formatDate } from "./../../../../lib/utils/formatDate";
import ReviewGradeIcon from "./../../../../components/atoms/icons/ReviewGradeIcon";
import SecondaryMiniBtn from "./../../../../components/atoms/buttons/SecondaryMiniBtn";
import Confirm from "./../../../../components/molecules/Confirm";
import route from "../../../../router/route";

const CommonMyReviewDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    if (myInfo.id === 0 || !myInfo) {
      return;
    } else {
      getTokens();
    }
  }, [myInfo]);

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    if (token !== "") {
      getReviewData();
    }
  }, [token, location]); // eslint-disable-line

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // 알림창 관련 상태
  const [alertOpen, setAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // ------------------------------------------- //
  // ---------- 리뷰 데이터 가져오기 ---------- //
  // ----------------------------------------- //
  const [reviewData, setReviewData] = useState({});

  const getReviewData = async () => {
    try {
      const response = await axios.get(`${url}member/review/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setReviewData(response.data.data.content);
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // 리뷰 삭제하기
  const [delReviewBtnClicked, setDelReviewBtnClicked] = useState(false);

  const deleteReview = async (delReviewId) => {
    try {
      let data = {
        ids: [delReviewId],
      };
      await axios.delete(`${url}member/reviews`, {
        data: data,
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      navigate(`${route.myPage_common_myReview}`);
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 550 + elementHeight,
      }}
    >
      <Header $isBgWhite={true} />
      <BodyContainer
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        <MyPageContainer ref={elementRef}>
          <Title>마이페이지</Title>
          <MyPageInnerContainerWrapper>
            <CommonSideBar />

            <MyPageContentsContainer>
              <MyReviewDetailContainer>
                <MyReviewDetailContentWrapper>
                  <MyReviewDetailReviewGradeWrapper>
                    <ReviewGradeIconWrapper>
                      <ReviewGradeIcon
                        $isChecked={
                          String(reviewData.rating).startsWith("1") ||
                          String(reviewData.rating).startsWith("2") ||
                          String(reviewData.rating).startsWith("3") ||
                          String(reviewData.rating).startsWith("4") ||
                          String(reviewData.rating).startsWith("5")
                        }
                        style={{ width: 22, height: 22 }}
                      />
                      <ReviewGradeIcon
                        $isChecked={
                          String(reviewData.rating).startsWith("2") ||
                          String(reviewData.rating).startsWith("3") ||
                          String(reviewData.rating).startsWith("4") ||
                          String(reviewData.rating).startsWith("5")
                        }
                        style={{ width: 22, height: 22 }}
                      />
                      <ReviewGradeIcon
                        $isChecked={
                          String(reviewData.rating).startsWith("3") ||
                          String(reviewData.rating).startsWith("4") ||
                          String(reviewData.rating).startsWith("5")
                        }
                        style={{ width: 22, height: 22 }}
                      />
                      <ReviewGradeIcon
                        $isChecked={
                          String(reviewData.rating).startsWith("4") ||
                          String(reviewData.rating).startsWith("5")
                        }
                        style={{ width: 22, height: 22 }}
                      />
                      <ReviewGradeIcon
                        $isChecked={String(reviewData.rating).startsWith("5")}
                        style={{ width: 22, height: 22 }}
                      />
                    </ReviewGradeIconWrapper>
                    <ReviewGradePointText>
                      {formatRatingNumber(String(reviewData.rating))}
                    </ReviewGradePointText>
                  </MyReviewDetailReviewGradeWrapper>
                  <MyReviewDetailContent>
                    {reviewData.content}
                  </MyReviewDetailContent>
                  <MyReviewContent
                    style={{
                      color: "#939393",
                      fontSize: 14,
                      lineHeight: "140%",
                    }}
                  >
                    {formatDate(reviewData.createdAt)}
                  </MyReviewContent>
                </MyReviewDetailContentWrapper>
                <MyReviewDetailCompanyWrapper>
                  <MyReviewDetailCompanyTextWrapper>
                    <MyReviewDetailCompanyText>
                      기업명
                    </MyReviewDetailCompanyText>
                    <MyReviewDetailCompanyTextVerticalLine>
                      {" "}
                      ㅣ{" "}
                    </MyReviewDetailCompanyTextVerticalLine>
                    <MyReviewDetailCompanyText>
                      {reviewData.companyName}
                    </MyReviewDetailCompanyText>
                  </MyReviewDetailCompanyTextWrapper>
                  <MyReviewDetailCompanyTextWrapper>
                    <MyReviewDetailCompanyText>
                      결제일
                    </MyReviewDetailCompanyText>
                    <MyReviewDetailCompanyTextVerticalLine>
                      {" "}
                      ㅣ{" "}
                    </MyReviewDetailCompanyTextVerticalLine>
                    <MyReviewDetailCompanyText>
                      {formatDate(reviewData.payDate)}
                    </MyReviewDetailCompanyText>
                  </MyReviewDetailCompanyTextWrapper>
                </MyReviewDetailCompanyWrapper>
                <MyInfoEditBtnWrapper
                  style={{
                    marginTop: 40,
                    alignSelf: "flex-end",
                  }}
                >
                  <SecondaryMiniBtn
                    type="button"
                    onClick={() => {
                      setDelReviewBtnClicked(true);
                    }}
                  >
                    삭제하기
                  </SecondaryMiniBtn>
                  {delReviewBtnClicked && (
                    <Confirm
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                      cancelText="아니요"
                      cancelEvent={() => {
                        setDelReviewBtnClicked(false);
                      }}
                      confirmText="예"
                      confirmEvent={() => {
                        deleteReview(reviewData.id);
                        setDelReviewBtnClicked(false);
                      }}
                    >
                      삭제하시겠습니까?
                      <br />
                      삭제한 내용은 복구가 불가능합니다.
                    </Confirm>
                  )}
                  <SecondaryMiniBtn
                    onClick={() => {
                      navigate(-1);
                    }}
                    type="button"
                  >
                    목록으로
                  </SecondaryMiniBtn>
                </MyInfoEditBtnWrapper>
              </MyReviewDetailContainer>

              {alertOpen && (
                <Alert
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAlertOpen(false);
                  }}
                >
                  {responseMessage}
                </Alert>
              )}
            </MyPageContentsContainer>
          </MyPageInnerContainerWrapper>
        </MyPageContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default CommonMyReviewDetail;

const MyPageContainer = styled.div`
  position: absolute;
  top: 165px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MyPageInnerContainerWrapper = styled.div`
  display: flex;
  gap: 31px;
  width: 100%;
  margin-top: 30px;
`;

const MyPageContentsContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MyInfoEditBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
`;

const MyReviewContent = styled(PretendardText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: #282828;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
`;

const MyReviewDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 892px;
`;

const MyReviewDetailContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  padding: 20px;
  gap: 15px;
  margin-bottom: 20px;
  background-color: #fff;
`;

const MyReviewDetailReviewGradeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ReviewGradeIconWrapper = styled.div`
  display: flex;
`;

const ReviewGradePointText = styled(PretendardText)`
  font-size: 16px;
  font-weight: 600;
  line-height: 145%;
  color: #282828;
`;

const MyReviewDetailContent = styled(PretendardText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: #383838;
`;

const MyReviewDetailCompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  gap: 10px;
  background-color: #f2f2f2;
  border-radius: 4px;
`;

const MyReviewDetailCompanyTextWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const MyReviewDetailCompanyText = styled(PretendardText)`
  color: #282828;
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  display: contents;
`;

const MyReviewDetailCompanyTextVerticalLine = styled(PretendardText)`
  display: contents;
  color: #c0c0c0;
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
`;
