import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import PretendardText from "./../atoms/PretendardText";
import TextLogo from "../atoms/TextLogo";
import {
  deleteStorage,
  getStorage,
  setStorage,
} from "../../lib/utils/sessionStorage";
import { useRecoilState } from "recoil";
import {
  initialRegisterCheckedValues,
  initialRegisterInputValues,
  registerCheckedValueState,
  registerInputValueState,
} from "../../store/registerStore";
import { initialLoginValues, loginValueState } from "../../store/loginStore";
import scrollToTop from "../../lib/utils/scrollToTop";
import ProfileIcon from "../atoms/icons/ProfileIcon";
import SelectList from "./../molecules/SelectList";
import SelectOption from "./../atoms/SelectOption";
import DivisionLine from "./../atoms/DivisionLine";
import DownArrowIcon from "../atoms/icons/DownArrowIcon";
import { myInfoValueState } from "../../store/myInfo";
import axios from "axios";
import { url } from "../../lib/api/api";
import { fileUrl } from "./../../lib/api/api";
import route from "../../router/route";

const Header = (props) => {
  const [token, setToken] = useState("");
  const [myInfo, setMyInfo] = useRecoilState(myInfoValueState);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    if (token) {
      const fetchMyInfo = async () => {
        const storedMyInfo = await getStorage("myInfo");
        if (storedMyInfo) {
          setMyInfo(storedMyInfo);
          setIsLogin(true);
        } else {
          getMyInfo();
        }
      };
      fetchMyInfo();
    } else {
      getTokens();
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getMyInfo = async () => {
    try {
      const response = await axios.get(`${url}member/my-info`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setMyInfo(response.data.data.content);
      setIsLogin(true);
      setStorage("myInfo", response.data.data.content);
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      }
    }
  };

  const [isSticky, setIsSticky] = useState(false);
  const [isLoginRotated, setIsLoginRotated] = useState(false);

  // 로그아웃 이후 로그인/회원가입 페이지로 이동 시 초기화
  const [, setLoginValue] = useRecoilState(loginValueState);
  const [, setRegisterInputValue] = useRecoilState(registerInputValueState);
  const [, setRegisterCheckedValue] = useRecoilState(registerCheckedValueState);

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const isSticky = window.scrollY > 85;
      setIsSticky(isSticky);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // 로그아웃
  const handleLogout = () => {
    deleteStorage("email");
    deleteStorage("current");
    deleteStorage("token");
    deleteStorage("myInfo");
    setMyInfo(null);
    setIsLogin(false);
    navigate("/");
    scrollToTop();
  };

  return (
    <HeaderContainer
      style={{
        position: isSticky ? "fixed" : "absolute",
        top: isSticky ? 0 : "auto",
        backgroundColor: isSticky || props.$isBgWhite ? "#fff" : "transparent",
        borderBottom: isSticky ? "1px solid #e9e9e9" : "none",
        ...props.style,
      }}
      $isWhite={props.isWhite}
    >
      <HeaderInnerContainer>
        <Link
          to="/"
          style={{
            position: "absolute",
            top: 24,
            left: 41,
          }}
        >
          <TextLogo>Alting</TextLogo>
        </Link>

        {/* 추후 링크 연결 필요 */}
        <NavigationWrapper>
          {!myInfo?.profile ? (
            <>
              <Link to="/around" onClick={scrollToTop}>
                <HeaderText
                  $isWhite={props.isWhite}
                  style={{
                    color: isSticky && "#282828",
                  }}
                >
                  둘러보기
                </HeaderText>
              </Link>
              <Link to="/curation" onClick={scrollToTop}>
                <HeaderText
                  $isWhite={props.isWhite}
                  style={{
                    color: isSticky && "#282828",
                  }}
                >
                  큐레이션
                </HeaderText>
              </Link>
            </>
          ) : (
            <Link to="/service" onClick={scrollToTop}>
              <HeaderText
                $isWhite={props.isWhite}
                style={{
                  color: isSticky && "#282828",
                }}
              >
                서비스 관리
              </HeaderText>
            </Link>
          )}

          {!isLogin ? (
            <Link to="/login">
              <PretendardText
                style={{
                  color: "#2ec5f6",
                  backgroundColor: "#fff",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "auto",
                  padding: "10px 15px",
                  border: "1px solid #2ec5f6",
                  borderRadius: 4,
                }}
                onClick={() => {
                  scrollToTop();
                  setLoginValue(initialLoginValues);
                  setRegisterInputValue(initialRegisterInputValues);
                  setRegisterCheckedValue(initialRegisterCheckedValues);
                }}
              >
                로그인/회원가입
              </PretendardText>
            </Link>
          ) : (
            // 로그인 후 변경될 컴포넌트
            <LoginSuccessWrapper>
              <div
                style={{
                  display: "inline-flex",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <HeaderText
                  $isWhite={props.isWhite}
                  style={{
                    color: isSticky && "#282828",
                  }}
                >
                  {myInfo?.profile
                    ? `${myInfo.profile.companyName}님`
                    : `${myInfo.name}님`}
                </HeaderText>
                <DownArrowIcon
                  style={{
                    transform: isLoginRotated
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsLoginRotated((prevState) => !prevState);
                  }}
                />

                <SelectList
                  style={{
                    top: 30,
                    right: 41,
                    width: 135,
                    display: isLoginRotated ? "block" : "none",
                  }}
                >
                  <SelectOption
                    onClick={() => {
                      if (myInfo.profile) {
                        navigate(`${route.myPage_company_info}`);
                      } else {
                        navigate(`${route.myPage_common_info}`);
                      }

                      setIsLoginRotated((prevState) => !prevState);
                    }}
                  >
                    마이페이지
                  </SelectOption>
                  <DivisionLine
                    style={{
                      margin: "12px 0",
                    }}
                  />
                  <SelectOption
                    onClick={() => {
                      handleLogout();
                      setIsLoginRotated((prevState) => !prevState);
                    }}
                  >
                    로그아웃
                  </SelectOption>
                  {/* {Object.keys(loginOptions).map((key) => (
                    <>
                      <SelectOption
                        key={key}
                        onClick={() => {
                          if (key === "logout") {
                            handleLogout();
                          } else {
                            navigate("/myPage");
                          }
                          setIsLoginRotated((prevState) => !prevState);
                        }}
                      >
                        {loginOptions[key]}
                      </SelectOption>
                      <DivisionLine
                        style={{
                          margin: "12px 0",
                        }}
                      />
                    </>
                  ))} */}
                </SelectList>
              </div>
              <ProfileIcon
                style={{
                  width: 36,
                  height: 36,
                }}
                src={
                  myInfo.profileServerFileName !== ""
                    ? `${fileUrl}/${myInfo.profileServerFileName}`
                    : ""
                }
              />
            </LoginSuccessWrapper>
          )}
        </NavigationWrapper>
      </HeaderInnerContainer>
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.header`
  position: "absolute";
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: ${(props) => (props.$isWhite ? "transparent" : "#fff")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderInnerContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1280px;
  height: 85px;
  display: flex;
  justify-content: space-between;
`;

const NavigationWrapper = styled.div`
  position: absolute;
  top: 23px;
  right: 37px;
  height: 39px;
  display: flex;
  align-items: center;
  gap: 25px;
`;

const LoginSuccessWrapper = styled.div`
  display: inline-flex;
  gap: 5px;
  align-items: center;
`;

const HeaderText = styled(PretendardText)`
  color: ${(props) => (props.$isWhite ? "#fff" : "#282828")};
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
`;
