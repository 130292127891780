import React, { useEffect, useMemo, useState } from "react"; // eslint-disable-line
import PageContainer from "../../components/molecules/layout/PageContainer";
import BodyContainer from "../../components/molecules/layout/BodyContainer";
import Header from "../../components/organisms/Header";
import Footer from "../../components/organisms/Footer";
import styled from "styled-components";
import PretendardText from "../../components/atoms/PretendardText";
import SortedResultBoldText from "../../components/molecules/SortedResultBoldText";
import TopBanner from "./../../components/molecules/TopBanner";
import SearchBar from "../../components/molecules/SearchBar";
import useResizeHeight from "../../hooks/useResizeHeight";
import DownArrowIcon from "../../components/atoms/icons/DownArrowIcon";
import SelectList from "./../../components/molecules/SelectList";
import SelectOption from "../../components/atoms/SelectOption";
import DivisionLine from "../../components/atoms/DivisionLine";
import PrimaryBtn from "./../../components/atoms/buttons/PrimaryBtn";
import HomeIcon from "../../components/atoms/icons/HomeIcon";
import LocationIcon from "../../components/atoms/icons/LocationIcon";
import PersonIcon from "../../components/atoms/icons/PersonIcon";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { myInfoValueState } from "../../store/myInfo";
import { deleteStorage, getStorage } from "../../lib/utils/sessionStorage";
import route from "../../router/route"; // eslint-disable-line
import axios from "axios";
import { url } from "../../lib/api/api";
import { fileUrl } from "./../../lib/api/api";
import { formatDate } from "./../../lib/utils/formatDate";
import Pagination from "./../../components/molecules/Pagination";
import Alert from "../../components/molecules/Alert";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Curation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
  const [keyword, setKeyword] = useState("");
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //

  // 기업 회원일 경우 홈으로 이동
  useEffect(() => {
    if (myInfo?.profile) {
      return navigate("/", { replace: true });
    }

    getTokens();
  }, [myInfo]); // eslint-disable-line

  // 토근이 있어야지만 API 요청 진행
  useEffect(() => {
    if (token !== "") {
      setKeyword(
        searchParams.get("keyword") ? searchParams.get("keyword") : ""
      );

      setCurationSelectValue((prevState) => ({
        ...prevState,
        region: searchParams.get("region") ? searchParams.get("region") : "",
        regionTitle: searchParams.get("region")
          ? regionList.find((item) => item.value === searchParams.get("region"))
              .title
          : "",
        sector: searchParams.get("sector") ? searchParams.get("sector") : "",
        sectorTitle: searchParams.get("sector")
          ? searchParams.get("sector")
            ? sectorList.find(
                (item) => item.value === searchParams.get("sector")
              ).title
            : ""
          : "",
        age: searchParams.get("age") ? searchParams.get("age") : "",
        ageTitle: searchParams.get("age")
          ? searchParams.get("age")
            ? ageList.find((item) => item.value === searchParams.get("age"))
                .title
            : ""
          : "",

        sort: searchParams.get("sort") ? searchParams.get("sort") : "id",
      }));

      getCurationList();
    }
  }, [location, token]); // eslint-disable-line

  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 3, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // 알림창 관련 상태
  const [alertOpen, setAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // ----------------------------------------------- //
  // ---------- 큐레이션 데이터 가져오기 ---------- //
  // --------------------------------------------- //
  const [curationList, setCurationList] = useState([]); // 큐레이션 목록
  const [curationSelectValue, setCurationSelectValue] = useState({
    region: "",
    regionTitle: "",
    sector: "",
    sectorTitle: "",
    age: "",
    ageTitle: "",
    sort: "id",
  });

  const getCurationList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword")
          ? searchParams.get("keyword")
          : undefined,
        page: searchParams.get("page") ? searchParams.get("page") : 1,
        size: pagination.size,
        sector: searchParams.get("sector")
          ? searchParams.get("sector")
          : curationSelectValue.sector,
        region: searchParams.get("region")
          ? searchParams.get("region")
          : curationSelectValue.region,
        age: searchParams.get("age")
          ? searchParams.get("age")
          : curationSelectValue.age,
        sort: searchParams.get("sort") ? searchParams.get("sort") : undefined,
        orderBy: "DESC",
      };
      const response = await axios.get(`${url}curations`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        params: data,
      });
      setCurationList(response.data.data.content);
      setPagination({
        ...pagination,
        totalCount: response.data.data.totalCount
          ? response.data.data.totalCount
          : response.data.data.content.length,
        totalPages: response.data.data.totalPage
          ? response.data.data.totalPage
          : Math.ceil(
              response.data.data.totalCount
                ? response.data.data.totalCount / 3
                : response.data.data.content.length / 3
            ),
      });
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // ---------------------------------- //
  // ---------- 셀렉트 박스 ---------- //
  // -------------------------------- //

  // 셀렉트 박스 핸들러
  const handleSelectValueChange = async (e) => {
    const { name, value } = e.target;
    setCurationSelectValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // useEffect(() => {
  //   getCurationList();
  // }, [
  //   curationSelectValue.region,
  //   curationSelectValue.sector,
  //   curationSelectValue.age,
  // ]);

  // DownArrowIcon 회전 관련
  const [isRegionRotated, setIsRegionRotated] = useState(false);
  const [isSectorRotated, setIsSectorRotated] = useState(false);
  const [isAgeRotated, setIsAgeRotated] = useState(false);
  const [isSortRotated, setIsSortRotated] = useState(false);

  // 지역 리스트
  const regionList = [
    { title: "지역을 선택해 주세요.", value: "" },
    { title: "서울", value: "SEOUL" },
    { title: "경기", value: "GYEONGGI" },
    { title: "인천", value: "INCHEON" },
    { title: "강원", value: "GANGWON" },
    { title: "충북", value: "CHUNGBUK" },
    { title: "충남", value: "CHUNGNAM" },
    { title: "대전", value: "DAEJEON" },
    { title: "세종", value: "SEJONG" },
    { title: "전북", value: "JEONBUK" },
    { title: "전남", value: "JEONNAM" },
    { title: "광주", value: "GWANGJU" },
    { title: "경북", value: "GYEONGBUK" },
    { title: "경남", value: "GYEONGNAM" },
    { title: "대구", value: "DAEGU" },
    { title: "울산", value: "ULSAN" },
    { title: "부산", value: "BUSAN" },
    { title: "제주", value: "JEJU" },
  ];

  // 업종 리스트
  const sectorList = [
    { title: "업종을 선택해 주세요.", value: "" },
    { title: "음식점업", value: "RESTAURANT" },
    { title: "부동산업", value: "REAL_ESTATE" },
    { title: "전문, 과학 및 기술 서비스업", value: "TECHNOLOGY" },
    { title: "통신판매업", value: "ONLINE" },
    { title: "예술, 스포츠 및 여가 관련 서비스업", value: "ART" },
    { title: "교육 서비스업", value: "EDUCATION" },
    { title: "수리 및 개인 서비스업", value: "PERSONAL" },
  ];

  // 연령대 리스트
  const ageList = [
    { title: "연령대를 선택해 주세요.", value: "" },
    { title: "20세 이상 ~ 29세 이하", value: "TWENTY" },
    { title: "30세 이상 ~ 39세 이하", value: "THIRTY" },
    { title: "40세 이상 ~ 49세 이하", value: "FORTY" },
    { title: "50대 이상", value: "FIFTY" },
  ];

  // 정렬 방식 리스트
  const sortingList = [
    { title: "최신순", value: "id" },
    { title: "조회순", value: "viewCount" },
  ];

  const sortTitle = useMemo(() => {
    return sortingList.find((item) => item.value === curationSelectValue.sort)
      .title;
  }, [curationSelectValue.sort]); // eslint-disable-line

  const getQueryString = (keyword, sort, region, sector, age) => {
    let query = `${route.curation}?page=1`;

    if (keyword) {
      query += `&keyword=${keyword == null ? "" : keyword}`;
    }

    if (sort) {
      query += `&sort=${sort == null ? "" : sort}`;
    }

    if (region) {
      query += `&region=${region == null ? "" : region}`;
    }

    if (sector) {
      query += `&sector=${sector == null ? "" : sector}`;
    }

    if (age) {
      query += `&age=${age == null ? "" : age}`;
    }

    return query;
  };

  // --------------------------------------- //
  // ---------- 디데이 계산 함수 ---------- //
  // ------------------------------------- //

  const calculateDday = (date) => {
    const targetDate = new Date(date);
    const currentDate = new Date();

    const differenceInMilliseconds = targetDate - currentDate;
    const differenceInDays = Math.ceil(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );

    if (differenceInDays > 0) {
      return `D-${differenceInDays}`;
    } else if (differenceInDays === 0) {
      return "D-0";
    } else {
      return "종료";
    }
  };

  // --------------------------------------- //
  // ---------- 링크 포맷팅 함수 ---------- //
  // ------------------------------------- //
  const formatLink = (link) => {
    if (!link.startsWith("http://") && !link.startsWith("https://")) {
      return `https://${link}`; // www.naver.com와 같이 www로 시작하는 경우 앞에 https://를 붙여줌
    }
    return link;
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 800 + elementHeight,
      }}
    >
      <Header />
      <BodyContainer>
        <TopBanner
          $title="큐레이션"
          $text="알팅이 소개하는 지원사업을 확인해 보세요."
          src="/assets/imgs/curation/banner.jpg"
          style={{
            backgroundPosition: "50% 70%",
          }}
        />
        <CurationContainer ref={elementRef}>
          <CurationInnerContainer>
            <SearchBar
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  navigate(
                    getQueryString(
                      keyword,
                      searchParams.get("sort"),
                      searchParams.get("region"),
                      searchParams.get("sector"),
                      searchParams.get("age")
                    ),
                    {
                      replace: true,
                    }
                  );
                }
              }}
              onClick={() => {
                navigate(
                  getQueryString(
                    keyword,
                    searchParams.get("sort"),
                    searchParams.get("region"),
                    searchParams.get("sector"),
                    searchParams.get("age")
                  ),
                  {
                    replace: true,
                  }
                );
              }}
            />

            <InputContainer>
              {/* 지역 */}
              <InputWrapper>
                <InputLabel htmlFor="region">지역</InputLabel>
                <InputField
                  type="text"
                  id="region"
                  name="region"
                  placeholder="지역을 선택해 주세요."
                  readOnly
                  $isRequired
                  value={
                    curationSelectValue.region === ""
                      ? ""
                      : curationSelectValue.regionTitle
                  }
                  onChange={handleSelectValueChange}
                  onClick={() => {
                    setIsRegionRotated((prevState) => !prevState);
                  }}
                />
                <DownArrowIcon
                  style={{
                    position: "absolute",
                    top: 13,
                    right: 15,
                    transform: isRegionRotated
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsRegionRotated((prevState) => !prevState);
                  }}
                />

                <SelectList
                  className="scrollbar"
                  style={{
                    top: 51,
                    right: 0,
                    width: 280,
                    display: isRegionRotated ? "block" : "none",
                  }}
                >
                  {regionList.map((option, index) => (
                    <React.Fragment key={index}>
                      <SelectOption
                        onClick={() => {
                          setIsRegionRotated((prevState) => !prevState);
                          setCurationSelectValue((prevState) => ({
                            ...prevState,
                            region: option.value,
                            regionTitle: option.title,
                          }));
                          navigate(
                            getQueryString(
                              searchParams.get("keyword"),
                              searchParams.get("sort"),
                              option.value,
                              searchParams.get("sector"),
                              searchParams.get("age")
                            ),
                            {
                              replace: true,
                            }
                          );
                        }}
                      >
                        {option.title}
                      </SelectOption>
                      {index < regionList.length - 1 && (
                        <DivisionLine
                          style={{
                            margin: "12px 0",
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </SelectList>
              </InputWrapper>

              {/* 업종 */}
              <InputWrapper>
                <InputLabel htmlFor="sector">업종</InputLabel>
                <InputField
                  type="text"
                  id="sector"
                  name="sector"
                  placeholder="업종을 선택해 주세요."
                  readOnly
                  $isRequired
                  value={
                    curationSelectValue.sector === ""
                      ? ""
                      : curationSelectValue.sectorTitle
                  }
                  onChange={handleSelectValueChange}
                  onClick={() => {
                    setIsSectorRotated((prevState) => !prevState);
                  }}
                />
                <DownArrowIcon
                  style={{
                    position: "absolute",
                    top: 13,
                    right: 15,
                    transform: isSectorRotated
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsSectorRotated((prevState) => !prevState);
                  }}
                />
                <SelectList
                  className="scrollbar"
                  style={{
                    top: 51,
                    right: 0,
                    width: 280,
                    display: isSectorRotated ? "block" : "none",
                  }}
                >
                  {sectorList.map((option, index) => (
                    <React.Fragment key={index}>
                      <SelectOption
                        onClick={() => {
                          setIsSectorRotated((prevState) => !prevState);
                          setCurationSelectValue((prevState) => ({
                            ...prevState,
                            sector: option.value,
                            sectorTitle: option.title,
                          }));
                          navigate(
                            getQueryString(
                              searchParams.get("keyword"),
                              searchParams.get("sort"),
                              searchParams.get("region"),
                              option.value,
                              searchParams.get("age")
                            ),
                            {
                              replace: true,
                            }
                          );
                        }}
                      >
                        {option.title}
                      </SelectOption>
                      {index < sectorList.length - 1 && (
                        <DivisionLine
                          style={{
                            margin: "12px 0",
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </SelectList>
              </InputWrapper>

              {/* 나이 */}
              <InputWrapper>
                <InputLabel htmlFor="age">나이</InputLabel>
                <InputField
                  type="text"
                  id="age"
                  name="age"
                  placeholder="연령대를 선택해 주세요."
                  readOnly
                  $isRequired
                  value={
                    curationSelectValue.age === ""
                      ? ""
                      : curationSelectValue.ageTitle
                  }
                  onChange={handleSelectValueChange}
                  onClick={() => {
                    setIsAgeRotated((prevState) => !prevState);
                  }}
                />
                <DownArrowIcon
                  style={{
                    position: "absolute",
                    top: 13,
                    right: 15,
                    transform: isAgeRotated ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsAgeRotated((prevState) => !prevState);
                  }}
                />
                <SelectList
                  className="scrollbar"
                  style={{
                    top: 51,
                    right: 0,
                    width: 280,
                    display: isAgeRotated ? "block" : "none",
                  }}
                >
                  {ageList.map((option, index) => (
                    <React.Fragment key={index}>
                      <SelectOption
                        onClick={() => {
                          setIsAgeRotated((prevState) => !prevState);
                          setCurationSelectValue((prevState) => ({
                            ...prevState,
                            age: option.value,
                          }));
                          navigate(
                            getQueryString(
                              searchParams.get("keyword"),
                              searchParams.get("sort"),
                              searchParams.get("region"),
                              searchParams.get("sector"),
                              option.value
                            ),
                            {
                              replace: true,
                            }
                          );
                        }}
                      >
                        {option.title}
                      </SelectOption>
                      {index < ageList.length - 1 && (
                        <DivisionLine
                          style={{
                            margin: "12px 0",
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </SelectList>
              </InputWrapper>

              {/* 정렬 */}
              <InputWrapper
                style={{
                  width: 133,
                  marginLeft: 23,
                }}
              >
                <InputLabel
                  htmlFor="sort"
                  style={{
                    position: "absolute",
                    width: 1,
                    height: 1,
                    padding: 0,
                    margin: -1,
                    overflow: "hidden",
                    clipPath: "inset(50%)",
                    border: 0,
                    clip: "rect(0 0 0 0)",
                  }}
                >
                  정렬 방식
                </InputLabel>
                <InputField
                  type="text"
                  id="sort"
                  name="sort"
                  readOnly
                  $isRequired
                  value={sortTitle}
                  onChange={handleSelectValueChange}
                  style={{ width: 133 }}
                  onClick={() => {
                    setIsSortRotated((prevState) => !prevState);
                  }}
                />
                <DownArrowIcon
                  style={{
                    position: "absolute",
                    top: 12,
                    right: 16,
                    transform: isSortRotated
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsSortRotated((prevState) => !prevState);
                  }}
                />
                <SelectList
                  className="scrollbar"
                  style={{
                    top: 51,
                    right: 0,
                    width: 133,
                    display: isSortRotated ? "block" : "none",
                  }}
                >
                  {sortingList.map((option, index) => (
                    <React.Fragment key={index}>
                      <SelectOption
                        onClick={() => {
                          setIsSortRotated((prevState) => !prevState);
                          setCurationSelectValue((prevState) => ({
                            ...prevState,
                            sort: option.value,
                          }));
                          navigate(
                            getQueryString(
                              searchParams.get("keyword"),
                              option.value,
                              searchParams.get("region"),
                              searchParams.get("sector"),
                              searchParams.get("age")
                            ),
                            {
                              replace: true,
                            }
                          );
                        }}
                      >
                        {option.title}
                      </SelectOption>
                      {index < sortingList.length - 1 && (
                        <DivisionLine
                          style={{
                            margin: "12px 0",
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </SelectList>
              </InputWrapper>
            </InputContainer>
          </CurationInnerContainer>

          <SortedResultBoldText
            style={{
              marginTop: 80,
            }}
            $title="전체"
            $count={
              pagination.totalCount
                ? pagination.totalCount
                : curationList.length
            }
          />

          {/* 게시물 영역 */}
          <PostContainer>
            <>
              {curationList.map((item, index) => {
                return (
                  <PostWrapper key={index}>
                    <PostImg
                      src={`${fileUrl}/${item.imageServerFileName}`}
                      effect="blur"
                    />
                    <PostTextWrapper>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 15,
                          width: 380,
                        }}
                      >
                        {/* 텍스트 영역 */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <PeriodWrapper>
                            <PeriodDate>
                              {calculateDday(item.endDate)}
                            </PeriodDate>

                            <PeriodText>
                              {formatDate(item.endDate)}까지 모집
                            </PeriodText>
                          </PeriodWrapper>
                          {/* 제목 및 내용 */}
                          <PostTitle>{item.title}</PostTitle>
                          <PostContentText>{item.content}</PostContentText>
                        </div>

                        {/* 업종, 지역명, 나이 */}
                        <TypeContainer>
                          <TypeTextWrapper>
                            <HomeIcon />
                            <TypeText
                              style={{
                                maxWidth: 120,
                              }}
                            >
                              {item.sectorTitle}
                            </TypeText>
                          </TypeTextWrapper>
                          <TypeTextWrapper>
                            <LocationIcon />
                            <TypeText>{item.regionTitle}</TypeText>
                          </TypeTextWrapper>
                          <TypeTextWrapper>
                            <PersonIcon />
                            <TypeText>{item.ageTitle}</TypeText>
                          </TypeTextWrapper>
                        </TypeContainer>
                      </div>

                      {/* 버튼 */}
                      <a
                        href={formatLink(item.link)}
                        target="_blank"
                        rel="noreferrer noopenner"
                      >
                        <PrimaryBtn
                          style={{
                            width: 155,
                            height: 39,
                          }}
                        >
                          지원사업 보러가기
                        </PrimaryBtn>
                      </a>
                    </PostTextWrapper>
                  </PostWrapper>
                );
              })}
              <Pagination
                route={route.curation}
                queryString={`keyword=${
                  searchParams.get("keyword") === null
                    ? ""
                    : searchParams.get("keyword")
                }&sort=${
                  searchParams.get("sort") ? searchParams.get("sort") : "id"
                }&region=${
                  searchParams.get("region") === null
                    ? ""
                    : searchParams.get("region")
                }&sector=${
                  searchParams.get("sector") === null
                    ? ""
                    : searchParams.get("sector")
                }&age=${
                  searchParams.get("age") === null
                    ? ""
                    : searchParams.get("age")
                }
                `}
                currentPage={page}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
              />
            </>
          </PostContainer>

          {alertOpen && (
            <Alert
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setAlertOpen(false);
              }}
            >
              {responseMessage}
            </Alert>
          )}
        </CurationContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default Curation;

const CurationContainer = styled.div`
  position: absolute;
  top: 475px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CurationInnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 28px;
  margin-bottom: 40px;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 10px;
  width: 318px;
  margin-right: 30px;
`;

const InputLabel = styled.label`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 145%;
  color: #282828;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  word-break: keep-all;
  white-space: nowrap;
`;

const InputField = styled.input`
  width: 280px;
  height: 51px;
  color: #282828;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  font-weight: 400;
  font-size: 16px;
  padding-left: 17px;
  outline: none;
  border-radius: 5px;
  &::placeholder {
    color: #c0c0c0;
  }
  &:focus {
    outline: 1px solid #2ec5f6;
  }
`;

const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1200px;
  gap: 30px;
  margin-top: 30px;
`;

const PostWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 350px;
  gap: 60px;
`;

const PostImg = styled(LazyLoadImage)`
  /* background-image: url(${(props) => props.src}); */
  /* background-color: #e9e9e9; */
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  object-fit: cover;
  width: 570px;
  height: 350px;
`;

const PostTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  width: 380px;
`;

const PeriodWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const PeriodDate = styled(PretendardText)`
  height: 35px;
  border-radius: 50px;
  padding: 5px 15px;
  background: #fff;
  border: 1px solid #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #282828;
`;

const PeriodText = styled(PretendardText)`
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #939393;
`;

const PostTitle = styled(PretendardText)`
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #282828;
  margin-top: 15px;
  margin-bottom: 10px;
`;

const PostContentText = styled(PretendardText)`
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #6f6f6f;
`;

const TypeContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
  background-color: #f8f8f8;
  width: 380px;
  height: 40px;
  border-radius: 5px;
`;

const TypeTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const TypeText = styled(PretendardText)`
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  color: #6f6f6f;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
`;
