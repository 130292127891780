import React from "react";
import styled from "styled-components";
import PretendardText from "./PretendardText";

const ToastPopup = (props) => {
  return <StyledToastPopup {...props}>{props.children}</StyledToastPopup>;
};

export default ToastPopup;

const StyledToastPopup = styled(PretendardText)`
  padding: 12px 35px;
  background-color: #939393;
  border-radius: 100px;
  color: #fff;
  font-weight: 600;
  line-height: 145%;
  font-size: 16px;
`;
