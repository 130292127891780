import React from "react";
import styled from "styled-components";

const BookmarkIcon = (props) => {
  return (
    <BookmarkIconSvg
      width="30"
      height="30"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.27148 13.0221V3.00515C3.27148 2.65497 3.55536 2.37109 3.90554 2.37109H12.7824C13.1325 2.37109 13.4164 2.65497 13.4164 3.00515V13.0221C13.4164 13.5285 12.852 13.8306 12.4306 13.5497L8.69566 11.0597C8.48268 10.9177 8.20522 10.9177 7.99224 11.0597L4.25725 13.5497C3.83589 13.8306 3.27148 13.5285 3.27148 13.0221Z"
        fill={props.$isChecked ? "#2ec5f6" : "#c2c2c2"}
      />
    </BookmarkIconSvg>
  );
};

export default BookmarkIcon;

const BookmarkIconSvg = styled.svg`
  cursor: pointer;
`;
