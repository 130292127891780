import React from "react";
import { styled } from "styled-components";

const InputWrapper = (props) => {
  return <StyledInputWrapper {...props}>{props.children}</StyledInputWrapper>;
};

export default InputWrapper;

const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 430px;
  margin-bottom: 20px;
`;
