import React from "react";
import styled from "styled-components";

const PenIcon = (props) => {
  return (
    <PenIconSvg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0857 3.91356L14.0862 7.91425L5.39929 16.6016L1.83251 16.9953C1.35502 17.0482 0.951591 16.6444 1.00471 16.1669L1.40158 12.5975L10.0857 3.91356ZM16.5606 3.31793L14.6822 1.43946C14.0962 0.853513 13.146 0.853513 12.56 1.43946L10.7929 3.20668L14.7934 7.20737L16.5606 5.44015C17.1465 4.85389 17.1465 3.90388 16.5606 3.31793Z"
        fill="#C0C0C0"
      />
    </PenIconSvg>
  );
};

export default PenIcon;

const PenIconSvg = styled.svg`
  cursor: pointer;
`;
