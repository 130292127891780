import React from "react";
import styled from "styled-components";

const DownloadBtn = (props) => {
  return (
    <DownloadBtnSvg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="32.993"
        height="32.993"
        rx="9.5"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="32.993"
        height="32.993"
        rx="9.5"
        stroke="#E9E9E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3 19.8C23.657 19.8 23.958 20.073 23.993 20.416V20.5V21.9C23.993 23.02 23.118 23.93 22.019 23.993H21.893H12.093C10.98 24 10.07 23.125 10 22.026V21.9V20.5C10 20.115 10.315 19.8 10.7 19.8C11.057 19.8 11.358 20.073 11.393 20.416V20.5V21.9C11.393 22.257 11.666 22.558 12.009 22.593H12.093H21.893C22.25 22.593 22.551 22.32 22.586 21.977V21.893V20.493C22.586 20.108 22.901 19.793 23.286 19.793L23.3 19.8ZM17 10C17.385 10 17.7 10.315 17.7 10.7V17.413L18.603 16.51C18.855 16.258 19.254 16.237 19.527 16.454L19.59 16.51C19.842 16.762 19.863 17.161 19.646 17.434L19.59 17.497L17.49 19.597L17.462 19.625L17.413 19.667L17.336 19.716L17.259 19.751L17.182 19.779L17.077 19.8H16.993H16.937L16.846 19.786L16.769 19.765L16.692 19.737L16.622 19.702L16.552 19.653C16.552 19.653 16.51 19.618 16.489 19.597L14.389 17.497C14.116 17.224 14.116 16.783 14.389 16.51C14.641 16.258 15.04 16.237 15.313 16.454L15.376 16.51L16.279 17.413V10.7C16.3 10.315 16.615 10 17 10Z"
        fill="#282828"
      />
    </DownloadBtnSvg>
  );
};

export default DownloadBtn;

const DownloadBtnSvg = styled.svg`
  cursor: pointer;
`;
