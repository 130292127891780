import React from "react";
import styled from "styled-components";
import MontserratText from "./MontserratText";

const TextLogo = (props) => {
  return <StyledTextLogo {...props}>Alting</StyledTextLogo>;
};

export default TextLogo;

const StyledTextLogo = styled(MontserratText)`
  color: #2ec5f6;
  font-weight: 700;
  font-size: 30px;
  line-height: 36.57px;
`;
