import React, { useState } from "react";
import styled from "styled-components";
import Header from "../components/organisms/Header";
import useResizeHeight from "../hooks/useResizeHeight";
import Footer from "./../components/organisms/Footer";
import BodyContainer from "./../components/molecules/layout/BodyContainer";
import PageContainer from "./../components/molecules/layout/PageContainer";
import PrimaryBtn from "../components/atoms/buttons/PrimaryBtn";
import SecondaryBtn from "../components/atoms/buttons/SecondaryBtn";
import PrimaryMiniBtn from "../components/atoms/buttons/PrimaryMiniBtn";
import SecondaryMiniBtn from "../components/atoms/buttons/SecondaryMiniBtn";
import XBtnIcon from "../components/atoms/icons/XBtnIcon";
import BookmarkIcon from "./../components/atoms/icons/BookmarkIcon";
import VCheckIcon from "../components/atoms/icons/VCheckIcon";
import DownArrowIcon from "../components/atoms/icons/DownArrowIcon";
import LeftArrowIcon from "../components/atoms/icons/LeftArrowIcon";
import PenIcon from "../components/atoms/icons/PenIcon";
import LocationIcon from "../components/atoms/icons/LocationIcon";
import HomeIcon from "../components/atoms/icons/HomeIcon";
import PersonIcon from "../components/atoms/icons/PersonIcon";
import CheckboxIcon from "../components/atoms/icons/CheckboxIcon";
import RadioIcon from "../components/atoms/icons/RadioIcon";
import MoreBtn from "../components/atoms/buttons/MoreBtn";
import FileBtn from "../components/atoms/buttons/FileBtn";
import SendBtn from "../components/atoms/buttons/SendBtn";
import DownloadBtn from "../components/atoms/buttons/DownloadBtn";
import PaginatedItems from "./../components/molecules/PaginatedItems";
import InputBox from "../components/molecules/InputBox";
import InputSelectBox from "../components/molecules/InputSelectBox";
import SelectList from "../components/molecules/SelectList";
import SelectOption from "../components/atoms/SelectOption";
import ProfileIcon from "../components/atoms/icons/ProfileIcon";
import CompanyIcon from "../components/atoms/icons/CompanyIcons";
import DivisionLine from "./../components/atoms/DivisionLine";
import ToastPopup from "../components/atoms/ToastPopup";
import Alert from "./../components/molecules/Alert";
import Confirm from "./../components/molecules/Confirm";
import DeleteIdModal from "../components/molecules/DeleteIdModal";
import ReportModal from "./../components/molecules/ReportModal";
import TextareaBox from "../components/molecules/TextareaBox";
import KakaoLoginIcon from "../components/atoms/icons/KakaoLoginIcon";
import NaverLoginIcon from "./../components/atoms/icons/NaverLoginIcon";
import GoogleLoginIcon from "./../components/atoms/icons/GoogleLoginIcon";
import qnaData from "../constants/qnaData";
import CompanyList from "../components/molecules/CompanyList";
import scrollToTop from "../lib/utils/scrollToTop";
import ChatBubble from "../components/molecules/ChatBubble";
import SearchIcon from "../components/atoms/icons/SearchIcon";
import Popup from "../components/molecules/Popup";
import TermsModal from "../components/molecules/termsModal";

const UIPage = () => {
  // DownArrow 관련
  const [isRotated, setIsRotated] = useState(false);

  // 인풋 입력 필드 관련
  const [inputValue, setInputValue] = useState({
    email: "",
    textBox: "",
    disabled: "",
    textarea: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // 인풋 선택 필드 관련
  const [isSelectBoxRotated, setIsSelectBoxRotated] = useState(false);
  const [isNoLabelSelectBoxRotated, setIsNoLabelSelectBoxRotated] =
    useState(false);

  const selectOptionsArray = [
    "항목이 이곳에 들어갑니다. 1",
    "항목이 이곳에 들어갑니다. 2",
    "항목이 이곳에 들어갑니다. 3",
    "항목이 이곳에 들어갑니다. 4",
    "항목이 이곳에 들어갑니다. 5",
    "항목이 이곳에 들어갑니다. 6",
    "항목이 이곳에 들어갑니다. 7",
    "항목이 이곳에 들어갑니다. 8",
    "항목이 이곳에 들어갑니다. 9",
    "항목이 이곳에 들어갑니다. 10",
  ];

  const selectOptions = {
    option1: "항목이 이곳에 들어갑니다. 1",
    option2: "항목이 이곳에 들어갑니다. 2",
    option3: "항목이 이곳에 들어갑니다. 3",
    option4: "항목이 이곳에 들어갑니다. 4",
    option5: "항목이 이곳에 들어갑니다. 5",
    option6: "항목이 이곳에 들어갑니다. 6",
    option7: "항목이 이곳에 들어갑니다. 7",
    option8: "항목이 이곳에 들어갑니다. 8",
    option9: "항목이 이곳에 들어갑니다. 9",
    option10: "항목이 이곳에 들어갑니다. 10",
  };
  const [selectedInputValue, setSelectedInputValue] = useState({
    selectType: "",
  });

  const handleSelectedValueChange = (e) => {
    const { name, value } = e.target;
    setSelectedInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // -------------------------------- //
  // ---------- 모달 관련 ---------- //
  // ------------------------------ //

  // 이용약관 관련
  const [isAgreementOpen, setIsAgreementOpen] = useState(false);

  const openAgreementModal = () => {
    setIsAgreementOpen(true);
  };

  const closeAgreementModal = () => {
    setIsAgreementOpen(false);
  };

  // 개인정보처리방침 관련
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);

  const openPrivacyModal = () => {
    setIsPrivacyOpen(true);
  };

  const closePrivacyModal = () => {
    setIsPrivacyOpen(false);
  };

  // 개인정보 제 3자 제공 동의
  const [isProvidePrivacyOpen, setIsProvidePrivacyOpen] = useState(false);

  const openProvidePrivacyModal = () => {
    setIsProvidePrivacyOpen(true);
  };

  const closeProvidePrivacyModal = () => {
    setIsProvidePrivacyOpen(false);
  };

  // 환불/취소 관련 동의
  const [isRefundAndCancelAgreeOpen, setIsRefundAndCancelAgreeOpen] =
    useState(false);

  const openRefundAndCancelAgreeModal = () => {
    setIsRefundAndCancelAgreeOpen(true);
  };

  const closeRefundAndCancelAgreeModal = () => {
    setIsRefundAndCancelAgreeOpen(false);
  };

  // 회원탈퇴 관련
  const [deleteIdIsOpen, setDeleteIdIsOpen] = useState(false);

  const openDeleteIdModal = () => {
    setDeleteIdIsOpen(true);
  };

  const closeDeleteIdModal = () => {
    setDeleteIdIsOpen(false);
  };

  // 신고하기 관련
  const [reportIsOpen, setReportIsOpen] = useState(false);

  const openReportModal = () => {
    setReportIsOpen(true);
  };

  const closeReportModal = () => {
    setReportIsOpen(false);
  };

  // ---------------------------------- //
  // ---------- 북마크 관련 ---------- //
  // -------------------------------- //

  // 북마크 상태 관련
  const [bookmarkStatus, setBookmarkStatus] = useState(false);

  // 북마크 토글
  // const toggleBookmark = () => {
  //   setBookmarkStatus(!bookmarkStatus);
  // };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer style={{ minHeight: 650 + elementHeight }}>
      <Header />
      <BodyContainer>
        <TestContainer ref={elementRef}>
          {/* 버튼 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              marginBottom: 40,
            }}
          >
            <h1>버튼</h1>
            {/* Primary btn - Large */}
            <PrimaryBtn>로그인</PrimaryBtn>
            <PrimaryBtn disabled>로그인</PrimaryBtn>
            {/* Primary btn - Small */}
            <PrimaryBtn $isSmall>로그인</PrimaryBtn>
            <PrimaryBtn $isSmall disabled>
              로그인
            </PrimaryBtn>
            {/* Secondary btn - Small */}
            <SecondaryBtn>로그인</SecondaryBtn>
            <SecondaryBtn disabled>로그인</SecondaryBtn>
            {/* Mini Btn */}
            <PrimaryMiniBtn>수정완료</PrimaryMiniBtn>
            <PrimaryMiniBtn $isSmall>수정완료</PrimaryMiniBtn>
            <SecondaryMiniBtn>취소하기</SecondaryMiniBtn>
            <SecondaryMiniBtn $isSmall>인증하기</SecondaryMiniBtn>
            {/* other btn */}
            <MoreBtn />
            <FileBtn />
            <SendBtn />
            <DownloadBtn />
          </div>

          {/* 아이콘 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              marginBottom: 40,
            }}
          >
            <h1>아이콘</h1>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <XBtnIcon />
              <BookmarkIcon
                onClick={() => {
                  setBookmarkStatus((prev) => !prev);
                }}
                $isChecked={bookmarkStatus}
              />
              <VCheckIcon checked={true} />
              <VCheckIcon checked={false} />
              <DownArrowIcon
                onClick={() => setIsRotated((prev) => !prev)}
                $isRotated={isRotated}
              />
              <DownArrowIcon
                $isSmall
                onClick={() => setIsRotated((prev) => !prev)}
                $isRotated={isRotated}
              />
              <LeftArrowIcon />
              <PenIcon />
              <LocationIcon />
              <HomeIcon />
              <PersonIcon />
              <CheckboxIcon checked={true} />
              <CheckboxIcon checked={false} />
              <RadioIcon checked={true} />
              <RadioIcon checked={false} />
              <SearchIcon />
            </div>
          </div>

          {/* 로그인 아이콘 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              marginBottom: 40,
            }}
          >
            <h1>로그인 아이콘</h1>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <KakaoLoginIcon
                onClick={() => {
                  alert("카카오 로그인 아이콘 클릭");
                }}
              />
              <NaverLoginIcon
                onClick={() => {
                  alert("네이버 로그인 아이콘 클릭");
                }}
              />

              <GoogleLoginIcon
                onClick={() => {
                  alert("구글 로그인 아이콘 클릭");
                }}
              />
            </div>
          </div>

          {/* 페이지네이션 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              marginBottom: 40,
            }}
          >
            <h1>페이지네이션</h1>
            <PaginatedItems $items={qnaData} $itemsPerPage={5}>
              {(item) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                  }}
                >
                  <div>{item.title}</div>
                  <div>{item.content}</div>
                </div>
              )}
            </PaginatedItems>
          </div>

          {/* 인풋 영역 */}
          <div
            style={{
              display: "flex",
              gap: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginBottom: 40,
              }}
            >
              <h1>인풋 입력 필드</h1>

              {/* 레이블 없음, 비필수 */}
              <InputBox
                $uniqueKey="textBox"
                type="text"
                placeholder="내용을 입력해주세요."
                value={inputValue.textBox}
                onChange={handleInputChange}
              />

              {/* 레이블, 필수 */}
              <InputBox
                $uniqueKey="email"
                label="이메일"
                type="email"
                $isRequired={true}
                placeholder="이메일을 입력해주세요."
                value={inputValue.email}
                onChange={handleInputChange}
              />

              {/* 레이블, 필수 아님 */}
              <InputBox
                $uniqueKey="email"
                label="이메일"
                type="email"
                $isRequired={false}
                placeholder="이메일을 입력해주세요."
                value={inputValue.email}
                onChange={handleInputChange}
              />

              {/* 레이블, 필수, 헬프 메시지 */}
              <InputBox
                $uniqueKey="email"
                label="이메일"
                type="email"
                $isRequired={true}
                placeholder="이메일을 입력해주세요."
                value={inputValue.email}
                onChange={handleInputChange}
                $isHelp={true}
                $helpMsg="이메일은 test@test.co.kr 형식으로 입력해 주세요."
              />

              {/* 레이블, 필수, 에러 메시지 */}
              <InputBox
                $uniqueKey="email"
                label="이메일"
                type="email"
                $isRequired={true}
                placeholder="이메일을 입력해주세요."
                value={inputValue.email}
                onChange={handleInputChange}
                $isError={true}
                $errorMsg="올바른 이메일을 입력해 주세요."
              />

              {/* 레이블, 필수, 비활성화 */}
              <InputBox
                $uniqueKey="email"
                label="이메일"
                type="email"
                $isRequired={true}
                placeholder="이메일을 입력해주세요."
                value={inputValue.disabled}
                onChange={handleInputChange}
                disabled={true}
              />

              {/* 레이블, 필수, 버튼 */}
              <InputBox
                $uniqueKey="email"
                label="이메일"
                type="email"
                $isRequired={true}
                placeholder="이메일을 입력해주세요."
                value={inputValue.email}
                onChange={handleInputChange}
                $isShowBtn={true}
                $btnText="인증하기"
                $btnHandler={() => {
                  alert("인증하기 버튼 클릭");
                }}
              />

              {/* 레이블, 필수, 버튼 비활성화 */}
              <InputBox
                $uniqueKey="email"
                label="이메일"
                type="email"
                $isRequired={true}
                placeholder="이메일을 입력해주세요."
                value={inputValue.email}
                onChange={handleInputChange}
                $isShowBtn={true}
                $btnText="인증하기"
                $btnDisabled={true}
                $btnHandler={() => {
                  alert("인증하기 버튼 클릭");
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginBottom: 40,
              }}
            >
              <h1>인풋 선택 필드</h1>
              {/* 배열 버전 */}
              <InputSelectBox
                $uniqueKey="selectType"
                label="항목"
                $isRequired={true}
                placeholder="항목을 선택해주세요."
                value={selectedInputValue.selectType}
                onChange={handleSelectedValueChange}
                $isRotated={isSelectBoxRotated}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsSelectBoxRotated((prev) => !prev);
                }}
              >
                <SelectList
                  className="scrollbar"
                  style={{
                    top: 75,
                    left: 0,
                    width: "100%",
                    display: isSelectBoxRotated ? "block" : "none",
                  }}
                >
                  {selectOptionsArray.map((option, index) => (
                    <React.Fragment key={index}>
                      <SelectOption
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsSelectBoxRotated((prev) => !prev);
                          setSelectedInputValue((prevState) => ({
                            ...prevState,
                            selectType: option,
                          }));
                        }}
                      >
                        {option}
                      </SelectOption>
                      {index < selectOptionsArray.length - 1 && (
                        <DivisionLine
                          style={{
                            margin: "12px 0",
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </SelectList>
              </InputSelectBox>

              {/* 객체 버전 */}
              <InputSelectBox
                isNoLabel={true}
                $uniqueKey="selectType"
                $isRequired={true}
                placeholder="항목을 선택해주세요."
                value={selectedInputValue.selectType}
                onChange={handleSelectedValueChange}
                $isRotated={isNoLabelSelectBoxRotated}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsNoLabelSelectBoxRotated((prev) => !prev);
                }}
              >
                <SelectList
                  className="scrollbar"
                  style={{
                    top: 43,
                    left: 0,
                    width: "100%",
                    display: isNoLabelSelectBoxRotated ? "block" : "none",
                  }}
                >
                  {Object.keys(selectOptions).map((key, index) => (
                    <>
                      <SelectOption
                        key={key}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsNoLabelSelectBoxRotated((prev) => !prev);
                          setSelectedInputValue((prevState) => ({
                            ...prevState,
                            selectType: selectOptions[key],
                          }));
                        }}
                      >
                        {selectOptions[key]}
                      </SelectOption>
                      {index < Object.keys(selectOptions).length - 1 && (
                        <DivisionLine
                          style={{
                            margin: "12px 0",
                          }}
                        />
                      )}
                    </>
                  ))}
                </SelectList>
              </InputSelectBox>
            </div>
          </div>

          {/* 텍스트 에어리어 영역 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              marginBottom: 40,
            }}
          >
            <h1>텍스트 에어리어</h1>
            <div
              style={{
                display: "flex",
                gap: 20,
              }}
            >
              <TextareaBox
                $uniqueKey="textarea"
                label="내용"
                $isRequired={true}
                placeholder="내용을 입력해 주세요."
                value={inputValue.textarea}
                onChange={handleInputChange}
                $isError={true}
                $errorMsg="두 글자 이상 입력해 주세요."
              />
              <TextareaBox
                $uniqueKey="textarea"
                label="내용"
                placeholder="내용을 입력해 주세요."
                value={inputValue.textarea}
                onChange={handleInputChange}
                $isHelp={true}
                $helpMsg="내용은 100자 이내로 입력해 주세요."
              />
            </div>
          </div>

          {/* 프로필 아이콘 영역 */}
          <div
            style={{
              display: "flex",
              gap: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginBottom: 40,
              }}
            >
              <h1>프로필 아이콘</h1>
              <ProfileIcon />
              <ProfileIcon src="https://s3-alpha-sig.figma.com/img/a591/f1bd/a035cd6d35b243976f3a02a0fcdde741?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Tyyna9NkCj0iTyHb-SL4XbUAlpAqvToE5wL~F9eyqSSL89KAlVBeJpqzB5BKXxiyNb56YuwSQJkooj2FGxnipam43nEqrzo5~wwH8j17d5NUzNd07XwxQnhN4WA78w~VbxuoVUx3pS9u97m1VjhllyPpNCAGlnaStlZH0pNjXvqEGDOy3-Y5aqk-K51r1GTjppd9uENgEQbXFxwpxdwxFS66z0HJPOc0sVGERYCtBb8uF9MV~OYpLNE5QqJIy5bMBz2l6bKhxpB~3K~eV9Q7DxBwXcrC~et2CdlH9uwaqfvNimJHQLzuke8Rkix2z5bErfU2vH40sT8A8VJpN2J4cA__" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginBottom: 40,
              }}
            >
              <h1>기업 아이콘</h1>
              <CompanyIcon
                $shadowOn
                style={{
                  width: 120,
                  height: 120,
                }}
              />
              <CompanyIcon
                style={{
                  width: 120,
                  height: 120,
                }}
                src="https://s3-alpha-sig.figma.com/img/b751/c865/b040ac53c4ab48d5ec4e594331a85b08?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UpzBumXF4I1lFNAR7SXsKHbFQl4B4p~VpQUCNokD4VFtn~e5CWoW3beeII0mrjV3CYW7m0JFpBluuDwyVPNd2RGkSlnunT0yMkspq9ObQk4lTuXyRLSYIGxhkvNEw~ghqaDmniGVQsqIfyWwI9lLn1lcIKAjQ3KC~NuVxekeaprls5qJTw6JeTtUnnVQ7PZWZEagu4ZHDD-ML5ZoFj-0~HNxT4FDwk4VncTnp43sIf5OrdpuK6QcyMcrYsK62KejCrBc12rXcfUpOFnVAg3ElJ78mpPFf-gKMPuDx-Rgae1vcBUe3xyonwgE39fObBIZs8Jk~vzPnpShdv~iWGAHJA__"
              />
            </div>
          </div>

          {/* 토스트팝업 / 알림 / 확인창 영역 */}
          <div
            style={{
              display: "flex",
              gap: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginBottom: 40,
              }}
            >
              <h1>토스트 팝업</h1>
              <ToastPopup>토스트팝업 내용이 이곳에 들어갑니다.</ToastPopup>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginBottom: 40,
              }}
            >
              <h1>알림창</h1>
              <Alert
                onClick={(e) => {
                  e.stopPropagation();
                  alert("두 줄 확인 버튼 클릭");
                }}
              >
                두 줄이 들어갈 경우 이렇게 배치됩니다.
                <br />두 줄이 들어갈 경우 이렇게 배치됩니다.
              </Alert>
              <Alert
                onClick={(e) => {
                  e.stopPropagation();
                  alert("한 줄 확인 버튼 클릭");
                }}
              >
                한 줄이 들어갈 경우 이렇게 배치됩니다.
              </Alert>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginBottom: 40,
              }}
            >
              <h1>확인창</h1>
              <Confirm
                cancelEvent={(e) => {
                  e.stopPropagation();
                  alert("두 줄 취소 버튼 클릭");
                }}
                confirmEvent={(e) => {
                  e.stopPropagation();
                  alert("두 줄 확인 버튼 클릭");
                }}
              >
                두 줄이 들어갈 경우 이렇게 배치됩니다.
                <br />두 줄이 들어갈 경우 이렇게 배치됩니다.
              </Confirm>
              <Confirm
                cancelEvent={(e) => {
                  e.stopPropagation();
                  alert("한 줄 취소 버튼 클릭");
                }}
                confirmEvent={(e) => {
                  e.stopPropagation();
                  alert("한 줄 확인 버튼 클릭");
                }}
              >
                한 줄이 들어갈 경우 이렇게 배치됩니다.
              </Confirm>
            </div>
          </div>

          {/* 신고하기 / 회원탈퇴, 이용약관, 개인정보처리방침 모달 영역 */}
          <div
            style={{
              display: "flex",
              gap: 20,
            }}
          >
            {/* 이용약관 동의 모달 영역 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginBottom: 40,
              }}
            >
              <h1>이용약관</h1>
              <SecondaryMiniBtn
                onClick={() => {
                  openAgreementModal();
                }}
              >
                모달열기
              </SecondaryMiniBtn>
              {isAgreementOpen && (
                <TermsModal
                  $isOpen={isAgreementOpen}
                  $termsId={1}
                  handleClose={closeAgreementModal}
                />
              )}
            </div>

            {/* 개인정보처리방침 모달 영역 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginBottom: 40,
              }}
            >
              <h1>개인정보처리방침</h1>
              <SecondaryMiniBtn
                onClick={() => {
                  openPrivacyModal();
                }}
              >
                모달열기
              </SecondaryMiniBtn>
              {isPrivacyOpen && (
                <TermsModal
                  $isOpen={isPrivacyOpen}
                  $termsId={2}
                  handleClose={closePrivacyModal}
                />
              )}
            </div>

            {/* 개인정보 제 3자 제공 동의 모달 영역 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginBottom: 40,
              }}
            >
              <h1>개인정보 제 3자 제공 동의</h1>
              <SecondaryMiniBtn
                onClick={() => {
                  openProvidePrivacyModal();
                }}
              >
                모달열기
              </SecondaryMiniBtn>
              {isProvidePrivacyOpen && (
                <TermsModal
                  $isOpen={isProvidePrivacyOpen}
                  $termsId={3}
                  handleClose={closeProvidePrivacyModal}
                />
              )}
            </div>

            {/* 환불/취소 관련 동의 모달 영역 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginBottom: 40,
              }}
            >
              <h1>환불/취소 관련</h1>
              <SecondaryMiniBtn
                onClick={() => {
                  openRefundAndCancelAgreeModal();
                }}
              >
                모달열기
              </SecondaryMiniBtn>
              {isRefundAndCancelAgreeOpen && (
                <TermsModal
                  $isOpen={isRefundAndCancelAgreeOpen}
                  $termsId={4}
                  handleClose={closeRefundAndCancelAgreeModal}
                />
              )}
            </div>

            {/* 신고하기 모달 영역 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginBottom: 40,
              }}
            >
              <h1>신고하기</h1>
              <SecondaryMiniBtn
                onClick={() => {
                  openReportModal();
                }}
              >
                모달 열기
              </SecondaryMiniBtn>
              {reportIsOpen && (
                <ReportModal
                  $isOpen={reportIsOpen}
                  handleClose={closeReportModal}
                />
              )}
            </div>

            {/* 회원탈퇴 모달 영역 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginBottom: 40,
              }}
            >
              <h1>회원탈퇴</h1>
              <SecondaryMiniBtn
                onClick={() => {
                  openDeleteIdModal();
                }}
              >
                모달열기
              </SecondaryMiniBtn>
              {deleteIdIsOpen && (
                <DeleteIdModal
                  $isOpen={deleteIdIsOpen}
                  handleClose={closeDeleteIdModal}
                />
              )}
            </div>
          </div>

          {/* 기업 리스트 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              marginBottom: 40,
            }}
          >
            <h1>기업 리스트</h1>
            <div
              style={{
                display: "flex",
                gap: 30,
              }}
            >
              <CompanyList
                path="/search/detail"
                pathHandler={() => {
                  scrollToTop();
                }}
                isShowBookmark
                bookmarkHandler={() => {
                  setBookmarkStatus((prev) => !prev);
                }}
                $isChecked={bookmarkStatus}
                serviceName="서비스명이 이곳에 들어갑니다."
                companyName="기업명이 이곳에 들어갑니다."
                marketingType="마케팅 종류"
                location="지역"
                reviewGrade="5.0"
              />
              <CompanyList
                $isSmall={true}
                path="/search/detail"
                pathHandler={() => {
                  scrollToTop();
                }}
                isShowBookmark
                bookmarkHandler={() => {
                  setBookmarkStatus((prev) => !prev);
                }}
                $isChecked={bookmarkStatus}
                serviceName="서비스명이 이곳에 들어갑니다."
                companyName="기업명이 이곳에 들어갑니다."
                marketingType="마케팅 종류"
                location="지역"
                reviewGrade="5.0"
              />
              <CompanyList
                path="/search/detail"
                pathHandler={() => {
                  scrollToTop();
                }}
                serviceName="서비스명이 이곳에 들어갑니다."
                companyName="기업명이 이곳에 들어갑니다."
                marketingType="마케팅 종류"
                location="지역"
                reviewGrade="5.0"
              />
              <CompanyList
                $isSmall={true}
                path="/search/detail"
                pathHandler={() => {
                  scrollToTop();
                }}
                bookmarkHandler={() => {
                  setBookmarkStatus((prev) => !prev);
                }}
                serviceName="서비스명이 이곳에 들어갑니다."
                companyName="기업명이 이곳에 들어갑니다."
                marketingType="마케팅 종류"
                location="지역"
                reviewGrade="5.0"
              />
            </div>
          </div>

          {/* 채팅 말풍선 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              marginBottom: 40,
            }}
          >
            <h1>채팅</h1>
            <ChatBubble
              $myMessage={false}
              $noProfile={false}
              $time="오후 00:00"
              $profileImgSrc="https://s3-alpha-sig.figma.com/img/a591/f1bd/a035cd6d35b243976f3a02a0fcdde741?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Tyyna9NkCj0iTyHb-SL4XbUAlpAqvToE5wL~F9eyqSSL89KAlVBeJpqzB5BKXxiyNb56YuwSQJkooj2FGxnipam43nEqrzo5~wwH8j17d5NUzNd07XwxQnhN4WA78w~VbxuoVUx3pS9u97m1VjhllyPpNCAGlnaStlZH0pNjXvqEGDOy3-Y5aqk-K51r1GTjppd9uENgEQbXFxwpxdwxFS66z0HJPOc0sVGERYCtBb8uF9MV~OYpLNE5QqJIy5bMBz2l6bKhxpB~3K~eV9Q7DxBwXcrC~et2CdlH9uwaqfvNimJHQLzuke8Rkix2z5bErfU2vH40sT8A8VJpN2J4cA__"
            >
              채팅 내용이 이곳에 들어갑니다.채팅 내용이 이곳에 들어갑니다.채팅
              내용이 이곳에 들어갑니다. 채팅 내용이 이곳에 들어갑니다.채팅
              내용이 이곳에 들어갑니다.채팅 내용이 이곳에 들어갑니다. 채팅
              내용이 이곳에 들어갑니다.채팅 내용이 이곳에 들어갑니다.채팅 내용이
              이곳에 들어갑니다. 채팅 내용이 이곳에 들어갑니다.채팅 내용이
              이곳에 들어갑니다.
            </ChatBubble>
            <ChatBubble
              $myMessage={false}
              $noProfile={true}
              $time="오후 00:00"
              $profileImgSrc="https://s3-alpha-sig.figma.com/img/a591/f1bd/a035cd6d35b243976f3a02a0fcdde741?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Tyyna9NkCj0iTyHb-SL4XbUAlpAqvToE5wL~F9eyqSSL89KAlVBeJpqzB5BKXxiyNb56YuwSQJkooj2FGxnipam43nEqrzo5~wwH8j17d5NUzNd07XwxQnhN4WA78w~VbxuoVUx3pS9u97m1VjhllyPpNCAGlnaStlZH0pNjXvqEGDOy3-Y5aqk-K51r1GTjppd9uENgEQbXFxwpxdwxFS66z0HJPOc0sVGERYCtBb8uF9MV~OYpLNE5QqJIy5bMBz2l6bKhxpB~3K~eV9Q7DxBwXcrC~et2CdlH9uwaqfvNimJHQLzuke8Rkix2z5bErfU2vH40sT8A8VJpN2J4cA__"
            >
              채팅 내용이 이곳에 들어갑니다.채팅 내용이 이곳에 들어갑니다.채팅
              내용이 이곳에 들어갑니다. 채팅 내용이 이곳에 들어갑니다.채팅
              내용이 이곳에 들어갑니다.채팅 내용이 이곳에 들어갑니다. 채팅
              내용이 이곳에 들어갑니다.채팅 내용이 이곳에 들어갑니다.채팅 내용이
              이곳에 들어갑니다. 채팅 내용이 이곳에 들어갑니다.채팅 내용이
              이곳에 들어갑니다.
            </ChatBubble>
            <ChatBubble $myMessage={true} $time="오후 00:00">
              채팅 내용이 이곳에 들어갑니다.채팅 내용이 이곳에 들어갑니다.채팅
              내용이 이곳에 들어갑니다. 채팅 내용이 이곳에 들어갑니다.채팅
              내용이 이곳에 들어갑니다.채팅 내용이 이곳에 들어갑니다. 채팅
              내용이 이곳에 들어갑니다.채팅 내용이 이곳에 들어갑니다.채팅 내용이
              이곳에 들어갑니다. 채팅 내용이 이곳에 들어갑니다.채팅 내용이
              이곳에 들어갑니다.
            </ChatBubble>
          </div>

          {/* 팝업 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              marginBottom: 40,
            }}
          >
            <h1>팝업</h1>
            <Popup
              data={[
                {
                  id: 1,
                  imageSrc:
                    "https://images.unsplash.com/photo-1709884732297-4b3c1a3f725b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGZyZWUlMjBpbWFnZXN8ZW58MHx8MHx8fDA%3D",
                },
                {
                  id: 2,
                  imageSrc:
                    "https://images.unsplash.com/photo-1706694442016-bd539e1d102b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJlZSUyMGltYWdlc3xlbnwwfHwwfHx8MA%3D%3D",
                },
                {
                  id: 3,
                  imageSrc:
                    "https://images.unsplash.com/photo-1709884735626-63e92727d8b6?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZnJlZSUyMGltYWdlc3xlbnwwfHwwfHx8MA%3D%3D",
                },
              ]}
            />
          </div>
        </TestContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default UIPage;

const TestContainer = styled.div`
  position: absolute;
  top: 105px;
  width: 1200px;
`;
