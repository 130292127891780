import api from "./api";

export default class FileApi {
  static async fileDownload(data) {
    return await api.post(`file/download`, data, {
      responseType: "blob",
    });
  }
  static async fileUpload(data) {
    return await api.fileForm(`file/upload`, data, "post");
  }
}
