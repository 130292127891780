import React from "react";
import styled from "styled-components";

const BodyContainer = (props) => {
  return <StyledBodyContainer {...props}>{props.children}</StyledBodyContainer>;
};

export default BodyContainer;

const StyledBodyContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
