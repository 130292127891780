import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import BodyContainer from "../../components/molecules/layout/BodyContainer";
import PageContainer from "../../components/molecules/layout/PageContainer";
import Header from "../../components/organisms/Header";
import DivisionLine from "../../components/atoms/DivisionLine";
import Footer from "./../../components/organisms/Footer";
import { useRecoilState, useRecoilValue } from "recoil";
import { loginValueState } from "../../store/loginStore";
import { Link, useNavigate } from "react-router-dom";
import { deleteStorage, setStorage } from "../../lib/utils/sessionStorage";
import Title from "../../components/atoms/Title";
import PretendardText from "../../components/atoms/PretendardText";
import scrollToTop from "../../lib/utils/scrollToTop";
import useResizeHeight from "../../hooks/useResizeHeight";
import KakaoLoginIcon from "../../components/atoms/icons/KakaoLoginIcon";
import NaverLoginIcon from "../../components/atoms/icons/NaverLoginIcon";
import GoogleLoginIcon from "../../components/atoms/icons/GoogleLoginIcon";
import InputBox from "../../components/molecules/InputBox";
import PrimaryBtn from "./../../components/atoms/buttons/PrimaryBtn";
import Alert from "../../components/molecules/Alert";
import regex from "../../constants/regex";
import { lastPageState } from "../../store/navigationStore";
import axios from "axios";
import { url } from "../../lib/api/api";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import route from "../../router/route";
import { removeNonNumeric } from "../../lib/utils/formatNumber";

const Login = () => {
  const navigate = useNavigate();

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    deleteStorage("socialToken");
    deleteStorage("joinType");
    deleteStorage("name");
    deleteStorage("email");
    deleteStorage("phoneNumber");
    deleteStorage("current");
    deleteStorage("token");
  }, []);

  const [loginValue, setLoginValue] = useRecoilState(loginValueState);
  const [emailRegexTest, setEmailRegexTest] = useState(true);
  const [, setEmailIsValid] = useState(false);
  const [pwRegexTest, setPwRegexTest] = useState(true);
  const [, setPwIsValid] = useState(false);

  // 서버 응답 메시지 상태
  // 알림창 관련
  const [alertIsOpen, setAlertIsOpen] = useState(false);

  const handleAlertClose = () => {
    setAlertIsOpen(false);
  };
  const [responseMessage, setResponseMessage] = useState("");

  const handleInputValueChange = (e) => {
    const { name, value } = e.target;

    const validateInput = (regex, value) => {
      return regex.test(value);
    };

    setLoginValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    const validateField = (name, value) => {
      const isValid = value !== "";

      switch (name) {
        case "email":
          if (value !== "") {
            setEmailRegexTest(validateInput(regex.email, value));
          } else {
            setEmailIsValid(isValid);
            setEmailRegexTest(true);
          }
          break;
        case "pw":
          if (value !== "") {
            setPwRegexTest(validateInput(regex.pw, value));
          } else {
            setPwIsValid(isValid);
            setPwRegexTest(true);
          }
          break;
        default:
          console.error("Invalid field name");
      }
    };

    validateField(name, value);
  };

  const form = useRef();
  const lastPage = useRecoilValue(lastPageState);

  // 일반 로그인 함수
  const handleLogin = async (e) => {
    e.preventDefault();
    const email = form.current[0].value;
    const password = form.current[1].value;
    // const loginValue = `${email}:${password}`;
    // const loginValueEncoded = btoa(loginValue);

    try {
      const response = await axios.post(
        `${url}auth/login`,
        {
          email: email,
          password: password,
        },
        {
          headers: {
            // Authorization: `Bearer ${loginValueEncoded}`,
            "Content-Type": "application/json",
          },
        }
      );
      navigate(lastPage, { replace: true });
      setStorage("email", email);
      setStorage("current", btoa(password));
      setStorage("token", response.data.data.accessToken);
    } catch (error) {
      if (error.response.data.code === 40001) {
        setResponseMessage(error.response.data.message); // 존재하지 않는 사용자
        setAlertIsOpen(true); // 전송 실패 알림창 열기
      } else if (error.response.data.code === 40002) {
        setResponseMessage(error.response.data.message); // 비밀번호 불일치
        setAlertIsOpen(true);
      } else if (error.response.data.code === 40003) {
        setResponseMessage(error.response.data.message); // 승인되지 않은 사용자
        setAlertIsOpen(true);
      } else if (error.response.data.code === 40004) {
        setResponseMessage(error.response.data.message); // 비활성 계정
        setAlertIsOpen(true);
      } else if (error.response.data.code === 40005) {
        setResponseMessage(error.response.data.message); // joinType 가입 계정
        setAlertIsOpen(true);
      } else {
        console.error(error);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
        setAlertIsOpen(true);
      }
    }
  };

  // ---------------------------------- //
  // ---------- 소셜 로그인 ---------- //
  // -------------------------------- //
  const socialLogin = async (socialId, joinType) => {
    try {
      // 가입되어있는지 확인
      const data = {
        socialId: socialId,
        joinType: joinType,
      };

      const response = await axios.post(`${url}auth/login/social`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      navigate(lastPage, { replace: true });
      // 가입되어있는 경우
      setStorage("email", response.data.data.email);
      setStorage("current", null);
      setStorage("token", response.data.data.accessToken);
      return true;
    } catch (error) {
      // 가입되어있지 않은 경우
      if (error.response.data.code === 40003) {
        navigate(route.login, { replace: true }); // hash 값 제거
        setResponseMessage(error.response.data.message); // 승인되지 않은 경우
        setAlertIsOpen(true);
        return true;
      } else if (error.response.data.code === 40004) {
        navigate(route.login, { replace: true }); // hash 값 제거
        setResponseMessage(error.response.data.message); // 비활성 계정인 경우
        setAlertIsOpen(true);
        return true;
      } else if (error.response.data.code === 40001) {
        navigate(route.register, { replace: true }); // 가입되어있지 않은 경우 회원가입 페이지로 이동
        return false;
      } else {
        console.error(error);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
        setAlertIsOpen(true);
      }
    }
  };

  // ------------------------------------ //
  // ---------- 네이버 로그인 ---------- //
  // ---------------------------------- //
  const initNaver = new window.naver.LoginWithNaverId({
    clientId: process.env.REACT_APP_NAVER_CLIENT_ID,
    // callbackUrl: "http://localhost:3000/login",
    callbackUrl: "https://alting.co.kr/login",
    isPopup: false,
    loginButton: {
      color: "green",
      type: 2,
      height: 40,
    },
  });

  useEffect(() => {
    initNaver.init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initNaver.accessToken) {
      initNaver.getLoginStatus((status) => {
        if (status) {
          const socialId = initNaver.user.id;

          socialLogin(socialId, "NAVER").then((joinFlag) => {
            if (!joinFlag) {
              // -- 필요한 정보 저장 후 회원가입으로 유도 --
              setStorage("socialToken", socialId);
              setStorage("joinType", "NAVER");

              if (initNaver.user.name) {
                setStorage("name", initNaver.user.name);
              }
              if (initNaver.user.email) {
                setStorage("email", initNaver.user.email);
              }
            } else {
              // --가입되어있는 경우 로그인 처리(socialLogin함수에서 처리했을 경우 따로 할 거 없음)--
              // ------------------------
            }
          });
        }
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const naverLogin = () => {
    const naverLoginBtn = document.getElementById("naverIdLogin").firstChild;
    naverLoginBtn.click();
  };

  // ------------------------------------------------- //
  // ---------- 카카오 로그인(아직 미구현) ---------- //
  // ----------------------------------------------- //
  const initKakao = () => {
    const jsKey = process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY;
    const Kakao = window.Kakao;
    if (Kakao && !Kakao.isInitialized()) {
      Kakao.init(jsKey);
    }
  };

  useEffect(() => {
    initKakao();
  }, []);

  const kakaoLogin = () => {
    window.Kakao.Auth.login({
      success() {
        window.Kakao.API.request({
          url: "/v2/user/me",
          success(res) {
            const socialId = String(res.id);
            const kakaoAccount = res.kakao_account;

            socialLogin(socialId, "KAKAO").then((joinFlag) => {
              if (!joinFlag) {
                // -- 필요한 정보 저장 후 회원가입으로 유도 --
                setStorage("socialToken", socialId);
                setStorage("joinType", "KAKAO");

                if (kakaoAccount.email) {
                  setStorage("email", kakaoAccount.email);
                }
                if (kakaoAccount.name) {
                  setStorage("name", kakaoAccount.name);
                }
                if (kakaoAccount.phone_number) {
                  setStorage(
                    "phoneNumber",
                    removeNonNumeric(
                      kakaoAccount.phone_number.replace("+82 ", "0")
                    )
                  );
                }
              } else {
                // --가입되어있는 경우 로그인 처리(socialLogin함수에서 처리했을 경우 따로 할 거 없음)--
                // ------------------------
              }
            });
          },
          fail(error) {},
        });
      },
      fail(error) {},
    });
  };

  // ---------------------------------------------- //
  // ---------- 구글 로그인 (오류 있음) ---------- //
  // -------------------------------------------- //
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const onSuccess = (res) => {
    // -- 구글로그인 성공 시 플랫폼에 가입되어있는지 확인하고 로그인 or 회원가입 처리 --
    const googleInfo = jwtDecode(res.credential);
    socialLogin(googleInfo.sub, "GOOGLE").then((joinFlag) => {
      if (!joinFlag) {
        setStorage("socialToken", googleInfo.sub);
        setStorage("joinType", "GOOGLE");
        if (googleInfo.email) {
          setStorage("email", googleInfo.email);
        }
        const familyName = googleInfo.family_name ? googleInfo.family_name : "";
        const givenName = googleInfo.given_name ? googleInfo.given_name : "";
        setStorage("name", familyName + givenName);
      } else {
        // --가입되어있는 경우 로그인 처리(socialLogin함수에서 처리했을 경우 따로 할 거 없음)--
        // ------------------------
      }
    });
  };

  const onFailure = (err) => {
    console.error(err);
  };

  const googleLogin = () => {
    const googleBtn = document.querySelector(
      '[aria-labelledby="button-label"]'
    );
    googleBtn?.click();
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 500 + elementHeight,
      }}
    >
      <Header />
      <BodyContainer
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        <LoginContainer ref={elementRef}>
          <LoginInnerContainer>
            <Title
              style={{
                marginBottom: 35,
              }}
            >
              로그인
            </Title>

            {/* 폼 영역 */}
            <LoginForm
              action="/"
              method="post"
              ref={form}
              onSubmit={handleLogin}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 15,
                }}
              >
                {/* 입력 필드 영역 */}
                <InputFieldWrapper>
                  {/* 이메일 입력 필드 영역 */}
                  <InputBox
                    $uniqueKey="email"
                    label="이메일"
                    type="email"
                    placeholder="이메일을 입력해 주세요."
                    $isRequired
                    value={loginValue.email}
                    onChange={handleInputValueChange}
                    $isError={!emailRegexTest}
                    $errorMsg="이메일 형식이 올바르지 않습니다."
                  />

                  {/* 비밀번호 입력 필드 영역 */}
                  <InputBox
                    $uniqueKey="pw"
                    label="비밀번호"
                    type="password"
                    placeholder="8자 이상 16자 이내 문자/영문/특수기호 사용 가능"
                    $isRequired
                    value={loginValue.pw}
                    onChange={handleInputValueChange}
                    $isError={!pwRegexTest}
                    $errorMsg="비밀번호는 8자 이상 16자 이내 문자/영문/특수기호를 사용하여야 합니다."
                  />
                </InputFieldWrapper>

                {/* 유저 정보 찾기 영역 */}
                <FindUserDataWrapper>
                  <FindUserData to="/findEmail" onClick={scrollToTop}>
                    이메일찾기
                  </FindUserData>
                  <span
                    style={{
                      height: 14,
                      width: 1,
                      backgroundColor: "#e9e9e9",
                    }}
                  />
                  <FindUserData to="/findPw" onClick={scrollToTop}>
                    비밀번호찾기
                  </FindUserData>
                </FindUserDataWrapper>
              </div>

              {/* 로그인 버튼 영역 */}
              <PrimaryBtn
                disabled={
                  !(
                    emailRegexTest &&
                    pwRegexTest &&
                    loginValue.email !== "" &&
                    loginValue.pw !== ""
                  )
                }
                type="submit"
              >
                로그인
              </PrimaryBtn>

              {/* 알림창 영역 */}
              {alertIsOpen && (
                <Alert
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  onClick={handleAlertClose}
                >
                  {responseMessage}
                </Alert>
              )}
            </LoginForm>

            {/* 회원가입하기 링크 영역 */}
            <RegisterWrapper>
              <PretendardText
                style={{
                  fontWeight: 400,
                  fontSize: 14,
                  lineHeight: "22px",
                  color: "#939393",
                  textAlign: "center",
                }}
              >
                아직 회원이 아닌가요?
              </PretendardText>
              <Link
                to="/register"
                style={{
                  fontFamily: "Pretendard",
                  fontWeight: 400,
                  fontSize: 14,
                  lineHeight: "22px",
                  color: "#383838",
                  textAlign: "center",
                  textDecoration: "underline",
                }}
                onClick={scrollToTop}
              >
                회원가입하기
              </Link>
            </RegisterWrapper>
          </LoginInnerContainer>

          {/* 구분선 영역 */}
          <DivisionLine
            style={{
              margin: "35px 0",
            }}
          />

          {/* 간편 로그인 영역 */}
          <EasyLoginContainer>
            <PretendardText
              style={{
                fontWeight: 400,
                fontSize: 14,
                lineHeight: "140%",
                color: "#6f6f6f",
                textAlign: "center",
              }}
            >
              간편 로그인
            </PretendardText>
            <EasyLoginWrapper>
              {/* 카카오 */}
              <KakaoLoginIcon
                onClick={() => {
                  kakaoLogin();
                }}
              />

              {/* 네이버 */}
              <NaverLoginIcon onClick={naverLogin} />
              <div id="naverIdLogin" style={{ display: "none" }} />

              {/* 구글 */}
              <GoogleLoginIcon
                onClick={() => {
                  googleLogin();
                }}
              />
              <div style={{ display: "none" }}>
                <GoogleOAuthProvider clientId={googleClientId}>
                  <GoogleLogin
                    onSuccess={(res) => {
                      onSuccess(res);
                    }}
                    onFailure={(err) => {
                      onFailure(err);
                    }}
                  />
                </GoogleOAuthProvider>
              </div>
            </EasyLoginWrapper>
          </EasyLoginContainer>
        </LoginContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default Login;

const LoginContainer = styled.section`
  position: absolute;
  top: 165px;
  width: 430px;
  display: flex;
  flex-direction: column;
`;

const LoginInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FindUserDataWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FindUserData = styled(Link)`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #6f6f6f;
`;

const RegisterWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;

const EasyLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-self: center;
`;

const EasyLoginWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
