import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Title from "../../../../components/atoms/Title";
import BodyContainer from "../../../../components/molecules/layout/BodyContainer";
import Header from "../../../../components/organisms/Header";
import useResizeHeight from "../../../../hooks/useResizeHeight";
import Footer from "../../../../components/organisms/Footer";
import PretendardText from "../../../../components/atoms/PretendardText";
import { useRecoilValue } from "recoil";
import {
  deleteStorage,
  getStorage,
} from "../../../../lib/utils/sessionStorage";
import axios from "axios";
import { url } from "../../../../lib/api/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { myInfoValueState } from "../../../../store/myInfo";
import PageContainer from "../../../../components/molecules/layout/PageContainer";
import Alert from "../../../../components/molecules/Alert";
import { formatRatingNumber } from "../../../../lib/utils/formatNumber";
import CommonSideBar from "../_components/CommonSideBar";
import { formatDate } from "./../../../../lib/utils/formatDate";
import route from "../../../../router/route";
import SortedResultBoldText from "./../../../../components/molecules/SortedResultBoldText";
import Pagination from "./../../../../components/molecules/Pagination";

const CommonMyReview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    if (myInfo.id === 0 || !myInfo) {
      return;
    } else {
      getTokens();
    }
  }, [myInfo]);

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    if (token !== "") {
      getReviewList();
    }
  }, [token, location]); // eslint-disable-line

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // 알림창 관련 상태
  const [alertOpen, setAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // ------------------------------------------- //
  // ---------- 리뷰 데이터 가져오기 ---------- //
  // ----------------------------------------- //
  const [reviewList, setReviewList] = useState([]);

  // 페이지네이션 관련 상태
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 5, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const getReviewList = async () => {
    try {
      const data = {
        page: searchParams.get("page") ? searchParams.get("page") : 1,
        size: pagination.size,
        orderBy: "DESC",
      };

      const response = await axios.get(`${url}member/reviews`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        params: data,
      });
      setReviewList(response.data.data.content);

      setPagination({
        ...pagination,
        totalCount: response.data.data.totalCount
          ? response.data.data.totalCount
          : response.data.data.content.length,
        totalPages: response.data.data.totalPage
          ? response.data.data.totalPage
          : Math.ceil(
              response.data.data.totalCount
                ? response.data.data.totalCount / 5
                : response.data.data.content.length / 5
            ),
      });
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 550 + elementHeight,
      }}
    >
      <Header $isBgWhite={true} />
      <BodyContainer
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        <MyPageContainer ref={elementRef}>
          <Title>마이페이지</Title>
          <MyPageInnerContainerWrapper>
            <CommonSideBar />

            <MyPageContentsContainer>
              <SortedResultTextWrapper>
                <SortedResultBoldText
                  fontSize={20}
                  $title="내 후기"
                  $count={pagination.totalCount}
                />
              </SortedResultTextWrapper>
              <MyReviewTitleWrapper>
                <MyReviewTitleText
                  style={{
                    width: 634,
                    marginRight: 37,
                  }}
                >
                  리뷰
                </MyReviewTitleText>
                <MyReviewTitleText
                  style={{
                    width: 25,
                    marginRight: 58,
                    textAlign: "center",
                  }}
                >
                  별점
                </MyReviewTitleText>
                <MyReviewTitleText
                  style={{
                    width: 98,
                    textAlign: "center",
                  }}
                >
                  작성일
                </MyReviewTitleText>
              </MyReviewTitleWrapper>
              <MyReviewContentContainer>
                <>
                  {reviewList.map((item, index) => (
                    <MyReviewContentWrapper
                      onClick={() => {
                        navigate(
                          `${route.myPage_common_myReview_detail}/${item.id}`
                        );
                      }}
                      key={index}
                    >
                      <MyReviewContent
                        style={{
                          width: 634,
                          marginRight: 37,
                        }}
                      >
                        {item.content}
                      </MyReviewContent>
                      <MyReviewContent
                        style={{
                          width: 25,
                          marginRight: 58,
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        {formatRatingNumber(String(item.rating))}
                      </MyReviewContent>
                      <MyReviewContent
                        style={{
                          width: 98,
                          textAlign: "center",
                          color: "#939393",
                        }}
                      >
                        {formatDate(item.createdAt)}
                      </MyReviewContent>
                    </MyReviewContentWrapper>
                  ))}
                  <Pagination
                    route={route.myPage_common_myReview}
                    currentPage={page}
                    totalPages={pagination.totalPages}
                    rangeSize={pagination.rangeSize}
                  />
                </>
              </MyReviewContentContainer>

              {alertOpen && (
                <Alert
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAlertOpen(false);
                  }}
                >
                  {responseMessage}
                </Alert>
              )}
            </MyPageContentsContainer>
          </MyPageInnerContainerWrapper>
        </MyPageContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default CommonMyReview;

const MyPageContainer = styled.div`
  position: absolute;
  top: 165px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MyPageInnerContainerWrapper = styled.div`
  display: flex;
  gap: 31px;
  width: 100%;
  margin-top: 30px;
`;

const MyPageContentsContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SortedResultTextWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const MyReviewTitleWrapper = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  height: 65px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #939393;
`;

const MyReviewTitleText = styled(PretendardText)`
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  color: #282828;
`;

const MyReviewContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
`;

const MyReviewContentWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 65px;
  border-bottom: 1px solid #e9e9e9;
  background-color: #fff;
`;

const MyReviewContent = styled(PretendardText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: #282828;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
`;
