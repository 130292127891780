import { useRef, useState, useEffect } from "react";

const useResizeHeight = () => {
  const elementRef = useRef(null);
  const [elementHeight, setElementHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (elementRef.current) {
        setElementHeight(elementRef.current.clientHeight);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    // 컴포넌트 언마운트 시 관찰 중지
    return () => {
      if (elementRef.current) {
        resizeObserver.disconnect();
      }
    };
  }, [elementRef.current]);

  return { elementRef, elementHeight };
};

export default useResizeHeight;
