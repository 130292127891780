import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import {
  initialRegisterCheckedValues,
  initialRegisterInputValues,
  registerCheckedValueState,
  registerInputValueState,
} from "../../store/registerStore";
import PageContainer from "../../components/molecules/layout/PageContainer";
import BodyContainer from "../../components/molecules/layout/BodyContainer";
import Header from "../../components/organisms/Header";
import Footer from "../../components/organisms/Footer";
import Title from "../../components/atoms/Title";
import SubTitle from "./../../components/atoms/SubTitle";
import PretendardText from "../../components/atoms/PretendardText";
import {
  deleteStorage,
  getStorage,
  setStorage,
} from "../../lib/utils/sessionStorage";
import RegisterCheckBox from "../../components/molecules/RegisterCheckBox";
import { useNavigate } from "react-router-dom";
import useResizeHeight from "../../hooks/useResizeHeight";
import regex from "../../constants/regex";
import PrimaryBtn from "./../../components/atoms/buttons/PrimaryBtn";
import InputBox from "../../components/molecules/InputBox";
import InputSelectBox from "../../components/molecules/InputSelectBox";
import SelectList from "./../../components/molecules/SelectList";
import SelectOption from "./../../components/atoms/SelectOption";
import DivisionLine from "./../../components/atoms/DivisionLine";
import CheckboxIcon from "./../../components/atoms/icons/CheckboxIcon";
import Alert from "../../components/molecules/Alert";
import sectorOptions from "./../../constants/sectorOptions";
import axios from "axios";
import regionOptions from "../../constants/regionOptions";
import TextFormEditor from "../../components/atoms/TextFormEditor";
import { url } from "../../lib/api/api";
import { removeNonNumeric } from "../../lib/utils/formatNumber";
import ModalOverlay from "../../components/atoms/ModalOverlay";
import Address from "../../components/organisms/Adress";
import route from "../../router/route";
import scrollToTop from "../../lib/utils/scrollToTop";
import TermsModal from "../../components/molecules/termsModal";
import _ from "lodash";

const RegisterCompany = () => {
  const navigate = useNavigate();

  // 전역 상태
  const [registerInputValue, setRegisterInputValue] = useRecoilState(
    registerInputValueState
  );
  const [registerCheckedValue, setRegisterCheckedValue] = useRecoilState(
    registerCheckedValueState
  );

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    const getInfo = async () => {
      const joinType = await getStorage("joinType");
      const email = await getStorage("email");
      const socialId = await getStorage("socialToken");
      const name = await getStorage("name");
      const phoneNumber = await getStorage("phoneNumber");

      setRegisterInputValue((prevState) => ({
        ...prevState,
        joinType: joinType !== null ? joinType : "NORMAL",
        email: email !== null ? email : "",
        socialId: socialId !== null ? socialId : "",
        name: name !== null ? name : "",
        phoneNumber: phoneNumber !== null ? phoneNumber : "",
      }));
    };

    getInfo();

    getTermsData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // 유효성 검사 결과 상태
  const [emailRegexTest, setEmailRegexTest] = useState(true); // 이메일 정규식 테스트 결과 상태
  const [nameRegexTest, setNameRegexTest] = useState(true); // 이름 정규식 테스트 결과 상태
  const [phoneNumberRegexTest, setPhoneNumberRegexTest] = useState(true); // 전화번호 정규식 테스트 결과 상태
  const [certNumRegexTest, setCertNumRegexTest] = useState(true); // 인증번호 정규식 테스트 결과 상태
  const [certNumSent, setCertNumSent] = useState(false); // 인증번호 발송 여부 상태
  const [verifyCertNum, setVerifyCertNum] = useState(false); // 인증번호 확인 상태
  // const [ageRegexTest, setAgeRegexTest] = useState(true); // 나이 정규식 테스트 결과 상태
  const [pwRegexTest, setPwRegexTest] = useState(true); // 비밀번호 정규식 테스트 결과 상태
  const [checkPwRegexTest, setCheckPwRegexTest] = useState(true); // 비밀번호 확인 정규식 테스트 결과 상태
  const [addressRegexTest, setAddressRegexTest] = useState(true); // 사업장 소재지 정규식 테스트 결과 상태
  const [addressDetailRegexTest, setAddressDetailRegexTest] = useState(true); // 상세주소 정규식 테스트 결과 상태
  const [companyNameRegexTest, setCompanyNameRegexTest] = useState(true); // 회사명 정규식 테스트 결과 상태
  const [companyNumberRegexTest, setCompanyNumberRegexTest] = useState(true); // 사업자번호 정규식 테스트 결과 상태
  const [ceoRegexTest, setCeoRegexTest] = useState(true); // 대표자명 정규식 테스트 결과 상태
  const [telRegexTest, setTelRegexTest] = useState(true); // 대표전화번호 정규식 테스트 결과 상태

  const [isEmailDuplicated, setIsEmailDuplicated] = useState(false); // 이메일 중복 여부 상태

  // 이메일 중복 확인 함수
  const checkDuplicateEmail = useCallback(
    _.throttle(async (email) => {
      const password = "check";
      try {
        await axios.post(
          `${url}auth/login`,
          {
            email: email,
            password: password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {
        if (error.response.data.code === 40002) {
          setIsEmailDuplicated(true);
        } else {
          setIsEmailDuplicated(false);
        }
      }
    }, 500),
    []
  );

  // 프로필 페이지 전환
  const [isShowRegisterProfilePage, setIsShowRegisterProfilePage] =
    useState(false);

  // 정규식 검사 함수
  const validateInput = (regex, value) => {
    return regex.test(value); // 통과하면 true, 실패하면 false 반환
  };

  // 입력 필드 핸들러
  const handleInputValueChange = (e) => {
    const { name, value } = e.target;

    if (
      // name === "age" ||
      name === "phoneNumber" ||
      name === "companyNumber" ||
      name === "tel"
    ) {
      setRegisterInputValue((prevState) => ({
        ...prevState,
        [name]: removeNonNumeric(value),
      }));
    } else {
      setRegisterInputValue((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    const storageCertNum = sessionStorage.getItem("certNum");

    const validateField = (name, value) => {
      switch (name) {
        case "email":
          if (value !== "") {
            setEmailRegexTest(validateInput(regex.email, value));
            checkDuplicateEmail(value);
          } else {
            setEmailRegexTest(true);
          }
          break;
        case "name":
          if (value !== "") {
            setNameRegexTest(validateInput(regex.text, value));
          } else {
            setNameRegexTest(true);
          }
          break;
        case "phoneNumber":
          if (value !== "") {
            setPhoneNumberRegexTest(validateInput(regex.tel, value));
          } else {
            setPhoneNumberRegexTest(true);
          }
          break;
        case "certNum":
          if (value !== "") {
            setCertNumRegexTest(validateInput(regex.certNum, value));
            if (value === storageCertNum) {
              setVerifyCertNum(true);
            } else {
              setVerifyCertNum(false);
            }
          } else {
            setCertNumRegexTest(true);
          }
          break;
        // case "age":
        //   if (value !== "") {
        //     setAgeRegexTest(validateInput(regex.age, value));
        //   } else {
        //     setAgeRegexTest(true);
        //   }
        //   break;
        case "password":
          if (value !== "") {
            setPwRegexTest(validateInput(regex.pw, value));
          } else {
            setPwRegexTest(true);
          }
          break;
        case "passwordCheck":
          if (value !== "") {
            setCheckPwRegexTest(value === registerInputValue.password);
          } else {
            setCheckPwRegexTest(true);
          }
          break;
        case "companyName":
          if (value !== "") {
            setCompanyNameRegexTest(validateInput(regex.text, value));
          } else {
            setCompanyNameRegexTest(true);
          }
          break;
        case "companyNumber":
          if (value !== "") {
            setCompanyNumberRegexTest(
              validateInput(regex.companyNumber, value)
            );
            console.log(validateInput(regex.companyNumber, value));
          } else {
            setCompanyNumberRegexTest(true);
          }
          break;
        case "ceo":
          if (value !== "") {
            setCeoRegexTest(validateInput(regex.text, value));
          } else {
            setCeoRegexTest(true);
          }
          break;
        case "tel":
          if (value !== "") {
            if (value.startsWith("010")) {
              setTelRegexTest(validateInput(regex.tel, value));
            } else {
              setTelRegexTest(validateInput(regex.companyTel, value));
            }
          } else {
            setTelRegexTest(true);
          }
          break;
        default:
          console.error("Invalid field name");
      }
    };

    validateField(name, value);
  };

  // 가입 유형, 약관 동의 체크박스 핸들러
  const handleCheckedChange = (e) => {
    const { name, checked } = e.target;
    setRegisterCheckedValue((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  // 주소 검색 관련
  const initialAddressValue = {
    address: "",
    addressDetail: "",
  };

  const [addressInputValue, setAddressInputValue] =
    useState(initialAddressValue);

  const [isAddressOpen, setIsAddressOpen] = useState(false); // 주소 검색 팝업 상태

  const handleAddressInput = (e) => {
    const { name, value } = e.target;

    setAddressInputValue({
      ...addressInputValue,
      [name]: value,
    });

    const validateField = (name, value) => {
      switch (name) {
        case "address":
          if (value !== "") {
            setAddressRegexTest(validateInput(regex.text, value));
          } else {
            setAddressRegexTest(true);
          }
          break;
        case "addressDetail":
          if (value !== "") {
            setAddressDetailRegexTest(validateInput(regex.text, value));
          } else {
            setAddressDetailRegexTest(true);
          }
          break;
        default:
          console.error("Invalid field name");
      }
    };

    validateField(name, value);
  };

  const handleAddressInputComplete = (data) => {
    setIsAddressOpen(!isAddressOpen);
  };

  const handleAddressPopupClose = () => {
    setIsAddressOpen(false);
  };

  // 회원가입 성공 여부 알림창 관련
  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);
  const [isFailAlertOpen, setIsFailAlertOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  // 서버 응답 메시지 상태
  const [responseMessage, setResponseMessage] = useState("");

  // 인증번호 발송 함수
  const handleCertNumSent = async () => {
    try {
      // 기존 회원인지 확인
      const response = await axios.post(`${url}auth/find/email`, {
        phoneNumber: removeNonNumeric(registerInputValue.phoneNumber),
      });
      if (response.data.data) {
        setIsFailAlertOpen(true);
        setResponseMessage("이미 가입된 정보입니다.");
        return;
      }
    } catch (error) {
      if (error.response) {
        // 가입한 휴대전화 정보가 없으면
        const response = await axios.post(`${url}auth/sms`, {
          phoneNumber: removeNonNumeric(registerInputValue.phoneNumber),
          content: "",
        });
        let certNum = response.data.data.authNumber;
        setStorage("certNum", certNum);
        setIsAlertOpen(true);
        setCertNumSent(true);
        setResponseMessage("인증번호가 발송되었습니다.");

        // 이전 입력된 인증번호 초기화
        setRegisterInputValue((prevState) => ({
          ...prevState,
          certNum: "",
        }));
      } else {
        // 서버 응답이 없을 때 처리
        setIsFailAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // 사용자의 전화번호 입력란 변경으로 인하여 인증번호 발송 상태 false로 변경
  useEffect(() => {
    if (!phoneNumberRegexTest || registerInputValue.phoneNumber === "") {
      setCertNumSent(false);
      setVerifyCertNum(false);
    }
  }, [phoneNumberRegexTest, registerInputValue.phoneNumber]);

  // 인증 이후 사용자의 전화번호 입력란 변경으로 인하여 인증번호 확인 상태 false로 변경
  useEffect(() => {
    if (!certNumRegexTest || registerInputValue.certNum === "") {
      setVerifyCertNum(false);
    }
  }, [certNumRegexTest, registerInputValue.certNum]);

  // ---------- 약관 관련 ---------- //
  const [termsData, setTermsData] = useState({
    useTerms: {},
    personalTerms: {},
    providePrivacyTerms: {},
  });

  const getTermsData = async () => {
    try {
      let response;
      response = await axios.get(`${url}terms/${1}`);
      setTermsData((prev) => ({
        ...prev,
        useTerms: response.data.data.content,
      }));
      response = await axios.get(`${url}terms/${2}`);
      setTermsData((prev) => ({
        ...prev,
        privacyTerms: response.data.data.content,
      }));
      response = await axios.get(`${url}terms/${4}`);
      setTermsData((prev) => ({
        ...prev,
        providePrivacyTerms: response.data.data.content,
      }));
    } catch (error) {
      console.error(error);
      setIsAlertOpen(true);
      setResponseMessage("잠시 후 다시 시도해주세요.");
    }
  };

  // -------------------------------- //
  // ---------- 모달 관련 ---------- //
  // ------------------------------ //

  // 모달 애니메이션 상태
  const [isAnimating, setIsAnimating] = useState(false);

  // 이용약관 모달
  const [isAgreementOpen, setIsAgreementOpen] = useState(false);

  const openAgreementModal = () => {
    setIsAgreementOpen(true);
  };

  const closeAgreementModal = () => {
    setIsAnimating(true);
    setTimeout(() => {
      // 300ms 후 모달 상태 변경
      setIsAgreementOpen(false);
      setIsAnimating(false);
    }, 300);
  };

  // 개인정보처리방침 모달
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);

  const openPrivacyModal = () => {
    setIsPrivacyOpen(true);
  };

  const closePrivacyModal = () => {
    setIsAnimating(true);
    setTimeout(() => {
      // 300ms 후 모달 상태 변경
      setIsPrivacyOpen(false);
      setIsAnimating(false);
    }, 300);
  };

  // 개인정보 제 3자 제공 동의 모달
  const [isProvidePrivacyOpen, setIsProvidePrivacyOpen] = useState(false);

  const openProvidePrivacyModal = () => {
    setIsProvidePrivacyOpen(true);
  };

  const closeProvidePrivacyModal = () => {
    setIsAnimating(true);
    setTimeout(() => {
      // 300ms 후 모달 상태 변경
      setIsProvidePrivacyOpen(false);
      setIsAnimating(false);
    }, 300);
  };

  // 지역 선택 관련
  const [selectedRegion, setSelectedRegion] = useState(""); // 선택된 지역 값
  const [isRegionRotated, setIsRegionRotated] = useState(false); // 지역 선택을 위한 드롭다운 회전
  const [verifyRegion, setVerifyRegion] = useState(true); // 지역 선택 완료 시 true 전환

  // 업종 선택 관련
  const [selectedSector, setSelectedSector] = useState(""); // 선택된 업종 값
  const [isSectorRotated, setIsSectorRotated] = useState(false); // 업종 선택을 위한 드롭다운 회전
  const [verifySector, setVerifySector] = useState(true); // 업종 선택 완료 시 true 전환

  // 폼 제출 핸들러
  const handleSendCompanyRegisterForm = async (e) => {
    e.preventDefault();

    if (registerInputValue.socialId === "") {
      const userData = {
        companyName: registerInputValue.companyName,
        region: registerInputValue.region,
        companyRegion: `${addressInputValue.address} ${addressInputValue.addressDetail}`,
        companyNumber: removeNonNumeric(registerInputValue.companyNumber),
        ceo: registerInputValue.ceo,
        tel: removeNonNumeric(registerInputValue.tel),
        intro: registerInputValue.intro,
        email: registerInputValue.email,
        name: registerInputValue.name,
        phoneNumber: removeNonNumeric(registerInputValue.phoneNumber),
        // age: Number(removeNonNumeric(registerInputValue.age)),
        age: 99,
        password: registerInputValue.password,
        sector: registerInputValue.sector,
        agreement: registerCheckedValue.agreement,
        privacy: registerCheckedValue.privacy,
        providePrivacy: registerCheckedValue.providePrivacy,
        advertisement: registerCheckedValue.advertisement,
        joinType: registerInputValue.joinType,
        socialId: registerInputValue.socialId
          ? registerInputValue.socialId
          : "",
      };

      try {
        await axios.post(`${url}auth/sign/company`, userData);
        setIsSuccessAlertOpen(true); // 회원가입 성공 알림창 열기
      } catch (error) {
        setIsFailAlertOpen(true); // 회원가입 실패 알림창 열기
        console.error("회원가입 에러 발생:", error);
        if (error.response) {
          // 서버 응답이 있을 때 처리
          console.error("응답: ", error.response);
          console.error("응답 데이터: ", error.response.data);
          console.error("응답 상태 코드: ", error.response.status);
          console.error("응답 메시지: ", error.response.data.message);
          setResponseMessage("잘못된 요청입니다.");
        } else {
          // 서버 응답이 없을 때 처리
          console.error("서버에 연결할 수 없습니다.");
          setResponseMessage("잠시 후 다시 시도해 주세요.");
        }
      }
    } else {
      const userData = {
        companyName: registerInputValue.companyName,
        region: registerInputValue.region,
        companyRegion: `${addressInputValue.address} ${addressInputValue.addressDetail}`,
        companyNumber: removeNonNumeric(registerInputValue.companyNumber),
        ceo: registerInputValue.ceo,
        tel: removeNonNumeric(registerInputValue.tel),
        intro: registerInputValue.intro,
        email: registerInputValue.email,
        name: registerInputValue.name,
        phoneNumber: removeNonNumeric(registerInputValue.phoneNumber),
        // age: Number(removeNonNumeric(registerInputValue.age)),
        age: 99,
        sector: registerInputValue.sector,
        agreement: registerCheckedValue.agreement,
        privacy: registerCheckedValue.privacy,
        providePrivacy: registerCheckedValue.providePrivacy,
        advertisement: registerCheckedValue.advertisement,
        joinType: registerInputValue.joinType,
        socialId: registerInputValue.socialId,
      };

      try {
        await axios.post(`${url}auth/sign/company/social`, userData);
        setIsSuccessAlertOpen(true); // 회원가입 성공 알림창 열기
      } catch (error) {
        setIsFailAlertOpen(true); // 회원가입 실패 알림창 열기
        console.error("회원가입 에러 발생:", error);
        if (error.response) {
          // 서버 응답이 있을 때 처리
          console.error("응답: ", error.response);
          console.error("응답 데이터: ", error.response.data);
          console.error("응답 상태 코드: ", error.response.status);
          console.error("응답 메시지: ", error.response.data.message);
          setResponseMessage("잘못된 요청입니다.");
        } else {
          // 서버 응답이 없을 때 처리
          console.error("서버에 연결할 수 없습니다.");
          setResponseMessage("잠시 후 다시 시도해 주세요.");
        }
      }
    }
  };

  // 에디터
  const [position, setPosition] = useState(0); // eslint-disable-line no-unused-vars
  const [isBlurEditor, setIsBlurEditor] = useState(false); // eslint-disable-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 1150 + elementHeight,
      }}
    >
      <Header />
      <BodyContainer
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        {/* 기업회원 가입 페이지 */}
        {!isShowRegisterProfilePage && (
          <RegisterContainer ref={elementRef} style={{ top: 165 }}>
            {/* 상단 Range 영역 */}
            <RangeContainer>
              <RangeWrapper>
                <RangeNumEllipse>01</RangeNumEllipse>
                <RangeText>회원정보</RangeText>
              </RangeWrapper>
              <RangeDivisionLine />
              <RangeWrapper>
                <RangeNumEllipse
                  style={{
                    backgroundColor: "#e9e9e9",
                    color: "#939393",
                  }}
                >
                  02
                </RangeNumEllipse>
                <RangeText
                  style={{
                    color: "#939393",
                  }}
                >
                  기업정보
                </RangeText>
              </RangeWrapper>
            </RangeContainer>

            {/* 입력 폼 시작 영역 */}
            <RegisterTitleWrapper>
              <Title>회원가입</Title>
              <SubTitle>아래의 정보를 모두 입력해 주세요.</SubTitle>
            </RegisterTitleWrapper>
            <RegisterForm
              onSubmit={(e) => {
                e.preventDefault();
                setIsShowRegisterProfilePage(true);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              {/* Input 영역 */}
              <InputBoxContainer>
                {/* 이메일 */}
                <InputBox
                  $uniqueKey="email"
                  label="이메일"
                  type="email"
                  placeholder="이메일을 입력해 주세요."
                  $isRequired
                  value={registerInputValue.email}
                  onChange={handleInputValueChange}
                  $isError={!emailRegexTest || isEmailDuplicated}
                  $errorMsg={
                    isEmailDuplicated
                      ? "이미 가입한 이메일입니다."
                      : "이메일 형식이 올바르지 않습니다."
                  }
                />

                {/* 이름 */}
                <InputBox
                  $uniqueKey="name"
                  label="이름"
                  type="text"
                  placeholder="이름을 입력해 주세요."
                  $isRequired
                  value={registerInputValue.name}
                  onChange={handleInputValueChange}
                  $isError={!nameRegexTest}
                  $errorMsg="2글자 이상 입력해 주세요."
                />

                {/* 전화번호 */}
                <InputBox
                  $uniqueKey="phoneNumber"
                  label="전화번호"
                  type="text"
                  placeholder="전화번호를 입력해 주세요."
                  $isRequired
                  value={registerInputValue.phoneNumber}
                  onChange={handleInputValueChange}
                  $isError={!phoneNumberRegexTest}
                  $errorMsg="전화번호 형식이 올바르지 않습니다."
                  $isShowBtn={true}
                  $btnText={certNumSent ? "재발송" : "인증하기"}
                  $btnDisabled={
                    (!phoneNumberRegexTest &&
                      registerInputValue.phoneNumber !== "") ||
                    verifyCertNum
                  }
                  $btnHandler={() => {
                    if (registerInputValue.phoneNumber !== "") {
                      handleCertNumSent();
                    }
                  }}
                />

                {/* 인증번호 */}
                {certNumSent && phoneNumberRegexTest && (
                  <InputBox
                    isNoLabel
                    $uniqueKey="certNum"
                    type="number"
                    placeholder="인증번호를 입력해 주세요."
                    value={registerInputValue.certNum}
                    onChange={handleInputValueChange}
                    $isError={!certNumRegexTest}
                    $errorMsg="인증번호 6자리를 올바르게 입력해 주세요."
                    $isHelp={
                      verifyCertNum ||
                      (registerInputValue.certNum !== "" &&
                        certNumRegexTest &&
                        !verifyCertNum)
                    }
                    $helpMsg={
                      certNumRegexTest && !verifyCertNum
                        ? "인증번호가 불일치합니다."
                        : "인증번호가 일치합니다."
                    }
                    readOnly={verifyCertNum}
                    disabled={verifyCertNum}
                  />
                )}

                {/* 나이 */}
                {/* <InputBox
                  $uniqueKey="age"
                  label="나이"
                  type="text"
                  placeholder="나이를 입력해 주세요."
                  $isRequired
                  value={registerInputValue.age}
                  onChange={handleInputValueChange}
                  $isError={!ageRegexTest}
                  $errorMsg="나이를 정확히 입력해 주세요."
                /> */}

                {registerInputValue.socialId === "" && (
                  <>
                    {/* 비밀번호 */}
                    <InputBox
                      $uniqueKey="password"
                      label="비밀번호"
                      type="password"
                      placeholder="8자 이상 16자 이내 문자/영문/특수기호 사용 가능"
                      $isRequired
                      value={registerInputValue.password}
                      onChange={handleInputValueChange}
                      $isError={!pwRegexTest}
                      $errorMsg="8~16자 이내, 문자/영문/특수기호 중 2가지 이상 사용해 주세요."
                    />

                    {/* 비밀번호 재입력 */}
                    <InputBox
                      $uniqueKey="passwordCheck"
                      label="비밀번호 확인"
                      type="password"
                      placeholder="비밀번호를 한 번 더 입력해 주세요."
                      $isRequired
                      value={registerInputValue.passwordCheck}
                      onChange={handleInputValueChange}
                      $isError={!checkPwRegexTest}
                      $errorMsg="비밀번호가 일치하지 않습니다."
                    />
                  </>
                )}

                {/* 동의 체크박스 영역 */}
                <CheckboxContainer>
                  {/* 이용약관 동의 영역 */}
                  <CheckboxWrapper>
                    <RegisterCheckBox
                      $uniqueKey="agreement"
                      $isRequired
                      value="동의"
                      checked={registerCheckedValue.agreement}
                      onChange={handleCheckedChange}
                    >
                      <CheckboxIcon checked={registerCheckedValue.agreement} />
                      {termsData.useTerms?.title
                        ? `${termsData.useTerms?.title} 동의(필수)`
                        : "이용약관 동의(필수)"}
                    </RegisterCheckBox>
                    <CheckboxOpenModalText onClick={openAgreementModal}>
                      전체보기
                    </CheckboxOpenModalText>
                  </CheckboxWrapper>

                  {/* 이용약관 동의 모달 영역 */}
                  {isAgreementOpen && (
                    <TermsModal
                      $termsId={1}
                      $isOpen={!isAnimating}
                      handleClose={closeAgreementModal}
                    />
                  )}

                  {/* 개인정보처리방침 동의 영역 */}
                  <CheckboxWrapper>
                    <RegisterCheckBox
                      $uniqueKey="privacy"
                      $isRequired
                      value="동의"
                      checked={registerCheckedValue.privacy}
                      onChange={handleCheckedChange}
                    >
                      <CheckboxIcon checked={registerCheckedValue.privacy} />
                      {termsData.privacyTerms?.title
                        ? `${termsData.privacyTerms?.title} 동의(필수)`
                        : "개인정보처리방침 동의(필수)"}
                    </RegisterCheckBox>
                    <CheckboxOpenModalText onClick={openPrivacyModal}>
                      전체보기
                    </CheckboxOpenModalText>
                  </CheckboxWrapper>

                  {/* 개인정보처리방침 모달 영역 */}
                  {isPrivacyOpen && (
                    <TermsModal
                      $termsId={2}
                      $isOpen={!isAnimating}
                      handleClose={closePrivacyModal}
                    />
                  )}

                  {/* 개인정보 수집 및 이용 동의 영역 */}
                  <CheckboxWrapper>
                    <RegisterCheckBox
                      $uniqueKey="providePrivacy"
                      $isRequired
                      value="동의"
                      checked={registerCheckedValue.providePrivacy}
                      onChange={handleCheckedChange}
                    >
                      <CheckboxIcon
                        checked={registerCheckedValue.providePrivacy}
                      />
                      {termsData.providePrivacyTerms?.title
                        ? `${termsData.providePrivacyTerms?.title} 동의(필수)`
                        : "개인정보 수집 및 이용 동의(필수)"}
                    </RegisterCheckBox>
                    <CheckboxOpenModalText onClick={openProvidePrivacyModal}>
                      전체보기
                    </CheckboxOpenModalText>
                  </CheckboxWrapper>

                  {/* 개인정보 제 3자 제공 모달 영역 */}
                  {isProvidePrivacyOpen && (
                    <TermsModal
                      $termsId={4}
                      $isOpen={!isAnimating}
                      handleClose={closeProvidePrivacyModal}
                    />
                  )}

                  {/* 광고성 정보 수신 동의 영역 */}
                  <CheckboxWrapper>
                    <RegisterCheckBox
                      $uniqueKey="advertisement"
                      $isRequired
                      value={
                        registerCheckedValue.advertisement ? "동의" : "미동의"
                      }
                      checked={registerCheckedValue.advertisement}
                      onChange={handleCheckedChange}
                    >
                      <CheckboxIcon
                        checked={registerCheckedValue.advertisement}
                      />
                      광고성 정보 수신 동의
                    </RegisterCheckBox>
                  </CheckboxWrapper>
                </CheckboxContainer>
              </InputBoxContainer>
            </RegisterForm>
            <PrimaryBtn
              disabled={
                (registerInputValue.socialId === "" &&
                  registerInputValue.password === "") ||
                !(
                  registerInputValue.email !== "" &&
                  registerInputValue.name !== "" &&
                  registerInputValue.phoneNumber !== "" &&
                  registerInputValue.certNum !== "" &&
                  emailRegexTest &&
                  nameRegexTest &&
                  phoneNumberRegexTest &&
                  verifyCertNum &&
                  pwRegexTest &&
                  checkPwRegexTest &&
                  registerCheckedValue.providePrivacy &&
                  registerCheckedValue.privacy &&
                  registerCheckedValue.agreement &&
                  !isEmailDuplicated
                )
              }
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                setIsShowRegisterProfilePage(true);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              다음
            </PrimaryBtn>
            {/* 회원가입 실패 시 알림창 */}
            {isFailAlertOpen && (
              <Alert
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsFailAlertOpen(false);
                }}
              >
                {responseMessage}
              </Alert>
            )}

            {isAlertOpen && (
              <Alert
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsAlertOpen(false);
                }}
              >
                {responseMessage}
              </Alert>
            )}
          </RegisterContainer>
        )}

        {/* 기업회원 가입 상세 페이지 */}
        {isShowRegisterProfilePage && (
          <RegisterContainer ref={elementRef} style={{ top: 165 }}>
            {/* 상단 Range 영역 */}
            <RangeContainer>
              <RangeWrapper>
                <RangeNumEllipse>01</RangeNumEllipse>
                <RangeText>회원정보</RangeText>
              </RangeWrapper>
              <RangeDivisionLine
                style={{
                  backgroundColor: "#2ec5f6",
                }}
              />
              <RangeWrapper>
                <RangeNumEllipse>02</RangeNumEllipse>
                <RangeText>기업정보</RangeText>
              </RangeWrapper>
            </RangeContainer>

            {/* 입력 폼 시작 영역 */}
            <RegisterTitleWrapper>
              <Title>회원가입</Title>
              <SubTitle>
                전문가 정보를 모두 입력해 주세요.
                <br />
                회원가입 신청이 완료되면 검토 후 승인이 진행됩니다.
              </SubTitle>
            </RegisterTitleWrapper>

            <RegisterForm onSubmit={handleSendCompanyRegisterForm}>
              {/* Input 영역 */}
              <InputBoxContainer>
                {/* 기업명 */}
                <InputBox
                  $uniqueKey="companyName"
                  label="기업명"
                  type="text"
                  placeholder="상호명을 입력해 주세요."
                  $isRequired
                  value={registerInputValue.companyName}
                  onChange={handleInputValueChange}
                  $isError={!companyNameRegexTest}
                  $errorMsg="2글자 이상 입력해 주세요."
                />

                {/* 지역 */}
                <InputSelectBox
                  $uniqueKey="region"
                  label="지역"
                  readOnly
                  $isRequired
                  placeholder="지역을 선택해 주세요."
                  value={selectedRegion}
                  onChange={handleInputValueChange}
                  $isRotated={isRegionRotated}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsRegionRotated((prevState) => !prevState);
                  }}
                >
                  <SelectList
                    className="scrollbar"
                    style={{
                      top: 75,
                      left: 0,
                      width: "100%",
                      display: isRegionRotated ? "block" : "none",
                    }}
                  >
                    {Object.keys(regionOptions).map((key, index) => (
                      <React.Fragment key={key}>
                        <SelectOption
                          onClick={(e) => {
                            e.stopPropagation();
                            setRegisterInputValue((prevState) => ({
                              ...prevState,
                              region: key,
                            }));
                            setSelectedRegion(regionOptions[key]);
                            setIsRegionRotated(false); // 선택 후 리스트 창 닫기
                            setVerifyRegion(true);
                          }}
                        >
                          {regionOptions[key]}
                        </SelectOption>
                        {index < Object.keys(regionOptions).length - 1 && (
                          <DivisionLine
                            style={{
                              margin: "12px 0",
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </SelectList>
                </InputSelectBox>

                {/* 업종 */}
                <InputSelectBox
                  $uniqueKey="sector"
                  label="업종"
                  readOnly
                  $isRequired
                  placeholder="업종을 선택해 주세요."
                  value={selectedSector}
                  onChange={handleInputValueChange}
                  $isRotated={isSectorRotated}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsSectorRotated((prevState) => !prevState);
                  }}
                >
                  <SelectList
                    className="scrollbar"
                    style={{
                      top: 75,
                      left: 0,
                      width: "100%",
                      display: isSectorRotated ? "block" : "none",
                    }}
                  >
                    {Object.keys(sectorOptions).map((key, index) => (
                      <React.Fragment key={key}>
                        <SelectOption
                          onClick={(e) => {
                            e.stopPropagation();
                            setRegisterInputValue((prevState) => ({
                              ...prevState,
                              sector: key,
                            }));
                            setSelectedSector(sectorOptions[key]);
                            setIsSectorRotated(false); // 선택 후 리스트 창 닫기
                            setVerifySector(true);
                          }}
                        >
                          {sectorOptions[key]}
                        </SelectOption>
                        {index < Object.keys(sectorOptions).length - 1 && (
                          <DivisionLine
                            style={{
                              margin: "12px 0",
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </SelectList>
                </InputSelectBox>

                {/* 소재지 */}
                <InputBox
                  $uniqueKey="address"
                  label="사업장 소재지"
                  type="text"
                  placeholder="사업장 소재지를 입력해 주세요."
                  $isRequired
                  value={addressInputValue.address}
                  onChange={handleAddressInput}
                  $isError={!addressRegexTest}
                  $errorMsg="소재지를 올바르게 입력해 주세요. ex) 서울특별시 중구 세종대로 110"
                  $isShowBtn={true}
                  $btnText="검색하기"
                  $btnHandler={handleAddressInputComplete}
                  readOnly={true}
                />

                {isAddressOpen && (
                  <ModalOverlay $isOpen={isAddressOpen}>
                    <Address
                      address={addressInputValue}
                      setAddress={setAddressInputValue}
                      onClose={handleAddressPopupClose}
                    />
                  </ModalOverlay>
                )}

                {/* 상세주소 */}
                {addressInputValue.address !== "" && addressRegexTest && (
                  <InputBox
                    isNoLabel
                    $uniqueKey="addressDetail"
                    type="text"
                    placeholder="상세주소를 입력해 주세요."
                    value={addressInputValue.addressDetail}
                    onChange={handleAddressInput}
                    $isHelp={!addressDetailRegexTest}
                    $helpMsg="2글자 이상 입력해 주세요."
                  />
                )}

                {/* 사업자 번호 */}
                <InputBox
                  $uniqueKey="companyNumber"
                  label="사업자 번호"
                  type="text"
                  placeholder="사업자번호를 입력해 주세요."
                  $isRequired
                  value={registerInputValue.companyNumber}
                  onChange={handleInputValueChange}
                  $isError={!companyNumberRegexTest}
                  $errorMsg="사업자번호 10자리를 올바르게 입력해 주세요."
                />

                {/* 대표자명 */}
                <InputBox
                  $uniqueKey="ceo"
                  label="대표자명"
                  type="text"
                  placeholder="대표자명을 입력해 주세요."
                  $isRequired
                  value={registerInputValue.ceo}
                  onChange={handleInputValueChange}
                  $isError={!ceoRegexTest}
                  $errorMsg="2글자 이상 입력해 주세요."
                />

                {/* 기업 전화번호 */}
                <InputBox
                  $uniqueKey="tel"
                  label="전화번호"
                  type="text"
                  placeholder="기업의 대표번호를 입력해 주세요."
                  $isRequired
                  value={registerInputValue.tel}
                  onChange={handleInputValueChange}
                  $isError={!telRegexTest}
                  $errorMsg="전화번호 형식이 올바르지 않습니다."
                />

                {/* 기업 소개 */}
                <InputWrapper>
                  <InputLabel>
                    기업 소개
                    <span
                      style={{
                        fontFamily: "Pretendard",
                        color: "#2ec5f6",
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "145%",
                      }}
                    >
                      *
                    </span>
                  </InputLabel>
                  {/* <QuillEditor /> */}
                  {/* {textEditorValue === "<p><br></p>" && (
                      <ErrorMsg>기업 소개를 입력해 주세요.</ErrorMsg>
                    )} */}
                  <TextFormEditor
                    placeholder={"상세설명을 입력하세요"}
                    value={registerInputValue.intro}
                    onChange={(contents) => {
                      setRegisterInputValue((prevState) => ({
                        ...prevState,
                        intro: contents,
                      }));
                    }}
                    editorHeight={245}
                    style={{
                      width: "430px",
                      marginBottom: "12px",
                    }}
                    position={position}
                    isBlur={isBlurEditor}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                </InputWrapper>
                {/* 에디터를 통해 작성된 내용 보여지는 코드 */}
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(textEditorValue),
                  }}
                  style={{
                    marginTop: "30px",
                    overflow: "hidden",
                    whiteSpace: "pre-wrap",
                  }}
                /> */}
              </InputBoxContainer>
            </RegisterForm>
            <PrimaryBtn
              disabled={
                (registerInputValue.socialId === "" &&
                  registerInputValue.password === "") ||
                !(
                  registerInputValue.email !== "" &&
                  registerInputValue.name !== "" &&
                  registerInputValue.phoneNumber !== "" &&
                  registerInputValue.certNum !== "" &&
                  // registerInputValue.age !== "" &&
                  registerInputValue.companyName !== "" &&
                  registerInputValue.region !== "" &&
                  registerInputValue.companyNumber !== "" &&
                  registerInputValue.sector !== "" &&
                  addressInputValue.address !== "" &&
                  addressInputValue.addressDetail !== "" &&
                  registerInputValue.ceo !== "" &&
                  registerInputValue.tel !== "" &&
                  emailRegexTest &&
                  nameRegexTest &&
                  phoneNumberRegexTest &&
                  verifyCertNum &&
                  // ageRegexTest &&
                  pwRegexTest &&
                  companyNameRegexTest &&
                  verifyRegion &&
                  verifySector &&
                  companyNumberRegexTest &&
                  ceoRegexTest &&
                  telRegexTest &&
                  registerCheckedValue.providePrivacy &&
                  registerCheckedValue.privacy &&
                  registerCheckedValue.agreement &&
                  registerCheckedValue.intro !== "" &&
                  registerCheckedValue.intro !== "<p><br></p>"
                )
              }
              type="submit"
              onClick={handleSendCompanyRegisterForm}
            >
              회원가입 신청하기
            </PrimaryBtn>

            {/* 회원가입 성공 시 알림창 */}
            {isSuccessAlertOpen && (
              <Alert
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  // 회원가입 성공 후 로그인 페이지로 이동
                  navigate(route.login);
                  deleteStorage("certNum"); // 인증번호 세션 삭제
                  setRegisterInputValue(initialRegisterInputValues); // 입력 영역 상태 초기화
                  setRegisterCheckedValue(initialRegisterCheckedValues); // 체크 상태 초기화
                  scrollToTop();
                  setIsSuccessAlertOpen(false);
                }}
              >
                회원가입을 축하드립니다.
                <br />
                로그인 페이지로 이동합니다.
              </Alert>
            )}

            {/* 회원가입 알림창 */}
            {isFailAlertOpen && (
              <Alert
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                onClick={setIsFailAlertOpen(false)}
              >
                {responseMessage}
              </Alert>
            )}

            {isAlertOpen && (
              <Alert
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsAlertOpen(false);
                }}
              >
                {responseMessage}
              </Alert>
            )}
          </RegisterContainer>
        )}

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default RegisterCompany;

const RegisterContainer = styled.section`
  position: absolute;
  width: 430px;
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

const RegisterTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RegisterForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;

const InputBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CheckboxOpenModalText = styled(PretendardText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  color: #939393;
  text-decoration: underline;
  cursor: pointer;
`;

const RangeContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 189px;
  margin-bottom: 45px;
`;

const RangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const RangeNumEllipse = styled(PretendardText)`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  background-color: #2ec5f6;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 145%;
`;

const RangeText = styled(PretendardText)`
  color: #2ec5f6;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
`;

const RangeDivisionLine = styled.div`
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  width: 112px;
  height: 1px;
  background-color: #c0c0c0;
  z-index: 1;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 430px;
`;

const InputLabel = styled.label`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 145%;
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 9px;
`;

// const ErrorMsg = styled.p`
//   position: absolute;
//   top: 280px;
//   font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
//     sans-serif;
//   color: #ff3124;
//   font-size: 12px;
//   font-weight: 400;
//   line-height: 130%;
//   word-break: keep-all;
//   white-space: nowrap;
// `;
