import React from "react";
import { styled } from "styled-components";

export const FirstRankingBubble = (props) => {
  return (
    <StyledRankingBubble
      width="94"
      height="50"
      viewBox="0 0 94 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="94" height="42" rx="16" fill="#2EC5F6" />
      <path
        d="M41.377 14.8594V29H38.4277V17.6523H38.3496L35.1074 19.6836V17.0859L38.6035 14.8594H41.377ZM49.873 13.7656C52.5879 13.7461 54.5605 15.2988 54.5605 17.5352C54.5605 19.752 52.5879 21.3242 49.873 21.3047C47.168 21.3242 45.1953 19.752 45.2051 17.5352C45.1953 15.2988 47.168 13.7461 49.873 13.7656ZM44.1113 22.3984C47.2656 22.3887 51.7285 22.3301 55.5176 21.7539L55.6738 23.5898C54.209 23.8828 52.7051 24.0684 51.2207 24.1855V30.2695H48.7402V24.332C47.1777 24.4004 45.7031 24.4102 44.4238 24.4297L44.1113 22.3984ZM47.6074 17.5352C47.5977 18.6484 48.5449 19.293 49.873 19.2734C51.2402 19.293 52.1973 18.6484 52.1973 17.5352C52.1973 16.4023 51.2402 15.748 49.873 15.7578C48.5449 15.748 47.5977 16.4023 47.6074 17.5352ZM56.5918 30.7969V13.043H59.0527V30.7969H56.5918Z"
        fill="white"
      />
      <path
        d="M23.366 48.5C22.9811 49.1667 22.0189 49.1667 21.634 48.5L16.0048 38.75C15.6199 38.0833 16.101 37.25 16.8708 37.25L28.1292 37.25C28.899 37.25 29.3801 38.0833 28.9952 38.75L23.366 48.5Z"
        fill="#2EC5F6"
      />
    </StyledRankingBubble>
  );
};

export const SecondRankingBubble = (props) => {
  return (
    <StyledRankingBubble
      width="94"
      height="50"
      viewBox="0 0 94 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="94" height="42" rx="16" fill="#B4B1B1" />
      <path
        d="M42.4592 29H31.6643L31.6567 26.9717L37.1491 21.6768C38.7216 20.1043 39.542 19.1775 39.5496 17.8785C39.542 16.4199 38.3949 15.5083 36.868 15.5083C35.2727 15.5083 34.2395 16.511 34.2471 18.0912H31.5883C31.5731 15.1436 33.7837 13.2293 36.906 13.2293C40.0738 13.2293 42.2161 15.1285 42.2237 17.7341C42.2161 19.4965 41.3728 20.9171 38.3114 23.8039L35.5538 26.5463V26.6602H42.4592V29ZM58.1384 30.8992V11.5124H60.5085V30.8992H58.1384ZM50.61 20.4917C47.6777 20.5069 45.5507 18.8204 45.5582 16.4199C45.5507 13.9965 47.6777 12.3405 50.61 12.3481C53.5576 12.3405 55.6922 13.9965 55.6922 16.4199C55.6922 18.8204 53.5576 20.5069 50.61 20.4917ZM50.61 14.2776C49.0071 14.2624 47.86 15.0905 47.8676 16.4199C47.86 17.7341 49.0071 18.547 50.61 18.5318C52.2661 18.547 53.4208 17.7341 53.4208 16.4199C53.4208 15.0905 52.2661 14.2624 50.61 14.2776ZM56.8849 21.1298L57.052 22.8923C55.3732 23.2189 53.6335 23.424 51.9319 23.5532V30.3142H49.5389V23.6975C47.7461 23.7735 46.052 23.7811 44.5783 23.7963L44.282 21.8287C47.7841 21.8287 52.6915 21.7528 56.8849 21.1298Z"
        fill="white"
      />
      <path
        d="M23.366 48.5C22.9811 49.1667 22.0189 49.1667 21.634 48.5L16.0048 38.75C15.6199 38.0833 16.101 37.25 16.8708 37.25L28.1292 37.25C28.899 37.25 29.3801 38.0833 28.9952 38.75L23.366 48.5Z"
        fill="#B4B1B1"
      />
    </StyledRankingBubble>
  );
};

export const ThirdRankingBubble = (props) => {
  return (
    <StyledRankingBubble
      width="94"
      height="50"
      viewBox="0 0 94 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="94" height="42" rx="16" fill="#B4B1B1" />
      <path
        d="M36.9863 29.2127C33.6666 29.2127 31.2964 27.4047 31.2205 24.7762H34.0464C34.1148 26.0373 35.3606 26.8577 36.9787 26.8577C38.6956 26.8577 39.9339 25.9157 39.9263 24.5407C39.9339 23.1354 38.726 22.1326 36.6977 22.1326H35.3151V19.9751H36.6977C38.3537 19.9751 39.5084 19.0559 39.5084 17.6961C39.5084 16.3895 38.5209 15.5083 37.0167 15.5083C35.543 15.5083 34.2819 16.3287 34.2287 17.6278H31.5471C31.6079 15.0221 33.9781 13.2293 37.0319 13.2293C40.1769 13.2293 42.2205 15.1588 42.2053 17.5366C42.2205 19.2914 41.0658 20.5449 39.3565 20.9019V21.0235C41.5595 21.3198 42.8054 22.7251 42.7902 24.6851C42.8054 27.3059 40.3517 29.2127 36.9863 29.2127ZM58.4606 30.8992V11.5124H60.8308V30.8992H58.4606ZM50.9323 20.4917C48 20.5069 45.8729 18.8204 45.8805 16.4199C45.8729 13.9965 48 12.3405 50.9323 12.3481C53.8798 12.3405 56.0145 13.9965 56.0145 16.4199C56.0145 18.8204 53.8798 20.5069 50.9323 20.4917ZM50.9323 14.2776C49.3294 14.2624 48.1823 15.0905 48.1899 16.4199C48.1823 17.7341 49.3294 18.547 50.9323 18.5318C52.5884 18.547 53.7431 17.7341 53.7431 16.4199C53.7431 15.0905 52.5884 14.2624 50.9323 14.2776ZM57.2072 21.1298L57.3743 22.8923C55.6954 23.2189 53.9558 23.424 52.2541 23.5532V30.3142H49.8612V23.6975C48.0684 23.7735 46.3743 23.7811 44.9005 23.7963L44.6043 21.8287C48.1063 21.8287 53.0138 21.7528 57.2072 21.1298Z"
        fill="white"
      />
      <path
        d="M23.366 48.5C22.9811 49.1667 22.0189 49.1667 21.634 48.5L16.0048 38.75C15.6199 38.0833 16.101 37.25 16.8708 37.25L28.1292 37.25C28.899 37.25 29.3801 38.0833 28.9952 38.75L23.366 48.5Z"
        fill="#B4B1B1"
      />
    </StyledRankingBubble>
  );
};

const StyledRankingBubble = styled.svg`
  filter: ${(props) =>
    props.isFirst && "drop-shadow(0px 4px 20px rgba(36, 150, 187, 0.28))"};
`;
