import React from "react";
import styled from "styled-components";

const PageContainer = (props) => {
  return <StyledPageContainer {...props}>{props.children}</StyledPageContainer>;
};

export default PageContainer;

const StyledPageContainer = styled.div`
  width: 100%;
  min-height: 1080px;
  display: flex;
  justify-content: center;
`;
