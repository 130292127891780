import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PageContainer from "../../components/molecules/layout/PageContainer";
import BodyContainer from "../../components/molecules/layout/BodyContainer";
import Header from "../../components/organisms/Header";
import Footer from "../../components/organisms/Footer";
import DetailTopBanner from "./../../components/atoms/DetailTopBanner";
import WriteIconBtn from "./../../components/atoms/WriteIconBtn";
import PretendardText from "./../../components/atoms/PretendardText";
import { useRecoilState, useRecoilValue } from "recoil";
import useResizeHeight from "../../hooks/useResizeHeight";
import InputWrapper from "./../../components/molecules/layout/InputWrapper";
import InputLabel from "./../../components/atoms/InputLabel";
import InputField from "./../../components/atoms/InputField";
import regex from "../../constants/regex";
import DetailPageFileAddIcon from "../../components/atoms/icons/DetailPageFileAddIcon";
import { useNavigate } from "react-router-dom";
import scrollToTop from "../../lib/utils/scrollToTop";
import DownArrowIcon from "../../components/atoms/icons/DownArrowIcon";
import CompanyIcon from "../../components/atoms/icons/CompanyIcons";
import InputBox from "./../../components/molecules/InputBox";
import InputSelectBox from "./../../components/molecules/InputSelectBox";
import SelectList from "./../../components/molecules/SelectList";
import SelectOption from "./../../components/atoms/SelectOption";
import DivisionLine from "./../../components/atoms/DivisionLine";
import TextareaBox from "../../components/molecules/TextareaBox";
import SecondaryMiniBtn from "../../components/atoms/buttons/SecondaryMiniBtn";
import PrimaryMiniBtn from "../../components/atoms/buttons/PrimaryMiniBtn";
import SecondaryBtn from "./../../components/atoms/buttons/SecondaryBtn";
import { myInfoValueState } from "../../store/myInfo";
import { deleteStorage, getStorage } from "../../lib/utils/sessionStorage";
import Alert from "./../../components/molecules/Alert";
import axios from "axios";
import { url } from "../../lib/api/api";
import route from "../../router/route";
import {
  initialServiceImgValues,
  initialSelectedPortfolioListValues,
  initialServiceInputValues,
  selectedPortfolioListState,
  serviceImgValueState,
  serviceInputValueState,
  portfolioListState,
  portfolioIdListState,
} from "../../store/serviceStore";
import { LazyLoadImage } from "react-lazy-load-image-component";

const AddService = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // ------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ----------------------------- //

  // 기업 회원이 아닐 경우 홈으로 이동
  useEffect(() => {
    if (myInfo.id === 0 || !myInfo) {
      return;
    } else {
      if (myInfo.profile) {
        getTokens();
      } else {
        navigate("/");
      }
    }
  }, [myInfo]); // eslint-disable-line

  // 토큰 가져오기
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    if (token !== "") {
      getDataList();
    }
  }, [token]); // eslint-disable-line

  // 알림창 관련
  const [alertOpen, setAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // ------------------------------------------------- //
  // ---------- 마케팅 종류, 서비스 가져오기 ---------- //
  // ----------------------------------------------- //
  const [marketingTypeList, setMarketingTypeList] = useState([]);
  const [companyServiceList, setCompanyServiceList] = useState([]);
  const [portfolioList, setPortfolioList] = useRecoilState(portfolioListState);
  const [portfolioIdList, setPortfolioIdList] =
    useRecoilState(portfolioIdListState);
  const [selectedPortfolioList, setSelectedPortfolioList] = useRecoilState(
    selectedPortfolioListState
  );

  const getDataList = async () => {
    try {
      let response;

      // 마케팅 종류 가져오기
      const data = {
        page: 1,
        size: 0,
      };

      response = await axios.get(`${url}marketing-types`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        params: data,
      });
      setMarketingTypeList(response.data.data.content);

      // 서비스 리스트 가져오기
      response = await axios.get(`${url}member/merches`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        params: data,
      });
      setCompanyServiceList(response.data.data.content);
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // Down Arrow 회전 애니메이션 관련
  const [isMarketingRotated, setIsMarketingRotated] = useState(false);
  const [isBudgetRotated, setIsBudgetRotated] = useState(false);
  const [isExecutionPeriodRotated, setIsExecutionPeriodRotated] =
    useState(false);
  const [isPortfolioRotated, setIsPortfolioRotated] = useState(false);

  // --------------------------------- //
  // ---------- 이미지 관련 ---------- //
  // ------------------------------- //

  // 전체 이미지 상태
  const [serviceImgValue, setServiceImgValue] =
    useRecoilState(serviceImgValueState);

  // 배너 이미지 변경 이벤트 핸들러
  const handleBannerFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      setServiceImgValue((prevState) => ({
        ...prevState,
        bannerFile: selectedFile,
        bannerUrl: url,
      }));
      setIsShowResetBtn(true);

      event.target.value = "";
    }
  };

  // 프로필 이미지 변경 이벤트 핸들러
  const handleIconFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      setServiceImgValue((prevState) => ({
        ...prevState,
        iconFile: selectedFile,
        iconUrl: url,
      }));
      setIsShowResetBtn(true);

      event.target.value = "";
    }
  };

  // 상세페이지 이미지 첨부 이벤트 핸들러
  const handleDetailPageFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      setServiceImgValue((prevState) => ({
        ...prevState,
        detailPageFile: selectedFile,
        detailPageUrl: url,
      }));
      setIsShowResetBtn(true);

      event.target.value = "";
    }
  };

  // ------------------------------------ //
  // ---------- 입력 필드 관련 ---------- //
  // ---------------------------------- //

  const [serviceInputValue, setServiceInputValue] = useRecoilState(
    serviceInputValueState
  );

  // 유효성 검사 관련 상태
  const [serviceNameRegexTest, setServiceNameRegexTest] = useState(true);
  const [priceRegexTest, setPriceRegexTest] = useState(true);
  const [isLastDigitNotZero, setIsLastDigitNotZero] = useState(true);
  // const [budgetRegexTest, setBudgetRegexTest] = useState(true);
  // const [executionPeriodRegexTest, setExecutionPeriodRegexTest] =
  //   useState(true);
  const [marketingStrategyRegexTest, setMarketingStrategyRegexTest] =
    useState(true);

  // 마지막 자릿수가 0이 아닌지 확인
  const lastDigitNotZeroCheck = (price) => {
    const priceValue = Number(price.replace(/[^0-9]/g, ""));

    return priceValue % 10 !== 0;
  };

  // 입력 필드 핸들러
  const handleInputValueChange = (e) => {
    const { name, value } = e.target;

    const validateInput = (regex, value) => {
      return regex.test(value);
    };

    if (name === "price") {
      const onlyNums = value.replace(/[^0-9]/g, "");
      // 숫자를 쉼표로 3자리마다 구분하여 표시
      const formattedInput = onlyNums.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      setServiceInputValue((prevState) => ({
        ...prevState,
        [name]: formattedInput,
      }));
    } else {
      setServiceInputValue((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    const validateField = (name, value) => {
      switch (name) {
        case "serviceName": // 서비스명
          if (value !== "") {
            setServiceNameRegexTest(validateInput(regex.text, value));
            setIsShowResetBtn(true);
          } else {
            setServiceNameRegexTest(true);
          }
          break;
        case "price": // 상품금액
          if (value !== "") {
            if (value.length > 1 && lastDigitNotZeroCheck(value)) {
              setIsLastDigitNotZero(false);
            } else {
              setIsLastDigitNotZero(true);
            }
            setPriceRegexTest(validateInput(regex.number, value));
            setIsShowResetBtn(true);
          } else {
            setPriceRegexTest(true);
          }
          break;
        case "marketingStrategy": // 마케팅 전략 소개
          if (value !== "") {
            setMarketingStrategyRegexTest(validateInput(regex.text, value));
            setIsShowResetBtn(true);
          } else {
            setMarketingStrategyRegexTest(true);
          }
          break;
        default:
          console.error("Invalid field name");
      }
    };

    validateField(name, value);
  };

  // 초기화 버튼 클릭 이벤트 핸들러
  const [isShowResetBtn, setIsShowResetBtn] = useState(false);

  useEffect(() => {
    if (Object.values(serviceInputValue).some((value) => value)) {
      setIsShowResetBtn(true);
    }

    if (Object.values(serviceImgValue).some((value) => value)) {
      setIsShowResetBtn(true);
    }

    if (portfolioList.length > 0) {
      setIsShowResetBtn(true);
    }
  }, []); // eslint-disable-line

  const allDataReset = () => {
    setServiceImgValue(initialServiceImgValues);
    setServiceInputValue(initialServiceInputValues);
    setPortfolioList([]);
    setPortfolioIdList([]);
    setSelectedPortfolioList(initialSelectedPortfolioListValues);
    setIsShowResetBtn(false);
    scrollToTop();
  };

  useEffect(() => {
    console.log(serviceImgValue.bannerUrl);
  }, [serviceImgValue.bannerUrl]);

  // 등록 버튼 클릭 시
  const createService = async () => {
    const formData = new FormData();

    if (serviceImgValue.bannerFile) {
      formData.append("bgImage", serviceImgValue.bannerFile);
    }
    formData.append("profileImage", serviceImgValue.iconFile);
    formData.append("title", serviceInputValue.serviceName);
    formData.append("marketingTypeId", serviceInputValue.marketingTypeId);
    formData.append("budget", serviceInputValue.budget);
    formData.append(
      "price",
      Number(serviceInputValue.price.replace(/[^0-9]/g, ""))
    );
    formData.append("period", serviceInputValue.executionPeriod);
    formData.append("intro", serviceInputValue.marketingStrategy);
    formData.append("detailImage", serviceImgValue.detailPageFile);
    if (portfolioIdList.length > 0) {
      formData.append("portfolioIds", portfolioIdList);
    }

    try {
      await axios.post(`${url}member/merch`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      setAlertOpen(true);
      setResponseMessage("추가되었습니다.");
      setServiceInputValue(initialServiceInputValues);
      setServiceImgValue(initialServiceImgValues);
    } catch (error) {
      console.error(error);
      setAlertOpen(true);
      setResponseMessage("잠시 후 다시 시도해 주세요.");
    }
  };

  // 예산 선택 옵션
  const budgetSelectList = [
    { title: "50만 원 이하", value: "BELOW_500K" },
    { title: "50만 원 초과 ~ 100만 원 이하", value: "FROM_500K_TO_1M" },
    { title: "100만 원 초과 ~ 300만 원 이하", value: "FROM_1M_TO_3M" },
    { title: "300만 원 초과 ~ 500만 원 이하", value: "FROM_3M_TO_5M" },
    { title: "500만 원 초과", value: "ABOVE_5M" },
  ];

  // 집행 기간 선택 옵션
  const executionPeriodSelectList = [
    { title: "1개월 이하", value: "BELOW_1_MONTH" },
    { title: "1개월 초과 ~ 3개월 이하", value: "FROM_1_TO_3_MONTHS" },
    { title: "3개월 초과 ~ 6개월 이하", value: "FROM_3_TO_6_MONTHS" },
    { title: "6개월 초과", value: "ABOVE_6_MONTHS" },
  ];

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 750 + elementHeight,
      }}
    >
      <Header />
      <BodyContainer>
        <DetailTopBanner src={serviceImgValue.bannerUrl} />
        <AddServiceContainer ref={elementRef}>
          {/* 배경 이미지 변경 버튼 */}
          <BannerEditBtn>
            <input
              type="file"
              id="fileInput"
              accept=".png, .jpeg, .jpg"
              style={{ display: "none" }}
              onChange={(e) => handleBannerFileChange(e)}
            />
            배경이미지 변경
          </BannerEditBtn>

          {/* 서비스 등록 영역 */}
          <AddServiceInnerContainer>
            <ProfileIconWrapper>
              <CompanyIcon
                style={{
                  width: 120,
                  height: 120,
                }}
                $shadowOn={true}
                src={serviceImgValue.iconUrl}
              />
              <WriteIconBtn
                onChange={(e) => handleIconFileChange(e)}
                style={{
                  position: "absolute",
                  right: -17,
                  top: 92,
                }}
                $onlyPngJpg=".png, .jpeg, .jpg"
              />
            </ProfileIconWrapper>

            {/* 입력 영역 */}
            <AddServiceForm>
              <InputBoxContainer>
                {/* 서비스명 */}
                <InputBox
                  $uniqueKey="serviceName"
                  label="서비스명"
                  type="text"
                  placeholder="서비스명을 입력해주세요."
                  $isRequired
                  value={serviceInputValue.serviceName}
                  onChange={handleInputValueChange}
                  $isError={!serviceNameRegexTest}
                  $errorMsg="2글자 이상 입력해 주세요."
                />

                {/* 마케팅 종류 */}
                <InputSelectBox
                  $uniqueKey="marketingType"
                  label="마케팅 종류"
                  $isRequired={true}
                  placeholder="항목을 선택해주세요."
                  $isRotated={isMarketingRotated}
                  value={serviceInputValue.marketingType}
                  onChange={handleInputValueChange}
                  onClick={() => {
                    setIsMarketingRotated((prevState) => !prevState);
                  }}
                >
                  <SelectList
                    className="scrollbar"
                    style={{
                      top: 75,
                      width: "100%",
                      display: isMarketingRotated ? "block" : "none",
                    }}
                  >
                    {marketingTypeList.map((item, index) => (
                      <React.Fragment key={index}>
                        <SelectOption
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsMarketingRotated((prevState) => !prevState);
                            setServiceInputValue((prevState) => ({
                              ...prevState,
                              marketingType: item.title,
                              marketingTypeId: item.id,
                            }));
                            setIsShowResetBtn(true);
                          }}
                        >
                          {item.title}
                        </SelectOption>
                        {index < marketingTypeList.length - 1 && (
                          <DivisionLine
                            style={{
                              margin: "12px 0",
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </SelectList>
                </InputSelectBox>

                {/* 예산 */}
                <InputSelectBox
                  $uniqueKey="budget"
                  label="예산"
                  $isRequired={true}
                  placeholder="예산을 선택해주세요."
                  $isRotated={isBudgetRotated}
                  value={serviceInputValue.budgetTitle}
                  onChange={handleInputValueChange}
                  onClick={() => {
                    setIsBudgetRotated((prevState) => !prevState);
                  }}
                >
                  <SelectList
                    className="scrollbar"
                    style={{
                      top: 75,
                      width: "100%",
                      display: isBudgetRotated ? "block" : "none",
                    }}
                  >
                    {budgetSelectList.map((item, index) => (
                      <React.Fragment key={index}>
                        <SelectOption
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsBudgetRotated((prevState) => !prevState);
                            setServiceInputValue((prevState) => ({
                              ...prevState,
                              budgetTitle: item.title,
                              budget: item.value,
                            }));
                            setIsShowResetBtn(true);
                          }}
                        >
                          {item.title}
                        </SelectOption>
                        {index < budgetSelectList.length - 1 && (
                          <DivisionLine
                            style={{
                              margin: "12px 0",
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </SelectList>
                </InputSelectBox>

                {/* 상품금액 */}
                <InputBox
                  $uniqueKey="price"
                  label="상품금액"
                  type="text"
                  placeholder="상품금액을 입력해주세요."
                  $isRequired
                  value={serviceInputValue.price}
                  onChange={handleInputValueChange}
                  $isError={!priceRegexTest || !isLastDigitNotZero}
                  $errorMsg={
                    !priceRegexTest
                      ? "숫자만 입력해 주세요."
                      : "1원 단위는 입력이 불가능합니다."
                  }
                />

                {/* 집행 기간 */}
                <InputSelectBox
                  $uniqueKey="executionPeriod"
                  label="집행 기간"
                  $isRequired={true}
                  placeholder="집행 기간을 선택해주세요."
                  $isRotated={isExecutionPeriodRotated}
                  value={serviceInputValue.executionPeriodTitle}
                  onChange={handleInputValueChange}
                  onClick={() => {
                    setIsExecutionPeriodRotated((prevState) => !prevState);
                  }}
                >
                  <SelectList
                    className="scrollbar"
                    style={{
                      top: 75,
                      width: "100%",
                      display: isExecutionPeriodRotated ? "block" : "none",
                    }}
                  >
                    {executionPeriodSelectList.map((item, index) => (
                      <React.Fragment key={index}>
                        <SelectOption
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsExecutionPeriodRotated(
                              (prevState) => !prevState
                            );
                            setServiceInputValue((prevState) => ({
                              ...prevState,
                              executionPeriodTitle: item.title,
                              executionPeriod: item.value,
                            }));
                            setIsShowResetBtn(true);
                          }}
                        >
                          {item.title}
                        </SelectOption>
                        {index < executionPeriodSelectList.length - 1 && (
                          <DivisionLine
                            style={{
                              margin: "12px 0",
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </SelectList>
                </InputSelectBox>

                {/* 마케팅 전략 소개 */}
                <TextareaBox
                  $uniqueKey="marketingStrategy"
                  label="마케팅 전략 소개"
                  $isRequired={true}
                  placeholder="내용을 입력해 주세요."
                  value={serviceInputValue.marketingStrategy}
                  onChange={handleInputValueChange}
                  $isError={!marketingStrategyRegexTest}
                  $errorMsg="두 글자 이상 입력해 주세요."
                />

                {/* 상세페이지 이미지 */}
                <InputWrapper
                  style={{
                    position: "relative",
                    gap: 0,
                    marginBottom: 0,
                  }}
                >
                  <InputLabel
                    htmlFor="portfolio"
                    style={{
                      marginBottom: 5,
                    }}
                  >
                    상세페이지
                    <span
                      style={{
                        fontFamily: "Pretendard",
                        color: "#2ec5f6",
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "145%",
                      }}
                    >
                      *
                    </span>
                  </InputLabel>
                  <PretendardText
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      lineHeight: "140%",
                      color: "#939393",
                      marginBottom: 10,
                    }}
                  >
                    {serviceImgValue?.detailPageUrl === ""
                      ? "파일 종류: PNG, JPEG, JPG"
                      : serviceImgValue?.detailPageFile?.name}
                  </PretendardText>
                  {serviceImgValue.detailPageUrl !== "" ? (
                    <div
                      style={{
                        position: "relative",
                        width: "fit-content",
                        height: "fit-content",
                      }}
                    >
                      <MarketingStrategyImg
                        src={serviceImgValue.detailPageUrl}
                        effect="blur"
                      />
                      <img
                        src="/assets/icons/ico_del.svg"
                        alt="삭제"
                        style={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          width: 16,
                          height: 16,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setServiceImgValue((prevState) => ({
                            ...prevState,
                            detailPageUrl: "",
                            detailPageFile: null,
                          }));
                        }}
                      />
                    </div>
                  ) : (
                    <DetailFileAddBtn htmlFor="detailPageFileInput">
                      <input
                        type="file"
                        id="detailPageFileInput"
                        accept=".png, .jpeg, .jpg"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleDetailPageFileChange(e);
                        }}
                      />
                      <DetailPageFileAddIcon />
                      파일 업로드
                    </DetailFileAddBtn>
                  )}
                </InputWrapper>

                {/* 포트폴리오 */}
                <InputWrapper
                  style={{
                    position: "relative",
                    gap: 0,
                    marginBottom: 0,
                  }}
                >
                  <InputLabel
                    htmlFor="portfolio"
                    style={{
                      marginBottom: 5,
                    }}
                  >
                    포트폴리오
                  </InputLabel>
                  <PretendardText
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      lineHeight: "140%",
                      color: "#939393",
                      marginBottom: 10,
                    }}
                  >
                    해당 기업에서 등록한 다른 서비스의 경우 선택 가능합니다.
                  </PretendardText>
                  <InputField
                    type="text"
                    readOnly
                    id="portfolio"
                    name="portfolio"
                    placeholder="소개할 서비스를 선택해 주세요."
                    $isRequired
                    value=""
                    onChange={handleInputValueChange}
                    onClick={() => {
                      setIsPortfolioRotated((prevState) => !prevState);
                    }}
                  />
                  <DownArrowIcon
                    $isRotated={isPortfolioRotated}
                    style={{
                      position: "absolute",
                      top: 71,
                      right: 15,
                    }}
                    onClick={() => {
                      setIsPortfolioRotated((prevState) => !prevState);
                    }}
                  />

                  <SelectList
                    className="scrollbar"
                    style={{
                      top: 110,
                      left: 0,
                      width: "100%",
                      display: isPortfolioRotated ? "block" : "none",
                    }}
                  >
                    {companyServiceList.map((item, index) => (
                      <React.Fragment key={index}>
                        <SelectOption
                          onClick={(e) => {
                            e.stopPropagation();
                            if (
                              !portfolioList.some(
                                (portfolio) => portfolio.title === item.title
                              )
                            ) {
                              setPortfolioList([
                                ...portfolioList,
                                { id: item.id, title: item.title },
                              ]);
                              setPortfolioIdList([...portfolioIdList, item.id]);
                              setSelectedPortfolioList([
                                ...selectedPortfolioList,
                                {
                                  ...item,
                                },
                              ]);
                            }
                            setIsPortfolioRotated((prevState) => !prevState);
                            setIsShowResetBtn(true);
                          }}
                        >
                          {item.title}
                        </SelectOption>
                        {index < companyServiceList.length - 1 && (
                          <DivisionLine
                            style={{
                              margin: "12px 0",
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </SelectList>
                </InputWrapper>
              </InputBoxContainer>
              {portfolioList.length > 0 && (
                <div
                  style={{
                    marginBottom: 20,
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: 10,
                  }}
                >
                  {portfolioList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                          border: "1px solid #B8BFC4",
                          borderRadius: 100,
                          padding: "13px 15px",
                        }}
                      >
                        <PretendardText
                          style={{
                            color: "#000",
                            fontSize: "15px",
                            fontWeight: "400",
                            lineHeight: "21.25px",
                          }}
                        >
                          {item.title}
                        </PretendardText>
                        <img
                          style={{ cursor: "pointer" }}
                          src="/assets/icons/ico_img_del.svg"
                          alt=""
                          onClick={() => {
                            setPortfolioList(
                              portfolioList.filter(
                                (value) => value.id !== item.id
                              )
                            );
                            setPortfolioIdList(
                              portfolioIdList.filter(
                                (value) => value !== item.id
                              )
                            );
                            setSelectedPortfolioList(
                              selectedPortfolioList.filter(
                                (value) => value.id !== item.id
                              )
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}

              <BottomBtnWrapper>
                <SecondaryMiniBtn
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`${route.addQnA}`);
                    scrollToTop();
                  }}
                >
                  자주 묻는 질문 작성
                </SecondaryMiniBtn>
                <PrimaryMiniBtn
                  type="button"
                  disabled={
                    !(
                      serviceInputValue.serviceName !== "" &&
                      serviceNameRegexTest &&
                      serviceInputValue.marketingType !== "" &&
                      serviceInputValue.budget !== "" &&
                      serviceInputValue.price !== "" &&
                      priceRegexTest &&
                      isLastDigitNotZero &&
                      serviceInputValue.executionPeriod !== "" &&
                      serviceInputValue.marketingStrategy !== "" &&
                      marketingStrategyRegexTest &&
                      serviceImgValue.iconUrl !== "" &&
                      serviceImgValue.detailPageUrl !== ""
                    )
                  }
                  onClick={createService}
                >
                  등록하기
                </PrimaryMiniBtn>
              </BottomBtnWrapper>
            </AddServiceForm>
          </AddServiceInnerContainer>

          {/* 미리보기 영역 */}
          <PreviewContainer>
            <PretendardText
              style={{
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "145%",
                color: "#6f6f6f",
                textAlign: "center",
              }}
            >
              작성한 내용이 어떻게 보이는지 궁금하신가요?
              <br />
              미리보기를 통해 확인할 수 있습니다
            </PretendardText>
            <SecondaryBtn
              onClick={() => {
                navigate(`${route.preview}`);
                scrollToTop();
              }}
            >
              화면 미리보기
            </SecondaryBtn>
          </PreviewContainer>

          {isShowResetBtn && <ResetBtn onClick={allDataReset}>초기화</ResetBtn>}

          {alertOpen && (
            <Alert
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setAlertOpen(false);
                allDataReset();
                navigate(route.service);
                scrollToTop();
              }}
            >
              {responseMessage}
            </Alert>
          )}
        </AddServiceContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default AddService;

const AddServiceContainer = styled.div`
  position: absolute;
  top: 275px;
  width: 1200px;
  display: flex;
  gap: 390px;
`;

const BannerEditBtn = styled.label`
  border-radius: 8px;
  padding: 7px 20px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 145%;
  color: #282828;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;
`;

const AddServiceInnerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 430px;
`;

const ProfileIconWrapper = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
`;

const AddServiceForm = styled.form``;

const InputBoxContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PreviewContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  background-color: #fff;
  width: fit-content;
  height: fit-content;
  margin-top: 140px;
  z-index: 1;
`;

const ResetBtn = styled.button`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 23.2px;
  color: #282828;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  align-self: end;
  cursor: pointer;
  padding: 7px 20px;
  border-radius: 8px;
  position: absolute;
  top: 316px;
  right: 0;
`;

const DetailFileAddBtn = styled.label`
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 145%;
  color: #282828;
  width: fit-content;
  padding: 14px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  cursor: pointer;
`;

const BottomBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const MarketingStrategyImg = styled(LazyLoadImage)`
  object-fit: cover;
  width: 100%;
  height: auto;
`;
