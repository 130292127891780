import React from "react";
import styled from "styled-components";

const RadioIcon = (props) => {
  return (
    <RadioIconSvg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="9"
        cy="9"
        r="8.5"
        fill="white"
        stroke={props.checked ? "#2EC5F6" : "#c0c0c0"}
      />
      {props.checked && <circle cx="9" cy="9" r="5" fill="#2EC5F6" />}
    </RadioIconSvg>
  );
};

export default RadioIcon;

const RadioIconSvg = styled.svg`
  cursor: pointer;
`;
