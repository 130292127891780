import React from "react";
import styled from "styled-components";
import SearchIcon from "../atoms/icons/SearchIcon";

const SearchBar = ({ style, onChange, onKeyDown, onClick, value }) => {
  return (
    <SearchBarWrapper
      style={{
        ...style,
      }}
    >
      <SearchText
        // type="search"
        type="text"
        name="keyword"
        placeholder="검색어를 입력해 주세요."
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <SearchIcon
        style={{
          position: "absolute",
          top: 14,
          right: 15,
        }}
        onClick={onClick}
      />
    </SearchBarWrapper>
  );
};

export default SearchBar;

const SearchBarWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 430px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
`;

const SearchText = styled.input`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  width: 430px;
  padding: 14px 15px 14px 17px;
  border: none;
  color: #282828;
  border-radius: 5px;
  &::placeholder {
    color: #c0c0c0;
  }

  &:focus {
    outline: 1px solid #2ec5f6;
  }
`;
