import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PageContainer from "../../components/molecules/layout/PageContainer";
import BodyContainer from "../../components/molecules/layout/BodyContainer";
import Header from "../../components/organisms/Header";
import Footer from "../../components/organisms/Footer";
import PretendardText from "../../components/atoms/PretendardText";
import { useNavigate, useParams } from "react-router-dom";
import scrollToTop from "../../lib/utils/scrollToTop";
import useResizeHeight from "../../hooks/useResizeHeight";
import { getStorage } from "../../lib/utils/sessionStorage";
import LeftArrowIcon from "./../../components/atoms/icons/LeftArrowIcon";
import PrimaryMiniBtn from "./../../components/atoms/buttons/PrimaryMiniBtn";
import SecondaryMiniBtn from "./../../components/atoms/buttons/SecondaryMiniBtn";
import MoreBtn from "./../../components/atoms/buttons/MoreBtn";
import ReportModal from "../../components/molecules/ReportModal";
import ChatBubble from "../../components/molecules/ChatBubble";
import FileBtn from "./../../components/atoms/buttons/FileBtn";
import XCircleIcon from "./../../components/atoms/icons/XCircleIcon";
import SendBtn from "./../../components/atoms/buttons/SendBtn";
import { useRecoilValue } from "recoil";
import { fileUrl, url } from "../../lib/api/api";
import axios from "axios";
import { myInfoValueState } from "../../store/myInfo";
import route from "../../router/route";
import DownArrowIcon from "../../components/atoms/icons/DownArrowIcon";
import { useInView } from "react-intersection-observer";
import moment from "moment-timezone";
import "moment/locale/ko";
import { fileAdd, fileDownload } from "../../lib/utils/file";
import socket from "../../lib/utils/socket";
import Confirm from "../../components/molecules/Confirm";

const Chat = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);
  const roomId = id;
  const userId = myInfo.id;

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    if (myInfo.id === 0) return; // 로그인하지 않은 사용자일 경우 데이터 불러오기 막기
    getChatInfo();
    getMessagesData();
  }, [myInfo.id]); // eslint-disable-line react-hooks/exhaustive-deps

  // 채팅방 정보 가져오기
  const [chatInfo, setChatInfo] = useState({}); // eslint-disable-line no-unused-vars
  const [companyMemberInfo, setCompanyMemberInfo] = useState({});
  const [commonMemberInfo, setCommonMemberInfo] = useState({});
  const [reportedId, setReportedId] = useState("");

  const getChatInfo = async () => {
    try {
      const tokenData = await getStorage("token");
      setToken(tokenData);

      const response = await axios.get(`${url}member/chat/${id}`, {
        headers: {
          authorization: `Bearer ${tokenData}`,
        },
      });

      // 채팅에 참여 중인 회원이 아닐 경우 이전 페이지로 이동
      if (
        response.data.data.content.companyMemberName.id !== myInfo.id &&
        response.data.data.content.commonMemberName.id !== myInfo.id
      ) {
        navigate(-1, { replace: true });
        scrollToTop();
      }

      setChatInfo(response.data.data.content);
      setCompanyMemberInfo(response.data.data.content.companyMemberName);
      setCommonMemberInfo(response.data.data.content.commonMemberName);

      // 신고하기 버튼 클릭 시 신고할 회원의 id 저장
      if (response.data.data.content.companyMemberName.id === myInfo.id) {
        return setReportedId(response.data.data.content.commonMemberName.id);
      } else if (response.data.data.content.commonMemberName.id === myInfo.id) {
        return setReportedId(response.data.data.content.companyMemberName.id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ---------------------------------- //
  // ---------- 메시지 관련 ---------- //
  // -------------------------------- //
  // 채팅참가: 'enter' : { room: id }
  // 메세지 전송: 'send_message' : { room: id, message: string, senderId : id }
  // 메시지 수신 : receive_message 구독
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isScrollBottom, setIsScrollBottom] = useState(false);
  const [downScrollOn, setDownScrollOn] = useState(false); // eslint-disable-line no-unused-vars

  // 채팅 메시지 데이터 가져오기
  const getMessagesData = async () => {
    setIsProgress(true);
    let tokenData;
    let response;

    if (token === "") {
      tokenData = await getStorage("token");
    } else {
      tokenData = token;
    }

    const requestMessageObj = {
      page: 1,
      size: 10,
      orderBy: "DESC",
    };

    try {
      response = await axios.get(`${url}member/messages/${id}`, {
        params: requestMessageObj,
        headers: {
          authorization: `Bearer ${tokenData}`,
        },
      });
    } catch (error) {
      console.error(error);
    }

    setMessages(response.data.data.content);
    setIsScrollBottom(true);
    setIsProgress(false);
  };

  // 메시지 송수신 관련
  useEffect(() => {
    socket.emit("enter", { room: roomId });

    // 메시지 수신
    socket.on("receive_message", (newMessage) => {
      const currentTime = new Date();
      const currentKSTTime = new Date(
        currentTime.getTime() + 9 * 60 * 60 * 1000
      ).toISOString();
      newMessage.createdAt = currentKSTTime;

      setMessages((prevMessages) => [newMessage, ...prevMessages]);
    });

    return () => {
      socket.off("receive_message");
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!file && !message) return;

    const currentTime = new Date();
    const currentKSTTime = new Date(
      currentTime.getTime() + 9 * 60 * 60 * 1000
    ).toISOString();

    if (message !== "") {
      const newMessage = {
        room: roomId,
        message: message,
        senderId: userId,
        createdAt: currentKSTTime,
      };

      setMessages((prevMessages) => [newMessage, ...prevMessages]);

      socket.emit("send_message", newMessage);
      setMessage("");
      setFile(null);
    }

    if (file !== null) {
      try {
        const result = await fileAdd(file);
        const newMessage = {
          room: roomId,
          message: `경로:${fileUrl}/${result.serverFileName}`,
          senderId: userId,
          createdAt: currentTime,
        };

        setMessages((prevMessages) => [newMessage, ...prevMessages]);

        socket.emit("send_message", newMessage);
        setMessage("");
        setFile(null);
      } catch (error) {
        console.error(error);
      }
    }

    setIsScrollBottom(true);
  };

  // 메시지가 업데이트될 때마다 스크롤을 최하단으로 이동
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current && isScrollBottom) {
      setTimeout(() => {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }, 100); // 이미지를 그리는 과정에서 지연이 생겨 setTimeout으로 처리 필요
    }
  }, [messages]); // eslint-disable-line react-hooks/exhaustive-deps

  // 스크롤 발생 시 최하단 여부 판단 후 상태 변경
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener("scroll", () => {
        if (
          chatContainerRef.current.scrollTop ===
          chatContainerRef.current.scrollHeight -
            chatContainerRef.current.clientHeight
        ) {
          setIsScrollBottom(true);
        } else {
          setIsScrollBottom(false);
        }
        if (
          chatContainerRef.current.scrollTop <
          chatContainerRef.current.scrollHeight -
            chatContainerRef.current.clientHeight -
            500
        ) {
          setDownScrollOn(true);
        } else {
          setDownScrollOn(false);
        }
      });
    }
  }, [chatContainerRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  // -------------------------------- //
  // ---------- 파일 관련 ---------- //
  // ------------------------------ //

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }

    e.target.value = null;
  };

  // ------------------------------- //
  // ---------- 신고하기 ---------- //
  // ----------------------------- //
  const [isThreeDotClicked, setIsThreeDotClicked] = useState(false);

  const handleThreeDotClick = () => {
    setIsThreeDotClicked(!isThreeDotClicked);
  };

  const [isReportClicked, setIsReportClicked] = useState(false);

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  // ------------------------------- //
  // ---------- 무한스크롤 ---------- //
  // ----------------------------- //
  const [page, setPage] = useState(1);
  const [isLast, setIsLast] = useState(false);
  const [refInview, inView] = useInView();
  const [isProgress, setIsProgress] = useState(false);

  useEffect(() => {
    if (inView && !isProgress && !isLast) {
      setPage(page + 1);
      getMessagesDataAdd(page + 1);
    }
  }, [inView]); // eslint-disable-line react-hooks/exhaustive-deps

  // 추가 채팅 메시지 데이터 가져오기
  const getMessagesDataAdd = async () => {
    let tokenData;
    let response;

    if (token === "") {
      tokenData = await getStorage("token");
    } else {
      tokenData = token;
    }

    const requestMessageObj = {
      page: page,
      size: 10,
      orderBy: "DESC",
    };

    setIsProgress(true);

    try {
      response = await axios.get(`${url}member/messages/${id}`, {
        headers: {
          authorization: `Bearer ${tokenData}`,
        },
        params: requestMessageObj,
      });

      if (response.data.data.content.length === 0) {
        setIsLast(true);
      } else {
        setMessages([...messages, ...response.data.data.content]);
      }
    } catch (error) {
      console.error(error);
    }

    setIsProgress(false);
  };

  const messageDateFormat = (date) => {
    const inputDate = moment(date).tz("UTC");
    const today = moment();

    if (inputDate.isSame(today, "day")) {
      return inputDate.format("A h:mm"); // 'A'는 '오전'/'오후'를 나타냅니다.
    } else {
      return inputDate.format("YYYY년MM월DD일 HH시mm분");
    }
  };

  const deleteChat = async () => {
    let tokenData;
    if (token === "") {
      tokenData = await getStorage("token");
    } else {
      tokenData = token;
    }

    try {
      await axios.delete(`${url}member/chat/${id}`, {
        headers: {
          authorization: `Bearer ${tokenData}`,
        },
      });
    } catch (error) {
      console.error(error);
    }

    navigate(-1);
    scrollToTop();
  };

  // 나가기 버튼 클릭 시 상태
  const [isLeaveClicked, setIsLeaveClicked] = useState(false);

  return (
    <PageContainer style={{ minHeight: 380 + elementHeight }}>
      <Header />
      <BodyContainer>
        <ChatRoomContainer ref={elementRef}>
          <ChatRoom>
            {/* 채팅 헤더 영역 */}
            <ChatRoomHeader>
              <ChatRoomHeaderTextWrapper
                onClick={() => {
                  navigate(-1);
                  scrollToTop();
                }}
              >
                <LeftArrowIcon />
                {myInfo.profile && (
                  <UserName>
                    {commonMemberInfo.name ? commonMemberInfo.name : ""}
                  </UserName>
                )}
                {!myInfo.profile && (
                  <UserName>
                    {companyMemberInfo.name ? companyMemberInfo.name : ""}
                  </UserName>
                )}
              </ChatRoomHeaderTextWrapper>
              <ChatRoomHeaderBtnWrapper>
                {!myInfo.profile && (
                  <PrimaryMiniBtn
                    onClick={() => {
                      navigate(`${route.payment}/${chatInfo.merchId}`);
                      scrollToTop();
                    }}
                  >
                    거래하기
                  </PrimaryMiniBtn>
                )}
                <SecondaryMiniBtn
                  onClick={() => {
                    setIsLeaveClicked(true);
                  }}
                >
                  나가기
                </SecondaryMiniBtn>

                <MoreBtn
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    handleThreeDotClick();
                  }}
                />
              </ChatRoomHeaderBtnWrapper>

              {/* 신고하기 버튼 */}
              {isThreeDotClicked && (
                <PretendardText
                  style={{
                    fontWeight: 400,
                    fontSize: 16,
                    color: "#282828",
                    lineHeight: "145%",
                    position: "absolute",
                    top: 63,
                    right: 18,
                    border: "1px solid #f0f0f0",
                    borderRadius: 10,
                    padding: "10px 25px",
                    backgroundColor: "#fff",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsReportClicked(true);
                  }}
                >
                  신고하기
                </PretendardText>
              )}

              {/* 신고하기 모달 영역 */}
              {isReportClicked && (
                <ReportModal
                  $isOpen={isReportClicked}
                  handleClose={() => {
                    setIsReportClicked(false);
                  }}
                  $reportedId={reportedId}
                  $token={token}
                />
              )}
            </ChatRoomHeader>

            {/* 채팅 말풍선 영역 */}
            <ChatRoomContentContainer
              className="scrollbar"
              ref={chatContainerRef}
            >
              {messages.map((msg, index, arr) => {
                // 연속된 메시지인지 판단
                // const isConsecutiveMessage =
                //   index > 0 && messages[index - 1].senderId === msg.senderId;
                const isConsecutiveMessage =
                  index < arr.length - 1 &&
                  arr[index + 1].senderId === msg.senderId;

                // 파일 이름 추출
                let fileName = "";
                if (msg.message.startsWith("경로:")) {
                  const filePath = msg.message.substring(3);
                  fileName = filePath.substring(filePath.lastIndexOf("/") + 1);
                }

                // 파일 확장자 추출
                const fileExtension = fileName.split(".").pop().toLowerCase();
                const imageExtensions = [
                  "jpg",
                  "jpeg",
                  "png",
                  "gif",
                  "bmp",
                  "webp",
                ];

                const isImage = imageExtensions.includes(fileExtension);

                return (
                  <ChatBubble
                    key={index}
                    $myMessage={msg.senderId === userId}
                    $noProfile={
                      myInfo.profile &&
                      commonMemberInfo.profileServerFileName !== ""
                        ? false
                        : !myInfo.profile &&
                          companyMemberInfo.profileServerFileName !== ""
                        ? false
                        : true
                    }
                    $time={messageDateFormat(msg.createdAt)}
                    $profileImgSrc={
                      myInfo.profile
                        ? commonMemberInfo.profileServerFileName
                        : companyMemberInfo.profileServerFileName
                    }
                    $transparentProfile={isConsecutiveMessage}
                    $isFile={msg.message.startsWith("경로:")}
                  >
                    {!msg.message.startsWith("경로:") ? (
                      msg.message
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: isImage ? "flex-end" : "center",
                          gap: 10,
                        }}
                      >
                        {isImage && (
                          <ChatImage src={msg.message.substring(3)} />
                        )}
                        <FileDownloadBtn
                          onClick={() => {
                            fileDownload(fileName);
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              letterSpacing: "-0.048px",
                              color: "#636c73",
                            }}
                          >
                            {fileName}
                          </PretendardText>
                          <img
                            src="/assets/icons/ico_file_download.svg"
                            alt=""
                          />
                        </FileDownloadBtn>
                      </div>
                    )}
                  </ChatBubble>
                );
              })}
              {downScrollOn && (
                <DownArrowIcon
                  style={{
                    position: "absolute",
                    bottom: 110,
                    right: 40,
                    backgroundColor: "#fff",
                    borderRadius: 999,
                  }}
                  onClick={() => {
                    chatContainerRef.current.scrollTop =
                      chatContainerRef.current.scrollHeight;
                  }}
                />
              )}
              <div ref={refInview}></div>
            </ChatRoomContentContainer>

            {/* 채팅 푸터 영역 */}
            <ChatRoomFooter onSubmit={handleSendMessage}>
              <FileBtn $isAllAccept onChange={(e) => handleFileChange(e)} />
              {/* 파일 첨부 시 */}
              {file && (
                <div
                  style={{
                    display: "flex",
                    gap: 5,
                    position: "absolute",
                    top: -40,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 15,
                      color: "#000",
                      fontWeight: 400,
                      lineHeight: "22px",

                      border: "1px solid #e9e9e9",
                      borderRadius: 10,
                      padding: "5px 10px",
                      backgroundColor: "#fff",
                    }}
                  >
                    {file.name}
                  </PretendardText>
                  <XCircleIcon
                    onClick={() => {
                      setFile(null);
                    }}
                  />
                </div>
              )}
              <ChatInput
                type="text"
                value={message}
                placeholder="내용을 입력해 주세요."
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
              <SendBtn type="submit" onClick={handleSendMessage} />
            </ChatRoomFooter>
            {isLeaveClicked && (
              <Confirm
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                cancelEvent={() => {
                  setIsLeaveClicked(false);
                }}
                confirmEvent={() => {
                  deleteChat();
                }}
              >
                채팅방을 나가시겠습니까?
                <br />
                모든 대화 기록이 삭제됩니다.
              </Confirm>
            )}
          </ChatRoom>
        </ChatRoomContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default Chat;

const ChatRoomContainer = styled.div`
  position: absolute;
  top: 115px;
  width: 1200px;
`;

const ChatRoom = styled.div`
  width: 100%;
  height: 682px;
  position: relative;
  border-radius: 10px;
  border-left: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  background: #f8f8f8;
  margin-bottom: 40px;
  display: flex;
  /* padding: 30px 0px 0 0; */
  box-sizing: border-box;
`;

const ChatRoomHeader = styled.div`
  border-radius: 10px 10px 0 0;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  background: #ffffff;
  position: absolute;
  top: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  gap: 20px;
`;

const ChatRoomHeaderTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const UserName = styled(PretendardText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: #282828;
  cursor: pointer;
`;

const ChatRoomHeaderBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ChatRoomContentContainer = styled.div`
  width: 100%;
  height: 512px;
  overflow-y: auto;
  padding: 45px 35px;
  margin-top: 80px;
  display: flex;
  flex-direction: column-reverse;
  gap: 25px;
  background-color: #f8f9fa;
`;

const ChatRoomFooter = styled.form`
  border-radius: 0 0 10px 10px;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  background: #ffffff;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  gap: 20px;
`;

const ChatInput = styled.input`
  width: 1040px;
  height: 51px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
  padding: 13px 17px;
  font-size: 16px;
  line-height: 145%;
  font-weight: 400;
  color: #282828;
  outline: none;
  &::placeholder {
    color: #c0c0c0;
  }
`;

const ChatImage = styled.img`
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #d9d9d9;
  width: 105px;
  height: 105px;
  object-fit: cover;
`;

const FileDownloadBtn = styled.button`
  display: flex;
  padding: 10px 20px;
  align-items: center;
  gap: 4px;
  border-radius: 23px;
  border: 1px solid #808991;
  background: #fff;
  cursor: pointer;
`;
