import styled from "styled-components";
import Title from "../../../../components/atoms/Title";
import BodyContainer from "../../../../components/molecules/layout/BodyContainer";
import Header from "../../../../components/organisms/Header";
import useResizeHeight from "../../../../hooks/useResizeHeight";
import Footer from "../../../../components/organisms/Footer";
import PretendardText from "../../../../components/atoms/PretendardText";
import { useRecoilValue } from "recoil";
import React, { useEffect, useMemo, useState } from "react";
import {
  deleteStorage,
  getStorage,
} from "../../../../lib/utils/sessionStorage";
import axios from "axios";
import { url } from "../../../../lib/api/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { myInfoValueState } from "../../../../store/myInfo";
import CompanySideBar from "../_components/CompanySideBar";
import PageContainer from "../../../../components/molecules/layout/PageContainer";
import scrollToTop from "../../../../lib/utils/scrollToTop";
import SecondaryMiniBtn from "../../../../components/atoms/buttons/SecondaryMiniBtn";
import { formatNumberWithCommas } from "../../../../lib/utils/formatNumber";
import SortedResultBoldText from "../../../../components/molecules/SortedResultBoldText";
import { formatDate } from "../../../../lib/utils/formatDate";
import Alert from "../../../../components/molecules/Alert";
import Pagination from "./../../../../components/molecules/Pagination";
import route from "./../../../../router/route";
import { saveAs } from "file-saver";

const CompanyPay = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    if (myInfo.id === 0 || !myInfo) {
      return;
    } else {
      getTokens();
    }
  }, [myInfo]);

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    scrollToTop();
    if (token !== "") {
      getCalculateList();
    }
  }, [token, location]); // eslint-disable-line

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // 알림창 관련 상태
  const [alertOpen, setAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // -------------------------------------- //
  // ---------- 데이터 가져오기 ---------- //
  // ------------------------------------ //
  const [totalCalculateInfo, setTotalCalculateInfo] = useState({});
  const [calculateList, setCalculateList] = useState([]);

  // 페이지네이션 관련 상태
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 5, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const getCalculateList = async () => {
    try {
      const data = {
        page: searchParams.get("page") ? searchParams.get("page") : 1,
        size: pagination.size,
        orderBy: "DESC",
      };

      const response = await axios.get(`${url}member/calculates`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        params: data,
      });

      setTotalCalculateInfo({
        totalCount: response.data.data.totalCount,
        completeAmount: {
          sum: response.data.data.completeAmount.sum,
        },
        awaitAmount: {
          sum: response.data.data.awaitAmount.sum,
        },
      });
      setCalculateList(response.data.data.content);

      setPagination({
        ...pagination,
        totalCount: response.data.data.totalCount
          ? response.data.data.totalCount
          : response.data.data.content.length,
        totalPages: response.data.data.totalPage
          ? response.data.data.totalPage
          : Math.ceil(
              response.data.data.totalCount
                ? response.data.data.totalCount / 5
                : response.data.data.content.length / 5
            ),
      });
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  //------------엑셀 생성
  const downloadExcel = async (excelData) => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    workbook.addWorksheet("Sheet One");
    const sheetOne = workbook.getWorksheet("Sheet One");

    sheetOne.columns = [
      { header: "거래번호", key: "paymentNumber", width: 40 },
      { header: "사업자등록번호", key: "companyNumber", width: 40 },
      { header: "고객명", key: "customerName", width: 40 },
      { header: "기업명", key: "companyName", width: 40 },
      { header: "서비스금액", key: "price", width: 40 },
      { header: "고객 결제금액", key: "customerPaymentAmount", width: 40 },
      { header: "최종 정산금액", key: "calculateAmount", width: 40 },
      { header: "고객 결제 일시", key: "payDate", width: 40 },
      { header: "기업 정산 일시", key: "calculateDate", width: 40 },
    ];

    const filteredData = excelData.map((item) => {
      return {
        paymentNumber: item.paymentNumber,
        companyNumber: item.companyNumber,
        customerName: item.customerName,
        companyName: item.companyName,
        price: item.price,
        customerPaymentAmount: item.calculateAmount,
        calculateAmount: item.calculateAmount,
        payDate: item.payDate,
        calculateDate: item.calculateDate,
      };
    });

    filteredData.forEach((item) => {
      // 행 추가
      sheetOne.addRow(item);
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "수익 관리.xlsx");
  };

  const handleExcelDownload = async () => {
    try {
      const data = {
        page: 1,
        size: 0,
      };
      const response = await axios.get(`${url}member/calculates`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        params: data,
      });
      downloadExcel(response.data.data.content);
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 550 + elementHeight,
      }}
    >
      <Header $isBgWhite={true} />
      <BodyContainer
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        <MyPageContainer ref={elementRef}>
          <Title>마이페이지</Title>
          <MyPageInnerContainerWrapper>
            <CompanySideBar></CompanySideBar>

            <MyPageContentsContainer>
              <>
                <SortedResultTextWrapper>
                  <SortedResultBoldText
                    fontSize={20}
                    $title="수익 내역"
                    $count={pagination.totalCount}
                  />
                </SortedResultTextWrapper>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: 892,
                    marginTop: 3,
                    marginBottom: 18,
                    gap: 30,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      padding: 20,
                      borderRadius: 10,
                      width: 431,
                      backgroundColor: "#edeff1",
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#939393",
                        lineHeight: "22px",
                      }}
                    >
                      총 정산 금액
                    </PretendardText>
                    <PretendardText
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                        color: "#282828",
                        lineHeight: "25px",
                      }}
                    >
                      {totalCalculateInfo.completeAmount?.sum === null
                        ? "0원"
                        : `${formatNumberWithCommas(
                            totalCalculateInfo.completeAmount?.sum
                          )}원`}
                    </PretendardText>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      padding: 20,
                      borderRadius: 10,
                      width: 431,
                      backgroundColor: "#edeff1",
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#939393",
                        lineHeight: "22px",
                      }}
                    >
                      정산 예정 금액
                    </PretendardText>
                    <PretendardText
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                        color: "#282828",
                        lineHeight: "25px",
                      }}
                    >
                      {totalCalculateInfo.awaitAmount?.sum === null
                        ? "0원"
                        : `${formatNumberWithCommas(
                            totalCalculateInfo.awaitAmount?.sum
                          )}원`}
                    </PretendardText>
                  </div>
                </div>

                <SecondaryMiniBtn
                  style={{
                    alignSelf: "flex-end",
                    marginBottom: 20,
                  }}
                  onClick={handleExcelDownload}
                >
                  엑셀로 다운로드
                </SecondaryMiniBtn>

                <div
                  className="scrollbar"
                  style={{
                    width: 892,
                    display: "flex",
                    flexDirection: "column",
                    overflowX: "auto",
                  }}
                >
                  <CalculateManagementTitleWrapper>
                    <CalculateManagementTitleText
                      style={{
                        width: 28,
                        marginRight: 40,
                      }}
                    >
                      순번
                    </CalculateManagementTitleText>
                    <CalculateManagementTitleText
                      style={{
                        width: 56,
                        marginRight: 60,
                        textAlign: "center",
                      }}
                    >
                      거래번호
                    </CalculateManagementTitleText>
                    <CalculateManagementTitleText
                      style={{
                        width: 110,
                        marginRight: 67,
                        textAlign: "center",
                      }}
                    >
                      사업자등록번호
                    </CalculateManagementTitleText>
                    <CalculateManagementTitleText
                      style={{
                        width: 42,
                        marginRight: 67,
                        textAlign: "center",
                      }}
                    >
                      고객명
                    </CalculateManagementTitleText>
                    <CalculateManagementTitleText
                      style={{
                        width: 47,
                        marginRight: 63,
                        textAlign: "center",
                      }}
                    >
                      기업명
                    </CalculateManagementTitleText>
                    <CalculateManagementTitleText
                      style={{
                        width: 70,
                        marginRight: 49,
                        textAlign: "center",
                      }}
                    >
                      서비스금액
                    </CalculateManagementTitleText>
                    <CalculateManagementTitleText
                      style={{
                        width: 87,
                        marginRight: 41,
                        textAlign: "center",
                      }}
                    >
                      고객 결제금액
                    </CalculateManagementTitleText>
                    <CalculateManagementTitleText
                      style={{
                        width: 87,
                        marginRight: 54,
                        textAlign: "center",
                      }}
                    >
                      최종 정산금액
                    </CalculateManagementTitleText>
                    <CalculateManagementTitleText
                      style={{
                        width: 98,
                        marginRight: 67,
                        textAlign: "center",
                      }}
                    >
                      고객 결제 일시
                    </CalculateManagementTitleText>
                    <CalculateManagementTitleText
                      style={{
                        width: 98,
                        textAlign: "center",
                      }}
                    >
                      기업 정산 일시
                    </CalculateManagementTitleText>
                  </CalculateManagementTitleWrapper>
                  <CalculateManagementContentContainer>
                    <>
                      {calculateList.map((item, index) => (
                        <CalculateManagementContentWrapper key={index}>
                          <CalculateManagementContent
                            style={{
                              width: 28,
                              marginRight: 40,
                            }}
                          >
                            {index + 1}
                          </CalculateManagementContent>
                          <CalculateManagementContent
                            style={{
                              width: 56,
                              marginRight: 60,
                              textAlign: "center",
                            }}
                          >
                            {item.paymentNumber}
                          </CalculateManagementContent>
                          <CalculateManagementContent
                            style={{
                              width: 110,
                              marginRight: 67,
                              textAlign: "center",
                            }}
                          >
                            {item.companyNumber}
                          </CalculateManagementContent>
                          <CalculateManagementContent
                            style={{
                              width: 42,
                              marginRight: 67,
                              textAlign: "center",
                            }}
                          >
                            {item.customerName}
                          </CalculateManagementContent>
                          <CalculateManagementContent
                            style={{
                              width: 42,
                              marginRight: 63,
                              textAlign: "center",
                            }}
                          >
                            {item.companyName}
                          </CalculateManagementContent>
                          <CalculateManagementContent
                            style={{
                              width: 70,
                              marginRight: 49,
                              textAlign: "center",
                            }}
                          >
                            {formatNumberWithCommas(item.price)}
                          </CalculateManagementContent>
                          <CalculateManagementContent
                            style={{
                              width: 87,
                              marginRight: 41,
                              textAlign: "center",
                            }}
                          >
                            {formatNumberWithCommas(item.calculateAmount)}
                          </CalculateManagementContent>
                          <CalculateManagementContent
                            style={{
                              width: 87,
                              marginRight: 54,
                              textAlign: "center",
                            }}
                          >
                            {formatNumberWithCommas(item.calculateAmount)}
                          </CalculateManagementContent>
                          <CalculateManagementContent
                            style={{
                              width: 98,
                              marginRight: 67,
                              textAlign: "center",
                            }}
                          >
                            {formatDate(item.payDate)}
                          </CalculateManagementContent>
                          <CalculateManagementContent
                            style={{
                              width: 98,
                              textAlign: "center",
                            }}
                          >
                            {item.calculateDate === null
                              ? "-"
                              : formatDate(item.calculateDate)}
                          </CalculateManagementContent>
                        </CalculateManagementContentWrapper>
                      ))}
                    </>
                  </CalculateManagementContentContainer>
                </div>
                <Pagination
                  route={route.myPage_company_pay}
                  currentPage={page}
                  totalPages={pagination.totalPages}
                  rangeSize={pagination.rangeSize}
                />

                {alertOpen && (
                  <Alert
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setAlertOpen(false);
                    }}
                  >
                    {responseMessage}
                  </Alert>
                )}
              </>
            </MyPageContentsContainer>
          </MyPageInnerContainerWrapper>
        </MyPageContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default CompanyPay;

const MyPageContainer = styled.div`
  position: absolute;
  top: 165px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MyPageInnerContainerWrapper = styled.div`
  display: flex;
  gap: 31px;
  width: 100%;
  margin-top: 30px;
`;

const MyPageContentsContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SortedResultTextWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const CalculateManagementTitleWrapper = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  height: 65px;
  background-color: #edeff1;
  min-width: 1270px;
  border-bottom: 1px solid #c0c0c0;
`;

const DealAlarmTitleText = styled(PretendardText)`
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  color: #282828;
`;

const CalculateManagementTitleText = styled(DealAlarmTitleText)``;

const CalculateManagementContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 1270px;
`;

const CalculateManagementContentWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 65px;
  border-bottom: 1px solid #e9e9e9;
  background-color: #fff;
`;

const CalculateManagementContent = styled(PretendardText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: #282828;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
