import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PretendardText from "../atoms/PretendardText";
import TextLogo from "./../atoms/TextLogo";
import { Link } from "react-router-dom";
import scrollToTop from "../../lib/utils/scrollToTop";
import DivisionLine from "../atoms/DivisionLine";
import {
  formatBusinessNumber,
  formatTelNumber,
} from "../../lib/utils/formatNumber";
import { useRecoilValueLoadable } from "recoil";
import { baseInfoState } from "../../store/footerStore";
import TermsModal from "../molecules/termsModal";
import axios from "axios";
import { url } from "../../lib/api/api";
import Alert from "./../molecules/Alert";

const Footer = (props) => {
  const baseInfoLoadable = useRecoilValueLoadable(baseInfoState);
  const [baseInfo, setBaseInfo] = useState(null);
  const [isAgreementOpen, setIsAgreementOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (baseInfoLoadable.state === "hasValue") {
      setBaseInfo(baseInfoLoadable.contents);
    }
  }, [baseInfoLoadable.state]); // eslint-disable-line

  const openAgreementModal = () => {
    setIsAgreementOpen(true);
  };

  const closeAgreementModal = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAgreementOpen(false);
      setIsAnimating(false);
    }, 300);
  };

  const openPrivacyModal = () => {
    setIsPrivacyOpen(true);
  };

  const closePrivacyModal = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsPrivacyOpen(false);
      setIsAnimating(false);
    }, 300);
  };

  // ---------- 마운트가 되면 ---------- //
  useEffect(() => {
    getTermsData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // ---------- 알림창 관련 ---------- //
  const [alertOpen, setAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // ---------- 데이터 가져오기 ---------- //
  const [termsData, setTermsData] = useState({
    useTerms: {},
    privacyTerms: {},
    providePrivacyTerms: {},
  });

  const getTermsData = async () => {
    try {
      let response;
      response = await axios.get(`${url}terms/${1}`);
      setTermsData((prev) => ({
        ...prev,
        useTerms: response.data.data.content,
      }));
      response = await axios.get(`${url}terms/${2}`);
      setTermsData((prev) => ({
        ...prev,
        privacyTerms: response.data.data.content,
      }));
    } catch (error) {
      console.error(error);
      setAlertOpen(true);
      setResponseMessage("잠시 후 다시 시도해주세요.");
    }
  };

  return (
    <FooterContainer>
      <FooterInnerContainer {...props}>
        <Link to="/" onClick={() => scrollToTop()}>
          <TextLogo
            style={{
              position: "absolute",
              left: 55,
              bottom: 159,
            }}
          >
            Alting
          </TextLogo>
        </Link>
        <NavigationContainer>
          <NavigationText onClick={openAgreementModal}>
            {termsData.useTerms?.title || "이용약관"}
          </NavigationText>
          <NavigationDivisionLine> ㅣ </NavigationDivisionLine>
          <NavigationText onClick={openPrivacyModal}>
            {termsData.privacyTerms?.title || "개인정보처리방침"}
          </NavigationText>
          <NavigationDivisionLine> ㅣ </NavigationDivisionLine>
          <Link to="/qna" onClick={scrollToTop}>
            <NavigationText>자주 묻는 질문</NavigationText>
          </Link>

          <NavigationDivisionLine> ㅣ </NavigationDivisionLine>
          <Link to="/notice" onClick={scrollToTop}>
            <NavigationText>공지사항</NavigationText>
          </Link>
        </NavigationContainer>

        {/* 이용약관 동의 모달 영역 */}
        {isAgreementOpen && (
          <TermsModal
            $isOpen={!isAnimating}
            $termsId={1}
            handleClose={closeAgreementModal}
          />
        )}

        {/* 개인정보처리방침 모달 영역 */}
        {isPrivacyOpen && (
          <TermsModal
            $isOpen={!isAnimating}
            $termsId={2}
            handleClose={closePrivacyModal}
          />
        )}

        <DivisionLine
          style={{
            position: "absolute",
            bottom: 147,
            left: 55,
          }}
        />

        {baseInfo && (
          <TextContainer>
            <TextWrapper>
              <TextTitle>{baseInfo.companyName}</TextTitle>
              <TextDivisionLine> |</TextDivisionLine>
              <TextTitle> 주소</TextTitle>
              <TextContent> {baseInfo.address}</TextContent>
            </TextWrapper>
            <TextWrapper>
              <TextTitle>사업자등록번호</TextTitle>
              <TextContent>
                {" "}
                {formatBusinessNumber(baseInfo.companyNumber)}
              </TextContent>
              <TextDivisionLine> |</TextDivisionLine>
              <TextTitle> 대표자명</TextTitle>
              <TextContent> {baseInfo.ceo}</TextContent>
            </TextWrapper>
            <TextWrapper>
              <TextTitle>개인정보책임관리자</TextTitle>
              <TextContent> {baseInfo.ceo}</TextContent>
            </TextWrapper>
            <TextWrapper>
              <TextTitle>이메일</TextTitle>
              <TextContent> {baseInfo.email}</TextContent>
              <TextDivisionLine> |</TextDivisionLine>
              <TextTitle> TEL</TextTitle>
              <TextContent> {formatTelNumber(baseInfo.tel)}</TextContent>
            </TextWrapper>
          </TextContainer>
        )}
      </FooterInnerContainer>
      {alertOpen && (
        <Alert
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setAlertOpen(false);
          }}
        >
          {responseMessage}
        </Alert>
      )}
      {alertOpen && (
        <Alert
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setAlertOpen(false);
          }}
        >
          {responseMessage}
        </Alert>
      )}
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d9d9d9;
  background-color: #fff;
`;

const FooterInnerContainer = styled.div`
  position: relative;
  height: 243px;
  width: 100%;
  max-width: 1280px;
`;

const NavigationContainer = styled.div`
  position: absolute;
  left: 185px;
  bottom: 166px;
`;

const NavigationDivisionLine = styled(PretendardText)`
  display: contents;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #e9e9e9;
`;

const NavigationText = styled(NavigationDivisionLine)`
  color: #383838;
  cursor: pointer;
`;

const TextContainer = styled.div`
  position: absolute;
  left: 55px;
  bottom: 40px;
  display: flex;
  flex-direction: column;
`;

const TextWrapper = styled.div``;

const TextTitle = styled(PretendardText)`
  display: contents;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #6f6f6f;
`;

const TextContent = styled(TextTitle)`
  color: #939393;
`;

const TextDivisionLine = styled(TextTitle)`
  color: #d9d9d9;
`;
