import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PageContainer from "../../components/molecules/layout/PageContainer";
import BodyContainer from "../../components/molecules/layout/BodyContainer";
import Header from "../../components/organisms/Header";
import Footer from "../../components/organisms/Footer";
import DetailTopBanner from "../../components/atoms/DetailTopBanner";
import PretendardText from "../../components/atoms/PretendardText";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import scrollToTop from "../../lib/utils/scrollToTop";
import useResizeHeight from "../../hooks/useResizeHeight";
import DownArrowIcon from "../../components/atoms/icons/DownArrowIcon";
import CompanyIcon from "../../components/atoms/icons/CompanyIcons";
import BookmarkIcon from "../../components/atoms/icons/BookmarkIcon";
import ReviewGradeIcon from "./../../components/atoms/icons/ReviewGradeIcon";
import PenIcon from "../../components/atoms/icons/PenIcon";
import LocationIcon from "../../components/atoms/icons/LocationIcon";
import CompanyList from "./../../components/molecules/CompanyList";
import PaginatedItems from "./../../components/molecules/PaginatedItems";
import ProfileIcon from "./../../components/atoms/icons/ProfileIcon";
import PrimaryBtn from "./../../components/atoms/buttons/PrimaryBtn";
import SecondaryBtn from "./../../components/atoms/buttons/SecondaryBtn";
import { useRecoilState, useRecoilValue } from "recoil";
import { myInfoValueState } from "../../store/myInfo";
import { deleteStorage, getStorage } from "../../lib/utils/sessionStorage";
import axios from "axios";
import { url } from "../../lib/api/api";
import ServiceApi from "../../lib/api/service.api";
import { fileUrl } from "./../../lib/api/api";
import {
  formatNumberWithCommas,
  formatTelNumber,
} from "./../../lib/utils/formatNumber";
import route from "../../router/route";
import { formatDate } from "./../../lib/utils/formatDate";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { isEditState } from "../../store/serviceEditStore";
import Alert from "./../../components/molecules/Alert";
import Confirm from "../../components/molecules/Confirm";

const ServiceDetail = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const location = useLocation();
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //

  // 토큰 가져오기
  useEffect(() => {
    getTokens();
    setIsEdit(false);
  }, []); // eslint-disable-line

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    if (token !== "") {
      getServiceInfo();
      getScrapList();
      getReviewList();
    }
  }, [token, location]); // eslint-disable-line

  // --------------------------------------------- //
  // ---------- 서비스 데이터 가져오기 ---------- //
  // ------------------------------------------- //
  const [, setIsEdit] = useRecoilState(isEditState);
  const [isMine, setIsMine] = useState(false);
  const [serviceInfo, setServiceInfo] = useState({});
  const [marketingTypeInfo, setMarketingTypeInfo] = useState({});
  const [memberInfo, setMemberInfo] = useState({});
  const [profileInfo, setProfileInfo] = useState({});
  const [portfolioList, setPortfolioList] = useState([]);
  const [serviceFaqList, setServiceFaqList] = useState([]);

  const getServiceInfo = async () => {
    try {
      const response = (await ServiceApi.getById(id)).data.data.content;
      if (response.activation === false) {
        return navigate(-1, { replace: true }); // 비활성화된 서비스일 경우 이전 페이지로 이동
      } else {
        setServiceInfo(response);
        setMarketingTypeInfo(response.marketingType);
        setMemberInfo(response.member);
        setProfileInfo(response.member.profile);
        setPortfolioList(response.portfolios);

        // 지도 관련
        const addressData = {
          query: response.member.profile.companyRegion,
        };

        const addressResponse = await axios.get(
          `https://dapi.kakao.com/v2/local/search/address.json`,
          {
            headers: {
              Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_REST_API_KEY}`,
            },
            params: addressData,
          }
        );

        const container = document.getElementById("map");
        const options = {
          center: new window.kakao.maps.LatLng(
            addressResponse.data.documents[0].y,
            addressResponse.data.documents[0].x
          ),
          level: 3,
        };
        const map = new window.kakao.maps.Map(container, options);

        const markerPosition = new window.kakao.maps.LatLng(
          addressResponse.data.documents[0].y,
          addressResponse.data.documents[0].x
        );

        const marker = new window.kakao.maps.Marker({
          position: markerPosition,
        });

        marker.setMap(map);

        // 서비스 FAQ 관련
        const data = {
          page: 1,
          size: 0,
        };
        const faqResponse = await axios.get(
          `${url}merch-faqs/${response.member.id}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
            params: data,
          }
        );
        setServiceFaqList(faqResponse.data.data.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (serviceInfo?.member?.id) {
      setIsMine(myInfo?.id === serviceInfo?.member?.id);
    }
  }, [myInfo, serviceInfo]);

  // --------------------------------- //
  // ---------- 스크랩 관련 ---------- //
  // ------------------------------- //
  const [scrapList, setScrapList] = useState([]);

  const getScrapList = async () => {
    try {
      const data = {
        page: 1,
        size: 0,
      };
      const response = await axios.get(`${url}member/scraps`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        params: data,
      });
      setScrapList(response.data.data.content);
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      }
    }
  };

  const toggleScrap = async (merchId) => {
    try {
      await axios.post(`${url}member/scrap/${merchId}`, null, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      getScrapList();
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      }
    }
  };

  // -------------------------------- //
  // ---------- 리뷰 관련 ---------- //
  // ------------------------------ //
  const [reviewList, setReviewList] = useState([]);

  const getReviewList = async () => {
    try {
      const data = {
        page: 1,
        size: 0,
      };
      const response = await axios.get(`${url}reviews/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        params: data,
      });
      setReviewList(response.data.data.content);
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      }
    }
  };

  // ----------------------------------- //
  // ---------- 삭제하기 관련 ---------- //
  // --------------------------------- //
  // 삭제하기 버튼 클릭 핸들러
  const handleDelete = async () => {
    try {
      await axios.delete(`${url}member/merch/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setConfirmOpen(false);
      navigate(route.service);
    } catch (error) {
      console.error("에러", error);
      if (error.response.data.code === 4001) {
        setConfirmOpen(false);
        setAlertOpen(true);
      }
    }
  };

  // 알림창 상태
  const [alertOpen, setAlertOpen] = useState(false);

  // 확인창 상태
  const [confirmOpen, setConfirmOpen] = useState(false);

  // ---------------------------- //
  // ---------- 그 외 ---------- //
  // -------------------------- //
  const [companyIntroductionState, setCompanyIntroductionState] =
    useState(true);
  const [marketingStrategyState, setMarketingStrategyState] = useState(false);
  const [budgetAndExecutionPeriodState, setBudgetAndExecutionPeriodState] =
    useState(false);
  const [businessLocationState, setBusinessLocationState] = useState(false);
  const [frequentlyQnAState, setFrequentlyQnAState] = useState(false);
  const [reviewState, setReviewState] = useState(false);

  // 설명 영역 클릭 핸들러
  const handleSectionClick = (section) => {
    setCompanyIntroductionState(section === "companyIntroduction");
    setMarketingStrategyState(section === "marketingStrategy");
    setBudgetAndExecutionPeriodState(section === "budgetAndExecutionPeriod");
    setBusinessLocationState(section === "businessLocation");
    setFrequentlyQnAState(section === "frequentlyQnA");
    setReviewState(section === "review");
  };

  // 설명 영역 타이틀 ref
  const companyIntroductionRef = useRef(null);
  const marketingStrategyRef = useRef(null);
  const budgetAndExecutionPeriodRef = useRef(null);
  const businessLocationRef = useRef(null);
  const frequentlyQnARef = useRef(null);
  const reviewRef = useRef(null);

  // 설명 영역 타이틀 클릭 시 해당 영역으로 스크롤 이동
  const scrollToSection = (ref) => {
    if (ref.current) {
      const topOffset =
        ref.current.getBoundingClientRect().top + window.scrollY - 100;
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    } else {
      console.error("ref is not assigned correctly", ref);
    }
  };

  // ------------------------------------------ //
  // ---------- 자주 묻는 질문 영역 ---------- //
  // ---------------------------------------- //

  // 각 게시물의 DownArrow 상태 배열로 관리
  const [rotatedItems, setRotatedItems] = useState([]);

  useEffect(() => {
    setRotatedItems(new Array(serviceFaqList.length).fill(false)); // serviceFaqList의 길이만큼 배열 생성 후 채우지 않음
  }, [serviceFaqList]);

  // 각 게시물의 DownArrow 상태 토글 함수
  const toggleItem = (index) => {
    setRotatedItems((prev) =>
      prev.map((item, i) => (i === index ? !item : item))
    );
  };

  // ----------------------------------- //
  // ---------- 1:1 채팅하기 ---------- //
  // --------------------------------- //
  const handleChat = async () => {
    try {
      const response = await axios.post(
        `${url}member/chat/${memberInfo.id}/${id}`,
        null,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(`${route.chat}/${response.data.data.chatId}`);
      scrollToTop();
    } catch (error) {
      console.error(error);
    }
  };

  // ----------------------------------------------------------------- //
  // ---------- ResizeObserver를 이용한 컨테이너 높이 계산 ---------- //
  // --------------------------------------------------------------- //
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer style={{ minHeight: 650 + elementHeight }}>
      <Header />
      <BodyContainer>
        <DetailTopBanner
          src={
            serviceInfo.bgServerFileName
              ? `${fileUrl}/${serviceInfo.bgServerFileName}`
              : ""
          }
        />
        <ServiceDetailContainer ref={elementRef}>
          <ServiceDetailInnerContainer>
            <CompanyIcon
              $shadowOn
              style={{
                width: 120,
                height: 120,
              }}
              src={`${fileUrl}/${serviceInfo.profileServerFileName}`}
            />
            <ServiceDetailCompanyInfoContainer>
              {/* 별점, 마케팅 종류, 지역 */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                {/* 별점 */}
                <ReviewGradeWrapper>
                  <ReviewGradeIcon $isChecked={true} />
                  <ReviewGradeText>{serviceInfo.reviewAverage}</ReviewGradeText>
                </ReviewGradeWrapper>

                {/* 마케팅 종류, 지역 */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 6,
                  }}
                >
                  {/* 마케팅 종류 */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 3,
                    }}
                  >
                    <PenIcon />
                    <TypeText>
                      {marketingTypeInfo.activation
                        ? marketingTypeInfo.title
                        : ""}
                    </TypeText>
                  </div>
                  {/* 지역 */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 3,
                    }}
                  >
                    <LocationIcon />
                    <TypeText>{profileInfo.regionTitle}</TypeText>
                  </div>
                </div>
              </div>

              {/* 서비스명, 기업명 */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                {/* 서비스명 */}
                <PretendardText
                  style={{
                    fontSize: 24,
                    fontWeight: 700,
                    lineHeight: "150%",
                    color: "#282828",
                  }}
                >
                  {serviceInfo.title}
                </PretendardText>

                {/* 기업명 */}
                <PretendardText
                  style={{
                    fontSize: 18,
                    fontWeight: 400,
                    lineHeight: "150%",
                    color: "#383838",
                  }}
                >
                  {profileInfo.companyName}
                </PretendardText>
              </div>
            </ServiceDetailCompanyInfoContainer>

            {/* 북마크 아이콘 */}
            {!myInfo.profile && (
              <BookmarkIcon
                style={{
                  position: "absolute",
                  top: 140,
                  right: 0,
                }}
                $isChecked={scrapList.some(
                  (scrap) => scrap.merch.id === Number(id)
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleScrap(Number(id));
                }}
              />
            )}

            {/* 예산, 집행 기간, 전화번호 영역 */}
            <div
              style={{
                width: 770,
                padding: 20,
                borderRadius: 6,
                gap: 20,
                display: "flex",
                alignItems: "center",
                backgroundColor: "#f8f9fa",
                marginTop: 30,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                {/* 예산 */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 18,
                      fontWeight: 700,
                      lineHeight: "150%",
                      color: "#939393",
                    }}
                  >
                    예산
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: 18,
                      fontWeight: 600,
                      lineHeight: "150%",
                      color: "#282828",
                    }}
                  >
                    {serviceInfo.budgetTitle ? serviceInfo.budgetTitle : ""}
                  </PretendardText>
                </div>

                <TextDivisionLine />

                {/* 집행 기간 */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 18,
                      fontWeight: 700,
                      lineHeight: "150%",
                      color: "#939393",
                    }}
                  >
                    집행 기간
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: 18,
                      fontWeight: 600,
                      lineHeight: "150%",
                      color: "#282828",
                    }}
                  >
                    {serviceInfo.periodTitle ? serviceInfo.periodTitle : ""}
                  </PretendardText>
                </div>
                <TextDivisionLine />

                {/* 전화번호 */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 18,
                      fontWeight: 700,
                      lineHeight: "150%",
                      color: "#939393",
                    }}
                  >
                    전화번호
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: 18,
                      fontWeight: 600,
                      lineHeight: "150%",
                      color: "#282828",
                    }}
                  >
                    {formatTelNumber(profileInfo.tel)}
                  </PretendardText>
                </div>
              </div>
            </div>

            {/* 포트폴리오 영역 */}
            <SectionTitleWrapper>
              <SectionTitleText>포트폴리오</SectionTitleText>
              <SectionTitleCountText>
                {portfolioList.length}
              </SectionTitleCountText>
            </SectionTitleWrapper>

            {/* 포트폴리오 컨텐츠 */}
            <PortfolioContainer>
              {portfolioList.map((item, index) => (
                <React.Fragment key={index}>
                  <CompanyList
                    src={`${fileUrl}/${item.merch.profileServerFileName}`}
                    path={`${route.serviceDetail}/${item.merch.id}`}
                    pathHandler={() => {
                      scrollToTop();
                    }}
                    isShowBookmark={myInfo.profile ? false : true}
                    $isSmall={true}
                    bookmarkHandler={(e) => {
                      e.stopPropagation();
                      toggleScrap(item.merch.id);
                    }}
                    $isChecked={scrapList.some(
                      (scrap) => scrap.merch.id === Number(item.merch.id)
                    )}
                    serviceName={item.merch.title}
                    companyName={item.merch.member.profile.companyName}
                    marketingType={item.merch.marketingType.title}
                    location={item.merch.member.profile.regionTitle}
                    reviewGrade={item.merch.reviewAverage}
                  />
                </React.Fragment>
              ))}
            </PortfolioContainer>

            {/* 설명 영역 */}
            <DescriptionTitleContainer>
              <DescriptionTitleTextWrapper
                $isClicked={companyIntroductionState}
              >
                <DescriptionTitleText
                  $isClicked={companyIntroductionState}
                  onClick={() => {
                    handleSectionClick("companyIntroduction");
                    scrollToSection(companyIntroductionRef);
                  }}
                >
                  기업 소개
                </DescriptionTitleText>
              </DescriptionTitleTextWrapper>
              <DescriptionTitleTextWrapper $isClicked={marketingStrategyState}>
                <DescriptionTitleText
                  $isClicked={marketingStrategyState}
                  onClick={() => {
                    handleSectionClick("marketingStrategy");
                    scrollToSection(marketingStrategyRef);
                  }}
                >
                  마케팅 전략 소개
                </DescriptionTitleText>
              </DescriptionTitleTextWrapper>
              <DescriptionTitleTextWrapper
                $isClicked={budgetAndExecutionPeriodState}
              >
                <DescriptionTitleText
                  $isClicked={budgetAndExecutionPeriodState}
                  onClick={() => {
                    handleSectionClick("budgetAndExecutionPeriod");
                    scrollToSection(budgetAndExecutionPeriodRef);
                  }}
                >
                  예산 및 집행 기간
                </DescriptionTitleText>
              </DescriptionTitleTextWrapper>
              <DescriptionTitleTextWrapper $isClicked={businessLocationState}>
                <DescriptionTitleText
                  $isClicked={businessLocationState}
                  onClick={() => {
                    handleSectionClick("businessLocation");
                    scrollToSection(businessLocationRef);
                  }}
                >
                  사업장 위치
                </DescriptionTitleText>
              </DescriptionTitleTextWrapper>
              <DescriptionTitleTextWrapper $isClicked={frequentlyQnAState}>
                <DescriptionTitleText
                  $isClicked={frequentlyQnAState}
                  onClick={() => {
                    handleSectionClick("frequentlyQnA");
                    scrollToSection(frequentlyQnARef);
                  }}
                >
                  자주 묻는 질문
                </DescriptionTitleText>
              </DescriptionTitleTextWrapper>
              <DescriptionTitleTextWrapper $isClicked={reviewState}>
                <DescriptionTitleText
                  $isClicked={reviewState}
                  onClick={() => {
                    handleSectionClick("review");
                    scrollToSection(reviewRef);
                  }}
                >
                  후기
                </DescriptionTitleText>
              </DescriptionTitleTextWrapper>
            </DescriptionTitleContainer>

            {/* 기업 소개 영역 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                width: "100%",
                marginTop: 60,
              }}
            >
              <SectionTitleText ref={companyIntroductionRef}>
                기업 소개
              </SectionTitleText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  width: "100%",
                }}
              >
                {/* 수정 필요 */}
                <DescriptionContentText>
                  <div
                    className="ql-editor"
                    dangerouslySetInnerHTML={{
                      __html: profileInfo.intro,
                    }}
                  />
                </DescriptionContentText>
              </div>
            </div>

            {/* 마케팅 전략 소개 영역 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                width: "100%",
                marginTop: 60,
              }}
            >
              <SectionTitleText ref={marketingStrategyRef}>
                마케팅 전략 소개
              </SectionTitleText>
              <DescriptionContentText>
                {serviceInfo.intro}
              </DescriptionContentText>
              {serviceInfo.detailServerFileName !== "" && (
                <MarketingStrategyImg
                  src={`${fileUrl}/${serviceInfo.detailServerFileName}`}
                  effect="blur"
                />
              )}
            </div>

            {/* 예산 및 집행 기간 영역 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                width: "100%",
                marginTop: 60,
              }}
            >
              <SectionTitleText ref={budgetAndExecutionPeriodRef}>
                예산 및 집행 기간
              </SectionTitleText>
              <BudgetAndPeriodContentWrapper>
                <BudgetAndPeriodContentTitle>예산</BudgetAndPeriodContentTitle>
                <BudgetAndPeriodContentList>
                  <li>{serviceInfo.budgetTitle}</li>
                </BudgetAndPeriodContentList>
              </BudgetAndPeriodContentWrapper>
              <BudgetAndPeriodContentWrapper>
                <BudgetAndPeriodContentTitle>
                  집행 기간
                </BudgetAndPeriodContentTitle>
                <BudgetAndPeriodContentList>
                  <li>{serviceInfo.periodTitle}</li>
                </BudgetAndPeriodContentList>
              </BudgetAndPeriodContentWrapper>
            </div>

            {/* 사업장 위치 영역 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                width: "100%",
                marginTop: 60,
              }}
            >
              <SectionTitleText ref={businessLocationRef}>
                사업장 소재지
              </SectionTitleText>
              <DescriptionContentText>
                {profileInfo.companyRegion}
              </DescriptionContentText>

              {/* 주소 API 들어갈 공간 */}
              <div
                id="map"
                style={{
                  width: "100%",
                  height: 380,
                }}
              />
            </div>

            {/* 자주 묻는 질문 영역 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginTop: 60,
              }}
            >
              <SectionTitleText ref={frequentlyQnARef}>
                자주 묻는 질문
              </SectionTitleText>

              <QnAContentContainer>
                <PaginatedItems
                  $items={serviceFaqList}
                  $itemsPerPage={5}
                  style={{ gap: 20 }}
                >
                  {(item, index) => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                          width: "100%",
                          padding: "20px 0",
                          borderBottom: "1px solid #b7b7b7",
                        }}
                        onClick={() => {
                          toggleItem(index);
                        }}
                      >
                        <QnAContentTitle>{item.title}</QnAContentTitle>
                        <DownArrowIcon $isRotated={rotatedItems[index]} />
                      </div>
                      <QnAContentText
                        className="scrollbar"
                        style={{
                          display: rotatedItems[index] ? "block" : "none",
                        }}
                      >
                        {item.content}
                      </QnAContentText>
                    </>
                  )}
                </PaginatedItems>
              </QnAContentContainer>
            </div>

            {/* 후기 영역 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <SectionTitleText
                ref={reviewRef}
                style={{
                  marginBottom: 20,
                }}
              >
                후기
              </SectionTitleText>

              <ReviewContentContainer>
                <PaginatedItems
                  $items={reviewList}
                  $itemsPerPage={5}
                  style={{ gap: 20 }}
                >
                  {(item, index) => (
                    <ReviewContentWrapper key={index}>
                      <ReviewTopWrapper>
                        <ProfileIcon
                          src={
                            item.member.profileServerFileName
                              ? `${fileUrl}/${item.member.profileServerFileName}`
                              : undefined
                          }
                          style={{
                            width: 50,
                            height: 50,
                          }}
                        />
                        <ReviewTopTextWrapper>
                          <ReviewUserName>
                            {item.member.name ? item.member.name : ""}
                          </ReviewUserName>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 5,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 5,
                              }}
                            >
                              <ReviewGradeIcon
                                $isChecked={true}
                                style={{ width: 14, height: 14 }}
                              />
                              <ReviewGradeText $isSmall>
                                {item.rating}
                              </ReviewGradeText>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <PretendardText
                                style={{
                                  display: "inline-block",
                                  fontSize: 12,
                                  fontWeight: 400,
                                  lineHeight: "130%",
                                  color: "#c0c0c0",
                                }}
                              >
                                ㅣ
                              </PretendardText>
                              <PretendardText
                                style={{
                                  display: "inline-block",
                                  fontSize: 12,
                                  fontWeight: 400,
                                  lineHeight: "130%",
                                  color: "#939393",
                                }}
                              >
                                {formatDate(item.createdAt)}
                              </PretendardText>
                            </div>
                          </div>
                        </ReviewTopTextWrapper>
                      </ReviewTopWrapper>
                      <PretendardText
                        style={{
                          width: "100%",
                          fontSize: 16,
                          color: "#282828",
                          lineHeight: "25px",
                          marginTop: 15,
                          fontWeight: 400,
                        }}
                      >
                        {item.content}
                      </PretendardText>
                    </ReviewContentWrapper>
                  )}
                </PaginatedItems>
              </ReviewContentContainer>
            </div>
          </ServiceDetailInnerContainer>

          {/* 결제 영역 */}
          <PaymentContainer>
            <PaymentInnerContainer>
              <PaymentWrapper>
                <PaymentInnerWrapper>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <PaymentPrice>
                      {formatNumberWithCommas(serviceInfo.price)}원
                    </PaymentPrice>
                    <PaymentDivisionLine />
                    <WorkingDayWrapper>
                      <WorkingDayText>작업일</WorkingDayText>
                      <WorkingDayText>{serviceInfo.periodTitle}</WorkingDayText>
                    </WorkingDayWrapper>
                  </div>
                  <ButtonWrapper>
                    <PrimaryBtn
                      $isSmall={true}
                      onClick={() => {
                        if (myInfo.id === 0) {
                          return navigate(route.login);
                        } else if (myInfo?.profile) return;
                        navigate(`${route.payment}/${id}`);
                        scrollToTop();
                      }}
                      disabled={myInfo?.profile ? true : false}
                    >
                      바로 결제하기
                    </PrimaryBtn>
                    <SecondaryBtn
                      $isSmall={true}
                      onClick={() => {
                        if (myInfo.id === 0) {
                          return navigate(route.login);
                        } else if (myInfo?.profile) return;
                        handleChat();
                      }}
                      disabled={myInfo?.profile ? true : false}
                    >
                      1:1 채팅하기
                    </SecondaryBtn>
                  </ButtonWrapper>
                </PaymentInnerWrapper>
              </PaymentWrapper>
              <PretendardText
                style={{
                  fontSize: 14,
                  color: "#939393",
                  lineHeight: "140%",
                  fontWeight: 600,
                }}
              >
                *서비스 제공이 완료된 이후 전문가에게 결제 대금이 전달됩니다.
              </PretendardText>
              {isMine && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: 15,
                  }}
                >
                  <DeleteBtn
                    onClick={() => {
                      setConfirmOpen(true);
                    }}
                  >
                    삭제하기
                  </DeleteBtn>
                  <ServiceEditBtn
                    onClick={() => {
                      navigate(`${route.serviceEdit}/${id}`);
                      scrollToTop();
                    }}
                  >
                    <PretendardText
                      style={{
                        fontWeight: 600,
                        fontSize: 16,
                        lineHeight: "23.2px",
                        color: "#282828",
                      }}
                    >
                      수정하기
                    </PretendardText>
                  </ServiceEditBtn>
                </div>
              )}
            </PaymentInnerContainer>
          </PaymentContainer>

          {alertOpen && (
            <Alert
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`${route.serviceDetail}/${id}`);
                scrollToTop();
                setAlertOpen(false);
              }}
            >
              해당 서비스로 진행중인 작업이 있어
              <br />
              삭제가 불가능 합니다.
            </Alert>
          )}

          {confirmOpen && (
            <Confirm
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              cancelEvent={() => {
                setConfirmOpen(false);
              }}
              confirmEvent={handleDelete}
            >
              서비스를 삭제하시나요?
              <br />
              삭제된 서비스는 복구할 수 없습니다.
            </Confirm>
          )}
        </ServiceDetailContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default ServiceDetail;

const ServiceDetailContainer = styled.div`
  position: absolute;
  top: 275px;
  width: 1200px;
  display: flex;
  gap: 30px;
`;

const ServiceDetailInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 790px;
  position: relative;
`;

const ServiceDetailCompanyInfoContainer = styled.div`
  width: 765px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const ReviewGradeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ReviewGradeText = styled(PretendardText)`
  font-weight: 700;
  font-size: ${(props) => (props.$isSmall ? "12px" : "20px")};
  line-height: ${(props) => (props.$isSmall ? "130%" : "145%")};
  color: #282828;
`;

const TypeText = styled(PretendardText)`
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  color: #6f6f6f;
`;

const PaymentContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const PaymentInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: sticky;
  margin-top: 140px;
  top: 140px;
`;

const PaymentWrapper = styled.div`
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 20px;
  width: 380px;
  background-color: #fff;
  box-shadow: 0px 4px 15px 0px rgba(132, 132, 132, 0.25);
`;

const PaymentInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const PaymentPrice = styled(PretendardText)`
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
  color: #282828;
`;

const PaymentDivisionLine = styled.div`
  width: 340px;
  height: 1px;
  background-color: #e9e9e9;
`;

const WorkingDayWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WorkingDayText = styled(PretendardText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: #939393;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TextDivisionLine = styled.span`
  width: 2px;
  height: 20px;
  background-color: #e9e9e9;
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 30px;
`;

const SectionTitleText = styled(PretendardText)`
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
  color: #282828;
`;

const SectionTitleCountText = styled(SectionTitleText)`
  color: #c0c0c0;
`;

const PortfolioContainer = styled.div`
  display: flex;
  width: 790px;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 11px;
`;

const DescriptionTitleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 40px;
  margin-top: 56.15px;
  width: 770px;
  border-bottom: 1px solid #939393;
`;

const DescriptionTitleTextWrapper = styled.div`
  border-bottom: ${(props) =>
    props.$isClicked ? "2px solid #2ec5f6" : "none"};
  padding: 20px 0;
  height: 67px;
`;

const DescriptionTitleText = styled(PretendardText)`
  font-size: 18px;
  font-weight: ${(props) => (props.$isClicked ? 700 : 600)};
  line-height: 150%;
  color: ${(props) => (props.$isClicked ? "#282828" : "#939393")};
  cursor: pointer;
`;

const DescriptionContentText = styled(PretendardText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: #383838;
  width: 100%;
`;

const MarketingStrategyImg = styled(LazyLoadImage)`
  object-fit: cover;
  width: 100%;
  height: auto;
`;

const BudgetAndPeriodContentWrapper = styled.div`
  width: 770px;
`;

const BudgetAndPeriodContentTitle = styled(PretendardText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: #383838;
`;

const BudgetAndPeriodContentList = styled.ul`
  color: #383838;
  padding-left: 0;

  li {
    position: relative;
    padding-left: 26px;
    line-height: 145%;
    font-size: 16px;
    font-weight: 400;

    &::before {
      content: "";
      position: absolute;
      left: 11px;
      top: 0.5em;
      width: 5px;
      height: 5px;
      background-color: #383838;
      border-radius: 999px;
      margin-top: 3px;
    }
  }
`;

const QnAContentContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  /* max-height: 325px; */
  margin-bottom: 40px;
  gap: 20px;
`;

const QnAContentTitle = styled(PretendardText)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 756px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #383838;
`;

const QnAContentText = styled(PretendardText)`
  width: 100%;
  font-size: 16px;
  background-color: #f8f9fa;
  font-weight: 400;
  border-bottom: 1px solid #e9e9e9;
  color: #282828;
  height: auto;
  max-height: 115px;
  margin-top: 0;
  padding: 20px 30px;
  overflow-y: auto;
  white-space: normal;
  word-break: break-word;
  line-height: 25px;
`;

const ReviewContentContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ReviewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  padding: 10px 0;
  border-bottom: 1px solid #e9e9e9;
  margin: 10px 0;
  width: 790px;
`;

const ReviewTopWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const ReviewTopTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  gap: 5px;
`;

const ReviewUserName = styled(PretendardText)`
  font-size: 16px;
  font-weight: 700;
  line-height: 145%;
  color: #282828;
`;

const ServiceEditBtn = styled.button`
  width: 96px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
`;

const DeleteBtn = styled.button`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 23.2px;
  color: #282828;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  align-self: end;
  cursor: pointer;
  padding: 7px 20px;
  border-radius: 8px;
`;
