import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ProfileIcon from "../../../../components/atoms/icons/ProfileIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { myInfoValueState } from "../../../../store/myInfo";
import { myPageInputValueState } from "../../../../store/myPageStore";
import {
  deleteStorage,
  getStorage,
} from "../../../../lib/utils/sessionStorage";
import axios from "axios";
import { fileUrl, url } from "../../../../lib/api/api";
import PretendardText from "../../../../components/atoms/PretendardText";
import { expertTabOptions } from "../../../../constants/myPageData";
import DeleteIdModal from "../../../../components/molecules/DeleteIdModal";
import route from "../../../../router/route";

function CompanySideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    if (myInfo.id === 0 || !myInfo) {
      return;
    } else {
      if (myInfo.profile) {
        getTokens();
      } else {
        navigate("/");
      }
    }
  }, [myInfo]); // eslint-disable-line

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    if (token !== "") {
      getMyInfo();
    }
  }, [token, location]); // eslint-disable-line

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // --------------------------------------- //
  // ---------- 내 정보 가져오기 ---------- //
  // ------------------------------------- //
  const [myPageInfo, setMyPageInfo] = useRecoilState(myPageInputValueState);

  const getMyInfo = async () => {
    try {
      const response = await axios.get(`${url}member/my-info`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setMyPageInfo(response.data.data.content);
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      }
    }
  };

  // --------------------------------------- //
  // ---------- 탈퇴 ---------- //
  // ------------------------------------- //
  const [deleteIdIsOpen, setDeleteIdIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const openDeleteIdModal = () => {
    setDeleteIdIsOpen(true);
  };

  const closeDeleteIdModal = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setDeleteIdIsOpen(false);
      setIsAnimating(false);
    }, 300);
  };

  return (
    <MyPageProfileContainer>
      {/* 프로필 설정하기 */}
      <ProfileIcon
        src={
          myPageInfo.profileServerFileName
            ? `${fileUrl}/${myPageInfo.profileServerFileName}`
            : ""
        }
      />
      <ProfileWrapper>
        <ProfileNameText>{myPageInfo.name}님</ProfileNameText>
        <ProfileEmailText>{myPageInfo.email}</ProfileEmailText>
      </ProfileWrapper>
      <ProfileTabWrapper>
        {Object.keys(expertTabOptions).map((tab, idx) => (
          <ProfileTab
            key={idx}
            isSelected={location.pathname.includes(expertTabOptions[tab].value)}
            onClick={() => {
              navigate(expertTabOptions[tab].value);
            }}
          >
            {expertTabOptions[tab].title}
          </ProfileTab>
        ))}
        <DeleteId onClick={openDeleteIdModal}>회원탈퇴</DeleteId>
        {deleteIdIsOpen && (
          <DeleteIdModal
            $isOpen={!isAnimating}
            $token={token}
            $delId={myPageInfo.id}
            handleClose={closeDeleteIdModal}
          />
        )}
      </ProfileTabWrapper>
    </MyPageProfileContainer>
  );
}

export default CompanySideBar;

const MyPageProfileContainer = styled.div`
  width: 278px;
  height: fit-content;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  background-color: #fff;
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 10px 15px;
  background-color: #f8f9fa;
  width: 100%;
  /* height: 73px; */
  margin-top: 15px;
  margin-bottom: 33px;
  border-radius: 5px;
`;

const ProfileNameText = styled(PretendardText)`
  font-size: 18px;
  font-weight: 600;
  color: #282828;
  line-height: 150%;
  overflow-x: auto;
`;

const ProfileEmailText = styled(PretendardText)`
  font-size: 16px;
  font-weight: 400;
  color: #939393;
  line-height: 145%;
  overflow-x: auto;
`;

const ProfileTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ProfileTab = styled(PretendardText)`
  font-size: 16px;
  font-weight: ${(props) => (props.isSelected ? 600 : 400)};
  color: ${(props) => (props.isSelected ? "#282828" : "#939393")};
  line-height: 145%;
  cursor: pointer;
`;

const DeleteId = styled(PretendardText)`
  font-size: 14px;
  font-weight: 600;
  color: #939393;
  line-height: 140%;
  text-decoration: underline;
  cursor: pointer;
`;
