import React, { useState } from "react";
import ModalOverlay from "./../atoms/ModalOverlay";
import Title from "./../atoms/Title";
import ModalTextContent from "./../atoms/ModalTextContent";
import XBtnIcon from "./../atoms/icons/XBtnIcon";
import ModalContainer from "./../atoms/ModalContainer";
import ModalContentWrapper from "./../atoms/ModalContentWrapper";
import axios from "axios";
import { url } from "../../lib/api/api";
import { useEffect } from "react";
import Alert from "./Alert";

const TermsModal = (props) => {
  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    getTermsData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // 알림창 관련 상태
  const [alertOpen, setAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // -------------------------------------- //
  // ---------- 데이터 가져오기 ---------- //
  // ------------------------------------ //
  const [termsData, setTermsData] = useState({});

  const getTermsData = async () => {
    try {
      const response = await axios.get(`${url}terms/${props.$termsId}`);
      setTermsData(response.data.data.content);
    } catch (error) {
      console.error(error);
      setAlertOpen(true);
      setResponseMessage("잠시 후 다시 시도해주세요.");
    }
  };

  return (
    <>
      <ModalOverlay $isOpen={props.$isOpen}>
        <ModalContainer $isOpen={props.$isOpen}>
          <XBtnIcon
            style={{ position: "absolute", top: 30, right: 30 }}
            onClick={props.handleClose}
          />
          <Title>{termsData.title}</Title>
          <ModalContentWrapper className="scrollbar">
            <ModalTextContent>{termsData.content}</ModalTextContent>
          </ModalContentWrapper>
          {alertOpen && (
            <Alert
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setAlertOpen(false);
              }}
            >
              {responseMessage}
            </Alert>
          )}
        </ModalContainer>
      </ModalOverlay>
    </>
  );
};

export default TermsModal;
