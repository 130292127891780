import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Title from "../../../../components/atoms/Title";
import BodyContainer from "../../../../components/molecules/layout/BodyContainer";
import Header from "../../../../components/organisms/Header";
import useResizeHeight from "../../../../hooks/useResizeHeight";
import Footer from "../../../../components/organisms/Footer";
import ProfileIcon from "../../../../components/atoms/icons/ProfileIcon";
import PretendardText from "../../../../components/atoms/PretendardText";
import { useRecoilState } from "recoil";
import { myPageInputValueState } from "../../../../store/myPageStore";
import {
  deleteStorage,
  getStorage,
  setStorage,
} from "../../../../lib/utils/sessionStorage";
import axios from "axios";
import { fileUrl, url } from "../../../../lib/api/api";
import { useLocation, useNavigate } from "react-router-dom";
import { myInfoValueState } from "../../../../store/myInfo";
import PageContainer from "../../../../components/molecules/layout/PageContainer";
import scrollToTop from "../../../../lib/utils/scrollToTop";
import InputBox from "../../../../components/molecules/InputBox";
import WriteIconBtn from "../../../../components/atoms/WriteIconBtn";
import regex from "../../../../constants/regex";
import SecondaryMiniBtn from "../../../../components/atoms/buttons/SecondaryMiniBtn";
import Confirm from "../../../../components/molecules/Confirm";
import PrimaryMiniBtn from "../../../../components/atoms/buttons/PrimaryMiniBtn";
import Alert from "../../../../components/molecules/Alert";
import { removeNonNumeric } from "../../../../lib/utils/formatNumber";
import CommonSideBar from "../_components/CommonSideBar";
import route from "../../../../router/route";

const CommonInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState("");
  const [myInfo, setMyInfo] = useRecoilState(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    if (myInfo.id === 0 || !myInfo || myInfo.profile) {
      return;
    } else {
      if (myInfo.id !== 0) {
        getTokens();
      } else return;
    }
  }, [myInfo]); // eslint-disable-line

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    scrollToTop();
    if (token !== "") {
      getMyInfo();
    }
  }, [token, location]); // eslint-disable-line

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // 알림창 관련 상태
  const [alertOpen, setAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // -------------------------------------- //
  // ---------- 데이터 가져오기 ---------- //
  // ------------------------------------ //
  const [myPageInfo, setMyPageInfo] = useRecoilState(myPageInputValueState);
  const [editMyInfoValue, setEditMyInfoValue] = useState({});
  const [editProfileImg, setEditProfileImg] = useState(
    myPageInfo.profileServerFileName
      ? `${fileUrl}/${myPageInfo.profileServerFileName}`
      : ""
  );
  const [isShowEditPw, setIsShowEditPw] = useState(false);

  const getMyInfo = async () => {
    try {
      const currentPw = await getStorage("current");
      setIsShowEditPw(currentPw !== null ? true : false);
      const response = await axios.get(`${url}member/my-info`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setMyPageInfo(response.data.data.content);
      setMyInfo(response.data.data.content);
      setStorage("myInfo", response.data.data.content);
      setEditMyInfoValue({
        name: response.data.data.content.name,
        currentPw: currentPw !== null ? atob(currentPw) : "",
        pw: currentPw !== null ? atob(currentPw) : "",
        checkPw: "",
        currentTel: response.data.data.content.phoneNumber,
        tel: response.data.data.content.phoneNumber,
        certNum: "",
      });

      setEditProfileImg(
        response.data.data.content.profileServerFileName
          ? `${fileUrl}/${response.data.data.content.profileServerFileName}`
          : ""
      );
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // ---------------------------------------- //
  // ---------- 내 정보 수정 영역 ---------- //
  // -------------------------------------- //

  // 프로필 사진 변경 관련
  const [profileImgFile, setProfileImgFile] = useState(null); // 업로드할 프로필 이미지 파일

  const handleProfileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      setEditProfileImg(url);
      setProfileImgFile(selectedFile);
    }
  };

  // 유효성 검사 관련 상태
  const [nameRegexTest, setNameRegexTest] = useState(true);
  const [telRegexTest, setTelRegexTest] = useState(true);
  const [certNumRegexTest, setCertNumRegexTest] = useState(true);
  const [verifyCertNum, setVerifyCertNum] = useState(false);
  const [pwRegexTest, setPwRegexTest] = useState(true);
  const [checkPwRegexTest, setCheckPwRegexTest] = useState(true);

  // 기본 정보 버튼 관련 상태
  const [isCancelBtnClicked, setIsCancelBtnClicked] = useState(false);
  const [editPwClicked, setEditPwClicked] = useState(false);

  // 유효성 검사 함수
  const validateInput = (regex, value) => {
    return regex.test(value);
  };

  // 회원 정보 입력 필드 핸들러
  const handleMyInfoEditInputValueChange = (e) => {
    const { name, value } = e.target;

    if (name === "tel") {
      let cleanValue = removeNonNumeric(value);
      setEditMyInfoValue((prevState) => ({
        ...prevState,
        [name]: cleanValue,
      }));
    } else {
      setEditMyInfoValue((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    const validateField = (name, value) => {
      switch (name) {
        case "name":
          if (value !== "") {
            setNameRegexTest(validateInput(regex.text, value));
          } else {
            setNameRegexTest(true);
          }
          break;
        case "tel":
          if (value !== "") {
            setTelRegexTest(validateInput(regex.tel, value));
          } else {
            setTelRegexTest(true);
          }
          break;
        case "certNum":
          if (value !== "") {
            setCertNumRegexTest(validateInput(regex.certNum, value));
          } else {
            setCertNumRegexTest(true);
          }
          break;
        case "pw":
          if (value !== "") {
            setPwRegexTest(validateInput(regex.pw, value));
          } else {
            setPwRegexTest(true);
            setEditPwClicked(false);
          }
          break;
        case "checkPw":
          if (value !== "") {
            setCheckPwRegexTest(validateInput(regex.pw, value));
          } else {
            setCheckPwRegexTest(true);
          }
          break;
        default:
          console.error("Invalid field name");
      }
    };

    validateField(name, value);
  };

  // 비밀번호 입력 값 변경 시 인증번호 관련 상태 초기화
  useEffect(() => {
    if (
      myPageInfo.pw === "" ||
      !pwRegexTest ||
      myPageInfo.pw === myPageInfo.currentPw
    ) {
      setMyPageInfo((prevState) => ({
        ...prevState,
        checkPw: "",
      }));
      setEditPwClicked(false);
    }
  }, [myPageInfo.pw, pwRegexTest, myPageInfo.currentPw]); // eslint-disable-line react-hooks/exhaustive-deps

  // 인증번호 관련 상태
  const [certNumSent, setCertNumSent] = useState(false);

  // 인증번호 발송 함수
  const handleCertNumSent = async () => {
    try {
      let response = await axios.post(`${url}auth/find/email`, {
        phoneNumber: removeNonNumeric(editMyInfoValue.tel),
      });
      if (response.data.data) {
        setAlertOpen(true);
        setResponseMessage("이미 가입된 정보입니다.");
      }
    } catch (error) {
      setAlertOpen(true);
      if (error.response) {
        // 가입한 휴대전화 정보가 없으면
        const response = await axios.post(`${url}auth/sms`, {
          phoneNumber: removeNonNumeric(editMyInfoValue.tel),
          content: "",
        });
        let certNum = response.data.data.authNumber;
        setStorage("certNum", certNum);
        setCertNumSent(true);
        setVerifyCertNum(false);
        setResponseMessage("인증번호가 발송되었습니다.");
      } else {
        console.error(error);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // 인증번호 인증 함수
  const handleCertNumVerify = (e) => {
    e.preventDefault();
    const storageCertNum = sessionStorage.getItem("certNum");
    if (editMyInfoValue.certNum === storageCertNum) {
      setVerifyCertNum(true);
      setAlertOpen(true);
      setResponseMessage("인증되었습니다.");
    } else {
      setVerifyCertNum(false);
      setAlertOpen(true);
      setResponseMessage("인증번호가 일치하지 않습니다.");
    }
  };

  // 전화번호 입력 값 변경 시 인증번호 관련 상태 초기화
  useEffect(() => {
    if (!telRegexTest || editMyInfoValue.tel === "") {
      setCertNumSent(false);
      setVerifyCertNum(false);
    }
  }, [telRegexTest, editMyInfoValue.tel]);

  // 인증번호 입력 값 변경 시 인증번호 관련 상태 초기화
  useEffect(() => {
    if (!certNumRegexTest || myPageInfo.certNum === "") {
      setVerifyCertNum(false);
    }
  }, [certNumRegexTest, myPageInfo.certNum]);

  // 수정 완료 버튼 클릭 시
  const handleMyInfoEditSubmit = async () => {
    const formData = new FormData();

    formData.append("name", editMyInfoValue.name);
    if (
      editMyInfoValue.currentPw !== "" &&
      editMyInfoValue.pw !== editMyInfoValue.currentPw
    ) {
      formData.append("password", editMyInfoValue.pw);
      setStorage("pw", btoa(editMyInfoValue.pw));
    }
    if (
      editMyInfoValue.currentPw !== "" &&
      editMyInfoValue.pw !== editMyInfoValue.currentPw
    ) {
      formData.append("passwordChangeFlag", true);
    } else {
      formData.append("passwordChangeFlag", false);
    }

    if (editMyInfoValue.tel !== editMyInfoValue.currentTel) {
      formData.append("phoneNumber", editMyInfoValue.tel);
      setCertNumSent(false);
    }
    if (editMyInfoValue.tel !== editMyInfoValue.currentTel) {
      formData.append("phoneNumberChangeFlag", true);
    } else {
      formData.append("phoneNumberChangeFlag", false);
    }

    if (profileImgFile) {
      formData.append("profileImage", profileImgFile);
    }

    if (profileImgFile) {
      formData.append("profileImageDeleteFlag", true);
    } else {
      formData.append("profileImageDeleteFlag", false);
    }

    try {
      await axios.patch(`${url}member/my-info`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: myPageInfo.id,
        },
      });
      setAlertOpen(true);
      setResponseMessage("수정되었습니다.");
      getMyInfo();
    } catch (error) {
      console.error(error);
      setAlertOpen(true);
      setResponseMessage("잠시 후 다시 시도해 주세요.");
    }
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 550 + elementHeight,
      }}
    >
      <Header $isBgWhite={true} />
      <BodyContainer
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        <MyPageContainer ref={elementRef}>
          <Title>마이페이지</Title>
          <MyPageInnerContainerWrapper>
            <CommonSideBar />

            <MyPageContentsContainer>
              <>
                <MyInfoEditContainer style={{ marginBottom: 30 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <ProfileIcon
                      src={editProfileImg}
                      style={{
                        width: 120,
                        height: 120,
                        marginBottom: 20,
                      }}
                    />
                    <WriteIconBtn
                      onChange={(e) => handleProfileChange(e)}
                      style={{
                        position: "absolute",
                        bottom: 28,
                        right: 3,
                        width: 25,
                        height: 25,
                      }}
                    />
                  </div>
                  <ProfileNameText>{myPageInfo.name}님</ProfileNameText>
                  <div
                    style={{
                      width: "100%",
                      marginTop: 40,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 20,
                      }}
                    >
                      {/* 이메일 */}
                      <InputBox
                        $uniqueKey="email"
                        label="이메일"
                        type="email"
                        $isRequired
                        readOnly
                        value={myPageInfo.email}
                        disabled
                      />

                      {/* 이름 */}
                      <InputBox
                        $uniqueKey="name"
                        label="이름"
                        type="text"
                        $isRequired
                        placeholder="이름을 입력해 주세요."
                        value={editMyInfoValue.name}
                        onChange={handleMyInfoEditInputValueChange}
                        $isHelp={!nameRegexTest}
                        $helpMsg="2글자 이상 입력해 주세요."
                      />

                      {/* 비밀번호 */}
                      {isShowEditPw && (
                        <InputBox
                          $uniqueKey="pw"
                          label="비밀번호"
                          type="password"
                          $isRequired
                          placeholder="비밀번호를 입력해 주세요."
                          value={editMyInfoValue.pw}
                          onChange={handleMyInfoEditInputValueChange}
                          $isHelp={
                            (!pwRegexTest && editMyInfoValue.pw !== "") ||
                            editMyInfoValue.pw === editMyInfoValue.currentPw
                          }
                          $helpMsg={
                            !pwRegexTest && editMyInfoValue.pw !== ""
                              ? "비밀번호 조건이 일치하지 않습니다."
                              : "기존 비밀번호와 동일합니다."
                          }
                          $isShowBtn
                          $btnText="수정하기"
                          $btnHandler={() => {
                            setEditPwClicked(true);
                            setEditMyInfoValue((prevState) => ({
                              ...prevState,
                              checkPw: "",
                            }));
                          }}
                          $btnDisabled={
                            editMyInfoValue.pw === "" ||
                            !pwRegexTest ||
                            editMyInfoValue.pw === editMyInfoValue.currentPw
                          }
                        />
                      )}

                      {isShowEditPw &&
                        editPwClicked &&
                        editMyInfoValue.pw !== "" &&
                        pwRegexTest &&
                        editMyInfoValue.pw !== editMyInfoValue.currentPw && (
                          <InputBox
                            $uniqueKey="checkPw"
                            label="비밀번호 확인"
                            type="password"
                            placeholder="비밀번호를 한 번 더 입력해 주세요."
                            $isRequired
                            value={editMyInfoValue.checkPw}
                            onChange={handleMyInfoEditInputValueChange}
                            $isHelp={
                              (!checkPwRegexTest &&
                                editMyInfoValue.checkPw !== "") ||
                              (editMyInfoValue.checkPw !== "" &&
                                editMyInfoValue.checkPw !== editMyInfoValue.pw)
                            }
                            $helpMsg={
                              !checkPwRegexTest &&
                              editMyInfoValue.checkPw !== ""
                                ? "비밀번호 조건이 일치하지 않습니다."
                                : editMyInfoValue.checkPw !== editMyInfoValue.pw
                                ? "비밀번호가 일치하지 않습니다."
                                : ""
                            }
                          />
                        )}

                      {/* 전화번호 */}
                      <InputBox
                        $uniqueKey="tel"
                        label="전화번호"
                        type="tel"
                        $isRequired
                        placeholder="전화번호를 입력해 주세요."
                        value={editMyInfoValue.tel}
                        onChange={handleMyInfoEditInputValueChange}
                        $isHelp={
                          !telRegexTest ||
                          editMyInfoValue.tel === editMyInfoValue.currentTel
                        }
                        $helpMsg={
                          !telRegexTest
                            ? "전화번호 형식이 올바르지 않습니다."
                            : editMyInfoValue.tel === editMyInfoValue.currentTel
                            ? "기존 전화번호와 동일합니다."
                            : ""
                        }
                        $isShowBtn
                        $btnText="수정하기"
                        $btnHandler={() => {
                          handleCertNumSent();
                          setEditMyInfoValue((prevState) => ({
                            ...prevState,
                            certNum: "",
                          }));
                        }}
                        $btnDisabled={
                          editMyInfoValue.tel === "" ||
                          !telRegexTest ||
                          editMyInfoValue.tel === editMyInfoValue.currentTel
                        }
                      />

                      {/* 인증번호 */}
                      {certNumSent && (
                        <InputBox
                          $uniqueKey="certNum"
                          label="인증번호"
                          type="tel"
                          $isRequired
                          placeholder="인증번호를 입력해 주세요."
                          value={editMyInfoValue.certNum}
                          onChange={handleMyInfoEditInputValueChange}
                          $isHelp={!certNumRegexTest}
                          $helpMsg="인증번호 6자리를 입력해 주세요."
                          $isShowBtn
                          $btnText={verifyCertNum ? "인증완료" : "인증하기"}
                          $btnHandler={handleCertNumVerify}
                          $btnDisabled={
                            !certNumRegexTest ||
                            editMyInfoValue.certNum === "" ||
                            verifyCertNum
                          }
                        />
                      )}
                      <MyInfoEditBtnWrapper>
                        {/* 취소하기 버튼 */}
                        <SecondaryMiniBtn
                          type="button"
                          onClick={() => {
                            setIsCancelBtnClicked(true);
                          }}
                        >
                          취소하기
                        </SecondaryMiniBtn>
                        {isCancelBtnClicked && (
                          <Confirm
                            style={{
                              position: "fixed",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                            cancelText="아니요"
                            cancelEvent={() => {
                              setIsCancelBtnClicked(false);
                            }}
                            confirmText="예"
                            confirmEvent={() => {
                              setEditMyInfoValue((prevState) => ({
                                ...prevState,
                                name: myPageInfo.name,
                                pw: editMyInfoValue.currentPw,
                                checkPw: "",
                                tel: editMyInfoValue.currentTel,
                                certNum: "",
                              }));
                              setCertNumSent(false);
                              setVerifyCertNum(false);
                              setEditProfileImg(
                                myPageInfo.profileServerFileName
                                  ? `${fileUrl}/${myPageInfo.profileServerFileName}`
                                  : ""
                              );
                              setProfileImgFile(null);
                              setIsCancelBtnClicked(false);
                              scrollToTop();
                            }}
                          >
                            취소하시겠습니까?
                            <br />
                            작성한 내용이 사라집니다.
                          </Confirm>
                        )}

                        {/* 수정완료 버튼 */}
                        {isShowEditPw ? (
                          <PrimaryMiniBtn
                            type="button"
                            disabled={
                              editMyInfoValue.name === "" ||
                              !nameRegexTest ||
                              editMyInfoValue.pw === "" ||
                              !pwRegexTest ||
                              (editMyInfoValue.pw !==
                                editMyInfoValue.currentPw &&
                                editMyInfoValue.checkPw === "") ||
                              (editMyInfoValue.pw !==
                                editMyInfoValue.currentPw &&
                                !checkPwRegexTest) ||
                              (editMyInfoValue.pw !==
                                editMyInfoValue.currentPw &&
                                editMyInfoValue.pw !==
                                  editMyInfoValue.checkPw) ||
                              editMyInfoValue.tel === "" ||
                              !telRegexTest ||
                              (editMyInfoValue.tel !==
                                editMyInfoValue.currentTel &&
                                !verifyCertNum)
                            }
                            onClick={() => {
                              handleMyInfoEditSubmit();
                              scrollToTop();
                            }}
                          >
                            수정완료
                          </PrimaryMiniBtn>
                        ) : (
                          <PrimaryMiniBtn
                            type="button"
                            disabled={
                              editMyInfoValue.name === "" ||
                              !nameRegexTest ||
                              editMyInfoValue.tel === "" ||
                              !telRegexTest ||
                              (editMyInfoValue.tel !==
                                editMyInfoValue.currentTel &&
                                !verifyCertNum)
                            }
                            onClick={() => {
                              handleMyInfoEditSubmit();
                              scrollToTop();
                            }}
                          >
                            수정완료
                          </PrimaryMiniBtn>
                        )}
                      </MyInfoEditBtnWrapper>
                    </div>
                  </div>
                </MyInfoEditContainer>

                {alertOpen && (
                  <Alert
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setAlertOpen(false);
                    }}
                  >
                    {responseMessage}
                  </Alert>
                )}
              </>
            </MyPageContentsContainer>
          </MyPageInnerContainerWrapper>
        </MyPageContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default CommonInfo;

const MyPageContainer = styled.div`
  position: absolute;
  top: 165px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MyPageInnerContainerWrapper = styled.div`
  display: flex;
  gap: 31px;
  width: 100%;
  margin-top: 30px;
`;

const MyPageContentsContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MyInfoEditContainer = styled.div`
  width: 892px;
  padding: 80px 231px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  border-radius: 10px;
  background-color: #fff;
`;

const ProfileNameText = styled(PretendardText)`
  font-size: 18px;
  font-weight: 600;
  color: #282828;
  line-height: 150%;
  overflow-x: auto;
`;

const MyInfoEditBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
`;
