import React from "react";
import styled from "styled-components";

const DetailTopBanner = (props) => {
  return <StyledDetailTopBanner {...props} />;
};

export default DetailTopBanner;

const StyledDetailTopBanner = styled.div`
  background: ${(props) =>
    props.src
      ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${props.src})`
      : "#f2f7fc"};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 85px;
  width: 100%;
  height: 250px;
`;
