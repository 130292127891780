import React from "react";
import styled from "styled-components";
import ProfileIcon from "./../atoms/icons/ProfileIcon";
import PretendardText from "../atoms/PretendardText";
import { fileUrl } from "../../lib/api/api";

const ChatBubble = (props) => {
  return props.$myMessage ? (
    <ChatBubbleContainer {...props}>
      <ChatRoomTimeText>{props.$time}</ChatRoomTimeText>
      <ChatRoomTextBox $myMessage={props.$myMessage} $isFile={props.$isFile}>
        {props.children}
      </ChatRoomTextBox>
    </ChatBubbleContainer>
  ) : (
    <div
      style={{
        display: "flex",
        gap: 15,
      }}
    >
      {!props.$noProfile && !props.$transparentProfile && (
        <ProfileIcon
          style={{
            width: 45,
            height: 45,
          }}
          src={`${fileUrl}/${props.$profileImgSrc}`}
        />
      )}
      {props.$noProfile && !props.$transparentProfile && (
        <ProfileIcon
          style={{
            width: 45,
            height: 45,
          }}
          src={"/assets/imgs/default_profile.png"}
        />
      )}
      <ChatBubbleContainer {...props}>
        <ChatRoomTextBox $isFile={props.$isFile}>
          {props.children}
        </ChatRoomTextBox>
        <ChatRoomTimeText>{props.$time}</ChatRoomTimeText>
      </ChatBubbleContainer>
    </div>
  );
};

export default ChatBubble;

const ChatBubbleContainer = styled.div`
  display: flex;
  gap: 5px;
  align-self: ${(props) => props.$myMessage && "flex-end"};
  margin-left: ${(props) => props.$transparentProfile && "60px"};
`;

const ChatRoomTextBox = styled(PretendardText)`
  padding: ${(props) => (props.$isFile ? "0px" : "20px")};
  border-radius: 10px;
  color: #282828;
  background: ${(props) =>
    props.$isFile ? "transparent" : props.$myMessage ? "#bee7f4" : "#fff"};
  /* width: ${(props) => props.$isFile && "105px"}; */
  max-height: ${(props) => props.$isFile && "105px"};
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  max-width: 628px;
  box-shadow: ${(props) =>
    !props.$isFile && "0px 4px 15px 0px rgba(0, 0, 0, 0.1)"};
  flex-wrap: wrap;
  word-break: break-all;
  overflow-wrap: break-word;
  white-space: normal;
`;

const ChatRoomTimeText = styled(PretendardText)`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #c0c0c0;
  align-self: flex-end;
`;
