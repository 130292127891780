import React, { useState } from "react";
import styled from "styled-components";
import PageContainer from "../../components/molecules/layout/PageContainer";
import BodyContainer from "../../components/molecules/layout/BodyContainer";
import Header from "../../components/organisms/Header";
import Footer from "../../components/organisms/Footer";
import Title from "../../components/atoms/Title";
import SubTitle from "./../../components/atoms/SubTitle";
import RegisterRadioBox from "./../../components/molecules/RegisterRadioBox";
import PretendardText from "../../components/atoms/PretendardText";
import { useNavigate } from "react-router-dom";
import useResizeHeight from "../../hooks/useResizeHeight";
import PrimaryBtn from "./../../components/atoms/buttons/PrimaryBtn";
import route from "../../router/route";

const Register = () => {
  const navigate = useNavigate();

  // 전역 상태
  const [registerCheckedValue, setRegisterCheckedValue] = useState({
    isCommonMember: true,
  });

  // 제출 버튼 핸들러
  const handleMoveRegisterPage = (e) => {
    e.preventDefault();
    if (registerCheckedValue.isCommonMember) {
      navigate(route.register_common);
    } else {
      navigate(route.register_company);
    }
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 750 + elementHeight,
      }}
    >
      <Header />
      <BodyContainer
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        <RegisterContainer ref={elementRef} style={{ top: 255 }}>
          <RegisterTitleWrapper>
            <Title>회원가입</Title>
            <SubTitle>어떤 유형의 회원으로 가입하시나요?</SubTitle>
          </RegisterTitleWrapper>
          <RegisterForm onSubmit={handleMoveRegisterPage}>
            <RadioBoxWrapper>
              <RegisterRadioBox
                id="commonMember"
                value="일반회원으로 가입"
                checked={registerCheckedValue.isCommonMember}
                onChange={(e) => {
                  e.stopPropagation();
                  setRegisterCheckedValue({
                    ...registerCheckedValue,
                    isCommonMember: true,
                  });
                }}
              >
                <RadioBoxLabelWrapper>
                  <RadioBoxLabelTitle>
                    소상공인으로 가입할래요
                  </RadioBoxLabelTitle>
                  <RadioBoxLabelSubTitle>
                    대표님께 딱 맞는 마케팅 전략과 업체를 확인할 수 있어요.
                  </RadioBoxLabelSubTitle>
                </RadioBoxLabelWrapper>
              </RegisterRadioBox>

              <RegisterRadioBox
                id="companyMember"
                value="기업회원으로 가입"
                checked={!registerCheckedValue.isCommonMember}
                onChange={(e) => {
                  e.stopPropagation();
                  setRegisterCheckedValue({
                    ...registerCheckedValue,
                    isCommonMember: false,
                  });
                }}
              >
                <RadioBoxLabelWrapper>
                  <RadioBoxLabelTitle>전문가로 가입할래요.</RadioBoxLabelTitle>
                  <RadioBoxLabelSubTitle>
                    기업 소개를 올리고 소상공인에게 서비스를 제공할 수 있어요.
                  </RadioBoxLabelSubTitle>
                </RadioBoxLabelWrapper>
              </RegisterRadioBox>
            </RadioBoxWrapper>
          </RegisterForm>
          <PrimaryBtn type="submit" onClick={handleMoveRegisterPage}>
            다음
          </PrimaryBtn>
        </RegisterContainer>
        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default Register;

const RegisterContainer = styled.section`
  position: absolute;
  width: 430px;
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

const RegisterTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RegisterForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;

const RadioBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RadioBoxLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const RadioBoxLabelTitle = styled(PretendardText)`
  font-weight: 600;
  font-size: 16px;
  line-height: 145%;
  color: #282828;
`;

const RadioBoxLabelSubTitle = styled(PretendardText)`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #939393;
`;
