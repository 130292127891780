import React from "react";
import PretendardText from "../atoms/PretendardText";

const SearchBtn = (props) => {
  return (
    <button
      style={{
        padding: "10px 15px",
        border: "1px solid #2ec5f6",
        borderRadius: 6,
        background: "transparent",
        cursor: "pointer",
        width: 76,
        height: 43,
      }}
      type="submit"
      form={props.form}
      {...props}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="7" cy="8" r="5.5" stroke="#2ec5f6" strokeWidth="2" />
          <path
            d="M10.9934 12.5L13.5002 15.5"
            stroke="#2ec5f6"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <PretendardText
          style={{
            color: "#2ec5f6",
            fontWeight: 700,
            fontSize: 16,
            lineHeight: "145%",
          }}
        >
          검색
        </PretendardText>
      </div>
    </button>
  );
};

export default SearchBtn;
