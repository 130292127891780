import React from "react";
import styled from "styled-components";
import PretendardText from "../components/atoms/PretendardText";
import { Link, useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <NotFoundContainer>
        <PretendardText
          style={{
            fontWeight: 900,
            fontSize: 64,
            color: "#fff",
            marginBottom: 20,
            textAlign: "center",
          }}
        >
          존재하지 않는 페이지입니다.
        </PretendardText>
        <div
          style={{
            display: "flex",
            gap: 20,
          }}
        >
          <BackBtn
            onClick={() => {
              navigate(-1);
            }}
          >
            뒤로 돌아가기
          </BackBtn>
          <Link to="/">
            <BackBtn>홈으로 돌아가기</BackBtn>
          </Link>
        </div>
      </NotFoundContainer>
    </>
  );
};

export default NotFound;

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #121212;
`;

const BackBtn = styled.div`
  border-radius: 6px;
  background-color: #fff;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 600;
  color: black;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover {
    background-color: #8e8e8e;
  }

  &:focus-visible {
    outline: 2px solid transparent;
    outline-offset: 2px;
    outline-color: #fff;
  }
`;
