import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import BodyContainer from "../../components/molecules/layout/BodyContainer";
import PageContainer from "../../components/molecules/layout/PageContainer";
import Header from "../../components/organisms/Header";
import SearchBtn from "../../components/molecules/SearchBtn";
import { Link, useNavigate } from "react-router-dom";
import DownArrowIcon from "../../components/atoms/icons/DownArrowIcon";
import PretendardText from "./../../components/atoms/PretendardText";
import SelectList from "./../../components/molecules/SelectList";
import SelectOption from "../../components/atoms/SelectOption";
import DivisionLine from "../../components/atoms/DivisionLine";
import axios from "axios";
import { url } from "../../lib/api/api";
import {
  deleteStorage,
  getStorage,
  setStorage,
} from "../../lib/utils/sessionStorage";
import Popup from "./../../components/molecules/Popup";
import route from "../../router/route";
import { budgetList, periodList, sectorList } from "../../constants/home/list";

// 비디오 프리로딩 함수
const preloadVideo = (src) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.src = src;
    video.onloadeddata = () => {
      resolve(src);
    };
    video.onerror = (error) => {
      reject(error);
    };
  });
};
const Home = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    if (token !== "") {
      getTokens();
    }
  }, [token]);

  // ----------------------------------- //
  // ---------- 토큰 가져오기 ---------- //
  // --------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    const loadVideo = async () => {
      try {
        await preloadVideo("/assets/imgs/home/home_video.mp4");
        setVideoLoaded(true);
      } catch (error) {
        console.error("Failed to preload video:", error);
      }
    };

    loadVideo();
  }, []);

  useEffect(() => {
    const checkVisited = async () => {
      const isVisited = await getStorage("visit");

      if (isVisited !== true) {
        setStorage("visit", true);
        addVisitCount();
      } else {
        return;
      }
    };

    checkVisited();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addVisitCount = async () => {
    try {
      await axios.post(`${url}visit`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      }
    }
  };

  const [isSectorRotated, setIsSectorRotated] = useState(false);
  const [isPeriodRotated, setIsPeriodRotated] = useState(false);
  const [isBudgetRotated, setIsBudgetRotated] = useState(false);

  // input 이벤트 핸들러
  const handleInputValueChange = (e) => {
    const { name, value } = e.target;
    setHomeInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const initialHomeInputValue = {
    sector: "",
    period: "",
    budget: "",
  };

  const [homeInputValue, setHomeInputValue] = useState(initialHomeInputValue);

  const form = useRef();

  const handleSearchCategory = (e) => {
    e.preventDefault();
  };

  return (
    <PageContainer
      style={{
        minHeight: "fit-content",
      }}
    >
      {videoLoaded && (
        <>
          <BackgroundVideo autoPlay muted loop>
            <BackgroundVideoSource
              src="/assets/imgs/home/home_video.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </BackgroundVideo>
          <BackgroundVideoOverlay />
        </>
      )}
      <Header isWhite />
      <Popup />
      <BodyContainer>
        <MainContentWrapper>
          <MainContentInnerWrapper>
            <PretendardText
              style={{
                width: 333,
                fontSize: 18,
                fontWeight: 600,
                lineHeight: "150%",
                color: "#fff",
                padding: "12px 30px",
                borderRadius: 100,
                background: "linear-gradient(90deg, #60d8ff 0%, #009af1 100%)",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.18)",
              }}
            >
              막막했던 온라인 마케팅, 1분 안에 해결!
            </PretendardText>
            <PretendardText
              style={{
                display: "inline-flex",
                fontSize: 38,
                fontWeight: 700,
                color: "#fff",
                textAlign: "center",
              }}
            >
              나에게 최적화된 온라인 마케팅 전략을{" "}
              <PretendardText
                style={{
                  fontSize: 38,
                  fontWeight: 700,
                  color: "#2ec5f6",
                  textAlign: "center",
                }}
              >
                무료로 확인하세요.
              </PretendardText>
            </PretendardText>
          </MainContentInnerWrapper>

          {/* Form 영역 */}
          <CategoryForm
            action="/"
            method="get"
            ref={form}
            id="categoryForm"
            onSubmit={handleSearchCategory}
          >
            <CategoryInnerContainer>
              {/* 업종 선택 */}
              <CategoryWrapper>
                {/* Label sr-only */}
                <InputLabel htmlFor="sector">업종 선택</InputLabel>
                <InputField
                  type="text"
                  id="sector"
                  name="sector"
                  placeholder="업종을 선택해 주세요."
                  readOnly
                  value={
                    homeInputValue.sector === ""
                      ? ""
                      : homeInputValue.sectorTitle
                  }
                  onChange={handleInputValueChange}
                  onClick={() => {
                    setIsSectorRotated(!isSectorRotated);
                    setIsPeriodRotated(false);
                    setIsBudgetRotated(false);
                  }}
                />
                <DownArrowIcon
                  style={{
                    position: "absolute",
                    top: 13,
                    right: 0,
                    transform: isSectorRotated
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsSectorRotated(!isSectorRotated);
                    setIsPeriodRotated(false);
                    setIsBudgetRotated(false);
                  }}
                />

                <SelectList
                  className="scrollbar"
                  style={{
                    top: 45,
                    right: 0,
                    width: 300,
                    display: isSectorRotated ? "block" : "none",
                  }}
                >
                  {sectorList.map((option, index) => (
                    <React.Fragment key={index}>
                      <SelectOption
                        onClick={() => {
                          setIsSectorRotated((prevState) => !prevState);
                          setHomeInputValue((prevState) => ({
                            ...prevState,
                            sector: option.value,
                            sectorTitle: option.title,
                          }));
                        }}
                      >
                        {option.title}
                      </SelectOption>
                      {index < sectorList.length - 1 && (
                        <DivisionLine
                          style={{
                            margin: "12px 0",
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </SelectList>
              </CategoryWrapper>

              <CategoryDivideLine />

              {/* 기간 선택 */}
              <CategoryWrapper>
                {/* Label sr-only */}
                <InputLabel htmlFor="period">기간 선택</InputLabel>
                <InputField
                  type="text"
                  id="period"
                  name="period"
                  placeholder="기간을 선택해 주세요."
                  readOnly
                  value={
                    homeInputValue.period === ""
                      ? ""
                      : homeInputValue.periodTitle
                  }
                  onChange={handleInputValueChange}
                  onClick={() => {
                    setIsPeriodRotated(!isPeriodRotated);
                    setIsSectorRotated(false);
                    setIsBudgetRotated(false);
                  }}
                />
                <DownArrowIcon
                  style={{
                    position: "absolute",
                    top: 13,
                    right: 0,
                    transform: isPeriodRotated
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsPeriodRotated(!isPeriodRotated);
                    setIsSectorRotated(false);
                    setIsBudgetRotated(false);
                  }}
                />
                <SelectList
                  className="scrollbar"
                  style={{
                    top: 45,
                    right: 0,
                    width: 300,
                    display: isPeriodRotated ? "block" : "none",
                  }}
                >
                  {periodList.map((option, index) => (
                    <React.Fragment key={index}>
                      <SelectOption
                        onClick={() => {
                          setIsPeriodRotated((prevState) => !prevState);
                          setHomeInputValue((prevState) => ({
                            ...prevState,
                            period: option.value,
                            periodTitle: option.title,
                          }));
                        }}
                      >
                        {option.title}
                      </SelectOption>
                      {index < periodList.length - 1 && (
                        <DivisionLine
                          style={{
                            margin: "12px 0",
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </SelectList>
              </CategoryWrapper>

              <CategoryDivideLine />

              {/* 예산 선택 */}
              <CategoryWrapper>
                {/* Label sr-only */}
                <InputLabel htmlFor="budget">예산 선택</InputLabel>
                <InputField
                  type="text"
                  id="budget"
                  name="budget"
                  placeholder="예산을 선택해 주세요."
                  readOnly
                  value={
                    homeInputValue.budget === ""
                      ? ""
                      : homeInputValue.budgetTitle
                  }
                  onChange={handleInputValueChange}
                  onClick={() => {
                    setIsBudgetRotated(!isBudgetRotated);
                    setIsSectorRotated(false);
                    setIsPeriodRotated(false);
                  }}
                />
                <DownArrowIcon
                  style={{
                    position: "absolute",
                    top: 13,
                    right: 0,
                    transform: isBudgetRotated
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsBudgetRotated(!isBudgetRotated);
                    setIsSectorRotated(false);
                    setIsPeriodRotated(false);
                  }}
                />
                <SelectList
                  className="scrollbar"
                  style={{
                    top: 45,
                    right: 0,
                    width: 300,
                    display: isBudgetRotated ? "block" : "none",
                  }}
                >
                  {budgetList.map((option, index) => (
                    <React.Fragment key={index}>
                      <SelectOption
                        onClick={() => {
                          setIsBudgetRotated((prevState) => !prevState);
                          setHomeInputValue((prevState) => ({
                            ...prevState,
                            budget: option.value,
                            budgetTitle: option.title,
                          }));
                        }}
                      >
                        {option.title}
                      </SelectOption>
                      {index < budgetList.length - 1 && (
                        <DivisionLine
                          style={{
                            margin: "12px 0",
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </SelectList>
              </CategoryWrapper>

              {/* 검색 버튼 */}
              <Link
                to={`${route.search}?sector=${homeInputValue.sector}&period=${homeInputValue.period}&budget=${homeInputValue.budget}`}
              >
                <SearchBtn type="button" />
              </Link>
            </CategoryInnerContainer>
          </CategoryForm>
        </MainContentWrapper>
      </BodyContainer>
    </PageContainer>
  );
};

export default Home;

const BackgroundVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
`;

const BackgroundVideoSource = styled.source``;

const BackgroundVideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: rgba(0, 0, 0, 0.65);
  z-index: -1;
`;

const MainContentWrapper = styled.div`
  position: absolute;
  top: 297px;
  display: flex;
  flex-direction: column;
  gap: 75px;
`;

const MainContentInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 29px;
`;

const CategoryForm = styled.form`
  display: flex;
  padding: 15px 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
`;

const CategoryInnerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const CategoryWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
`;

const InputLabel = styled.label`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip-path: inset(50%);
  border: 0;
  clip: rect(0, 0, 0, 0);
`;

const InputField = styled.input`
  width: 300px;
  height: 51px;
  color: #939393;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  font-size: 18px;
  outline: none;
  border-radius: 5px;
  &::placeholder {
    color: #c0c0c0;
  }
  &:focus {
    outline: 1px solid #2ec5f6;
  }
`;

const CategoryDivideLine = styled.div`
  border-right: 2px solid #e9e9e9;
  height: 39px;
`;
