import React from "react";
import styled from "styled-components";

const InputField = (props) => {
  return <StyledInputField {...props} />;
};

export default InputField;

const StyledInputField = styled.input`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #000;
  width: 100%;
  height: 51px;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  padding-left: 17px;
  outline: none;
  &::placeholder {
    color: #c0c0c0;
  }
  &:focus {
    outline: 1px solid #282828;
  }
`;
