import React from "react";
import styled from "styled-components";

const SendBtn = (props) => {
  return (
    <SendBtnSvg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="40" height="40" rx="10" fill="#2EC5F6" />
      <g clipPath="url(#clip0_275_6024)">
        <path
          d="M29.7765 10.2416C29.5429 10.0166 29.2008 9.94155 28.9004 10.0499L10.5441 16.7166C10.1102 16.8832 9.8933 17.3582 10.0602 17.7916C10.1436 18.0166 10.3188 18.1916 10.5441 18.2749L17.7114 21.1332L23.0013 15.8332L24.1778 17.0082L18.8628 22.3166L21.7331 29.4749C21.8583 29.7916 22.167 29.9999 22.5091 29.9999C22.8512 29.9999 23.1599 29.7749 23.2767 29.4499L29.9517 11.1166C30.0685 10.8166 30.0018 10.4749 29.7765 10.2416Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_275_6024">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </SendBtnSvg>
  );
};

export default SendBtn;

const SendBtnSvg = styled.svg`
  cursor: pointer;
`;
