import styled from "styled-components";
import Title from "../../../../components/atoms/Title";
import BodyContainer from "../../../../components/molecules/layout/BodyContainer";
import Header from "../../../../components/organisms/Header";
import useResizeHeight from "../../../../hooks/useResizeHeight";
import Footer from "../../../../components/organisms/Footer";
import PretendardText from "../../../../components/atoms/PretendardText";
import { useRecoilValue } from "recoil";
import React, { useEffect, useState } from "react";
import {
  deleteStorage,
  getStorage,
} from "../../../../lib/utils/sessionStorage";
import axios from "axios";
import { url } from "../../../../lib/api/api";
import { useNavigate } from "react-router-dom";
import { myInfoValueState } from "../../../../store/myInfo";
import PageContainer from "../../../../components/molecules/layout/PageContainer";
import scrollToTop from "../../../../lib/utils/scrollToTop";
import InputBox from "../../../../components/molecules/InputBox";
import SecondaryMiniBtn from "../../../../components/atoms/buttons/SecondaryMiniBtn";
import Confirm from "../../../../components/molecules/Confirm";
import PrimaryMiniBtn from "../../../../components/atoms/buttons/PrimaryMiniBtn";
import regex from "../../../../constants/regex";
import TextareaBox from "../../../../components/molecules/TextareaBox";
import Alert from "../../../../components/molecules/Alert";
import CommonSideBar from "./../_components/CommonSideBar";
import route from "../../../../router/route";

const CommonAskCreate = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    if (myInfo.id === 0 || !myInfo || myInfo.profile) {
      return;
    } else {
      if (myInfo.id !== 0) {
        getTokens();
      } else return;
    }
  }, [myInfo]); // eslint-disable-line

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // 알림창 관련 상태
  const [alertOpen, setAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // ------------------------------------ //
  // ---------- 문의하기 영역 ---------- //
  // ---------------------------------- //
  const [addAskInputValue, setAddAskInputValue] = useState({
    title: "",
    content: "",
  });
  const [isAskCancelBtnClicked, setIsAskCancelBtnClicked] = useState(false);

  // 유효성 검사
  const [titleRegexTest, setTitleRegexTest] = useState(true);
  const [contentRegexTest, setContentRegexTest] = useState(true);

  // 입력 필드 핸들러
  const handleAskInputValueChange = (e) => {
    const { name, value } = e.target;

    const validateInput = (regex, value) => {
      return regex.test(value);
    };

    setAddAskInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    const validateField = (name, value) => {
      switch (name) {
        case "title":
          if (value !== "") {
            setTitleRegexTest(validateInput(regex.text, value));
          } else {
            setTitleRegexTest(true);
          }
          break;
        case "content":
          if (value !== "") {
            setContentRegexTest(validateInput(regex.text, value));
          } else {
            setContentRegexTest(true);
          }
          break;
        default:
          console.error("Invalid field name");
      }
    };

    validateField(name, value);
  };

  // 문의하기 생성
  const [isFail, setIsFail] = useState(false);

  const createAsk = async () => {
    const data = {
      title: addAskInputValue.title,
      content: addAskInputValue.content,
    };

    try {
      await axios.post(`${url}member/ask`, data, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setAlertOpen(true);
      setResponseMessage("제출하였습니다.");
      setAddAskInputValue({
        title: "",
        content: "",
      });
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setIsFail(true);
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 550 + elementHeight,
      }}
    >
      <Header $isBgWhite={true} />
      <BodyContainer
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        <MyPageContainer ref={elementRef}>
          <Title>마이페이지</Title>
          <MyPageInnerContainerWrapper>
            <CommonSideBar />

            <MyPageContentsContainer>
              <AskWriteContainer>
                <AskWriteTitleText>문의내용 작성</AskWriteTitleText>
                <div
                  style={{
                    marginTop: 30,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                  }}
                >
                  {/* 제목 */}
                  <InputBox
                    $uniqueKey="title"
                    label="제목"
                    $isRequired
                    placeholder="제목을 입력해 주세요."
                    value={addAskInputValue.title}
                    onChange={handleAskInputValueChange}
                    $isHelp={!titleRegexTest}
                    $helpMsg="2글자 이상 입력해 주세요."
                    style={{ width: 892 }}
                  />

                  {/* 내용 */}
                  <TextareaBox
                    $uniqueKey="content"
                    label="내용"
                    $isRequired
                    placeholder="내용을 입력해 주세요."
                    value={addAskInputValue.content}
                    onChange={handleAskInputValueChange}
                    $isHelp={!contentRegexTest}
                    $helpMsg="2글자 이상 입력해 주세요."
                    style={{ width: 892 }}
                  />

                  {/* 하단 버튼 */}
                  <AskWriteBottomBtnWrapper>
                    <SecondaryMiniBtn
                      type="button"
                      onClick={() => {
                        setIsAskCancelBtnClicked(true);
                      }}
                    >
                      취소하기
                    </SecondaryMiniBtn>
                    {isAskCancelBtnClicked && (
                      <Confirm
                        style={{
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        cancelText="아니요"
                        cancelEvent={() => {
                          setIsAskCancelBtnClicked(false);
                        }}
                        confirmText="예"
                        confirmEvent={() => {
                          setAddAskInputValue({
                            title: "",
                            content: "",
                          });
                          setIsAskCancelBtnClicked(false);
                          navigate(-1);
                          scrollToTop();
                        }}
                      >
                        취소하시겠습니까?
                        <br />
                        작성한 내용이 사라집니다.
                      </Confirm>
                    )}

                    <PrimaryMiniBtn
                      disabled={
                        addAskInputValue.title === "" ||
                        !titleRegexTest ||
                        addAskInputValue.content === "" ||
                        !contentRegexTest
                      }
                      type="button"
                      onClick={createAsk}
                    >
                      문의하기
                    </PrimaryMiniBtn>
                  </AskWriteBottomBtnWrapper>
                </div>
              </AskWriteContainer>

              {alertOpen && (
                <Alert
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isFail) {
                      setAlertOpen(false);
                    } else {
                      setAlertOpen(false);
                      navigate(`${route.myPage_common_ask}`);
                      scrollToTop();
                    }
                  }}
                >
                  {responseMessage}
                </Alert>
              )}
            </MyPageContentsContainer>
          </MyPageInnerContainerWrapper>
        </MyPageContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default CommonAskCreate;

const MyPageContainer = styled.div`
  position: absolute;
  top: 165px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MyPageInnerContainerWrapper = styled.div`
  display: flex;
  gap: 31px;
  width: 100%;
  margin-top: 30px;
`;

const MyPageContentsContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const AskWriteContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AskWriteTitleText = styled(PretendardText)`
  font-size: 20px;
  font-weight: 700;
  line-height: 145%;
  color: #282828;
`;

const AskWriteBottomBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
`;
