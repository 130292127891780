import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import PretendardText from "../atoms/PretendardText";
import DownArrowIcon from "./../atoms/icons/DownArrowIcon";

function Pagination({
  route,
  queryString,
  totalPages,
  currentPage,
  rangeSize,
  style,
}) {
  const navigate = useNavigate();

  const pageList = useMemo(() => {
    const pageGroup = Math.ceil(currentPage / rangeSize);

    let firstNumber = pageGroup * rangeSize - (rangeSize - 1);
    if (firstNumber <= 0) {
      firstNumber = 1;
    }

    let lastNumber = pageGroup * rangeSize;
    if (lastNumber > totalPages) {
      lastNumber = totalPages;
    }

    const pageList = [];
    for (let i = firstNumber; i <= lastNumber; i++) {
      pageList.push(i);
    }

    return pageList;
  }, [totalPages, currentPage, rangeSize]);

  return (
    <>
      {pageList.length > 0 ? (
        <>
          <PagenationWrapper style={style}>
            <PaginationBtnWrapper>
              <DownArrowIcon
                style={{
                  transform: "rotate(90deg)",
                }}
                onClick={() => {
                  if (Number(currentPage) === 1) {
                    return;
                  }

                  navigate(
                    route +
                      `?page=${Number(currentPage) - 1}${
                        queryString ? "&" + queryString : ""
                      }`,
                    { replace: true }
                  );
                }}
              />
            </PaginationBtnWrapper>
            <PagenationNumWrapper>
              {pageList.map((item, index) => {
                return (
                  <PagenationNum
                    key={index}
                    style={{
                      color:
                        Number(currentPage) === item ? "#282828" : "#6f6f6f",
                      background: Number(currentPage) === item ? "#e9e9e9" : "",
                      borderRadius: Number(currentPage) === item ? "08px" : "",
                    }}
                    onClick={() => {
                      navigate(
                        route +
                          `?page=${item}${
                            queryString ? "&" + queryString : ""
                          }`,
                        { replace: true }
                      );
                    }}
                  >
                    {item}
                  </PagenationNum>
                );
              })}
            </PagenationNumWrapper>
            <PaginationBtnWrapper>
              <DownArrowIcon
                style={{
                  transform: "rotate(-90deg)",
                }}
                onClick={() => {
                  if (Number(currentPage) === totalPages) {
                    return;
                  }

                  navigate(
                    route +
                      `?page=${Number(currentPage) + 1}${
                        queryString ? "&" + queryString : ""
                      }`,
                    { replace: true }
                  );
                }}
              />
            </PaginationBtnWrapper>
          </PagenationWrapper>
        </>
      ) : null}
    </>
  );
}

export default Pagination;

const PagenationWrapper = styled.div.attrs((props) => {})`
  margin-top: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PaginationBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`;

const PagenationNum = styled(PretendardText).attrs((props) => {})`
  display: flex;
  font-weight: 700;
  line-height: 28px;

  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: #b8bfc4;
`;

const PagenationNumWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 4px;

  cursor: pointer;
`;
