import React from "react";
import styled from "styled-components";

const SelectOption = (props) => {
  return (
    <StyledSelectOption key={props.key} onClick={props.onClick}>
      {props.children}
    </StyledSelectOption>
  );
};

export default SelectOption;

const StyledSelectOption = styled.span`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 145%;
  text-align: left;
  display: block;
  word-break: keep-all;
  white-space: pre-wrap;
  color: #282828;
  cursor: default;
`;
