const sortingOptions = [{
    sort: 'id',
    sortTitle: "최신순"
},
{
    sort: 'viewCount',
    sortTitle: "조회순"
},
{
    sort: 'reviewCount',
    sortTitle: "후기순"
},
{
    sort: 'reviewAverage',
    sortTitle: "별점순"
}];

export default sortingOptions;
