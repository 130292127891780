import React from "react";
import styled from "styled-components";

const ModalContainer = (props) => {
  return (
    <StyledModalContainer $isOpen={props.$isOpen} {...props}>
      {props.children}
    </StyledModalContainer>
  );
};

export default ModalContainer;

const StyledModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 710px;
  height: 594px;
  padding: 40px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  animation: ${(props) =>
    props.$isOpen
      ? "fadeIn 0.3s ease-out forwards"
      : "fadeOut 0.3s ease-out forwards"};

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;
