import React from "react";
import styled from "styled-components";
import VCheckIcon from "./../atoms/icons/VCheckIcon";

const RegisterRadioBox = (props) => {
  return (
    <RadioBoxLabel {...props}>
      <VCheckIcon style={{ margin: "0 20px" }} checked={props.checked} />
      {props.children}
      <RadioBox
        type="radio"
        value={props.value}
        name={props.id}
        id={props.id}
        checked={props.checked}
        onChange={props.onChange}
      />
    </RadioBoxLabel>
  );
};

export default RegisterRadioBox;

const RadioBoxLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 23px 0;
  border: ${(props) =>
    props.checked ? "1px solid #2ec5f6" : "1px solid #e9e9e9"};
  transition: all 0.3s ease;
  border-radius: 5px;
  box-shadow: ${(props) =>
    props.checked ? "0px 5px 15px rgba(72, 162, 191, 0.25)" : "none"};
  cursor: pointer;
  &:hover {
    box-shadow: 0px 5px 15px rgba(72, 162, 191, 0.25);
    border: 1px solid #2ec5f6;
  }
`;

const RadioBox = styled.input`
  display: none;
`;
