import styled from "styled-components";
import Title from "../../../../components/atoms/Title";
import BodyContainer from "../../../../components/molecules/layout/BodyContainer";
import Header from "../../../../components/organisms/Header";
import useResizeHeight from "../../../../hooks/useResizeHeight";
import Footer from "../../../../components/organisms/Footer";
import PretendardText from "../../../../components/atoms/PretendardText";
import { useRecoilValue } from "recoil";
import React, { useEffect, useState } from "react";
import {
  deleteStorage,
  getStorage,
} from "../../../../lib/utils/sessionStorage";
import axios from "axios";
import { url } from "../../../../lib/api/api";
import { useLocation, useNavigate } from "react-router-dom";
import { myInfoValueState } from "../../../../store/myInfo";
import CompanySideBar from "../_components/CompanySideBar";
import PageContainer from "../../../../components/molecules/layout/PageContainer";
import scrollToTop from "../../../../lib/utils/scrollToTop";
import InputBox from "../../../../components/molecules/InputBox";
import SelectList from "../../../../components/molecules/SelectList";
import { bankList } from "../../../../constants/myPageData";
import SelectOption from "../../../../components/atoms/SelectOption";
import DivisionLine from "../../../../components/atoms/DivisionLine";
import SecondaryMiniBtn from "../../../../components/atoms/buttons/SecondaryMiniBtn";
import Confirm from "../../../../components/molecules/Confirm";
import PrimaryMiniBtn from "../../../../components/atoms/buttons/PrimaryMiniBtn";
import { removeNonNumeric } from "../../../../lib/utils/formatNumber";
import DownArrowIcon from "../../../../components/atoms/icons/DownArrowIcon";
import regex from "../../../../constants/regex";
import Alert from "../../../../components/molecules/Alert";
import route from "../../../../router/route";

const CompanyAccount = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    if (myInfo.id === 0 || !myInfo) {
      return;
    } else {
      if (myInfo.profile) {
        getTokens();
      } else {
        navigate("/");
      }
    }
  }, [myInfo]); // eslint-disable-line

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    scrollToTop();
    if (token !== "") {
      getAccountInfo();
    }
  }, [token, location]); // eslint-disable-line

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // -------------------------------------- //
  // ---------- 데이터 가져오기 ---------- //
  // ------------------------------------ //
  const [accountEditValue, setAccountEditValue] = useState({
    accountHolder: "",
    bank: "",
    accountNum: "",
  });

  const getAccountInfo = async () => {
    try {
      const response = await axios.get(`${url}member/my-info`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setAccountEditValue({
        accountHolder: response.data.data.content.profile?.accountName
          ? response.data.data.content.profile.accountName
          : "",
        bank: response.data.data.content.profile?.accountBank
          ? response.data.data.content.profile.accountBank
          : "",
        accountNum: response.data.data.content.profile?.accountNumber
          ? response.data.data.content.profile.accountNumber
          : "",
      });
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // 알림창 관련 상태
  const [alertOpen, setAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // 수정 제출 핸들러
  const handleAccountEditSubmit = async () => {
    const data = {
      accountName: accountEditValue.accountHolder,
      accountBank: accountEditValue.bank,
      accountNumber: accountEditValue.accountNum,
    };
    try {
      await axios.patch(`${url}member/account`, data, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      getAccountInfo();
      setAlertOpen(true);
      setResponseMessage("수정되었습니다.");
      scrollToTop();
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // 셀렉트 박스 핸들러
  const [isBankNameRotated, setIsBankNameRotated] = useState(false);

  const handleSelectValueChange = async (e) => {
    const { name, value } = e.target;
    setAccountEditValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // 유효성 검사 관련 상태
  const [accountHolderRegexTest, setAccountHolderRegexTest] = useState(true);
  const [accountNumRegexTest, setAccountNumRegexTest] = useState(true);

  // 입력 필드 핸들러
  const handleAccountInputValueChange = (e) => {
    const { name, value } = e.target;

    if (name === "accountNum") {
      let cleanValue = removeNonNumeric(value);
      setAccountEditValue((prevState) => ({
        ...prevState,
        [name]: cleanValue,
      }));
    } else {
      setAccountEditValue((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    const validateInput = (regex, value) => {
      return regex.test(value);
    };

    const validateField = (name, value) => {
      switch (name) {
        case "accountHolder":
          if (value !== "") {
            setAccountHolderRegexTest(validateInput(regex.text, value));
          } else {
            setAccountHolderRegexTest(true);
          }
          break;
        case "accountNum":
          if (value !== "") {
            setAccountNumRegexTest(validateInput(regex.accountNum, value));
          } else {
            setAccountNumRegexTest(true);
          }
          break;
        default:
          console.error("Invalid field name");
      }
    };

    validateField(name, value);
  };

  // 취소 및 저장 버튼 클릭 시 관련 상태
  const [isEditAccountBtnClicked, setIsEditAccountBtnClicked] = useState(false);
  const [isCancelAccountBtnClicked, setIsCancelAccountBtnClicked] =
    useState(false);

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 550 + elementHeight,
      }}
    >
      <Header $isBgWhite={true} />
      <BodyContainer
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        <MyPageContainer ref={elementRef}>
          <Title>마이페이지</Title>
          <MyPageInnerContainerWrapper>
            <CompanySideBar></CompanySideBar>

            <MyPageContentsContainer>
              <MyInfoEditContainer>
                <ProfileNameText
                  style={{
                    marginBottom: 10,
                    fontSize: 20,
                    fontWeight: 700,
                    lineHeight: "145%",
                  }}
                >
                  계좌 관리
                </ProfileNameText>
                <ProfileNameText
                  style={{
                    marginBottom: 40,
                    fontSize: 16,
                    lineHeight: "145%",
                    color: "#383838",
                  }}
                >
                  입력한 정보로 결제금액이 입금됩니다.
                </ProfileNameText>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 20,
                    }}
                  >
                    {/* 예금주 */}
                    <InputBox
                      $uniqueKey="accountHolder"
                      label="예금주"
                      type="text"
                      $isRequired
                      placeholder="예금주를 입력해 주세요."
                      value={accountEditValue.accountHolder}
                      onChange={handleAccountInputValueChange}
                      $isHelp={!accountHolderRegexTest}
                      $helpMsg="2글자 이상 입력해 주세요."
                    />

                    {/* 은행 */}
                    <BankSelectWrapper>
                      <BankSelectLabel htmlFor="bank">
                        은행
                        <span
                          style={{
                            fontFamily: "Pretendard",
                            color: "#2ec5f6",
                            fontSize: "16px",
                            fontWeight: "600",
                            lineHeight: "145%",
                          }}
                        >
                          *
                        </span>
                      </BankSelectLabel>
                      <BankSelectField
                        type="text"
                        id="bank"
                        name="bank"
                        placeholder="은행을 선택해 주세요."
                        readOnly
                        $isRequired
                        value={accountEditValue.bank}
                        onChange={handleSelectValueChange}
                        onClick={() => {
                          setIsBankNameRotated((prevState) => !prevState);
                        }}
                      />
                      <DownArrowIcon
                        style={{
                          position: "absolute",
                          top: 45,
                          right: 10,
                          transform: isBankNameRotated
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                          transition: "transform 0.3s ease",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setIsBankNameRotated((prevState) => !prevState);
                        }}
                      />
                      <SelectList
                        className="scrollbar"
                        style={{
                          top: 80,
                          right: 0,
                          width: 430,
                          display: isBankNameRotated ? "block" : "none",
                        }}
                      >
                        {bankList.map((option, index) => (
                          <React.Fragment key={index}>
                            <SelectOption
                              onClick={() => {
                                setIsBankNameRotated((prevState) => !prevState);
                                setAccountEditValue((prevState) => ({
                                  ...prevState,
                                  bank: option.title,
                                }));
                              }}
                            >
                              {option.title}
                            </SelectOption>
                            {index < bankList.length - 1 && (
                              <DivisionLine
                                style={{
                                  margin: "12px 0",
                                }}
                              />
                            )}
                          </React.Fragment>
                        ))}
                      </SelectList>
                    </BankSelectWrapper>

                    {/* 계좌번호 */}
                    <InputBox
                      $uniqueKey="accountNum"
                      label="계좌번호"
                      type="text"
                      $isRequired
                      placeholder="계좌번호를 입력해 주세요."
                      value={accountEditValue.accountNum}
                      onChange={handleAccountInputValueChange}
                      $isHelp={!accountNumRegexTest}
                      $helpMsg="계좌번호를 올바르게 입력해 주세요."
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 20,
                      marginTop: 40,
                    }}
                  >
                    <SecondaryMiniBtn
                      type="button"
                      onClick={() => {
                        setIsCancelAccountBtnClicked(true);
                      }}
                    >
                      취소하기
                    </SecondaryMiniBtn>
                    {isCancelAccountBtnClicked && (
                      <Confirm
                        style={{
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        cancelText="아니요"
                        cancelEvent={() => {
                          setIsCancelAccountBtnClicked(false);
                        }}
                        confirmText="예"
                        confirmEvent={() => {
                          setIsCancelAccountBtnClicked(false);
                          getAccountInfo();
                          scrollToTop();
                        }}
                      >
                        취소하시겠습니까?
                        <br />
                        작성한 내용이 사라집니다.
                      </Confirm>
                    )}
                    <PrimaryMiniBtn
                      type="button"
                      disabled={
                        accountEditValue.bank === "" ||
                        accountEditValue.bank === "은행을 선택해 주세요." ||
                        accountEditValue.accountHolder === "" ||
                        !accountHolderRegexTest ||
                        accountEditValue.accountNum === "" ||
                        !accountNumRegexTest
                      }
                      onClick={() => {
                        setIsEditAccountBtnClicked(true);
                      }}
                    >
                      저장하기
                    </PrimaryMiniBtn>
                    {isEditAccountBtnClicked && (
                      <Confirm
                        style={{
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        cancelText="아니요"
                        cancelEvent={() => {
                          setIsEditAccountBtnClicked(false);
                        }}
                        confirmText="예"
                        confirmEvent={() => {
                          setIsEditAccountBtnClicked(false);
                          scrollToTop();
                          handleAccountEditSubmit();
                        }}
                      >
                        저장하시나요?
                      </Confirm>
                    )}
                  </div>
                </div>
              </MyInfoEditContainer>

              {alertOpen && (
                <Alert
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAlertOpen(false);
                  }}
                >
                  {responseMessage}
                </Alert>
              )}
            </MyPageContentsContainer>
          </MyPageInnerContainerWrapper>
        </MyPageContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default CompanyAccount;

const MyPageContainer = styled.div`
  position: absolute;
  top: 165px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MyPageInnerContainerWrapper = styled.div`
  display: flex;
  gap: 31px;
  width: 100%;
  margin-top: 30px;
`;

const MyPageContentsContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MyInfoEditContainer = styled.div`
  width: 892px;
  padding: 80px 231px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  border-radius: 10px;
  background-color: #fff;
`;

const ProfileNameText = styled(PretendardText)`
  font-size: 18px;
  font-weight: 600;
  color: #282828;
  line-height: 150%;
  overflow-x: auto;
`;

const BankSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 9px;
  width: 430px;
  margin-right: 30px;
`;

const BankSelectLabel = styled.label`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 145%;
  text-align: left;
  display: flex;
  align-items: center;
`;

const BankSelectField = styled.input`
  width: 430px;
  height: 51px;
  color: #282828;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  font-weight: 400;
  font-size: 16px;
  padding-left: 17px;
  outline: none;
  &::placeholder {
    color: #c0c0c0;
  }
  &:focus {
    outline: 1px solid #282828;
  }
`;
