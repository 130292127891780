import React from "react";
import { Routes, Route } from "react-router-dom";
import route from "./route";
import NotFound from "../pages/NotFound";
import TestPage from "../pages/TestPage";
import Home from "../pages/home/Home";
import Login from "../pages/login/Login";
import FindEmail from "../pages/login/FindEmail";
import FindPw from "../pages/login/FindPw";
import Register from "../pages/register/Register";
import Notice from "./../pages/notice/Notice";
import QnA from "./../pages/qna/QnA";
import Curation from "./../pages/curation/Curation";
import Chat from "../pages/chat/Chat";
import Payment from "../pages/payment/Payment";
import AddQnA from "../pages/service/AddQnA";
import Service from "../pages/service/Service";
import ServiceDetail from "../pages/service/ServiceDetail";
import AddService from "./../pages/service/AddService";
import Preview from "./../pages/service/Preview";
import Around from "../pages/around/Around";
import Search from "./../pages/search/Search";
import UIPage from "../pages/UIPage";
import CompanyInfo from "../pages/myPage/company/info/CompanyInfo";
import CompanyAlarm from "../pages/myPage/company/alarm/CompanyAlarm";
import CompanyAccount from "../pages/myPage/company/account/CompanyAccount";
import CompanyChat from "../pages/myPage/company/chat/CompanyChat";
import CompanyAsk from "../pages/myPage/company/ask/CompanyAsk";
import CompanyPay from "../pages/myPage/company/pay/CompanyPay";
import CommonInfo from "../pages/myPage/common/info/CommonInfo";
import CommonChat from "../pages/myPage/common/chat/CommonChat";
import CommonAsk from "./../pages/myPage/common/ask/CommonAsk";
import CompanyAskCreate from "./../pages/myPage/company/ask/CompanyAskCreate";
import CompanyAskDetail from "./../pages/myPage/company/ask/CompanyAskDetail";
import CommonAskCreate from "./../pages/myPage/common/ask/CommonAskCreate";
import CommonAskDetail from "./../pages/myPage/common/ask/CommonAskDetail";
import CommonScrap from "./../pages/myPage/common/scrap/CommonScrap";
import CommonMyReview from "./../pages/myPage/common/myReview/CommonMyReview";
import CommonMyReviewDetail from "./../pages/myPage/common/myReview/CommonMyReviewDetail";
import CommonPaymentRecords from "./../pages/myPage/common/paymentRecords/CommonPaymentRecords";
import CommonPaymentRecordsDetail from "./../pages/myPage/common/paymentRecords/CommonPaymentRecordsDetail";
import ServiceQnAList from "../pages/service/ServiceQnAList";
import EditQnA from "./../pages/service/EditQnA";
import RegisterCommon from "./../pages/register/RegisterCommon";
import RegisterCompany from "./../pages/register/RegisterCompany";
import ServiceEdit from "./../pages/service/ServiceEdit";
import PreviewEdit from "./../pages/service/PreviewEdit";

const router = () => {
  return (
    <Routes>
      {/* default */}
      <Route path={route.default} element={<Home />} />

      {/* login page */}
      <Route path={route.login} element={<Login />} />
      <Route path={route.findEmail} element={<FindEmail />} />
      <Route path={route.findPw} element={<FindPw />} />

      {/* register page */}
      <Route path={route.register} element={<Register />} />
      <Route path={route.register_common} element={<RegisterCommon />} />
      <Route path={route.register_company} element={<RegisterCompany />} />

      {/* common my page */}
      <Route path={route.myPage_common_info} element={<CommonInfo />} />
      <Route path={route.myPage_common_scrap} element={<CommonScrap />} />
      <Route path={route.myPage_common_myReview} element={<CommonMyReview />} />
      <Route
        path={route.myPage_common_myReview_detail + "/:id"}
        element={<CommonMyReviewDetail />}
      />
      <Route
        path={route.myPage_common_paymentRecords}
        element={<CommonPaymentRecords />}
      />
      <Route
        path={route.myPage_common_paymentRecords_detail + "/:id"}
        element={<CommonPaymentRecordsDetail />}
      />
      <Route path={route.myPage_common_chat} element={<CommonChat />} />
      <Route path={route.myPage_common_ask} element={<CommonAsk />} />
      <Route
        path={route.myPage_common_ask_detail + "/:id"}
        element={<CommonAskDetail />}
      />
      <Route
        path={route.myPage_common_ask_create}
        element={<CommonAskCreate />}
      />

      {/* company my page */}
      <Route path={route.myPage_company_info} element={<CompanyInfo />} />
      <Route path={route.myPage_company_alarm} element={<CompanyAlarm />} />
      <Route path={route.myPage_company_account} element={<CompanyAccount />} />
      <Route path={route.myPage_company_pay} element={<CompanyPay />} />
      <Route path={route.myPage_company_chat} element={<CompanyChat />} />
      <Route path={route.myPage_company_ask} element={<CompanyAsk />} />
      <Route
        path={route.myPage_company_ask_detail + "/:id"}
        element={<CompanyAskDetail />}
      />
      <Route
        path={route.myPage_company_ask_create}
        element={<CompanyAskCreate />}
      />

      {/* notice page */}
      <Route path={route.notice} element={<Notice />} />

      {/* qna page */}
      <Route path={route.qna} element={<QnA />} />

      {/* curation page */}
      <Route path={route.curation} element={<Curation />} />

      {/* around page */}
      <Route path={route.around} element={<Around />} />

      {/* search page */}
      <Route path={route.search} element={<Search />} />

      {/* chat page */}
      <Route path={route.chat + "/:id"} element={<Chat />} />

      {/* payment page */}
      <Route path={route.payment + "/:id"} element={<Payment />} />

      {/* service page */}
      <Route path={route.service} element={<Service />} />
      <Route path={route.serviceDetail + "/:id"} element={<ServiceDetail />} />
      <Route path={route.serviceEdit + "/:id"} element={<ServiceEdit />} />
      <Route path={route.addService} element={<AddService />} />
      <Route path={route.addQnA} element={<AddQnA />} />
      <Route path={route.editQnA + "/:id"} element={<EditQnA />} />
      <Route path={route.serviceQnAList} element={<ServiceQnAList />} />
      <Route path={route.preview} element={<Preview />} />
      <Route path={route.previewEdit} element={<PreviewEdit />} />

      {/* Test Page */}
      <Route path={route.testPage} element={<TestPage />} />

      {/* UI Page */}
      <Route path={route.uiPage} element={<UIPage />} />

      {/* Not Found */}
      <Route path={route.notFound} element={<NotFound />} />
    </Routes>
  );
};

export default router;
