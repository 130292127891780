import React, { useState } from "react";
import styled from "styled-components";
import ModalOverlay from "./../atoms/ModalOverlay";
import Title from "./../atoms/Title";
import PretendardText from "./../atoms/PretendardText";
import XBtnIcon from "./../atoms/icons/XBtnIcon";
import SecondaryMiniBtn from "./../atoms/buttons/SecondaryMiniBtn";
import PrimaryMiniBtn from "./../atoms/buttons/PrimaryMiniBtn";
import RadioIcon from "../atoms/icons/RadioIcon";
import InputBox from "./InputBox";
import regex from "../../constants/regex";
import Confirm from "./Confirm";
import axios from "axios";
import { url } from "./../../lib/api/api";
import { useNavigate } from "react-router-dom";
import { deleteStorage } from "../../lib/utils/sessionStorage";

const DeleteIdModal = (props) => {
  const navigate = useNavigate();

  // 라디오 버튼 상태
  const [selectedRadioValue, setSelectedRadioValue] = useState("");

  // 기타 라디오 버튼 클릭 시 상태
  const [inputFieldOpen, setInputFieldOpen] = useState(false);
  const [etcInputValue, setEtcInputValue] = useState("");
  const [etcRegexTest, setEtcRegexTest] = useState(true);

  // 취소 버튼 클릭 시 상태
  const [isCancelBtnClicked, setIsCancelBtnClicked] = useState(false);

  // 확인 버튼 클릭 시 상태
  const [isConfirmBtnClicked, setIsConfirmBtnClicked] = useState(false);

  // 라디오 버튼 변경 핸들러
  const handleRadioChange = (e) => {
    if (e.target.value === "기타") {
      setSelectedRadioValue(e.target.value);
      setInputFieldOpen(true);
    } else {
      setSelectedRadioValue(e.target.value);
      setInputFieldOpen(false);
    }
  };

  // input 값 변경 핸들러
  const handleInputChange = (e) => {
    const value = e.target.value;

    setEtcInputValue(value);

    const validateInput = (regex, value) => {
      return regex.test(value);
    };

    if (value !== "") {
      setEtcRegexTest(validateInput(regex.text, value));
    } else {
      setEtcRegexTest(true);
    }
  };

  // ------------------------------- //
  // ---------- 회원탈퇴 ---------- //
  // ----------------------------- //
  const withdraw = async () => {
    try {
      await axios.post(
        `${url}member/withdraw`,
        {
          reason:
            selectedRadioValue === "기타" ? etcInputValue : selectedRadioValue,
        },
        {
          headers: {
            authorization: `Bearer ${props.$token}`,
          },
        }
      );
      deleteStorage("email");
      deleteStorage("current");
      deleteStorage("token");
      deleteStorage("myInfo");
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <ModalOverlay $isOpen={props.$isOpen}>
        <DeleteIdModalContainer $isOpen={props.$isOpen}>
          <XBtnIcon
            style={{ position: "absolute", top: 30, right: 30 }}
            onClick={props.handleClose}
          />
          <DeleteIdModalContentContainer>
            <DeleteIdModalTitleTextWrapper>
              <Title>회원탈퇴</Title>
              <DeleteIdModalText>
                정말로 탈퇴하시나요?
                <br />
                탈퇴 사유를 선택해 주세요.
              </DeleteIdModalText>
            </DeleteIdModalTitleTextWrapper>
            <DeleteIdModalRadioWrapper>
              <DeleteIdModalRadioLabel>
                <RadioIcon
                  checked={
                    selectedRadioValue === "서비스가 마음에 들지 않아요."
                  }
                />
                <DeleteIdModalRadioInput
                  type="radio"
                  value="서비스가 마음에 들지 않아요."
                  checked={
                    selectedRadioValue === "서비스가 마음에 들지 않아요."
                  }
                  onChange={handleRadioChange}
                />
                서비스가 마음에 들지 않아요.
              </DeleteIdModalRadioLabel>
              <DeleteIdModalRadioLabel>
                <RadioIcon
                  checked={selectedRadioValue === "오류가 너무 많아요."}
                />
                <DeleteIdModalRadioInput
                  type="radio"
                  value="오류가 너무 많아요."
                  checked={selectedRadioValue === "오류가 너무 많아요."}
                  onChange={handleRadioChange}
                />
                오류가 너무 많아요.
              </DeleteIdModalRadioLabel>
              <DeleteIdModalRadioLabel>
                <RadioIcon
                  checked={selectedRadioValue === "사용하기 어려워요"}
                />
                <DeleteIdModalRadioInput
                  type="radio"
                  value="사용하기 어려워요"
                  checked={selectedRadioValue === "사용하기 어려워요"}
                  onChange={handleRadioChange}
                />
                사용하기 어려워요
              </DeleteIdModalRadioLabel>
              <DeleteIdModalRadioLabel>
                <RadioIcon checked={selectedRadioValue === "기타"} />
                <DeleteIdModalRadioInput
                  type="radio"
                  value="기타"
                  checked={selectedRadioValue === "기타"}
                  onChange={handleRadioChange}
                />
                기타
              </DeleteIdModalRadioLabel>
              {inputFieldOpen && (
                <InputBox
                  $uniqueKey="etc"
                  type="text"
                  placeholder="기타 사유를 입력해주세요."
                  value={etcInputValue}
                  onChange={handleInputChange}
                  $isError={!etcRegexTest}
                />
              )}
            </DeleteIdModalRadioWrapper>
            <DeleteIdModalBottomTextWrapper>
              <DeleteIdModalBottomText>
                · 탈퇴 시 회원님의 지원 서류를 포함하여 회원님의 계정에 저장된
                모든 정보가 영구적으로 삭제되며, 다시는 복구할 수 없습니다.
              </DeleteIdModalBottomText>
              <DeleteIdModalBottomText>
                · 기업에 대한 채용 수수료 정산과 부정 이용 방지를 위해, 기업에
                대한 지원 내역은 관련 법령에 의거하여 탈퇴 후 최장 3년간
                저장됩니다.
              </DeleteIdModalBottomText>
              <DeleteIdModalBottomText>
                · 탈퇴 시점 이전까지의 기업에 대한 지원 내역은 기업 고객에게
                공개됩니다.
              </DeleteIdModalBottomText>
            </DeleteIdModalBottomTextWrapper>
          </DeleteIdModalContentContainer>
          <DeleteIdModalBtnWrapper>
            <SecondaryMiniBtn
              onClick={() => {
                setIsCancelBtnClicked(true);
              }}
            >
              취소
            </SecondaryMiniBtn>
            {isCancelBtnClicked && (
              <Confirm
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                cancelEvent={() => {
                  setIsCancelBtnClicked(false);
                }}
                confirmEvent={() => {
                  setIsCancelBtnClicked(false);
                  props.handleClose();
                }}
              >
                취소하시겠습니까?
                <br />
                작성한 내용이 사라집니다.
              </Confirm>
            )}

            <PrimaryMiniBtn
              style={{
                width: 68,
              }}
              onClick={() => {
                setIsConfirmBtnClicked(true);
              }}
              disabled={
                selectedRadioValue === "" ||
                (inputFieldOpen && etcInputValue === "" && etcRegexTest) ||
                !etcRegexTest
              }
            >
              확인
            </PrimaryMiniBtn>
            {isConfirmBtnClicked && (
              <Confirm
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                cancelEvent={() => {
                  setIsConfirmBtnClicked(false);
                }}
                confirmEvent={() => {
                  setIsConfirmBtnClicked(false);
                  withdraw();
                }}
              >
                정말로 탈퇴하시겠습니까?
                <br />
                계정 복구가 불가합니다.
              </Confirm>
            )}
          </DeleteIdModalBtnWrapper>
        </DeleteIdModalContainer>
      </ModalOverlay>
    </>
  );
};

export default DeleteIdModal;

const DeleteIdModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 510px;
  min-height: 586px;
  padding: 40px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  animation: ${(props) =>
    props.$isOpen
      ? "fadeIn 0.3s ease-out forwards"
      : "fadeOut 0.3s ease-out forwards"};
`;

const DeleteIdModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 430px;
  min-height: 395px;
  gap: 30px;
`;

const DeleteIdModalTitleTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DeleteIdModalText = styled(PretendardText)`
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #6f6f6f;
`;

const DeleteIdModalRadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DeleteIdModalRadioLabel = styled.label`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #282828;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const DeleteIdModalRadioInput = styled.input`
  display: none;
`;

const DeleteIdModalBottomTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const DeleteIdModalBottomText = styled(PretendardText)`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #939393;
`;

const DeleteIdModalBtnWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 20px;
  align-items: center;
  align-self: center;
`;
