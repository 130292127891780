import styled from "styled-components";
import Title from "../../../../components/atoms/Title";
import BodyContainer from "../../../../components/molecules/layout/BodyContainer";
import Header from "../../../../components/organisms/Header";
import useResizeHeight from "../../../../hooks/useResizeHeight";
import Footer from "../../../../components/organisms/Footer";
import PretendardText from "../../../../components/atoms/PretendardText";
import { useRecoilValue } from "recoil";
import { useEffect, useMemo, useState } from "react";
import { getStorage } from "../../../../lib/utils/sessionStorage";
import axios from "axios";
import { url } from "../../../../lib/api/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { myInfoValueState } from "../../../../store/myInfo";
import CompanySideBar from "../_components/CompanySideBar";
import PageContainer from "../../../../components/molecules/layout/PageContainer";
import scrollToTop from "../../../../lib/utils/scrollToTop";
import SortedResultBoldText from "../../../../components/molecules/SortedResultBoldText";
import { formatDate } from "../../../../lib/utils/formatDate";
import Alert from "../../../../components/molecules/Alert";
import route from "./../../../../router/route";
import Pagination from "./../../../../components/molecules/Pagination";

const CompanyAlarm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    if (myInfo.id === 0 || !myInfo) {
      return;
    } else {
      if (myInfo.profile) {
        getTokens();
      } else {
        navigate("/");
      }
    }
  }, [myInfo]); // eslint-disable-line

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    scrollToTop();
    if (token !== "") {
      getDealAlarmList();
    }
  }, [token, location]); // eslint-disable-line

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // 알림창 관련 상태
  const [alertOpen, setAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // -------------------------------------- //
  // ---------- 데이터 가져오기 ---------- //
  // ------------------------------------ //
  const [dealAlarmList, setDealAlarmList] = useState([]);

  // 페이지네이션 관련 상태
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 5, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const getDealAlarmList = async () => {
    try {
      const data = {
        page: searchParams.get("page") ? searchParams.get("page") : 1,
        size: pagination.size,
        orderBy: "DESC",
      };

      const response = await axios.get(
        `${url}member-alarm/member/member-alarms`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
          params: data,
        }
      );
      setDealAlarmList(response.data.data.content);

      setPagination({
        ...pagination,
        totalCount: response.data.data.totalCount
          ? response.data.data.totalCount
          : response.data.data.content.length,
        totalPages: response.data.data.totalPage
          ? response.data.data.totalPage
          : Math.ceil(
              response.data.data.totalCount
                ? response.data.data.totalCount / 5
                : response.data.data.content.length / 5
            ),
      });
    } catch (error) {
      console.error(error);
      setAlertOpen(true);
      setResponseMessage("잠시 후 다시 시도해 주세요.");
    }
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 550 + elementHeight,
      }}
    >
      <Header $isBgWhite={true} />
      <BodyContainer
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        <MyPageContainer ref={elementRef}>
          <Title>마이페이지</Title>
          <MyPageInnerContainerWrapper>
            <CompanySideBar />
            <MyPageContentsContainer>
              <>
                <SortedResultTextWrapper>
                  <SortedResultBoldText
                    fontSize={20}
                    $title="거래알림"
                    $count={pagination.totalCount}
                  />
                </SortedResultTextWrapper>
                <DealAlarmTitleWrapper>
                  <DealAlarmTitleText
                    style={{
                      width: 634,
                      marginRight: 120,
                    }}
                  >
                    내용
                  </DealAlarmTitleText>
                  <DealAlarmTitleText
                    style={{
                      width: 98,
                      textAlign: "center",
                      marginRight: 20,
                    }}
                  >
                    수신일
                  </DealAlarmTitleText>
                </DealAlarmTitleWrapper>
                <DealAlarmContentContainer>
                  <>
                    {dealAlarmList.map((item, index) => (
                      <DealAlarmContentWrapper key={index}>
                        <DealAlarmContent
                          style={{
                            width: 634,
                            marginRight: 120,
                          }}
                        >
                          {item.content}
                        </DealAlarmContent>
                        <DealAlarmContent
                          style={{
                            width: 98,
                            marginRight: 20,
                            textAlign: "center",
                            color: "#939393",
                          }}
                        >
                          {formatDate(item.createdAt)}
                        </DealAlarmContent>
                      </DealAlarmContentWrapper>
                    ))}
                    <Pagination
                      route={route.myPage_company_alarm}
                      currentPage={page}
                      totalPages={pagination.totalPages}
                      rangeSize={pagination.rangeSize}
                    />
                  </>
                </DealAlarmContentContainer>

                {alertOpen && (
                  <Alert
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setAlertOpen(false);
                    }}
                  >
                    {responseMessage}
                  </Alert>
                )}
              </>
            </MyPageContentsContainer>
          </MyPageInnerContainerWrapper>
        </MyPageContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default CompanyAlarm;

const MyPageContainer = styled.div`
  position: absolute;
  top: 165px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MyPageInnerContainerWrapper = styled.div`
  display: flex;
  gap: 31px;
  width: 100%;
  margin-top: 30px;
`;

const MyPageContentsContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SortedResultTextWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const DealAlarmTitleWrapper = styled.div`
  display: flex;
  padding: 20px;
  width: 892px;
  height: 65px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #939393;
`;

const DealAlarmTitleText = styled(PretendardText)`
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  color: #282828;
`;

const DealAlarmContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 892px;
  height: 100%;
  margin-bottom: 40px;
`;

const DealAlarmContentWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 65px;
  border-bottom: 1px solid #e9e9e9;
  background-color: #fff;
`;

const DealAlarmContent = styled(PretendardText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: #282828;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* cursor: pointer; */
`;
