import React from "react";
import styled from "styled-components";

const PrimaryMiniBtn = ({
  style,
  disabled,
  children,
  $isSmall,
  type,
  onClick,
}) => {
  return (
    <StyledPrimaryMiniBtn
      style={{
        ...style,
      }}
      type={type}
      $isSmall={$isSmall}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </StyledPrimaryMiniBtn>
  );
};

export default PrimaryMiniBtn;

const StyledPrimaryMiniBtn = styled.button`
  width: fit-content;
  padding: ${(props) => (props.$isSmall ? "5px 10px" : "8px 20px")};
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-size: ${(props) => (props.$isSmall ? "14px" : "16px")};
  font-weight: 600;
  line-height: 145%;
  background-color: #2ec5f6;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s color 0.3s;

  &:hover {
    background-color: #29abd6;
  }

  &:disabled {
    background-color: #c0c0c0;
    color: #939393;
    cursor: not-allowed;
  }
`;
