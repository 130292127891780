import { atom } from "recoil";

export const initialServiceImgValues = {
  bannerFile: "",
  bannerUrl: "",
  iconFile: "",
  iconUrl: "",
  detailPageFile: "",
  detailPageUrl: "",
};

export const serviceImgValueState = atom({
  key: "serviceImgValueState",
  default: initialServiceImgValues,
});

export const initialServiceInputValues = {
  serviceName: "",
  marketingType: "",
  marketingTypeId: "",
  budget: "",
  budgetTitle: "",
  price: "",
  executionPeriod: "",
  executionPeriodTitle: "",
  marketingStrategy: "",
};

export const serviceInputValueState = atom({
  key: "serviceInputValueState",
  default: initialServiceInputValues,
});

export const portfolioListState = atom({
  key: "portfolioListState",
  default: [],
});

export const portfolioIdListState = atom({
  key: "portfolioIdListState",
  default: [],
});

export const initialSelectedPortfolioListValues = [
  {
    id: 0,
    merch: {
      id: 0,
      marketingType: {
        id: 0,
        title: "",
      },
      member: {
        profile: {
          companyName: "",
          regionTitle: "",
        },
      },
      title: "",
      profileServerFileName: "",
      reviewAverage: "",
    },
  },
];

export const selectedPortfolioListState = atom({
  key: "selectedPortfolioListState",
  default: [],
});
