import React from "react";
import styled from "styled-components";
import PretendardText from "../atoms/PretendardText";

const SortedResultBoldText = (props) => {
  return (
    <SortedResultTextWrapper {...props}>
      <SortedResultText fontSize={props.fontSize} isThin={props.isThin}>
        {props.$title}
      </SortedResultText>
      <TextVerticalLine isBlack={props.isBlack} isThin={props.isThin}>
        {" "}
        ㅣ{" "}
      </TextVerticalLine>
      <SortedResultText fontSize={props.fontSize} isThin={props.isThin}>
        {props.$count}개
      </SortedResultText>
    </SortedResultTextWrapper>
  );
};

export default SortedResultBoldText;

const SortedResultTextWrapper = styled.div`
  display: flex;
`;
const SortedResultText = styled(PretendardText)`
  color: #282828;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "16px")};
  font-weight: ${(props) => (props.isThin ? 400 : 700)};
  line-height: 145%;
  display: contents;
`;

const TextVerticalLine = styled(SortedResultText)`
  color: ${(props) => (props.isBlack ? "#000" : "#e9e9e9")};
`;
