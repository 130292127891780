import { atom } from "recoil";

export const initialRegisterInputValues = {
  companyName: "", // 회사명
  region: "", // 지역
  companyRegion: "", // 사업장 소재지
  companyNumber: "", // 사업자번호
  ceo: "", // 대표자명
  tel: "", // 대표전화번호
  intro: "", // 회사소개
  email: "", // 이메일
  name: "", // 이름
  phoneNumber: "", // 전화번호
  age: "", // 나이
  password: "", // 비번
  passwordCheck: "", // 비번확인
  sector: "", // 업종
  joinType: "", // 가입종류
  socialId: "", // 소셜로그인 아이디
};

export const registerInputValueState = atom({
  key: "registerInputValueState",
  default: initialRegisterInputValues,
});

export const initialRegisterCheckedValues = {
  agreement: false, // 이용약관 동의
  privacy: false, // 개인정보처리방침 동의
  providePrivacy: false, // 개인정보 제 3자 제공 동의
  advertisement: false, // 광고성 정보 수신 동의
};

export const registerCheckedValueState = atom({
  key: "registerCheckedValueState",
  default: initialRegisterCheckedValues,
});

export const initialRegisterTextEditorValue = {
  text: "",
};

export const registerTextEditorValueState = atom({
  key: "registerTextEditorValueState",
  default: initialRegisterTextEditorValue,
});

export const addressState = atom({
  key: "addressState",
  default: {
    zipCode: "",
    address: "",
    detailAddress: "",
  },
});
