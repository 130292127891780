import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import PageContainer from "../../components/molecules/layout/PageContainer";
import BodyContainer from "../../components/molecules/layout/BodyContainer";
import Header from "../../components/organisms/Header";
import Footer from "../../components/organisms/Footer";
import DivisionLine from "./../../components/atoms/DivisionLine";
import useResizeHeight from "../../hooks/useResizeHeight";
import Title from "./../../components/atoms/Title";
import scrollToTop from "../../lib/utils/scrollToTop";
import PretendardText from "../../components/atoms/PretendardText";
import DownArrowIcon from "../../components/atoms/icons/DownArrowIcon";
import SecondaryMiniBtn from "./../../components/atoms/buttons/SecondaryMiniBtn";
import PrimaryMiniBtn from "./../../components/atoms/buttons/PrimaryMiniBtn";
import axios from "axios";
import { url } from "../../lib/api/api";
import { deleteStorage, getStorage } from "../../lib/utils/sessionStorage";
import Alert from "./../../components/molecules/Alert";
import Confirm from "../../components/molecules/Confirm";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { myInfoValueState } from "../../store/myInfo";
import Pagination from "./../../components/molecules/Pagination";
import route from "./../../router/route";

const ServiceQnAList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // ------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ----------------------------- //

  // 기업 회원이 아닐 경우 홈으로 이동
  useEffect(() => {
    if (myInfo.id === 0 || !myInfo) {
      return;
    } else {
      if (myInfo.profile) {
        getTokens();
      } else {
        navigate("/");
      }
    }
  }, [myInfo]); // eslint-disable-line

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    scrollToTop();
    if (token !== "") {
      getFaqList();
    }
  }, [token, location]); // eslint-disable-line

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // 알림창 관련 상태
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");

  // ------------------------------------------- //
  // ---------- 목록 데이터 가져오기 ---------- //
  // ----------------------------------------- //
  const [serviceFaqList, setServiceFaqList] = useState([]);

  // 페이지네이션 관련 상태
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 5, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const getFaqList = async () => {
    try {
      const data = {
        page: searchParams.get("page") ? searchParams.get("page") : 1,
        size: pagination.size,
        orderBy: "DESC",
      };

      const response = await axios.get(`${url}member/merch-faqs`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        params: data,
      });
      setServiceFaqList(response.data.data.content);

      setPagination({
        ...pagination,
        totalCount: response.data.data.totalCount
          ? response.data.data.totalCount
          : response.data.data.content.length,
        totalPages: response.data.data.totalPage
          ? response.data.data.totalPage
          : Math.ceil(
              response.data.data.totalCount
                ? response.data.data.totalCount / 5
                : response.data.data.content.length / 5
            ),
      });
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      }
    }
  };

  // 각 게시물의 DownArrow 상태 배열로 관리
  const [rotatedItems, setRotatedItems] = useState([]);

  useEffect(() => {
    setRotatedItems(new Array(serviceFaqList.length).fill(false)); // serviceFaqList의 길이만큼 배열 생성 후 채우지 않음
  }, [serviceFaqList]);

  // 각 게시물의 DownArrow 상태 토글 함수
  const toggleItem = (index) => {
    setRotatedItems((prev) =>
      prev.map((item, i) => (i === index ? !item : item))
    );
  };

  // ------------------------------ //
  // ---------- 삭제하기 ---------- //
  // ---------------------------- //
  const [delFaqBtnClicked, setDelFaqBtnClicked] = useState(false);

  const deleteFaq = async (delFaqId) => {
    try {
      let data = {
        ids: [delFaqId],
      };
      await axios.delete(`${url}member/merch-faqs`, {
        data: data,
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setAlertIsOpen(true);
      setResponseMsg("삭제되었습니다.");
      getFaqList();
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertIsOpen(true);
        setResponseMsg("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 500 + elementHeight,
      }}
    >
      <Header />
      <BodyContainer>
        <QnAListContainer ref={elementRef}>
          <Title>자주 묻는 질문</Title>
          <DivisionLine
            style={{
              position: "static",
              borderBottom: "1px solid #b7b7b7",
              marginTop: 40,
            }}
          />
          <QnAContentContainer>
            <>
              {serviceFaqList.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <QnAContentTitle
                      onClick={() => {
                        toggleItem(index);
                      }}
                    >
                      {item.title}
                      <DownArrowIcon $isRotated={rotatedItems[index]} />
                    </QnAContentTitle>
                    <QnAContentTextWrapper
                      style={{
                        display: rotatedItems[index] ? "flex" : "none",
                      }}
                    >
                      <QnAContentText className="scrollbar">
                        {item.content}
                      </QnAContentText>
                      <QnAContentBtnWrapper>
                        <SecondaryMiniBtn
                          onClick={() => {
                            setDelFaqBtnClicked(true);
                          }}
                        >
                          삭제하기
                        </SecondaryMiniBtn>
                        {delFaqBtnClicked && (
                          <Confirm
                            style={{
                              position: "fixed",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                            cancelText="아니요"
                            cancelEvent={() => {
                              setDelFaqBtnClicked(false);
                            }}
                            confirmText="예"
                            confirmEvent={() => {
                              toggleItem(index);
                              deleteFaq(item.id);
                              setDelFaqBtnClicked(false);
                            }}
                          >
                            삭제하시겠습니까?
                            <br />
                            삭제한 내용은 복구가 불가능합니다.
                          </Confirm>
                        )}
                        <SecondaryMiniBtn
                          onClick={() => {
                            scrollToTop();
                            navigate(`${route.editQnA}/${item.id}`);
                          }}
                        >
                          수정하기
                        </SecondaryMiniBtn>
                      </QnAContentBtnWrapper>
                    </QnAContentTextWrapper>
                  </React.Fragment>
                );
              })}
              <Pagination
                route={route.serviceQnAList}
                currentPage={page}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
              />
            </>
          </QnAContentContainer>
          <PrimaryMiniBtn
            style={{
              alignSelf: "flex-end",
            }}
            onClick={() => {
              navigate(`${route.addQnA}`);
              scrollToTop();
            }}
          >
            작성하기
          </PrimaryMiniBtn>
        </QnAListContainer>

        {/* 알림창 영역 */}
        {alertIsOpen && (
          <Alert
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            onClick={() => {
              setAlertIsOpen(false);
            }}
          >
            {responseMsg}
          </Alert>
        )}

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default ServiceQnAList;

const QnAListContainer = styled.div`
  position: absolute;
  top: 165px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const QnAContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 765px;
  margin-bottom: 40px;
`;

const QnAContentTitle = styled(PretendardText)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  max-height: 65px;
  padding: 20px 30px;
  color: #282828;
  border-bottom: 1px solid #b7b7b7;
`;

const QnAContentTextWrapper = styled.div`
  width: 1200px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #b7b7b7;
  height: auto;
  margin-top: 0;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
`;

const QnAContentText = styled(PretendardText)`
  width: 100%;
  font-size: 16px;
  background-color: #f8f9fa;
  font-weight: 400;
  color: #383838;
  max-height: 164px;
  margin-top: 0;
  white-space: normal;
  word-break: break-word;
  line-height: 145%;
`;

const QnAContentBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
`;
