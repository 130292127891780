import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PageContainer from "../../components/molecules/layout/PageContainer";
import BodyContainer from "../../components/molecules/layout/BodyContainer";
import Header from "../../components/organisms/Header";
import Footer from "../../components/organisms/Footer";
import PretendardText from "../../components/atoms/PretendardText";
import { FirstRankingBubble } from "../../components/molecules/RankingBubble";
import useResizeHeight from "../../hooks/useResizeHeight";
import scrollToTop from "./../../lib/utils/scrollToTop";
import HashTagBox from "../../components/atoms/HashTagBox";
import CompanyList from "../../components/molecules/CompanyList";
import SearchCard from "./../../components/molecules/SearchCard";
import Alert from "../../components/molecules/Alert";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { fileUrl, url } from "../../lib/api/api";
import { useRecoilValue } from "recoil";
import { myInfoValueState } from "../../store/myInfo";
import { deleteStorage, getStorage } from "../../lib/utils/sessionStorage";
import route from "./../../router/route";
import callChatGPT from "../../lib/api/chatGPT";
import prompt from "../../lib/utils/prompt";
import { budgetList, periodList, sectorList } from "../../constants/home/list";
import { marketingTypeInfo } from "../../constants/home/marketingTypeInfo";
import { ClipLoader } from "react-spinners";

const Search = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // ------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ----------------------------- //
  useEffect(() => {
    getTokens();
    getMarketingTypeList();
  }, []); // eslint-disable-line

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // getCompanyList(token);
  }, [location]); // eslint-disable-line

  // 렌더링 관련 상태
  const [isLoaded, setIsLoaded] = useState(false);

  // 알림창 관련 상태
  const [alertOpen, setAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // -------------------------------------------------- //
  // ---------- 마케팅 타입 리스트 가져오기 ---------- //
  // ------------------------------------------------ //
  const [marketingTypeList, setMarketingTypeList] = useState([]); // eslint-disable-line

  const getMarketingTypeList = async () => {
    try {
      const data = {
        page: 1,
        size: 0,
      };
      const response = await axios.get(`${url}marketing-types`, {
        params: data,
      });
      const activatedList = response.data.data.content.filter(
        (item) => item.activation // 활성화된 마케팅 타입만 가져오기
      );
      const exceptList = activatedList.map((item) => {
        return {
          id: item.id,
          title: item.title,
        };
      });

      setMarketingTypeList(exceptList);
      executeGPT(exceptList);
    } catch (error) {
      console.error(error);
      setAlertOpen(true);
      setResponseMessage("잠시 후 다시 시도해 주세요.");
    }
  };

  // -------------------------- //
  // ---------- GPT ---------- //
  // ------------------------ //
  const [gptResponse, setGptResponse] = useState("");
  const [marketingTypeCard, setMarketingTypeCard] = useState({
    a: null,
    b: null,
    c: null,
  });

  const executeGPT = async (marketingTypeList) => {
    let sectorTitle;
    if (searchParams.get("sector")) {
      const findTitle = sectorList.find(
        (item) => item.value === searchParams.get("sector")
      );
      sectorTitle = findTitle.title;
    }

    let periodTitle;
    if (searchParams.get("period")) {
      const findTitle = periodList.find(
        (item) => item.value === searchParams.get("period")
      );
      periodTitle = findTitle.title;
    }

    let budgetTitle;
    if (searchParams.get("budget")) {
      const findTitle = budgetList.find(
        (item) => item.value === searchParams.get("budget")
      );
      budgetTitle = findTitle.title;
    }

    try {
      const response = await callChatGPT(
        prompt({
          sector: sectorTitle,
          period: periodTitle,
          budget: budgetTitle,
          marketingTypeList: JSON.stringify(marketingTypeList),
        })
      );
      const gptContent = JSON.parse(response.choices[0].message.content);
      setGptResponse(gptContent);

      const updatedMarketingTypeCard = {
        a: marketingTypeInfo.find((item) => item.title === gptContent[0].title),
        b: marketingTypeInfo.find((item) => item.title === gptContent[1].title),
        c: marketingTypeInfo.find((item) => item.title === gptContent[2].title),
      };

      setMarketingTypeCard(updatedMarketingTypeCard);
      getCompanyList(token, gptContent[0].id);
    } catch (error) {
      console.error(error);
      setAlertOpen(true);
      setResponseMessage(
        <>
          올바른 요청이 아닙니다.
          <br />
          잠시 후 다시 시도해 주세요.
        </>
      );
    }
  };

  // -------------------------------------------------- //
  // ---------- 기업 서비스 리스트 가져오기 ---------- //
  // ------------------------------------------------ //
  const [companyList, setCompanyList] = useState([]);

  const getCompanyList = async (token, marketingId) => {
    try {
      let data = {
        page: 1,
        size: 4,
        sort: "reviewAverage",
        marketingTypeId: marketingId,
      };

      if (searchParams.get("sector")) {
        data.sector = searchParams.get("sector");
      }

      if (searchParams.get("budget")) {
        data.budget = searchParams.get("budget");
      }

      if (searchParams.get("period")) {
        data.period = searchParams.get("period");
      }

      let response;
      if (token) {
        response = await axios.get(`${url}merches`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
          params: data,
        });
      } else {
        response = await axios.get(`${url}merches`, {
          params: data,
        });
      }
      setCompanyList(response.data.data.content);
      setIsLoaded(true);
    } catch (error) {
      console.error(error);
      setAlertOpen(true);
      setResponseMessage("잠시 후 다시 시도해 주세요.");
    }
  };

  // --------------------------- //
  // ---------- 기타 ---------- //
  // ------------------------- //
  const [keywordStatus, setKeywordStatus] = useState({
    a: true,
    b: false,
    c: false,
  });

  // ---------------------------------- //
  // ---------- 스크랩 관련 ---------- //
  // -------------------------------- //
  const toggleScrap = async (merchId) => {
    try {
      await axios.post(`${url}member/scrap/${merchId}`, null, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      getCompanyList(token);
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();
  const [height, setHeight] = useState(elementHeight);

  // useEffect를 사용하여 isLoaded가 true가 될 때 높이 다시 계산
  useEffect(() => {
    if (isLoaded) {
      setHeight(elementRef.current.offsetHeight);
    }
  }, [isLoaded, elementRef]);

  return (
    <PageContainer
      style={{
        minHeight: 750 + height,
      }}
    >
      <Header />
      <BodyContainer>
        {/* 상단 배너 */}
        <SearchTopBanner src="/assets/imgs/search/banner.jpg">
          {/* 상단 배너 텍스트 */}
          <SearchTopBannerText>
            대표님께 필요한 마케팅 전략을 확인하세요!
          </SearchTopBannerText>
          {isLoaded && (
            <SearchTopBannerTextWrapper>
              <SearchTopBannerSubText>
                대표님께 필요한 마케팅 전략은
              </SearchTopBannerSubText>
              <HashTagBoxWrapper>
                {gptResponse[0] && (
                  <HashTagBox>{gptResponse[0]?.title}</HashTagBox>
                )}
                {gptResponse[1] && (
                  <HashTagBox>{gptResponse[1]?.title}</HashTagBox>
                )}
                {gptResponse[2] && (
                  <HashTagBox>{gptResponse[2]?.title}</HashTagBox>
                )}
              </HashTagBoxWrapper>
              <SearchTopBannerSubText>입니다.</SearchTopBannerSubText>
            </SearchTopBannerTextWrapper>
          )}
        </SearchTopBanner>
        {/* 본문 영역 */}
        <SearchContainer
          ref={elementRef}
          style={{
            alignItems: isLoaded ? "flex-start" : "center",
          }}
        >
          {!isLoaded ? (
            <ClipLoader
              color="#2EC5F6"
              loading={!isLoaded}
              css={
                {
                  // position: "fixed",
                  // top: "50%",
                  // left: "50%",
                  // transform: "translate(-50%, -50%)",
                }
              }
            />
          ) : (
            <>
              {/* 디자인 카드 영역 */}
              <StrategyContainer>
                {marketingTypeCard.a && (
                  <SearchCard
                    $isFirst
                    $title={marketingTypeCard.a?.title}
                    $content={marketingTypeCard.a?.content}
                    $hashTag={marketingTypeCard.a?.hashTag}
                    src={marketingTypeCard.a?.src}
                  />
                )}

                {marketingTypeCard.b && (
                  <SearchCard
                    $isSecond
                    $title={marketingTypeCard.b?.title}
                    $content={marketingTypeCard.b?.content}
                    $hashTag={marketingTypeCard.b?.hashTag}
                    src={marketingTypeCard.b?.src}
                  />
                )}

                {marketingTypeCard.c && (
                  <SearchCard
                    $isThird
                    $title={marketingTypeCard.c?.title}
                    $content={marketingTypeCard.c?.content}
                    $hashTag={marketingTypeCard.c?.hashTag}
                    src={marketingTypeCard.c?.src}
                  />
                )}
              </StrategyContainer>

              {/* 업체 목록 영역 */}
              <PostTitle>전략에 맞는 업체를 확인하세요!</PostTitle>
              <HashTagBoxWrapper>
                <Keyword
                  isGray={!keywordStatus.a}
                  onClick={() => {
                    setKeywordStatus({
                      a: true,
                      b: false,
                      c: false,
                    });
                    getCompanyList(token, gptResponse[0]?.id);
                  }}
                >
                  {gptResponse[0]?.title}
                </Keyword>
                <Keyword
                  isGray={!keywordStatus.b}
                  onClick={() => {
                    setKeywordStatus({
                      a: false,
                      b: true,
                      c: false,
                    });
                    getCompanyList(token, gptResponse[1]?.id);
                  }}
                >
                  {gptResponse[1]?.title}
                </Keyword>
                <Keyword
                  isGray={!keywordStatus.c}
                  onClick={() => {
                    setKeywordStatus({
                      a: false,
                      b: false,
                      c: true,
                    });
                    getCompanyList(token, gptResponse[2]?.id);
                  }}
                >
                  {gptResponse[2]?.title}
                </Keyword>
              </HashTagBoxWrapper>

              {/* 게시물 영역 */}
              <PostContainer>
                {companyList.length !== 0 ? (
                  <FirstRankingBubble
                    style={{
                      position: "absolute",
                      top: -18,
                      left: 0,
                      zIndex: 1,
                    }}
                  />
                ) : (
                  <PostSubTitle>
                    조건에 해당하는 서비스가 없습니다!
                  </PostSubTitle>
                )}
                {companyList.map((item, index) => (
                  <React.Fragment key={index}>
                    <CompanyList
                      path={`${route.serviceDetail}/${item.id}`}
                      pathHandler={() => {
                        scrollToTop();
                      }}
                      isShowBookmark={
                        token === null || myInfo?.profile ? false : true
                      }
                      bookmarkHandler={(e) => {
                        e.stopPropagation();
                        toggleScrap(item.id);
                      }}
                      src={`${fileUrl}/${item.profileServerFileName}`}
                      $isChecked={item.isScrap}
                      serviceName={item.title}
                      companyName={item.member.profile.companyName}
                      marketingType={item.marketingType.title}
                      location={item.member.profile.regionTitle}
                      reviewGrade={item.reviewAverage}
                    />
                  </React.Fragment>
                ))}
              </PostContainer>
            </>
          )}
        </SearchContainer>

        {alertOpen && (
          <Alert
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setAlertOpen(false);
            }}
          >
            {responseMessage}
          </Alert>
        )}
        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default Search;

const SearchContainer = styled.div`
  position: absolute;
  top: 475px;
  width: 1200px;
  display: flex;
  flex-direction: column;
`;

const SearchTopBanner = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(
      180deg,
      rgba(46, 197, 246, 0) 34.84%,
      rgba(0, 132, 175, 0.25) 96.77%
    ),
    url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 85px;
  width: 100%;
  height: 310px;
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const SearchTopBannerTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const SearchTopBannerText = styled(PretendardText)`
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  color: #fff;
`;

const SearchTopBannerSubText = styled(PretendardText)`
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #fff;
`;

const HashTagBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
`;

const Keyword = styled(PretendardText)`
  font-weight: 700;
  font-size: 16px;
  line-height: 145%;
  height: 39px;
  color: ${(props) => (props.isGray ? "#939393" : "#2ec5f6")};
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: ${(props) =>
    props.isGray ? "1px solid #e9e9e9" : "1px solid #2ec5f6"};
  cursor: pointer;
`;

const StrategyContainer = styled.div`
  display: flex;
  width: 1200px;
  gap: 20px;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 80px;
`;

const PostTitle = styled(PretendardText)`
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  color: #282828;
  margin-bottom: 14px;
`;

const PostSubTitle = styled(PretendardText)`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #282828;
`;

const PostContainer = styled(StrategyContainer)`
  margin-top: 40px;
  gap: 29px;
  min-height: 381px;
`;
