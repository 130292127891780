import React from "react";
import styled from "styled-components";

const PrimaryBtn = (props) => {
  return <StyledPrimaryBtn {...props}>{props.children}</StyledPrimaryBtn>;
};

export default PrimaryBtn;

const StyledPrimaryBtn = styled.button`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 145%;
  width: ${(props) => (props.$isSmall ? "340px" : "430px")};
  height: 51px;
  background-color: #2ec5f6;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s color 0.3s;

  &:hover {
    background-color: #29abd6;
  }

  &:disabled {
    background-color: #c0c0c0;
    color: #939393;
    cursor: not-allowed;
  }
`;
