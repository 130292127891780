import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PageContainer from "../../components/molecules/layout/PageContainer";
import BodyContainer from "../../components/molecules/layout/BodyContainer";
import Header from "../../components/organisms/Header";
import Footer from "../../components/organisms/Footer";
import useResizeHeight from "../../hooks/useResizeHeight";
import Title from "./../../components/atoms/Title";
import SubTitle from "./../../components/atoms/SubTitle";
import regex from "../../constants/regex";
import scrollToTop from "../../lib/utils/scrollToTop";
import InputBox from "../../components/molecules/InputBox";
import TextareaBox from "./../../components/molecules/TextareaBox";
import SecondaryMiniBtn from "./../../components/atoms/buttons/SecondaryMiniBtn";
import PrimaryMiniBtn from "./../../components/atoms/buttons/PrimaryMiniBtn";
import axios from "axios";
import { url } from "../../lib/api/api";
import { deleteStorage, getStorage } from "../../lib/utils/sessionStorage";
import Alert from "./../../components/molecules/Alert";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { myInfoValueState } from "../../store/myInfo";
import route from "../../router/route";

const EditQnA = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // ------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ----------------------------- //

  // 기업 회원이 아닐 경우 홈으로 이동
  useEffect(() => {
    if (myInfo.id === 0 || !myInfo) {
      return;
    } else {
      if (myInfo.profile) {
        getTokens();
      } else {
        navigate("/");
      }
    }
  }, [myInfo]); // eslint-disable-line

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    scrollToTop();
    if (token !== "") {
      getFaqData();
    }
  }, [token]); // eslint-disable-line

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // 알림창 관련 상태
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");

  // ------------------------------------------- //
  // ---------- 목록 데이터 가져오기 ---------- //
  // ----------------------------------------- //
  const [editQnAInputValue, setEditQnAInputValue] = useState({
    title: "",
    content: "",
  });

  const getFaqData = async () => {
    try {
      const response = await axios.get(`${url}member/merch-faq/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setEditQnAInputValue({
        title: response.data.data.content.title,
        content: response.data.data.content.content,
      });
    } catch (error) {
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      }
    }
  };

  // 입력 필드 관련 상태
  const [titleRegexTest, setTitleRegexTest] = useState(true);
  const [contentRegexTest, setContentRegexTest] = useState(true);

  // 입력 필드 핸들러
  const handleInputValueChange = (e) => {
    const { name, value } = e.target;

    const validateInput = (regex, value) => {
      return regex.test(value);
    };

    setEditQnAInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    const validateField = (name, value) => {
      switch (name) {
        case "title":
          if (value !== "") {
            setTitleRegexTest(validateInput(regex.text, value));
          } else {
            setTitleRegexTest(true);
          }
          break;
        case "content":
          if (value !== "") {
            setContentRegexTest(validateInput(regex.text, value));
          } else {
            setContentRegexTest(true);
          }
          break;
        default:
          console.error("Invalid field name");
      }
    };

    validateField(name, value);
  };

  // 수정하기
  const editFaq = async () => {
    const data = {
      title: editQnAInputValue.title,
      content: editQnAInputValue.content,
    };

    try {
      await axios.patch(`${url}member/merch-faq/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAlertIsOpen(true);
      setResponseMsg("수정되었습니다.");
      getFaqData();
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertIsOpen(true);
        setResponseMsg("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 500 + elementHeight,
      }}
    >
      <Header />
      <BodyContainer>
        <EditQnAContainer ref={elementRef}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              marginBottom: 35,
            }}
          >
            <Title>자주 묻는 질문</Title>
            <SubTitle>
              전문가에 대해 더 자세히 알 수 있도록
              <br />
              자주 묻는 질문을 작성해 보세요.
            </SubTitle>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            {/* 제목 */}
            <InputBox
              $uniqueKey="title"
              label="제목"
              type="text"
              $isRequired
              placeholder="제목을 입력해 주세요."
              value={editQnAInputValue.title}
              onChange={handleInputValueChange}
              $isError={!titleRegexTest}
              $errorMsg="두 글자 이상 입력해 주세요."
            />

            {/* 내용 */}
            <TextareaBox
              $uniqueKey="content"
              label="내용"
              $isRequired
              placeholder="내용을 입력해 주세요."
              value={editQnAInputValue.content}
              onChange={handleInputValueChange}
              $isError={!contentRegexTest}
              $errorMsg="두 글자 이상 입력해 주세요."
            />

            {/* 하단 버튼 */}
            <BottomBtnWrapper>
              <SecondaryMiniBtn
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                  scrollToTop();
                }}
              >
                목록보기
              </SecondaryMiniBtn>
              <PrimaryMiniBtn
                type="button"
                disabled={
                  editQnAInputValue.title === "" ||
                  !titleRegexTest ||
                  editQnAInputValue.content === "" ||
                  !contentRegexTest
                }
                onClick={() => {
                  editFaq();
                }}
              >
                저장하기
              </PrimaryMiniBtn>
            </BottomBtnWrapper>
          </div>
        </EditQnAContainer>

        {/* 알림창 영역 */}
        {alertIsOpen && (
          <Alert
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            onClick={() => {
              setAlertIsOpen(false);
            }}
          >
            {responseMsg}
          </Alert>
        )}

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default EditQnA;

const EditQnAContainer = styled.div`
  position: absolute;
  top: 165px;
  width: 430px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const BottomBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
`;
