import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Title from "../../../../components/atoms/Title";
import BodyContainer from "../../../../components/molecules/layout/BodyContainer";
import Header from "../../../../components/organisms/Header";
import useResizeHeight from "../../../../hooks/useResizeHeight";
import Footer from "../../../../components/organisms/Footer";
import PretendardText from "../../../../components/atoms/PretendardText";
import { useRecoilValue } from "recoil";
import {
  deleteStorage,
  getStorage,
} from "../../../../lib/utils/sessionStorage";
import axios from "axios";
import { url } from "../../../../lib/api/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { myInfoValueState } from "../../../../store/myInfo";
import PageContainer from "../../../../components/molecules/layout/PageContainer";
import Alert from "../../../../components/molecules/Alert";
import CommonSideBar from "../_components/CommonSideBar";
import { formatDate } from "./../../../../lib/utils/formatDate";
import { formatNumberWithCommas } from "./../../../../lib/utils/formatNumber";
import ServiceApi from "../../../../lib/api/service.api";
import DivisionLine from "./../../../../components/atoms/DivisionLine";
import PrimaryMiniBtn from "./../../../../components/atoms/buttons/PrimaryMiniBtn";
import ReviewModal from "./../../../../components/molecules/ReviewModal";
import SecondaryMiniBtn from "./../../../../components/atoms/buttons/SecondaryMiniBtn";
import route from "../../../../router/route";
import Confirm from "../../../../components/molecules/Confirm";

const CommonPaymentRecordsDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    if (myInfo.id === 0 || !myInfo) {
      return;
    } else {
      getTokens();
    }
  }, [myInfo]);

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    if (token !== "") {
      getPaymentData();
    }
  }, [token, location]); // eslint-disable-line

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // 알림창 관련 상태
  const [alertOpen, setAlertOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // ------------------------------------------- //
  // ---------- 결제 데이터 가져오기 ---------- //
  // ----------------------------------------- //
  const [paymentData, setPaymentData] = useState({});

  const getPaymentData = async () => {
    try {
      const response = await axios.get(`${url}member/payment/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (response.data.data.content.merchId !== 0) {
        const companyNameResponse = await ServiceApi.getById(
          response.data.data.content.merchId
        );
        const companyNameData =
          companyNameResponse.data.data.content.member.profile.companyName;

        setPaymentData({
          ...response.data.data.content,
          companyName: companyNameData,
        });
      }

      console.log(response.data.data.content);

      setPaymentData(response.data.data.content);
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else if (error.response.data.default === "정보를 찾을 수 없습니다.") {
        setAlertOpen(true);
        setResponseMessage("삭제된 서비스 결제내역입니다.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // 결제 확정
  const completePayment = async (paymentId) => {
    const data = {
      completeStatus: "COMPLETE",
    };

    try {
      await axios.post(`${url}member/payment/complete/${paymentId}`, data, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setAlertOpen(true);
      setResponseMessage("확정 완료하였습니다.");
      getPaymentData(paymentId);
      setReviewModalIsOpen(true);
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // 환불 요청
  const refundRequest = async (paymentId) => {
    const data = {
      refundStatus: "REQUEST",
    };

    try {
      await axios.post(`${url}member/payment/refund/${paymentId}`, data, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setAlertOpen(true);
      setResponseMessage("요청이 완료 되었습니다.");
      getPaymentData(paymentId);
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // 리뷰 작성
  const [reviewModalIsOpen, setReviewModalIsOpen] = useState(false);

  const closeReviewModal = () => {
    setTimeout(() => {
      setReviewModalIsOpen(false);
    }, 300);
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 550 + elementHeight,
      }}
    >
      <Header $isBgWhite={true} />
      <BodyContainer
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        <MyPageContainer ref={elementRef}>
          <Title>마이페이지</Title>
          <MyPageInnerContainerWrapper>
            <CommonSideBar />
            <MyPageContentsContainer>
              <PaymentRecordsDetailContainer>
                <PaymentRecordsDetailContentsContainer>
                  <PaymentRecordsDetailContentTitle>
                    결제내역
                  </PaymentRecordsDetailContentTitle>
                  <PaymentRecordsDetailContentWrapper>
                    <PaymentRecordsDetailContentTextWrapper>
                      <PaymentRecordsDetailContentText>
                        결제일자
                      </PaymentRecordsDetailContentText>
                      <PaymentRecordsDetailContentTextVerticalLine>
                        {" "}
                        ㅣ{" "}
                      </PaymentRecordsDetailContentTextVerticalLine>
                      <PaymentRecordsDetailContentText>
                        {formatDate(paymentData.createdAt)}
                      </PaymentRecordsDetailContentText>
                    </PaymentRecordsDetailContentTextWrapper>
                    <PaymentRecordsDetailContentTextWrapper>
                      <PaymentRecordsDetailContentText>
                        주문번호
                      </PaymentRecordsDetailContentText>
                      <PaymentRecordsDetailContentTextVerticalLine>
                        {" "}
                        ㅣ{" "}
                      </PaymentRecordsDetailContentTextVerticalLine>
                      <PaymentRecordsDetailContentText>
                        {paymentData.paymentNumber}
                      </PaymentRecordsDetailContentText>
                    </PaymentRecordsDetailContentTextWrapper>
                    <PaymentRecordsDetailContentTextWrapper>
                      <PaymentRecordsDetailContentText>
                        상품명
                      </PaymentRecordsDetailContentText>
                      <PaymentRecordsDetailContentTextVerticalLine>
                        {" "}
                        ㅣ{" "}
                      </PaymentRecordsDetailContentTextVerticalLine>
                      <PaymentRecordsDetailContentText>
                        {paymentData.merchTitle}
                      </PaymentRecordsDetailContentText>
                    </PaymentRecordsDetailContentTextWrapper>
                  </PaymentRecordsDetailContentWrapper>
                </PaymentRecordsDetailContentsContainer>
                <PaymentRecordsDetailContentsContainer>
                  <PaymentRecordsDetailContentTitle>
                    결제정보
                  </PaymentRecordsDetailContentTitle>
                  <PaymentRecordsDetailContentWrapper
                    style={{
                      gap: 20,
                    }}
                  >
                    <PaymentRecordsDetailContentTextWrapper
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <PaymentRecordsDetailContentText
                        style={{
                          display: "block",
                        }}
                      >
                        상품 가격
                      </PaymentRecordsDetailContentText>
                      <PaymentRecordsDetailContentText
                        style={{
                          display: "block",
                        }}
                      >
                        {formatNumberWithCommas(paymentData.amount)}원
                      </PaymentRecordsDetailContentText>
                    </PaymentRecordsDetailContentTextWrapper>
                    <PaymentRecordsDetailContentTextWrapper
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <PaymentRecordsDetailContentText
                        style={{
                          display: "block",
                        }}
                      >
                        수수료
                      </PaymentRecordsDetailContentText>
                      <PaymentRecordsDetailContentText
                        style={{
                          display: "block",
                        }}
                      >
                        {formatNumberWithCommas(paymentData.charge)}원
                      </PaymentRecordsDetailContentText>
                    </PaymentRecordsDetailContentTextWrapper>
                    <DivisionLine
                      style={{
                        position: "static",
                        width: "100%",
                        borderBottom: "1px solid #cbcbcb",
                      }}
                    />
                    <PaymentRecordsDetailContentTextWrapper
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <PaymentRecordsDetailContentText
                        style={{
                          display: "block",
                        }}
                      >
                        총 결제금액
                      </PaymentRecordsDetailContentText>
                      <PaymentRecordsDetailContentText
                        style={{
                          display: "block",
                          fontWeight: 600,
                        }}
                      >
                        {formatNumberWithCommas(paymentData.price)}원
                      </PaymentRecordsDetailContentText>
                    </PaymentRecordsDetailContentTextWrapper>
                  </PaymentRecordsDetailContentWrapper>
                </PaymentRecordsDetailContentsContainer>
                <PaymentRecordsDetailContentsContainer>
                  <PaymentRecordsDetailContentTitle>
                    결제수단
                  </PaymentRecordsDetailContentTitle>
                  <PaymentRecordsDetailContentWrapper>
                    <PaymentRecordsDetailContentTextWrapper
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <PaymentRecordsDetailContentText
                        style={{
                          display: "block",
                        }}
                      >
                        {paymentData.payMethodTitle}
                      </PaymentRecordsDetailContentText>
                      <PaymentRecordsDetailContentText
                        style={{
                          display: "block",
                        }}
                      >
                        {formatNumberWithCommas(paymentData.price)}원
                      </PaymentRecordsDetailContentText>
                    </PaymentRecordsDetailContentTextWrapper>
                  </PaymentRecordsDetailContentWrapper>
                </PaymentRecordsDetailContentsContainer>
                <PaymentRecordsDetailBtnWrapper>
                  <PrimaryMiniBtn
                    type="button"
                    disabled={
                      paymentData.merchId === 0 ||
                      paymentData.completeStatus !== "PROGRESS"
                    }
                    onClick={() => {
                      completePayment(paymentData.id);
                    }}
                  >
                    {paymentData.completeStatus === "COMPLETE"
                      ? "구매확정 완료"
                      : "구매확정 하기"}
                  </PrimaryMiniBtn>

                  {reviewModalIsOpen && (
                    <ReviewModal
                      $isOpen={reviewModalIsOpen}
                      $token={token}
                      $merchId={paymentData.merchId}
                      $companyName={paymentData.companyName}
                      $payDate={paymentData.createdAt}
                      $handleClose={closeReviewModal}
                    />
                  )}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 20,
                    }}
                  >
                    <SecondaryMiniBtn
                      type="button"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      목록으로
                    </SecondaryMiniBtn>
                    <SecondaryMiniBtn
                      type="button"
                      disabled={
                        paymentData.refundStatus === "COMPLETE" ||
                        paymentData.refundStatus === "REQUEST" ||
                        paymentData.completeStatus === "CANCEL"
                      }
                      onClick={() => {
                        if (paymentData.completeStatus === "AWAIT") {
                          setAlertOpen(true);
                          setResponseMessage(
                            <>
                              입금대기 중인 상품은
                              <br />
                              환불 요청이 불가합니다.
                            </>
                          );
                          return;
                        }

                        setResponseMessage("환불 요청을 진행하시겠습니까?");
                        setConfirmOpen(true);
                      }}
                    >
                      {paymentData.completeStatus === "CANCEL"
                        ? "결제취소"
                        : paymentData.refundStatus === "NONE"
                        ? "환불요청"
                        : paymentData.refundStatus === "REQUEST"
                        ? "환불 진행중"
                        : "환불완료"}
                    </SecondaryMiniBtn>
                  </div>
                </PaymentRecordsDetailBtnWrapper>
                <PaymentRecordsDetailBottomText>
                  *구매확정 이후에는 알팅 내에서 상품 취소/환불이 불가합니다.
                  <br />
                  *환불이 필요한 경우 계좌 정보 공유를 통해 직접 환불이
                  가능하며, 관리자에게 우선 1:1문의로 환불 요청을 진행해 주세요.
                </PaymentRecordsDetailBottomText>
              </PaymentRecordsDetailContainer>

              {alertOpen && (
                <Alert
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAlertOpen(false);
                  }}
                >
                  {responseMessage}
                </Alert>
              )}

              {confirmOpen && (
                <Confirm
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  cancelEvent={(e) => {
                    e.stopPropagation();
                    setConfirmOpen(false);
                  }}
                  confirmEvent={(e) => {
                    e.stopPropagation();
                    refundRequest(paymentData.id);
                    setConfirmOpen(false);
                  }}
                >
                  {responseMessage}
                </Confirm>
              )}
            </MyPageContentsContainer>
          </MyPageInnerContainerWrapper>
        </MyPageContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default CommonPaymentRecordsDetail;

const MyPageContainer = styled.div`
  position: absolute;
  top: 165px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MyPageInnerContainerWrapper = styled.div`
  display: flex;
  gap: 31px;
  width: 100%;
  margin-top: 30px;
`;

const MyPageContentsContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PaymentRecordsDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 892px;
  gap: 30px;
`;

const PaymentRecordsDetailContentsContainer = styled(PretendardText)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`;

const PaymentRecordsDetailContentTitle = styled(PretendardText)`
  font-size: 16px;
  font-weight: 600;
  line-height: 145%;
  color: #282828;
`;

const PaymentRecordsDetailContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  gap: 10px;
  background-color: #f2f2f2;
  border-radius: 4px;
`;

const PaymentRecordsDetailContentTextWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const PaymentRecordsDetailContentText = styled(PretendardText)`
  color: #282828;
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  display: contents;
`;

const PaymentRecordsDetailContentTextVerticalLine = styled(PretendardText)`
  display: contents;
  color: #c0c0c0;
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
`;

const PaymentRecordsDetailBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
`;

const PaymentRecordsDetailBottomText = styled(PretendardText)`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #828282;
`;
