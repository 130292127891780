import React from "react";
import styled from "styled-components";
import PretendardText from "../atoms/PretendardText";

const TopBanner = (props) => {
  return (
    <TopBannerContainer {...props}>
      <TopBannerTItle>{props.$title}</TopBannerTItle>
      <TopBannerText>{props.$text}</TopBannerText>
    </TopBannerContainer>
  );
};

export default TopBanner;

const TopBannerContainer = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${(props) => props.src}) 50% / cover no-repeat;
  background-position: center;
  background-color: #b8b8b8;
  margin-top: 85px;
  width: 100%;
  height: 310px;
  gap: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TopBannerTItle = styled(PretendardText)`
  font-weight: 700;
  font-size: 38px;
  line-height: 150%;
  color: #fff;
`;

const TopBannerText = styled(PretendardText)`
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #fff;
`;
