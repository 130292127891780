import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import PageContainer from "../../components/molecules/layout/PageContainer";
import BodyContainer from "../../components/molecules/layout/BodyContainer";
import Header from "../../components/organisms/Header";
import Footer from "../../components/organisms/Footer";
import Title from "../../components/atoms/Title";
import SubTitle from "./../../components/atoms/SubTitle";
import PretendardText from "../../components/atoms/PretendardText";
import {
  deleteStorage,
  getStorage,
  setStorage,
} from "../../lib/utils/sessionStorage";
import RegisterCheckBox from "../../components/molecules/RegisterCheckBox";
import { useNavigate } from "react-router-dom";
import useResizeHeight from "../../hooks/useResizeHeight";
import regex from "../../constants/regex";
import PrimaryBtn from "./../../components/atoms/buttons/PrimaryBtn";
import InputBox from "../../components/molecules/InputBox";
import InputSelectBox from "../../components/molecules/InputSelectBox";
import SelectList from "./../../components/molecules/SelectList";
import SelectOption from "./../../components/atoms/SelectOption";
import DivisionLine from "./../../components/atoms/DivisionLine";
import CheckboxIcon from "./../../components/atoms/icons/CheckboxIcon";
import Alert from "../../components/molecules/Alert";
import sectorOptions from "./../../constants/sectorOptions";
import axios from "axios";
import { url } from "../../lib/api/api";
import { removeNonNumeric } from "../../lib/utils/formatNumber";
import ModalOverlay from "../../components/atoms/ModalOverlay";
import Address from "../../components/organisms/Adress";
import route from "../../router/route";
import scrollToTop from "../../lib/utils/scrollToTop";
import TermsModal from "../../components/molecules/termsModal";
import _ from "lodash";

const RegisterCommon = () => {
  const navigate = useNavigate();

  const [registerInputValue, setRegisterInputValue] = useState({
    email: "",
    name: "",
    phoneNumber: "",
    certNum: "",
    age: "",
    password: "",
    passwordCheck: "",
    sector: "",
    joinType: "",
    socialId: "",
  });

  const [registerCheckedValue, setRegisterCheckedValue] = useState({
    agreement: false, // 이용약관 동의
    privacy: false, // 개인정보처리방침 동의
    providePrivacy: false, // 개인정보 제 3자 제공 동의
    advertisement: false, // 광고성 정보 수신 동의
  });

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    const getInfo = async () => {
      const joinType = await getStorage("joinType");
      const email = await getStorage("email");
      const socialId = await getStorage("socialToken");
      const name = await getStorage("name");
      const phoneNumber = await getStorage("phoneNumber");

      setRegisterInputValue((prevState) => ({
        ...prevState,
        joinType: joinType !== null ? joinType : "NORMAL",
        email: email !== null ? email : "",
        socialId: socialId !== null ? socialId : "",
        name: name !== null ? name : "",
        phoneNumber: phoneNumber !== null ? phoneNumber : "",
      }));
    };

    getInfo();

    getTermsData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // 유효성 검사 결과 상태
  const [emailRegexTest, setEmailRegexTest] = useState(true); // 이메일 정규식 테스트 결과 상태
  const [nameRegexTest, setNameRegexTest] = useState(true); // 이름 정규식 테스트 결과 상태
  const [phoneNumberRegexTest, setPhoneNumberRegexTest] = useState(true); // 전화번호 정규식 테스트 결과 상태
  const [certNumRegexTest, setCertNumRegexTest] = useState(true); // 인증번호 정규식 테스트 결과 상태
  const [certNumSent, setCertNumSent] = useState(false); // 인증번호 발송 여부 상태
  const [verifyCertNum, setVerifyCertNum] = useState(false); // 인증번호 확인 상태
  const [ageRegexTest, setAgeRegexTest] = useState(true); // 나이 정규식 테스트 결과 상태
  const [pwRegexTest, setPwRegexTest] = useState(true); // 비밀번호 정규식 테스트 결과 상태
  const [checkPwRegexTest, setCheckPwRegexTest] = useState(true); // 비밀번호 확인 정규식 테스트 결과 상태
  const [addressRegexTest, setAddressRegexTest] = useState(true); // 사업장 소재지 정규식 테스트 결과 상태
  const [addressDetailRegexTest, setAddressDetailRegexTest] = useState(true); // 상세주소 정규식 테스트 결과 상태

  const [isEmailDuplicated, setIsEmailDuplicated] = useState(false); // 이메일 중복 여부 상태

  // 이메일 중복 확인 함수
  const checkDuplicateEmail = useCallback(
    _.throttle(async (email) => {
      const password = "check";
      try {
        await axios.post(
          `${url}auth/login`,
          {
            email: email,
            password: password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {
        if (error.response.data.code === 40002) {
          setIsEmailDuplicated(true);
        } else {
          setIsEmailDuplicated(false);
        }
      }
    }, 500),
    []
  );

  // 정규식 검사 함수
  const validateInput = (regex, value) => {
    return regex.test(value); // 통과하면 true, 실패하면 false 반환
  };

  // 입력 필드 핸들러
  const handleInputValueChange = (e) => {
    const { name, value } = e.target;

    // 입력값 상태 업데이트
    if (name === "phoneNumber" || name === "age") {
      setRegisterInputValue((prevState) => ({
        ...prevState,
        [name]: removeNonNumeric(value),
      }));
    } else {
      setRegisterInputValue((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    const storageCertNum = sessionStorage.getItem("certNum");

    const validateField = (name, value) => {
      switch (name) {
        case "email":
          if (value !== "") {
            setEmailRegexTest(validateInput(regex.email, value));
            checkDuplicateEmail(value);
          } else {
            setEmailRegexTest(true);
          }
          break;
        case "name":
          if (value !== "") {
            setNameRegexTest(validateInput(regex.text, value));
          } else {
            setNameRegexTest(true);
          }
          break;
        case "phoneNumber":
          if (value !== "") {
            setPhoneNumberRegexTest(validateInput(regex.tel, value));
          } else {
            setPhoneNumberRegexTest(true);
          }
          break;
        case "certNum":
          if (value !== "") {
            setCertNumRegexTest(validateInput(regex.certNum, value));
            if (value === storageCertNum) {
              setVerifyCertNum(true);
            } else {
              setVerifyCertNum(false);
            }
          } else {
            setCertNumRegexTest(true);
          }
          break;
        case "age":
          if (value !== "") {
            setAgeRegexTest(validateInput(regex.age, value));
          } else {
            setAgeRegexTest(true);
          }
          break;
        case "password":
          if (value !== "") {
            setPwRegexTest(validateInput(regex.pw, value));
          } else {
            setPwRegexTest(true);
          }
          break;
        case "passwordCheck":
          if (value !== "") {
            setCheckPwRegexTest(value === registerInputValue.password);
          } else {
            setCheckPwRegexTest(true);
          }
          break;
        default:
          console.error("Invalid field name");
      }
    };

    validateField(name, value);
  };

  // 가입 유형, 약관 동의 체크박스 핸들러
  const handleCheckedChange = (e) => {
    const { name, checked } = e.target;
    setRegisterCheckedValue((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  // 주소 검색 관련
  const initialAddressValue = {
    address: "",
    addressDetail: "",
  };

  const [addressInputValue, setAddressInputValue] =
    useState(initialAddressValue);

  const [isAddressOpen, setIsAddressOpen] = useState(false); // 주소 검색 팝업 상태

  const handleAddressInput = (e) => {
    const { name, value } = e.target;

    setAddressInputValue({
      ...addressInputValue,
      [name]: value,
    });

    const validateField = (name, value) => {
      switch (name) {
        case "address":
          if (value !== "") {
            setAddressRegexTest(validateInput(regex.text, value));
          } else {
            setAddressRegexTest(true);
          }
          break;
        case "addressDetail":
          if (value !== "") {
            setAddressDetailRegexTest(validateInput(regex.text, value));
          } else {
            setAddressDetailRegexTest(true);
          }
          break;
        default:
          console.error("Invalid field name");
      }
    };

    validateField(name, value);
  };

  const handleAddressInputComplete = (data) => {
    setIsAddressOpen(!isAddressOpen);
  };

  const handleAddressPopupClose = () => {
    setIsAddressOpen(false);
  };

  // 회원가입 성공 여부 알림창 관련
  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);
  const [isFailAlertOpen, setIsFailAlertOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  // 서버 응답 메시지 상태
  const [responseMessage, setResponseMessage] = useState("");

  // 인증번호 발송 함수
  const handleCertNumSent = async () => {
    try {
      // 기존 회원인지 확인
      const response = await axios.post(`${url}auth/find/email`, {
        phoneNumber: removeNonNumeric(registerInputValue.phoneNumber),
      });
      if (response.data.data) {
        setIsFailAlertOpen(true);
        setResponseMessage("이미 가입된 정보입니다.");
        return;
      }
    } catch (error) {
      if (error.response) {
        // 가입한 휴대전화 정보가 없으면
        const response = await axios.post(`${url}auth/sms`, {
          phoneNumber: removeNonNumeric(registerInputValue.phoneNumber),
          content: "",
        });
        let certNum = response.data.data.authNumber;
        setStorage("certNum", certNum);
        setIsAlertOpen(true);
        setCertNumSent(true);
        setResponseMessage("인증번호가 발송되었습니다.");

        // 이전 입력된 인증번호 초기화
        setRegisterInputValue((prevState) => ({
          ...prevState,
          certNum: "",
        }));
      } else {
        // 서버 응답이 없을 때 처리
        setIsFailAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // 사용자의 전화번호 입력란 변경으로 인하여 인증번호 발송 상태 false로 변경
  useEffect(() => {
    if (!phoneNumberRegexTest || registerInputValue.phoneNumber === "") {
      setCertNumSent(false);
      setVerifyCertNum(false);
    }
  }, [phoneNumberRegexTest, registerInputValue.phoneNumber]);

  // 인증 이후 사용자의 전화번호 입력란 변경으로 인하여 인증번호 확인 상태 false로 변경
  useEffect(() => {
    if (!certNumRegexTest || registerInputValue.certNum === "") {
      setVerifyCertNum(false);
    }
  }, [certNumRegexTest, registerInputValue.certNum]);

  // ---------- 약관 관련 ---------- //
  const [termsData, setTermsData] = useState({
    useTerms: {},
    personalTerms: {},
    providePrivacyTerms: {},
  });

  const getTermsData = async () => {
    try {
      let response;
      response = await axios.get(`${url}terms/${1}`);
      setTermsData((prev) => ({
        ...prev,
        useTerms: response.data.data.content,
      }));
      response = await axios.get(`${url}terms/${2}`);
      setTermsData((prev) => ({
        ...prev,
        privacyTerms: response.data.data.content,
      }));
      response = await axios.get(`${url}terms/${4}`);
      setTermsData((prev) => ({
        ...prev,
        providePrivacyTerms: response.data.data.content,
      }));
    } catch (error) {
      console.error(error);
      setIsAlertOpen(true);
      setResponseMessage("잠시 후 다시 시도해주세요.");
    }
  };

  // -------------------------------- //
  // ---------- 모달 관련 ---------- //
  // ------------------------------ //

  // 모달 애니메이션 상태
  const [isAnimating, setIsAnimating] = useState(false);

  // 이용약관 모달
  const [isAgreementOpen, setIsAgreementOpen] = useState(false);

  const openAgreementModal = () => {
    setIsAgreementOpen(true);
  };

  const closeAgreementModal = () => {
    setIsAnimating(true);
    setTimeout(() => {
      // 300ms 후 모달 상태 변경
      setIsAgreementOpen(false);
      setIsAnimating(false);
    }, 300);
  };

  // 개인정보처리방침 모달
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);

  const openPrivacyModal = () => {
    setIsPrivacyOpen(true);
  };

  const closePrivacyModal = () => {
    setIsAnimating(true);
    setTimeout(() => {
      // 300ms 후 모달 상태 변경
      setIsPrivacyOpen(false);
      setIsAnimating(false);
    }, 300);
  };

  // 개인정보 제 3자 제공 동의 모달
  const [isProvidePrivacyOpen, setIsProvidePrivacyOpen] = useState(false);

  const openProvidePrivacyModal = () => {
    setIsProvidePrivacyOpen(true);
  };

  const closeProvidePrivacyModal = () => {
    setIsAnimating(true);
    setTimeout(() => {
      // 300ms 후 모달 상태 변경
      setIsProvidePrivacyOpen(false);
      setIsAnimating(false);
    }, 300);
  };

  // 상태 변수
  const [selectedSector, setSelectedSector] = useState(""); // 선택된 업종 값
  const [isSectorRotated, setIsSectorRotated] = useState(false); // 업종 선택을 위한 드롭다운 회전
  const [verifySector, setVerifySector] = useState(false); // 업종 선택 완료 시 true 전환

  // 폼 제출 핸들러
  const handleSendCommonRegisterForm = async (e) => {
    e.preventDefault();

    if (registerInputValue.socialId === "") {
      const userData = {
        email: registerInputValue.email,
        name: registerInputValue.name,
        phoneNumber: removeNonNumeric(registerInputValue.phoneNumber),
        age: Number(removeNonNumeric(registerInputValue.age)),
        password: registerInputValue.password,
        sector: registerInputValue.sector,
        address: `${addressInputValue.address} ${addressInputValue.addressDetail}`,
        agreement: registerCheckedValue.agreement,
        privacy: registerCheckedValue.privacy,
        providePrivacy: registerCheckedValue.providePrivacy,
        advertisement: registerCheckedValue.advertisement,
        joinType: registerInputValue.joinType,
        socialId:
          registerInputValue.socialId !== "" ? registerInputValue.socialId : "",
      };

      try {
        await axios.post(`${url}auth/sign/common`, userData);
        setIsSuccessAlertOpen(true); // 회원가입 성공 알림창 열기
      } catch (error) {
        setIsFailAlertOpen(true); // 회원가입 실패 알림창 열기
        console.error("회원가입 에러 발생:", error);
        if (error.response) {
          // 서버 응답이 있을 때 처리
          console.error("응답: ", error.response);
          console.error("응답 데이터: ", error.response.data);
          console.error("응답 상태 코드: ", error.response.status);
          console.error("응답 메시지: ", error.response.data.message);
          setResponseMessage("잘못된 요청입니다.");
        } else {
          // 서버 응답이 없을 때 처리
          console.error("서버에 연결할 수 없습니다.");
          setResponseMessage("잠시 후 다시 시도해 주세요.");
        }
      }
    } else {
      const userData = {
        email: registerInputValue.email,
        name: registerInputValue.name,
        phoneNumber: removeNonNumeric(registerInputValue.phoneNumber),
        age: Number(removeNonNumeric(registerInputValue.age)),
        sector: registerInputValue.sector,
        address: `${addressInputValue.address} ${addressInputValue.addressDetail}`,
        agreement: registerCheckedValue.agreement,
        privacy: registerCheckedValue.privacy,
        providePrivacy: registerCheckedValue.providePrivacy,
        advertisement: registerCheckedValue.advertisement,
        joinType: registerInputValue.joinType,
        socialId: registerInputValue.socialId,
      };

      try {
        await axios.post(`${url}auth/sign/common/social`, userData);
        setIsSuccessAlertOpen(true); // 회원가입 성공 알림창 열기
      } catch (error) {
        setIsFailAlertOpen(true); // 회원가입 실패 알림창 열기
        console.error("회원가입 에러 발생:", error);
        if (error.response) {
          // 서버 응답이 있을 때 처리
          console.error("응답: ", error.response);
          console.error("응답 데이터: ", error.response.data);
          console.error("응답 상태 코드: ", error.response.status);
          console.error("응답 메시지: ", error.response.data.message);
          setResponseMessage("잘못된 요청입니다.");
        } else {
          // 서버 응답이 없을 때 처리
          console.error("서버에 연결할 수 없습니다.");
          setResponseMessage("잠시 후 다시 시도해 주세요.");
        }
      }
    }
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 750 + elementHeight,
      }}
    >
      <Header />
      <BodyContainer
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        <RegisterContainer ref={elementRef} style={{ top: 170 }}>
          <RegisterTitleWrapper>
            <Title>회원가입</Title>
            <SubTitle>아래의 정보를 모두 입력해 주세요.</SubTitle>
          </RegisterTitleWrapper>
          <RegisterForm onSubmit={handleSendCommonRegisterForm}>
            {/* Input 영역 */}
            <InputBoxContainer>
              {/* 이메일 */}
              <InputBox
                $uniqueKey="email"
                label="이메일"
                type="email"
                placeholder="이메일을 입력해 주세요."
                $isRequired
                value={registerInputValue.email}
                onChange={handleInputValueChange}
                $isError={!emailRegexTest || isEmailDuplicated}
                $errorMsg={
                  isEmailDuplicated
                    ? "이미 가입한 이메일입니다."
                    : "이메일 형식이 올바르지 않습니다."
                }
              />

              {/* 이름 */}
              <InputBox
                $uniqueKey="name"
                label="이름"
                type="text"
                placeholder="이름을 입력해 주세요."
                $isRequired
                value={registerInputValue.name}
                onChange={handleInputValueChange}
                $isError={!nameRegexTest}
                $errorMsg="2글자 이상 입력해 주세요."
              />

              {/* 전화번호 */}
              <InputBox
                $uniqueKey="phoneNumber"
                label="전화번호"
                type="text"
                placeholder="전화번호를 입력해 주세요."
                $isRequired
                value={registerInputValue.phoneNumber}
                onChange={handleInputValueChange}
                $isError={!phoneNumberRegexTest}
                $errorMsg="전화번호 형식이 올바르지 않습니다."
                $isShowBtn={true}
                $btnText={certNumSent ? "재발송" : "인증하기"}
                $btnDisabled={
                  !phoneNumberRegexTest ||
                  registerInputValue.phoneNumber === "" ||
                  verifyCertNum
                }
                $btnHandler={() => {
                  if (registerInputValue.phoneNumber !== "") {
                    handleCertNumSent();
                  }
                }}
              />

              {/* 인증번호 */}
              {certNumSent && phoneNumberRegexTest && (
                <InputBox
                  isNoLabel
                  $uniqueKey="certNum"
                  type="number"
                  placeholder="인증번호를 입력해 주세요."
                  value={registerInputValue.certNum}
                  onChange={handleInputValueChange}
                  $isError={!certNumRegexTest}
                  $errorMsg="인증번호 6자리를 올바르게 입력해 주세요."
                  $isHelp={
                    verifyCertNum ||
                    (registerInputValue.certNum !== "" &&
                      certNumRegexTest &&
                      !verifyCertNum)
                  }
                  $helpMsg={
                    certNumRegexTest && !verifyCertNum
                      ? "인증번호가 불일치합니다."
                      : "인증번호가 일치합니다."
                  }
                  readOnly={verifyCertNum}
                  disabled={verifyCertNum}
                />
              )}

              {/* 나이 */}
              <InputBox
                $uniqueKey="age"
                label="나이"
                type="text"
                placeholder="나이를 입력해 주세요."
                $isRequired
                value={registerInputValue.age}
                onChange={handleInputValueChange}
                $isError={!ageRegexTest}
                $errorMsg="나이를 정확히 입력해 주세요."
              />

              {registerInputValue.socialId === "" && (
                <>
                  {/* 비밀번호 */}
                  <InputBox
                    $uniqueKey="password"
                    label="비밀번호"
                    type="password"
                    placeholder="8자 이상 16자 이내 문자/영문/특수기호 사용 가능"
                    $isRequired
                    value={registerInputValue.password}
                    onChange={handleInputValueChange}
                    $isError={!pwRegexTest}
                    $errorMsg="8~16자 이내, 문자/영문/특수기호 중 2가지 이상 사용해 주세요."
                  />

                  {/* 비밀번호 재입력 */}
                  <InputBox
                    $uniqueKey="passwordCheck"
                    label="비밀번호 확인"
                    type="password"
                    placeholder="비밀번호를 한 번 더 입력해 주세요."
                    $isRequired
                    value={registerInputValue.passwordCheck}
                    onChange={handleInputValueChange}
                    $isError={!checkPwRegexTest}
                    $errorMsg="비밀번호가 일치하지 않습니다."
                  />
                </>
              )}

              {/* 업종 */}
              <InputSelectBox
                $uniqueKey="sector"
                label="업종"
                readOnly
                $isRequired
                placeholder="업종을 선택해 주세요."
                value={selectedSector}
                onChange={handleInputValueChange}
                $isRotated={isSectorRotated}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsSectorRotated((prevState) => !prevState);
                }}
              >
                <SelectList
                  className="scrollbar"
                  style={{
                    top: 75,
                    left: 0,
                    width: "100%",
                    display: isSectorRotated ? "block" : "none",
                  }}
                >
                  {Object.keys(sectorOptions).map((key, index) => (
                    <React.Fragment key={key}>
                      <SelectOption
                        onClick={(e) => {
                          e.stopPropagation();
                          setRegisterInputValue((prevState) => ({
                            ...prevState,
                            sector: key,
                          }));
                          setSelectedSector(sectorOptions[key]);
                          setIsSectorRotated(false); // 선택 후 리스트 창 닫기
                          setVerifySector(true);
                        }}
                      >
                        {sectorOptions[key]}
                      </SelectOption>
                      {index < Object.keys(sectorOptions).length - 1 && (
                        <DivisionLine
                          style={{
                            margin: "12px 0",
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </SelectList>
              </InputSelectBox>

              {/* 소재지 */}
              <InputBox
                $uniqueKey="address"
                label="사업장 소재지"
                type="text"
                placeholder="사업장 소재지를 입력해 주세요."
                $isRequired
                value={addressInputValue.address}
                onChange={handleAddressInput}
                $isError={!addressRegexTest}
                $errorMsg="소재지를 올바르게 입력해 주세요. ex) 서울특별시 중구 세종대로 110"
                $isShowBtn={true}
                $btnText="검색하기"
                $btnHandler={handleAddressInputComplete}
                readOnly={true}
              />

              {isAddressOpen && (
                <ModalOverlay $isOpen={isAddressOpen}>
                  <Address
                    address={addressInputValue}
                    setAddress={setAddressInputValue}
                    onClose={handleAddressPopupClose}
                  />
                </ModalOverlay>
              )}

              {/* 상세주소 */}
              {addressInputValue.address !== "" && addressRegexTest && (
                <InputBox
                  isNoLabel
                  $uniqueKey="addressDetail"
                  type="text"
                  placeholder="상세주소를 입력해 주세요."
                  value={addressInputValue.addressDetail}
                  onChange={handleAddressInput}
                  $isHelp={!addressDetailRegexTest}
                  $helpMsg="2글자 이상 입력해 주세요."
                />
              )}

              {/* 동의 체크박스 영역 */}
              <CheckboxContainer>
                {/* 이용약관 동의 영역 */}
                <CheckboxWrapper>
                  <RegisterCheckBox
                    $uniqueKey="agreement"
                    $isRequired
                    value="동의"
                    checked={registerCheckedValue.agreement}
                    onChange={handleCheckedChange}
                  >
                    <CheckboxIcon checked={registerCheckedValue.agreement} />
                    {termsData.useTerms?.title
                      ? `${termsData.useTerms?.title} 동의(필수)`
                      : "이용약관 동의(필수)"}
                  </RegisterCheckBox>
                  <CheckboxOpenModalText onClick={openAgreementModal}>
                    전체보기
                  </CheckboxOpenModalText>
                </CheckboxWrapper>

                {/* 이용약관 동의 모달 영역 */}
                {isAgreementOpen && (
                  <TermsModal
                    $termsId={1}
                    $isOpen={!isAnimating}
                    handleClose={closeAgreementModal}
                  />
                )}

                {/* 개인정보처리방침 동의 영역 */}
                <CheckboxWrapper>
                  <RegisterCheckBox
                    $uniqueKey="privacy"
                    $isRequired
                    value="동의"
                    checked={registerCheckedValue.privacy}
                    onChange={handleCheckedChange}
                  >
                    <CheckboxIcon checked={registerCheckedValue.privacy} />
                    {termsData.privacyTerms?.title
                      ? `${termsData.privacyTerms?.title} 동의(필수)`
                      : "개인정보처리방침 동의(필수)"}
                  </RegisterCheckBox>
                  <CheckboxOpenModalText onClick={openPrivacyModal}>
                    전체보기
                  </CheckboxOpenModalText>
                </CheckboxWrapper>

                {/* 개인정보처리방침 모달 영역 */}
                {isPrivacyOpen && (
                  <TermsModal
                    $termsId={2}
                    $isOpen={!isAnimating}
                    handleClose={closePrivacyModal}
                  />
                )}

                {/* 개인정보 수집 및 이용 동의 영역 */}
                <CheckboxWrapper>
                  <RegisterCheckBox
                    $uniqueKey="providePrivacy"
                    $isRequired
                    value="동의"
                    checked={registerCheckedValue.providePrivacy}
                    onChange={handleCheckedChange}
                  >
                    <CheckboxIcon
                      checked={registerCheckedValue.providePrivacy}
                    />
                    {termsData.providePrivacyTerms?.title
                      ? `${termsData.providePrivacyTerms?.title} 동의(필수)`
                      : "개인정보 수집 및 이용 동의(필수)"}
                  </RegisterCheckBox>
                  <CheckboxOpenModalText onClick={openProvidePrivacyModal}>
                    전체보기
                  </CheckboxOpenModalText>
                </CheckboxWrapper>

                {/* 개인정보 제 3자 제공 모달 영역 */}
                {isProvidePrivacyOpen && (
                  <TermsModal
                    $termsId={4}
                    $isOpen={!isAnimating}
                    handleClose={closeProvidePrivacyModal}
                  />
                )}

                {/* 광고성 정보 수신 동의 영역 */}
                <CheckboxWrapper>
                  <RegisterCheckBox
                    $uniqueKey="advertisement"
                    $isRequired
                    value={
                      registerCheckedValue.advertisement ? "동의" : "미동의"
                    }
                    checked={registerCheckedValue.advertisement}
                    onChange={handleCheckedChange}
                  >
                    <CheckboxIcon
                      checked={registerCheckedValue.advertisement}
                    />
                    광고성 정보 수신 동의
                  </RegisterCheckBox>
                </CheckboxWrapper>
              </CheckboxContainer>
            </InputBoxContainer>
          </RegisterForm>
          <PrimaryBtn
            disabled={
              (registerInputValue.socialId === "" &&
                registerInputValue.password === "") ||
              !(
                registerInputValue.email !== "" &&
                registerInputValue.name !== "" &&
                registerInputValue.phoneNumber !== "" &&
                registerInputValue.certNum !== "" &&
                registerInputValue.age !== "" &&
                registerInputValue.sector !== "" &&
                addressInputValue.address !== "" &&
                addressInputValue.addressDetail !== "" &&
                emailRegexTest &&
                nameRegexTest &&
                phoneNumberRegexTest &&
                verifyCertNum &&
                ageRegexTest &&
                pwRegexTest &&
                checkPwRegexTest &&
                verifySector &&
                addressRegexTest &&
                addressDetailRegexTest &&
                registerCheckedValue.providePrivacy &&
                registerCheckedValue.privacy &&
                registerCheckedValue.agreement &&
                !isEmailDuplicated
              )
            }
            type="submit"
            onClick={handleSendCommonRegisterForm}
          >
            회원가입 완료
          </PrimaryBtn>

          {/* 회원가입 성공 시 알림창 */}
          {isSuccessAlertOpen && (
            <Alert
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              onClick={(e) => {
                e.stopPropagation();
                // 회원가입 성공 후 로그인 페이지로 이동
                navigate(route.login);
                deleteStorage("certNum"); // 인증번호 세션 삭제
                scrollToTop();
                setIsSuccessAlertOpen(false);
              }}
            >
              회원가입을 축하드립니다.
              <br />
              로그인 페이지로 이동합니다.
            </Alert>
          )}

          {/* 회원가입 실패 시 알림창 */}
          {isFailAlertOpen && (
            <Alert
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setIsFailAlertOpen(false);
              }}
            >
              {responseMessage}
            </Alert>
          )}

          {isAlertOpen && (
            <Alert
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setIsAlertOpen(false);
              }}
            >
              {responseMessage}
            </Alert>
          )}
        </RegisterContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default RegisterCommon;

const RegisterContainer = styled.section`
  position: absolute;
  width: 430px;
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

const RegisterTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RegisterForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;

const InputBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CheckboxOpenModalText = styled(PretendardText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  color: #939393;
  text-decoration: underline;
  cursor: pointer;
`;
