const regex = {
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  text: /^[\s\S]{2,}$/,
  tel: /^01([0|1|6|7|8|9])[-\s]?(\d{3,4})[-\s]?(\d{4})$/,
  certNum: /^\d{6}$/,
  age: /^(1[0-1][0-9]|12[0-0]|[0-9]{1,2})$/,
  pw: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#\$%\^&\*\(\)\-_\+=\{\}\[\]\|\\:;"'<>,\.\?/`~])[A-Za-z\d!@#\$%\^&\*\(\)\-_\+=\{\}\[\]\|\\:;"'<>,\.\?/`~]{8,16}$/, // eslint-disable-line no-useless-escape
  companyNumber: /^\d{10,}$/,
  companyTel: /^(0(?:2|[3-6][1-5]|[7-9][0-8]))[-\s]?(\d{3,4})[-\s]?(\d{4})$/,
  number: /^[0-9,]+$/,
  executionPeriod: /^\d+$/,
  accountNum: /^\d+(-\d+)*$/,
};

export default regex;
