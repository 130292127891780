import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Title from "../../../../components/atoms/Title";
import BodyContainer from "../../../../components/molecules/layout/BodyContainer";
import Header from "../../../../components/organisms/Header";
import useResizeHeight from "../../../../hooks/useResizeHeight";
import Footer from "../../../../components/organisms/Footer";
import PretendardText from "../../../../components/atoms/PretendardText";
import { useRecoilValue } from "recoil";
import {
  deleteStorage,
  getStorage,
} from "../../../../lib/utils/sessionStorage";
import axios from "axios";
import { url } from "../../../../lib/api/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { myInfoValueState } from "../../../../store/myInfo";
import PageContainer from "../../../../components/molecules/layout/PageContainer";
import Alert from "../../../../components/molecules/Alert";
import CommonSideBar from "../_components/CommonSideBar";
import { formatDate } from "./../../../../lib/utils/formatDate";
import route from "../../../../router/route";
import { formatNumberWithCommas } from "./../../../../lib/utils/formatNumber";
import SortedResultBoldText from "./../../../../components/molecules/SortedResultBoldText";
import Pagination from "./../../../../components/molecules/Pagination";

const CommonPaymentRecords = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    if (myInfo.id === 0 || !myInfo) {
      return;
    } else {
      getTokens();
    }
  }, [myInfo]);

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    if (token !== "") {
      getPaymentList();
    }
  }, [token, location]); // eslint-disable-line

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // 알림창 관련 상태
  const [alertOpen, setAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // ------------------------------------------- //
  // ---------- 결제 리스트 가져오기 ---------- //
  // ----------------------------------------- //
  const [paymentList, setPaymentList] = useState([]);

  // 페이지네이션 관련 상태
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 5, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const getPaymentList = async () => {
    try {
      const data = {
        page: searchParams.get("page") ? searchParams.get("page") : 1,
        size: pagination.size,
        orderBy: "DESC",
      };
      const response = await axios.get(`${url}member/payments`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        params: data,
      });

      setPaymentList(response.data.data.content);

      setPagination({
        ...pagination,
        totalCount: response.data.data.totalCount
          ? response.data.data.totalCount
          : response.data.data.content.length,
        totalPages: response.data.data.totalPage
          ? response.data.data.totalPage
          : Math.ceil(
              response.data.data.totalCount
                ? response.data.data.totalCount / 5
                : response.data.data.content.length / 5
            ),
      });
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 550 + elementHeight,
      }}
    >
      <Header $isBgWhite={true} />
      <BodyContainer
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        <MyPageContainer ref={elementRef}>
          <Title>마이페이지</Title>
          <MyPageInnerContainerWrapper>
            <CommonSideBar />
            <MyPageContentsContainer>
              <SortedResultTextWrapper>
                <SortedResultBoldText
                  fontSize={20}
                  $title="결제 내역"
                  $count={pagination.totalCount}
                />
              </SortedResultTextWrapper>
              <PaymentRecordsTitleWrapper>
                <PaymentRecordsTitleText
                  style={{
                    width: 265,
                    marginRight: 22,
                  }}
                >
                  상품명
                </PaymentRecordsTitleText>
                <PaymentRecordsTitleText
                  style={{
                    width: 98,
                    marginRight: 30,
                    textAlign: "center",
                  }}
                >
                  결제일
                </PaymentRecordsTitleText>
                <PaymentRecordsTitleText
                  style={{
                    width: 70,
                    marginRight: 56,
                    textAlign: "center",
                  }}
                >
                  결제방법
                </PaymentRecordsTitleText>
                <PaymentRecordsTitleText
                  style={{
                    width: 61,
                    marginRight: 54,
                    textAlign: "center",
                  }}
                >
                  결제금액
                </PaymentRecordsTitleText>
                <PaymentRecordsTitleText
                  style={{
                    width: 73,
                    marginRight: 50,
                    textAlign: "center",
                  }}
                >
                  구매확정
                </PaymentRecordsTitleText>
                <PaymentRecordsTitleText
                  style={{
                    width: 73,
                    textAlign: "center",
                  }}
                >
                  환불요청
                </PaymentRecordsTitleText>
              </PaymentRecordsTitleWrapper>
              <PaymentRecordsContentContainer>
                <>
                  {paymentList.map((item, index) => (
                    <PaymentRecordsContentWrapper
                      onClick={() => {
                        navigate(
                          `${route.myPage_common_paymentRecords_detail}/${item.id}`
                        );
                      }}
                      key={index}
                    >
                      <PaymentRecordsContent
                        style={{
                          width: 265,
                          marginRight: 22,
                        }}
                      >
                        {item.merchTitle}
                      </PaymentRecordsContent>
                      <PaymentRecordsContent
                        style={{
                          width: 98,
                          marginRight: 30,
                          textAlign: "center",
                        }}
                      >
                        {formatDate(item.createdAt)}
                      </PaymentRecordsContent>
                      <PaymentRecordsContent
                        style={{
                          width: 70,
                          marginRight: 56,
                          textAlign: "center",
                        }}
                      >
                        {item.payMethodTitle}
                      </PaymentRecordsContent>
                      <PaymentRecordsContent
                        style={{
                          width: 61,
                          marginRight: 54,
                          textAlign: "center",
                        }}
                      >
                        {formatNumberWithCommas(item.price)}
                      </PaymentRecordsContent>
                      <div
                        style={{
                          width: 73,
                          marginRight: 50,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <PaymentRecordsDecideStatusText
                          style={{
                            color: item.completeStatus === "COMPLETE" && "#fff",
                            backgroundColor:
                              item.completeStatus === "COMPLETE" && "#2ec5f6",
                          }}
                        >
                          {item.completeStatusTitle
                            ? item.completeStatusTitle
                            : item.completeStatus === "COMPLETE"
                            ? "확정"
                            : item.completeStatus === "Progress"
                            ? "미확정"
                            : item.completeStatus === "AWAIT"
                            ? "입금대기"
                            : item.completeStatus === "CANCEL"
                            ? "결제취소"
                            : "해당없음"}
                        </PaymentRecordsDecideStatusText>
                      </div>
                      <div
                        style={{
                          width: 73,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <PaymentRecordsRefundProgressText
                          style={{
                            color: item.refundStatus === "COMPLETE" && "#fff",
                            backgroundColor:
                              item.refundStatus === "COMPLETE" && "#2ec5f6",
                          }}
                        >
                          {item.refundStatusTitle
                            ? item.refundStatusTitle
                            : item.refundStatus === "COMPLETE"
                            ? "환불완료"
                            : item.refundStatus === "REQUEST"
                            ? "진행중"
                            : "해당없음"}
                        </PaymentRecordsRefundProgressText>
                      </div>
                    </PaymentRecordsContentWrapper>
                  ))}
                  <Pagination
                    route={route.myPage_common_paymentRecords}
                    currentPage={page}
                    totalPages={pagination.totalPages}
                    rangeSize={pagination.rangeSize}
                  />
                </>
              </PaymentRecordsContentContainer>

              {alertOpen && (
                <Alert
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAlertOpen(false);
                  }}
                >
                  {responseMessage}
                </Alert>
              )}
            </MyPageContentsContainer>
          </MyPageInnerContainerWrapper>
        </MyPageContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default CommonPaymentRecords;

const MyPageContainer = styled.div`
  position: absolute;
  top: 165px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MyPageInnerContainerWrapper = styled.div`
  display: flex;
  gap: 31px;
  width: 100%;
  margin-top: 30px;
`;

const MyPageContentsContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SortedResultTextWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const MyReviewTitleWrapper = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  height: 65px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #939393;
`;

const MyReviewTitleText = styled(PretendardText)`
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  color: #282828;
`;

const MyReviewContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
`;

const MyReviewContentWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 65px;
  border-bottom: 1px solid #e9e9e9;
  background-color: #fff;
`;

const MyReviewContent = styled(PretendardText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: #282828;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
`;

const PaymentRecordsTitleWrapper = styled(MyReviewTitleWrapper)``;

const PaymentRecordsTitleText = styled(MyReviewTitleText)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const PaymentRecordsContentContainer = styled(MyReviewContentContainer)``;

const PaymentRecordsContentWrapper = styled(MyReviewContentWrapper)``;

const PaymentRecordsContent = styled(MyReviewContent)``;

const PaymentRecordsDecideStatusText = styled(PretendardText)`
  padding: 5px 12px;
  border-radius: 50px;
  background-color: #e9e9e9;
  color: #6f6f6f;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  width: fit-content;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaymentRecordsRefundProgressText = styled(
  PaymentRecordsDecideStatusText
)``;
