import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PageContainer from "../../components/molecules/layout/PageContainer";
import BodyContainer from "../../components/molecules/layout/BodyContainer";
import Header from "../../components/organisms/Header";
import Footer from "../../components/organisms/Footer";
import DetailTopBanner from "../../components/atoms/DetailTopBanner";
import PretendardText from "../../components/atoms/PretendardText";
import ReviewGradeIcon from "./../../components/atoms/icons/ReviewGradeIcon";
import { useNavigate } from "react-router-dom";
import ProfileIcon from "../../components/atoms/ProfileIcon";
import scrollToTop from "../../lib/utils/scrollToTop";
import useResizeHeight from "../../hooks/useResizeHeight";
import {
  selectedPortfolioListState,
  serviceImgValueState,
  serviceInputValueState,
} from "../../store/serviceStore";
import { useRecoilValue } from "recoil";
import DownArrowIcon from "../../components/atoms/icons/DownArrowIcon";
import CompanyIcon from "../../components/atoms/icons/CompanyIcons";
import PenIcon from "./../../components/atoms/icons/PenIcon";
import LocationIcon from "./../../components/atoms/icons/LocationIcon";
import BookmarkIcon from "../../components/atoms/icons/BookmarkIcon";
import CompanyList from "./../../components/molecules/CompanyList";
import PaginatedItems from "./../../components/molecules/PaginatedItems";
import PrimaryBtn from "./../../components/atoms/buttons/PrimaryBtn";
import SecondaryBtn from "./../../components/atoms/buttons/SecondaryBtn";
import { myInfoValueState } from "../../store/myInfo";
import { deleteStorage, getStorage } from "../../lib/utils/sessionStorage";
import axios from "axios";
import { url } from "../../lib/api/api";
import { formatTelNumber } from "./../../lib/utils/formatNumber";
import { fileUrl } from "./../../lib/api/api";
import route from "../../router/route";

const Preview = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //

  // 기업 회원이 아닐 경우 홈으로 이동
  useEffect(() => {
    if (myInfo?.id === 0 || !myInfo) {
      return;
    } else {
      if (myInfo?.profile) {
        getTokens();
      } else {
        navigate("/");
      }
    }
  }, [myInfo]); // eslint-disable-line

  // 토큰 가져오기
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    if (token !== "") {
      getServiceFaqList();
    }
  }, [token]); // eslint-disable-line

  // --------------------------------------------- //
  // ---------- 데이터 가져오기 ---------- //
  // ------------------------------------------- //
  const serviceInputValue = useRecoilValue(serviceInputValueState);
  const serviceImgValue = useRecoilValue(serviceImgValueState);
  const selectedPortfolioListValue = useRecoilValue(selectedPortfolioListState);
  const [serviceFaqList, setServiceFaqList] = useState([]);

  const getServiceFaqList = async () => {
    try {
      const data = {
        page: 1,
        size: 0,
      };
      const faqResponse = await axios.get(`${url}merch-faqs/${myInfo?.id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        params: data,
      });
      setServiceFaqList(faqResponse.data.data.content);
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      }
    }
  };

  // ------------------------------------------ //
  // ---------- 자주 묻는 질문 영역 ---------- //
  // ---------------------------------------- //

  // 각 게시물의 DownArrow 상태 배열로 관리
  const [rotatedItems, setRotatedItems] = useState([]);

  useEffect(() => {
    setRotatedItems(new Array(serviceFaqList.length).fill(false)); // serviceFaqList의 길이만큼 배열 생성 후 채우지 않음
  }, [serviceFaqList]);

  // 각 게시물의 DownArrow 상태 토글 함수
  const toggleItem = (index) => {
    setRotatedItems((prev) =>
      prev.map((item, i) => (i === index ? !item : item))
    );
  };

  // --------------------------------------------- //

  // 북마크 관련
  const [bookmarkStatus, setBookmarkStatus] = useState(false);
  const [portfolioBookmarkStatus, setPortfolioBookmarkStatus] = useState(false);

  const toggleBookmark = () => {
    setBookmarkStatus(!bookmarkStatus);
  };

  const togglePortfolioBookmark = () => {
    setPortfolioBookmarkStatus(!portfolioBookmarkStatus);
  };

  // ------------------------------- //
  // ---------- 설명 영역 ---------- //
  // ----------------------------- //
  const [companyIntroductionState, setCompanyIntroductionState] =
    useState(true);
  const [marketingStrategyState, setMarketingStrategyState] = useState(false);
  const [budgetAndExecutionPeriodState, setBudgetAndExecutionPeriodState] =
    useState(false);
  const [businessLocationState, setBusinessLocationState] = useState(false);
  const [frequentlyQnAState, setFrequentlyQnAState] = useState(false);
  const [reviewState, setReviewState] = useState(false);

  // 설명 영역 클릭 핸들러
  const handleSectionClick = (section) => {
    setCompanyIntroductionState(section === "companyIntroduction");
    setMarketingStrategyState(section === "marketingStrategy");
    setBudgetAndExecutionPeriodState(section === "budgetAndExecutionPeriod");
    setBusinessLocationState(section === "businessLocation");
    setFrequentlyQnAState(section === "frequentlyQnA");
    setReviewState(section === "review");
  };

  // 설명 영역 타이틀 ref
  const companyIntroductionRef = useRef(null);
  const marketingStrategyRef = useRef(null);
  const budgetAndExecutionPeriodRef = useRef(null);
  const businessLocationRef = useRef(null);
  const frequentlyQnARef = useRef(null);
  const reviewRef = useRef(null);

  // 설명 영역 타이틀 클릭 시 해당 영역으로 스크롤 이동
  const scrollToSection = (ref) => {
    if (ref.current) {
      const topOffset =
        ref.current.getBoundingClientRect().top + window.scrollY - 100;
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    } else {
      console.error("ref is not assigned correctly", ref);
    }
  };

  // 후기 데이터
  const reviewData = [
    {
      profileImg:
        "https://s3-alpha-sig.figma.com/img/a61e/8d0d/68b8d9a7f516c717d4ef12c06c6d0472?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ataSvk6jhw~0oAAu1of2hvVq~fXWDaXldcEv4g8WDO8AucYVb6xOsFD3MthsHFMNxRtaErTFLURsYo6D9ay-sD4mgNKi~pKtEozzIzFUSuZfaVxFbL09wcpUnLA2X~RLD3~vXcE7VOBPMtf2Iqc1q05OUlrG8nvyUOhKyCKlaBE7tnHX2Ks2N8nlqPjY8QlXpldTdRLNYaYwPnRPGxrIWrMuyoY5wNCIIH2fPwhs8hCtHBxTwvGDRGraSMEne6B1LF2X6oVHjf9UokmMcr9qX7nYzF-0d2pY3j-2Mx320nCpGcCl5b3OW84R2F0CXwmlnk6YxId6B-qU45RJcxV2dQ__",
      userName: "홍**",
      reviewGrade: "5.0",
      date: "YYY.MM.DD",
      content:
        "후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.",
    },
    {
      profileImg:
        "https://s3-alpha-sig.figma.com/img/11bc/c7b5/46c9511e90d3a202e88e6796e919ff66?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Qv6vqJwWtzQIy-pWMpaPZ6w6x8XGGZrQosMMiePFd4so2cIF8Xnq1i6NfrLzhrYaCfCEtsCaYmAusM3DDCcUiy7Pz0PielfzEHpxkyKc35vHyP9i2uiaPHk1NYw~ZGK~i68N1MZdigzMMCr5RhQXqAo5uO27RorpyCpga-Q8~PxFWNrqFl2PYlqCLpwC2tp3IjIaCGUZQ4I2mNRH-kz7ckNs4EX1sGWZTG3kLbq6MQPqYqtEmXdyHZraC-EplHyYK05UKrnL1VLuE12IOlVBuVeKNrK1xAwVEPP8vP5R22SqI1UStrT~A1TG5mKNmNrg4NmPeji2UFqCrhGiHJ5pOw__",
      userName: "홍**",
      reviewGrade: "5.0",
      date: "YYY.MM.DD",
      content:
        "후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.",
    },
    {
      profileImg:
        "https://s3-alpha-sig.figma.com/img/acff/6544/4f82a071a6e351030a18db3661878d8b?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=d2O6lcK4N7Y2MuI~~QQZ1heehDaHQ0N9DeAiQ2GSIYu-rJ7WhhIz042duKmZsfKeJQUosNLjC4aK-UeW0wr22zMXwjvcWl1F64q2UioL42FU07kE6bDZM4kJsNsg3jyxHX9Gtdldufegrdfuif62YVd1~Jye25DCFWnTRF5naTifzZSwRq1ITPzy4x~vqvA6mJCWJJHlRDp04SGh7Zxi6ZL3Asy1Bnju-E~-lAh~ryRlqo1civO2NrY6N8RRgfIchc3pca~5eS-gTkrQEgz9FiL4VRSJuB-8O5IRO7b6CghFmLgAdjn2xDm1LXAW8CL8ReA8O7K3gMa40XDhfH167w__",
      userName: "홍**",
      reviewGrade: "5.0",
      date: "YYY.MM.DD",
      content:
        "후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.",
    },
    {
      profileImg:
        "https://s3-alpha-sig.figma.com/img/a591/f1bd/a035cd6d35b243976f3a02a0fcdde741?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Tyyna9NkCj0iTyHb-SL4XbUAlpAqvToE5wL~F9eyqSSL89KAlVBeJpqzB5BKXxiyNb56YuwSQJkooj2FGxnipam43nEqrzo5~wwH8j17d5NUzNd07XwxQnhN4WA78w~VbxuoVUx3pS9u97m1VjhllyPpNCAGlnaStlZH0pNjXvqEGDOy3-Y5aqk-K51r1GTjppd9uENgEQbXFxwpxdwxFS66z0HJPOc0sVGERYCtBb8uF9MV~OYpLNE5QqJIy5bMBz2l6bKhxpB~3K~eV9Q7DxBwXcrC~et2CdlH9uwaqfvNimJHQLzuke8Rkix2z5bErfU2vH40sT8A8VJpN2J4cA__",
      userName: "홍**",
      reviewGrade: "5.0",
      date: "YYY.MM.DD",
      content:
        "후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.",
    },
    {
      profileImg:
        "https://s3-alpha-sig.figma.com/img/b751/c865/b040ac53c4ab48d5ec4e594331a85b08?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UpzBumXF4I1lFNAR7SXsKHbFQl4B4p~VpQUCNokD4VFtn~e5CWoW3beeII0mrjV3CYW7m0JFpBluuDwyVPNd2RGkSlnunT0yMkspq9ObQk4lTuXyRLSYIGxhkvNEw~ghqaDmniGVQsqIfyWwI9lLn1lcIKAjQ3KC~NuVxekeaprls5qJTw6JeTtUnnVQ7PZWZEagu4ZHDD-ML5ZoFj-0~HNxT4FDwk4VncTnp43sIf5OrdpuK6QcyMcrYsK62KejCrBc12rXcfUpOFnVAg3ElJ78mpPFf-gKMPuDx-Rgae1vcBUe3xyonwgE39fObBIZs8Jk~vzPnpShdv~iWGAHJA__",
      userName: "홍**",
      reviewGrade: "5.0",
      date: "YYY.MM.DD",
      content:
        "후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다. 후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.후기 내용이 이곳에 들어갑니다.",
    },
  ];

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer style={{ minHeight: 650 + elementHeight }}>
      <Header />
      <BodyContainer>
        <DetailTopBanner src={serviceImgValue?.bannerUrl} />
        <PreviewContainer ref={elementRef}>
          <PreviewInnerContainer>
            <CompanyIcon
              $shadowOn
              style={{
                width: 120,
                height: 120,
              }}
              src={serviceImgValue?.iconUrl}
            />
            <PreviewCompanyInfoContainer>
              <div
                style={{
                  display: "flex",
                  gap: 20,
                  alignItems: "center",
                }}
              >
                {/* 별점 */}
                <ReviewGradeWrapper>
                  <ReviewGradeIcon $isChecked />
                  <ReviewGradeText>5.0</ReviewGradeText>
                </ReviewGradeWrapper>

                {/* 마케팅 종류, 지역 */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 6,
                  }}
                >
                  {/* 마케팅 종류 */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 3,
                    }}
                  >
                    <PenIcon />
                    <TypeText>
                      {serviceInputValue?.marketingType
                        ? serviceInputValue?.marketingType
                        : "마케팅 종류가 이곳에 들어갑니다."}
                    </TypeText>
                  </div>
                  {/* 지역 */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 3,
                    }}
                  >
                    <LocationIcon />
                    <TypeText>
                      {myInfo?.profile?.regionTitle
                        ? myInfo?.profile?.regionTitle
                        : "지역이 이곳에 들어갑니다."}
                    </TypeText>
                  </div>
                </div>
              </div>

              {/* 서비스명, 기업명 */}
              <PostTextWrapper>
                {/* 서비스명 */}
                <PretendardText
                  style={{
                    fontSize: 24,
                    fontWeight: 700,
                    lineHeight: "150%",
                    color: "#282828",
                  }}
                >
                  {serviceInputValue?.serviceName
                    ? serviceInputValue?.serviceName
                    : "서비스명이 이곳에 들어갑니다."}
                </PretendardText>

                {/* 기업명 */}
                <PretendardText
                  style={{
                    fontSize: 18,
                    fontWeight: 400,
                    lineHeight: "150%",
                    color: "#383838",
                  }}
                >
                  {myInfo?.profile?.companyName
                    ? myInfo?.profile?.companyName
                    : "기업명이 이곳에 들어갑니다."}
                </PretendardText>
              </PostTextWrapper>
            </PreviewCompanyInfoContainer>

            {/* 북마크 아이콘 */}
            <BookmarkIcon
              style={{
                position: "absolute",
                top: 140,
                right: 0,
              }}
              $isChecked={bookmarkStatus}
              onClick={() => {
                toggleBookmark();
              }}
            />

            {/* 예산, 집행 기간, 전화번호 영역 */}
            <div
              style={{
                width: 770,
                padding: 20,
                borderRadius: 6,
                gap: 20,
                display: "flex",
                alignItems: "center",
                backgroundColor: "#f8f9fa",
                marginTop: 30,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                {/* 예산 */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 18,
                      fontWeight: 700,
                      lineHeight: "150%",
                      color: "#939393",
                    }}
                  >
                    예산
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: 18,
                      fontWeight: 600,
                      lineHeight: "150%",
                      color: "#282828",
                    }}
                  >
                    {serviceInputValue?.budgetTitle}
                  </PretendardText>
                </div>

                <TextDivisionLine />

                {/* 집행 기간 */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 18,
                      fontWeight: 700,
                      lineHeight: "150%",
                      color: "#939393",
                    }}
                  >
                    집행 기간
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: 18,
                      fontWeight: 600,
                      lineHeight: "150%",
                      color: "#282828",
                    }}
                  >
                    {serviceInputValue?.executionPeriodTitle}
                  </PretendardText>
                </div>

                <TextDivisionLine />

                {/* 전화번호 */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 18,
                      fontWeight: 700,
                      lineHeight: "150%",
                      color: "#939393",
                    }}
                  >
                    전화번호
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: 18,
                      fontWeight: 600,
                      lineHeight: "150%",
                      color: "#282828",
                    }}
                  >
                    {myInfo?.profile?.tel
                      ? formatTelNumber(myInfo?.profile?.tel)
                      : "00-0000-0000"}
                  </PretendardText>
                </div>
              </div>
            </div>

            {/* 포트폴리오 영역 */}
            <SectionTitleWrapper>
              <SectionTitleText>포트폴리오</SectionTitleText>
              <SectionTitleCountText>
                {selectedPortfolioListValue.length}
              </SectionTitleCountText>
            </SectionTitleWrapper>

            {/* 포트폴리오 컨텐츠 */}
            <PortfolioContainer>
              {selectedPortfolioListValue.map((item, index) => (
                <CompanyList
                  key={index}
                  src={`${fileUrl}/${item.profileServerFileName}`}
                  pathHandler={() => {
                    scrollToTop();
                  }}
                  isShowBookmark
                  $isSmall
                  bookmarkHandler={(e) => {
                    e.stopPropagation();
                    togglePortfolioBookmark();
                  }}
                  $isChecked={portfolioBookmarkStatus}
                  serviceName={item?.title}
                  companyName={item?.member?.profile?.companyName}
                  marketingType={item?.marketingType?.title}
                  location={item?.member?.profile?.regionTitle}
                  reviewGrade={item?.reviewAverage}
                />
              ))}
            </PortfolioContainer>

            {/* 설명 영역 */}
            <DescriptionTitleContainer>
              <DescriptionTitleTextWrapper
                $isClicked={companyIntroductionState}
              >
                <DescriptionTitleText
                  $isClicked={companyIntroductionState}
                  onClick={() => {
                    handleSectionClick("companyIntroduction");
                    scrollToSection(companyIntroductionRef);
                  }}
                >
                  기업 소개
                </DescriptionTitleText>
              </DescriptionTitleTextWrapper>
              <DescriptionTitleTextWrapper $isClicked={marketingStrategyState}>
                <DescriptionTitleText
                  $isClicked={marketingStrategyState}
                  onClick={() => {
                    handleSectionClick("marketingStrategy");
                    scrollToSection(marketingStrategyRef);
                  }}
                >
                  마케팅 전략 소개
                </DescriptionTitleText>
              </DescriptionTitleTextWrapper>
              <DescriptionTitleTextWrapper
                $isClicked={budgetAndExecutionPeriodState}
              >
                <DescriptionTitleText
                  $isClicked={budgetAndExecutionPeriodState}
                  onClick={() => {
                    handleSectionClick("budgetAndExecutionPeriod");
                    scrollToSection(budgetAndExecutionPeriodRef);
                  }}
                >
                  예산 및 집행 기간
                </DescriptionTitleText>
              </DescriptionTitleTextWrapper>
              <DescriptionTitleTextWrapper $isClicked={businessLocationState}>
                <DescriptionTitleText
                  $isClicked={businessLocationState}
                  onClick={() => {
                    handleSectionClick("businessLocation");
                    scrollToSection(businessLocationRef);
                  }}
                >
                  사업장 위치
                </DescriptionTitleText>
              </DescriptionTitleTextWrapper>
              <DescriptionTitleTextWrapper $isClicked={frequentlyQnAState}>
                <DescriptionTitleText
                  $isClicked={frequentlyQnAState}
                  onClick={() => {
                    handleSectionClick("frequentlyQnA");
                    scrollToSection(frequentlyQnARef);
                  }}
                >
                  자주 묻는 질문
                </DescriptionTitleText>
              </DescriptionTitleTextWrapper>
              <DescriptionTitleTextWrapper $isClicked={reviewState}>
                <DescriptionTitleText
                  $isClicked={reviewState}
                  onClick={() => {
                    handleSectionClick("review");
                    scrollToSection(reviewRef);
                  }}
                >
                  후기
                </DescriptionTitleText>
              </DescriptionTitleTextWrapper>
            </DescriptionTitleContainer>

            {/* 기업 소개 영역 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                width: "100%",
                marginTop: 60,
              }}
            >
              <SectionTitleText ref={companyIntroductionRef}>
                기업 소개
              </SectionTitleText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  width: "100%",
                }}
              >
                <DescriptionContentText>
                  <div
                    className="ql-editor"
                    dangerouslySetInnerHTML={{
                      __html: myInfo?.profile?.intro,
                    }}
                  />
                </DescriptionContentText>
              </div>
            </div>

            {/* 마케팅 전략 소개 영역 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                width: "100%",
                marginTop: 60,
              }}
            >
              <SectionTitleText ref={marketingStrategyRef}>
                마케팅 전략 소개
              </SectionTitleText>
              <DescriptionContentText>
                {serviceInputValue.marketingStrategy
                  ? serviceInputValue.marketingStrategy
                  : ""}
              </DescriptionContentText>
              <DescriptionContentImg
                src={
                  serviceImgValue.detailPageUrl
                    ? serviceImgValue.detailPageUrl
                    : ""
                }
              />
            </div>

            {/* 예산 및 집행 기간 영역 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                width: "100%",
                marginTop: 60,
              }}
            >
              <SectionTitleText ref={budgetAndExecutionPeriodRef}>
                예산 및 집행 기간
              </SectionTitleText>
              <BudgetAndPeriodContentWrapper>
                <BudgetAndPeriodContentTitle>예산</BudgetAndPeriodContentTitle>
                <BudgetAndPeriodContentList>
                  <li>{serviceInputValue.budgetTitle}</li>
                </BudgetAndPeriodContentList>
              </BudgetAndPeriodContentWrapper>
              <BudgetAndPeriodContentWrapper>
                <BudgetAndPeriodContentTitle>
                  집행 기간
                </BudgetAndPeriodContentTitle>
                <BudgetAndPeriodContentList>
                  <li>{serviceInputValue.executionPeriodTitle}</li>
                </BudgetAndPeriodContentList>
              </BudgetAndPeriodContentWrapper>
            </div>

            {/* 사업장 위치 영역 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                width: "100%",
                marginTop: 60,
              }}
            >
              <SectionTitleText ref={businessLocationRef}>
                사업장 소재지
              </SectionTitleText>
              <DescriptionContentText>
                {myInfo?.profile?.companyRegion
                  ? myInfo?.profile?.companyRegion
                  : "사업장 소재지가 이곳에 들어갑니다."}
              </DescriptionContentText>

              {/* 주소 API 들어갈 공간 */}
              <DescriptionContentImg
                style={{
                  height: 380,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                지도가 이곳에 들어갑니다.
              </DescriptionContentImg>
            </div>

            {/* 자주 묻는 질문 영역 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                width: "100%",
                marginTop: 60,
              }}
            >
              <SectionTitleText ref={frequentlyQnARef}>
                자주 묻는 질문
              </SectionTitleText>

              <QnAContentContainer>
                <PaginatedItems
                  $items={serviceFaqList}
                  $itemsPerPage={5}
                  style={{ gap: 20 }}
                >
                  {(item, index) => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                          width: "100%",
                          padding: "20px 0",
                          borderBottom: "1px solid #b7b7b7",
                        }}
                        onClick={() => {
                          toggleItem(index);
                        }}
                      >
                        <QnAContentTitle>{item.title}</QnAContentTitle>
                        <DownArrowIcon $isRotated={rotatedItems[index]} />
                      </div>
                      <QnAContentText
                        className="scrollbar"
                        style={{
                          display: rotatedItems[index] ? "block" : "none",
                        }}
                      >
                        {item.content}
                      </QnAContentText>
                    </>
                  )}
                </PaginatedItems>
              </QnAContentContainer>
            </div>

            {/* 후기 영역 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginTop: 54,
              }}
            >
              <SectionTitleText ref={reviewRef}>후기</SectionTitleText>

              <ReviewContentContainer>
                <PaginatedItems
                  $items={reviewData}
                  $itemsPerPage={5}
                  style={{ gap: 20 }}
                >
                  {(item, index) => (
                    <ReviewContentWrapper key={index}>
                      <ReviewTopWrapper>
                        <ProfileIcon
                          src={item.profileImg}
                          style={{
                            width: 50,
                            height: 50,
                          }}
                        />
                        <ReviewTopTextWrapper>
                          <ReviewUserName>{item.userName}</ReviewUserName>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 5,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 5,
                              }}
                            >
                              <ReviewGradeIcon
                                $isChecked={true}
                                style={{ width: 14, height: 14 }}
                              />
                              <ReviewGradeText $isSmall>
                                {item.reviewGrade}
                              </ReviewGradeText>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <PretendardText
                                style={{
                                  display: "inline-block",
                                  fontSize: 12,
                                  fontWeight: 400,
                                  lineHeight: "130%",
                                  color: "#c0c0c0",
                                }}
                              >
                                ㅣ
                              </PretendardText>
                              <PretendardText
                                style={{
                                  display: "inline-block",
                                  fontSize: 12,
                                  fontWeight: 400,
                                  lineHeight: "130%",
                                  color: "#939393",
                                }}
                              >
                                {item.date}
                              </PretendardText>
                            </div>
                          </div>
                        </ReviewTopTextWrapper>
                      </ReviewTopWrapper>
                      <PretendardText
                        style={{
                          width: "100%",
                          fontSize: 16,
                          color: "#282828",
                          lineHeight: "25px",
                          marginTop: 15,
                          fontWeight: 400,
                        }}
                      >
                        {item.content}
                      </PretendardText>
                    </ReviewContentWrapper>
                  )}
                </PaginatedItems>
              </ReviewContentContainer>
            </div>
          </PreviewInnerContainer>

          {/* 결제 영역 */}
          <PaymentContainer>
            <PaymentInnerContainer>
              <PaymentWrapper>
                <PaymentInnerWrapper>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <PaymentPrice>
                      {serviceInputValue.price
                        ? serviceInputValue.price
                        : "000,000"}
                      원
                    </PaymentPrice>
                    <PaymentDivisionLine />
                    <WorkingDayWrapper>
                      <WorkingDayText>작업일</WorkingDayText>
                      <WorkingDayText>
                        {serviceInputValue.executionPeriod
                          ? serviceInputValue.executionPeriodTitle
                          : "0일"}
                      </WorkingDayText>
                    </WorkingDayWrapper>
                  </div>
                  <ButtonWrapper>
                    <PrimaryBtn $isSmall={true}>바로 결제하기</PrimaryBtn>
                    <SecondaryBtn $isSmall={true}>1:1 채팅하기</SecondaryBtn>
                  </ButtonWrapper>
                </PaymentInnerWrapper>
              </PaymentWrapper>
              <PretendardText
                style={{
                  fontSize: 14,
                  color: "#939393",
                  lineHeight: "140%",
                  fontWeight: 600,
                }}
              >
                *서비스 제공이 완료된 이후 전문가에게 결제 대금이 전달됩니다.
              </PretendardText>
              <MoveToBackBtn
                onClick={() => {
                  navigate(-1);
                }}
              >
                뒤로가기
              </MoveToBackBtn>
            </PaymentInnerContainer>
          </PaymentContainer>
        </PreviewContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default Preview;

const PreviewContainer = styled.div`
  position: absolute;
  top: 275px;
  width: 1200px;
  display: flex;
  gap: 30px;
`;

const PreviewInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 790px;
  position: relative;
`;

const PreviewCompanyInfoContainer = styled.div`
  width: 765px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const PostTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ReviewGradeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ReviewGradeText = styled(PretendardText)`
  font-weight: 700;
  font-size: ${(props) => (props.$isSmall ? "12px" : "20px")};
  line-height: ${(props) => (props.$isSmall ? "130%" : "145%")};
  color: #282828;
`;

const TypeText = styled(PretendardText)`
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  color: #6f6f6f;
`;

const PaymentContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const PaymentInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: sticky;
  margin-top: 140px;
  top: 140px;
`;

const PaymentWrapper = styled.div`
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 20px;
  width: 380px;
  background-color: #fff;
  box-shadow: 0px 4px 15px 0px rgba(132, 132, 132, 0.25);
`;

const PaymentInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const PaymentPrice = styled(PretendardText)`
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
  color: #282828;
`;

const PaymentDivisionLine = styled.div`
  width: 340px;
  height: 1px;
  background-color: #e9e9e9;
`;

const WorkingDayWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WorkingDayText = styled(PretendardText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: #939393;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TextDivisionLine = styled.span`
  width: 2px;
  height: 20px;
  background-color: #e9e9e9;
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 30px;
`;

const SectionTitleText = styled(PretendardText)`
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
  color: #282828;
`;

const SectionTitleCountText = styled(SectionTitleText)`
  color: #c0c0c0;
`;

const PortfolioContainer = styled.div`
  display: flex;
  width: 790px;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 11px;
`;

const DescriptionTitleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 40px;
  margin-top: 56.15px;
  width: 770px;
  border-bottom: 1px solid #939393;
`;

const DescriptionTitleTextWrapper = styled.div`
  border-bottom: ${(props) =>
    props.$isClicked ? "2px solid #2ec5f6" : "none"};
  padding: 20px 0;
  height: 67px;
`;

const DescriptionTitleText = styled(PretendardText)`
  font-size: 18px;
  font-weight: ${(props) => (props.$isClicked ? 700 : 600)};
  line-height: 150%;
  color: ${(props) => (props.$isClicked ? "#282828" : "#939393")};
  cursor: pointer;
`;

const DescriptionContentText = styled(PretendardText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: #383838;
  width: 100%;
`;

const DescriptionContentImg = styled.div`
  background-image: url(${(props) => props.src});
  background-color: #d9d9d9;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 721px;
`;

const BudgetAndPeriodContentWrapper = styled.div`
  width: 770px;
`;

const BudgetAndPeriodContentTitle = styled(PretendardText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: #383838;
`;

const BudgetAndPeriodContentList = styled.ul`
  color: #383838;
  padding-left: 0;

  li {
    position: relative;
    padding-left: 26px;
    line-height: 145%;
    font-size: 16px;
    font-weight: 400;

    &::before {
      content: "";
      position: absolute;
      left: 11px;
      top: 0.5em;
      width: 5px;
      height: 5px;
      background-color: #383838;
      border-radius: 999px;
      margin-top: 3px;
    }
  }
`;

const QnAContentContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-height: 325px;
  margin-bottom: 40px;
  gap: 20px;
`;

const QnAContentTitle = styled(PretendardText)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 756px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #383838;
`;

const QnAContentText = styled(PretendardText)`
  width: 100%;
  font-size: 16px;
  background-color: #f8f9fa;
  font-weight: 400;
  border-bottom: 1px solid #e9e9e9;
  color: #282828;
  height: auto;
  max-height: 115px;
  margin-top: 0;
  padding: 20px 30px;
  overflow-y: auto;
  white-space: normal;
  word-break: break-word;
  line-height: 25px;
`;

const ReviewContentContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ReviewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 20px;
`;

const ReviewTopWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const ReviewTopTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  gap: 5px;
`;

const ReviewUserName = styled(PretendardText)`
  font-size: 16px;
  font-weight: 700;
  line-height: 145%;
  color: #282828;
`;

const MoveToBackBtn = styled.button`
  width: 175px;
  background: #fff;
  color: #000;
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 11px;
  cursor: pointer;
  &:focus {
    outline: 2px solid #d9d9d9;
  }
`;
