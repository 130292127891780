import React from "react";
import styled from "styled-components";

const PersonIcon = (props) => {
  return (
    <PersonIconSvg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 9C11.2091 9 13 7.20914 13 5C13 2.79086 11.2091 1 9 1C6.79086 1 5 2.79086 5 5C5 7.20914 6.79086 9 9 9Z"
        fill="#C0C0C0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 10C6.33 10 1 11.34 1 14V16C1 16.55 1.45 17 2 17H16C16.55 17 17 16.55 17 16V14C17 11.34 11.67 10 9 10Z"
        fill="#C0C0C0"
      />
    </PersonIconSvg>
  );
};

export default PersonIcon;

const PersonIconSvg = styled.svg``;
