export const marketingTypeInfo = [
  {
    title: "콘텐츠 마케팅",
    content: (
      <>
        SNS 채널을 운영하며 콘텐츠를 등록하고,
        <br />
        고객이 이를 보고 찾아오게 만듭니다.
      </>
    ),
    hashTag: ["인스타그램", "페이스북"],
    src: "/assets/imgs/search/contentMarketing.png",
  },
  {
    title: "네이버 검색광고",
    content: (
      <>
        ‘강서구 맛집’, ‘여의도 맛집’ 등 검색어 입력 시
        <br />
        업체 정보가 상위에 노출되도록 설정합니다.
      </>
    ),
    hashTag: ["SEO 최적화", "네이버 검색광고"],
    src: "/assets/imgs/search/naverSearchAd.png",
  },
  {
    title: "블로그 체험단",
    content: (
      <>
        검색어 입력 시 블로그 체험단 리뷰글을 통해
        <br />
        업체의 정보와 후기를 공유합니다.
      </>
    ),
    hashTag: ["블로그 체험단", "후기 작성"],
    src: "/assets/imgs/search/blogExperience.png",
  },
  {
    title: "블로그 마케팅",
    content: (
      <>
        블로그에 제품 및 서비스 관련 글을
        <br />
        작성하여 자연스럽게 홍보합니다.
      </>
    ),
    hashTag: ["블로그", "리뷰 및 홍보"],
    src: "/assets/imgs/search/blogMarketing.png",
  },
  {
    title: "스마트 플레이스",
    content: (
      <>
        네이버 지도에 사업장 정보를 등록하여
        <br />
        검색어 입력 시 나의 사업장을 노출합니다.
      </>
    ),
    hashTag: ["네이버 지도", "추천 장소"],
    src: "/assets/imgs/search/smartPlace.png",
  },
  {
    title: "유튜브 마케팅",
    content: (
      <>
        유튜브에 홍보 영상을 올려 잠재 고객에게
        <br />
        도달합니다.
      </>
    ),
    hashTag: ["유튜브", "알고리즘"],
    src: "/assets/imgs/search/youtubeMarketing.png",
  },
  {
    title: "바이럴 마케팅",
    content: (
      <>
        SNS에서 입소문을 통해 빠르고 자연스러운
        <br />
        홍보를 유도합니다.
      </>
    ),
    hashTag: ["인스타그램", "페이스북"],
    src: "/assets/imgs/search/viralMarketing.png",
  },
  {
    title: "유튜브 체험단",
    content: (
      <>
        검색어 입력 시 유튜버를 통해 제품 체험 리뷰
        <br />
        영상을 공유합니다.
      </>
    ),
    hashTag: ["유튜브", "후기 영상"],
    src: "/assets/imgs/search/youtubeExperience.png",
  },
  {
    title: "리뷰 체험단",
    content: (
      <>
        소비자가 제품 체험 후 블로그나 리뷰 사이트에
        <br />
        후기를 작성하면 해당 내용으로 홍보합니다.
      </>
    ),
    hashTag: ["체험 후기", "고객 참여"],
    src: "/assets/imgs/search/reviewExperience.png",
  },
  {
    title: "SNS 체험단",
    content: (
      <>
        SNS 인플루언서를 통해 제품 체험 후기를
        <br />
        게시글이나 영상으로 공유합니다.
      </>
    ),
    hashTag: ["인스타그램", "페이스북"],
    src: "/assets/imgs/search/snsExperience.png",
  },
  {
    title: "퍼포먼스 마케팅",
    content: (
      <>
        클릭, 전환 등의 성과를 측정하고 알맞은
        <br />
        광고비를 지출합니다.
      </>
    ),
    hashTag: ["데이터 분석", "성과 확인"],
    src: "/assets/imgs/search/performanceMarketing.png",
  },
  {
    title: "배너 광고",
    content: (
      <>
        웹사이트에 배너 이미지를 통해 광고를
        <br />
        노출합니다
      </>
    ),
    hashTag: ["광고 배너", "웹사이트"],
    src: "/assets/imgs/search/bannerAd.png",
  },
  {
    title: "당근마켓 광고",
    content: (
      <>
        중고 거래 플랫폼에서 지역 기반 광고를
        <br />
        게시합니다.
      </>
    ),
    hashTag: ["중고거래 APP", "위치 기반"],
    src: "/assets/imgs/search/carrotAd.png",
  },
];
