import React from "react";
import styled from "styled-components";

const MontserratText = (props) => {
  return <SuiteTextWrapper {...props} />;
};

export default MontserratText;

const SuiteTextWrapper = styled.div`
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  display: block;
  word-break: keep-all;
  white-space: pre-wrap;
  /* white-space: nowrap; */
`;
