const route = {
  default: "/",

  login: "/login",
  findEmail: "/findEmail",
  findPw: "/findPw",

  register: "/register",
  register_common: "/register/common",
  register_company: "/register/company",

  notice: "/notice",
  qna: "/qna",
  curation: "/curation",
  around: "/around",
  search: "/search",
  chat: "/chat",
  payment: "/payment",
  service: "/service",
  serviceDetail: "/service/detail",
  serviceEdit: "/service/edit",
  addService: "/addService",
  addQnA: "/QnA/addQnA",
  editQnA: "/QnA/editQnA",
  serviceQnAList: "/QnA/List",
  preview: "/preview",
  previewEdit: "/previewEdit",
  notFound: "*", // 404
  testPage: "testPage",
  uiPage: "uiPage",

  myPage: "/myPage",
  myPage_common: "/myPage/common",
  myPage_common_info: "/myPage/common/info",
  myPage_common_scrap: "/myPage/common/scrap",
  myPage_common_myReview: "/myPage/common/myReview",
  myPage_common_myReview_detail: "/myPage/common/myReview/detail",
  myPage_common_paymentRecords: "/myPage/common/paymentRecords",
  myPage_common_paymentRecords_detail: "/myPage/common/paymentRecords/detail",
  myPage_common_chat: "/myPage/common/chat",
  myPage_common_ask: "/myPage/common/ask",
  myPage_common_ask_detail: "/myPage/common/ask/detail",
  myPage_common_ask_create: "/myPage/common/ask/create",

  myPage_company_info: "/myPage/company/info",
  myPage_company_alarm: "/myPage/company/alarm",
  myPage_company_account: "/myPage/company/account",
  myPage_company_pay: "/myPage/company/pay",
  myPage_company_chat: "/myPage/company/chat",
  myPage_company_ask: "/myPage/company/ask",
  myPage_company_ask_detail: "/myPage/company/ask/detail",
  myPage_company_ask_create: "/myPage/company/ask/create",
};

export default route;
