import { atom } from "recoil";

export const initialMyPageInputValues = {
  email: "",
  name: "",
  address: "",
  phoneNumber: "",
  profile: {
    companyName: "",
    regionTitle: "",
    companyRegion: "",
    companyNumber: "",
    ceo: "",
    tel: "",
    intro: "",
  },
};

export const myPageInputValueState = atom({
  key: "myPageInputValueState",
  default: initialMyPageInputValues,
});
