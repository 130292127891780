import React from "react";
import styled from "styled-components";

const InputLabel = (props) => {
  return <StyledInputLabel {...props}>{props.children}</StyledInputLabel>;
};

export default InputLabel;

const StyledInputLabel = styled.label`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 145%;
  text-align: left;
  display: flex;
  align-items: center;
  word-break: keep-all;
  white-space: nowrap;
  margin-bottom: 9px;
`;
