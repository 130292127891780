import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PageContainer from "../../components/molecules/layout/PageContainer";
import BodyContainer from "../../components/molecules/layout/BodyContainer";
import Header from "../../components/organisms/Header";
import Footer from "../../components/organisms/Footer";
import Title from "../../components/atoms/Title";
import PretendardText from "../../components/atoms/PretendardText";
import PaymentCheckBox from "./../../components/molecules/PaymentCheckBox";
// import NaverPayIcon from "../../components/atoms/NaverPayIcon";
// import KakaoPayIcon from "../../components/atoms/KakaoPayIcon";
// import TossIcon from "../../components/atoms/TossIcon";
import ReviewGradeIcon from "./../../components/atoms/icons/ReviewGradeIcon";
import CompanyIcon from "../../components/atoms/icons/CompanyIcons";
import PenIcon from "./../../components/atoms/icons/PenIcon";
import LocationIcon from "./../../components/atoms/icons/LocationIcon";
import RadioIcon from "../../components/atoms/icons/RadioIcon";
import CheckboxIcon from "./../../components/atoms/icons/CheckboxIcon";
import PrimaryBtn from "./../../components/atoms/buttons/PrimaryBtn";
import { deleteStorage, getStorage } from "../../lib/utils/sessionStorage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { myInfoValueState } from "../../store/myInfo";
import ServiceApi from "../../lib/api/service.api";
import { fileUrl, url } from "./../../lib/api/api";
import { formatNumberWithCommas } from "./../../lib/utils/formatNumber";
import Alert from "../../components/molecules/Alert";
import axios from "axios";
import TermsModal from "../../components/molecules/termsModal";
import route from "../../router/route";
import scrollToTop from "../../lib/utils/scrollToTop";

const Payment = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const location = useLocation();
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //

  // 기업 회원일 경우 홈으로 이동
  useEffect(() => {
    if (myInfo?.profile) {
      return navigate("/", { replace: true });
    }

    getTokens();

    getTermsData();
  }, [myInfo]); // eslint-disable-line

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    if (token !== "") {
      getServiceInfo();
    }
  }, [token, location]); // eslint-disable-line

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // --------------------------------------------- //
  // ---------- 데이터 가져오기 ---------- //
  // ------------------------------------------- //
  const [serviceInfo, setServiceInfo] = useState({});
  const [marketingTypeInfo, setMarketingTypeInfo] = useState({});
  const [profileInfo, setProfileInfo] = useState({});
  const [accountInfo, setAccountInfo] = useState({});

  const getServiceInfo = async () => {
    try {
      const response = (await ServiceApi.getById(id)).data.data.content;

      if (response.activation === false) {
        return navigate(-1, { replace: true }); // 비활성화된 서비스일 경우 이전 페이지로 이동
      } else {
        setServiceInfo(response);
        setMarketingTypeInfo(response.marketingType);
        setProfileInfo(response.member.profile);
      }

      const accountResponse = await axios.get(`${url}common/account`);
      setAccountInfo(accountResponse.data.data.content);
    } catch (error) {
      console.error(error);
    }
  };

  // ------------------------------------ //
  // ---------- 결제수단 관련 ---------- //
  // ---------------------------------- //

  // 결제수단 라디오 버튼 관련 상태
  const [selectedRadioValue, setSelectedRadioValue] = useState("");

  // 결제수단 라디오 버튼 변경 핸들러
  const handleRadioChange = (e) => {
    setSelectedRadioValue(e.target.value);
  };

  // ------------------------------------ //
  // ---------- 체크박스 관련 ---------- //
  // ---------------------------------- //

  // 체크박스 관련 상태
  const initialCheckedValues = {
    taxReceipt: false, // 세금계산서 발행 여부
    allAgree: false, // 약관 전체 동의
    orderAndPayAgree: false, // 주문내용 확인 및 결제 동의
    refundAndCancelAgree: false, // 환불/취소 관련 동의
  };

  const [checkboxValues, setCheckboxValues] = useState(initialCheckedValues);

  // 체크박스 변경 핸들러
  const handleCheckboxChange = (e) => {
    setCheckboxValues({
      ...checkboxValues,
      [e.target.name]: !checkboxValues[e.target.name],
    });
  };

  //  전체 동의 체크박스 변경 핸들러
  const handleAllAgreeCheckboxChange = (checked) => {
    setCheckboxValues({
      taxReceipt: checkboxValues.taxReceipt,
      allAgree: checked,
      orderAndPayAgree: checked,
      refundAndCancelAgree: checked,
    });
  };

  // 전체 동의 체크박스가 변경될 때, 필수 동의 체크박스도 변경
  useEffect(() => {
    const { orderAndPayAgree, refundAndCancelAgree } = checkboxValues;
    const allChecked = orderAndPayAgree && refundAndCancelAgree;
    setCheckboxValues((prev) => ({
      ...prev,
      allAgree: allChecked,
    }));
  }, [checkboxValues.orderAndPayAgree, checkboxValues.refundAndCancelAgree]); // eslint-disable-line

  // ------------------------------- //
  // ---------- 결제하기 ---------- //
  // ----------------------------- //
  const [paymentSubmit, setPaymentSubmit] = useState(false);

  const handlePayment = async () => {
    if (!selectedRadioValue) {
      setAlertMessage("결제수단을 선택해주세요.");
      return setAlertIsOpen(true);
    }

    if (!checkboxValues.orderAndPayAgree) {
      setAlertMessage("주문내용 확인 및 결제 동의를 확인해주세요.");
      return setAlertIsOpen(true);
    }

    if (!checkboxValues.refundAndCancelAgree) {
      setAlertMessage("환불/취소 관련 동의를 확인해주세요.");
      return setAlertIsOpen(true);
    }

    try {
      const data = {
        price: serviceInfo.price,
        charge: serviceInfo.price * 0.1,
        amount: serviceInfo.price * 0.9,
        payMethod: "BANK",
        taxReceiptFlag: checkboxValues.taxReceipt,
        payId: "결제플랫폼 아이디", // 추후 수정 필요
      };

      await axios.post(`${url}member/payment/${id}`, data, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      setPaymentSubmit(true);
      scrollToTop();
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        if (error.response.statusText === "Unauthorized") {
          deleteStorage("token");
          navigate(route.login + "?refresh=true");
          alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
        }
      }
    }
  };

  // ---------- 알림창 관련 ---------- //

  // ---------- 약관 관련 ---------- //
  const [termsData, setTermsData] = useState({});

  const getTermsData = async () => {
    try {
      const response = await axios.get(`${url}terms/${3}`);
      setTermsData(response.data.data.content);
    } catch (error) {
      console.error(error);
      setAlertIsOpen(true);
      setAlertMessage("잠시 후 다시 시도해주세요.");
    }
  };

  // -------------------------------- //
  // ---------- 모달 관련 ---------- //
  // ------------------------------ //

  // 모달 애니메이션 상태
  const [isAnimating, setIsAnimating] = useState(false);

  // 환불/취소 관련 동의 모달
  const [isRefundAndCancelAgreeOpen, setIsRefundAndCancelAgreeOpen] =
    useState(false);

  const openRefundAndCancelAgreeModal = () => {
    setIsRefundAndCancelAgreeOpen(true);
  };

  const closeRefundAndCancelAgreeModal = () => {
    setIsAnimating(true);
    setTimeout(() => {
      // 300ms 후 모달 상태 변경
      setIsRefundAndCancelAgreeOpen(false);
      setIsAnimating(false);
    }, 300);
  };

  // ---------------------------------- //
  // ---------- 알림창 관련 ---------- //
  // -------------------------------- //
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleAlertClose = () => {
    setAlertIsOpen(false);
  };

  return (
    <PageContainer style={{ minHeight: 1400 }}>
      <Header />
      <BodyContainer style={{ backgroundColor: "#fafafa" }}>
        <PaymentContainer>
          {!paymentSubmit && (
            <>
              <PaymentInnerContainer>
                <Title
                  style={{
                    fontWeight: 700,
                    marginBottom: 30,
                  }}
                >
                  결제하기
                </Title>

                {/* 주문정보 영역 */}
                <PaymentContentContainer>
                  <PaymentTitleText>주문정보</PaymentTitleText>
                  <PaymentDivisionLine />
                  <PaymentProductContainer>
                    <CompanyIcon
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      src={`${fileUrl}/${serviceInfo.profileServerFileName}`}
                    />
                    <PaymentProductTextWrapper>
                      {/* 기업명 */}
                      <PretendardText
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          lineHeight: "145%",
                          color: "#282828",
                        }}
                      >
                        {profileInfo.companyName}
                      </PretendardText>

                      {/* 마케팅 종류, 지역 */}
                      <div
                        style={{
                          display: "flex",
                          gap: 20,
                          alignItems: "center",
                        }}
                      >
                        <TypeWrapper>
                          {/* 마케팅 종류 */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 3,
                            }}
                          >
                            <PenIcon />
                            <TypeText>
                              {" "}
                              {marketingTypeInfo.activation
                                ? marketingTypeInfo.title
                                : ""}
                            </TypeText>
                          </div>
                          {/* 지역 */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 3,
                            }}
                          >
                            <LocationIcon />
                            <TypeText>{profileInfo.regionTitle}</TypeText>
                          </div>
                        </TypeWrapper>
                        {/* 평점 */}
                        <ReviewGradeWrapper>
                          <ReviewGradeIcon
                            $isChecked
                            style={{
                              width: 18,
                              height: 18,
                            }}
                          />
                          <ReviewGradeText>
                            {serviceInfo.reviewAverage}
                          </ReviewGradeText>
                        </ReviewGradeWrapper>
                      </div>
                    </PaymentProductTextWrapper>
                  </PaymentProductContainer>

                  {/* 작업일, 금액 영역 */}
                  <PaymentBoxContentContainer>
                    <PaymentBoxContentWrapper>
                      <PaymentBoxContentText>작업일</PaymentBoxContentText>
                      <PaymentBoxContentText>
                        {serviceInfo.periodTitle}
                      </PaymentBoxContentText>
                    </PaymentBoxContentWrapper>
                    <PaymentBoxContentWrapper>
                      <PaymentBoxContentText>금액</PaymentBoxContentText>
                      <PaymentBoxContentText>
                        {formatNumberWithCommas(serviceInfo.price)}원
                      </PaymentBoxContentText>
                    </PaymentBoxContentWrapper>
                  </PaymentBoxContentContainer>
                </PaymentContentContainer>

                {/* 결제수단 영역 */}
                <PaymentContentContainer>
                  <PaymentTitleText>결제수단</PaymentTitleText>
                  <PaymentDivisionLine />
                  <PaymentRadioWrapper>
                    {/* <PaymentRadioBoxLabel>
                  <RadioIcon checked={selectedRadioValue === "카드결제"} />
                  <PaymentRadioBoxInput
                    type="radio"
                    value="카드결제"
                    checked={selectedRadioValue === "카드결제"}
                    onChange={handleRadioChange}
                  />
                  카드결제
                </PaymentRadioBoxLabel>
                <PaymentRadioBoxLabel>
                  <RadioIcon checked={selectedRadioValue === "휴대폰 결제"} />
                  <PaymentRadioBoxInput
                    type="radio"
                    value="휴대폰 결제"
                    checked={selectedRadioValue === "휴대폰 결제"}
                    onChange={handleRadioChange}
                  />
                  휴대폰 결제
                </PaymentRadioBoxLabel> */}
                    <PaymentRadioBoxLabel>
                      <RadioIcon
                        checked={selectedRadioValue === "무통장 입금"}
                      />
                      <PaymentRadioBoxInput
                        type="radio"
                        value="무통장 입금"
                        checked={selectedRadioValue === "무통장 입금"}
                        onChange={handleRadioChange}
                      />
                      무통장 입금
                    </PaymentRadioBoxLabel>
                  </PaymentRadioWrapper>
                  {/* <PaymentRadioWrapper style={{ marginTop: 15 }}>
                <PaymentRadioBoxLabel>
                  <RadioIcon checked={selectedRadioValue === "네이버페이"} />
                  <PaymentRadioBoxInput
                    type="radio"
                    value="네이버페이"
                    checked={selectedRadioValue === "네이버페이"}
                    onChange={handleRadioChange}
                  />
                  <NaverPayIcon />
                  네이버페이
                </PaymentRadioBoxLabel>
                <PaymentRadioBoxLabel>
                  <RadioIcon checked={selectedRadioValue === "카카오페이"} />
                  <PaymentRadioBoxInput
                    type="radio"
                    value="카카오페이"
                    checked={selectedRadioValue === "카카오페이"}
                    onChange={handleRadioChange}
                  />
                  <KakaoPayIcon />
                  카카오페이
                </PaymentRadioBoxLabel>
                <PaymentRadioBoxLabel>
                  <RadioIcon checked={selectedRadioValue === "토스페이"} />
                  <PaymentRadioBoxInput
                    type="radio"
                    value="토스페이"
                    checked={selectedRadioValue === "토스페이"}
                    onChange={handleRadioChange}
                  />
                  <TossIcon />
                  토스페이
                </PaymentRadioBoxLabel>
              </PaymentRadioWrapper> */}
                  <div
                    style={{
                      width: "100%",
                      height: "fit-content",
                      borderRadius: 10,
                      padding: 15,
                      display: "flex",
                      flexDirection: "column",
                      gap: 5,
                      backgroundColor: "#f8f9fa",
                      marginTop: 10,
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: "19.6px",
                        color: "#282828",
                      }}
                    >
                      무통장 입금 계좌 안내
                    </PretendardText>
                    <PretendardText
                      style={{
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: "19.6px",
                        color: "#6f6f6f",
                      }}
                    >
                      {accountInfo.bankName} {accountInfo.accountNumber}
                      <br />
                      예금주명 {accountInfo.accountName}
                    </PretendardText>
                  </div>
                </PaymentContentContainer>

                {/* 세금계산서 발행 영역 */}
                <PaymentContentContainer style={{ marginBottom: 0 }}>
                  <PaymentTitleText>세금계산서 발행</PaymentTitleText>
                  <PaymentDivisionLine />
                  <PaymentBoxCheckboxWrapper>
                    <CheckboxIcon
                      checked={checkboxValues.taxReceipt}
                      onClick={() => {
                        setCheckboxValues({
                          ...checkboxValues,
                          taxReceipt: !checkboxValues.taxReceipt,
                        });
                      }}
                    />
                    <PaymentCheckBox
                      id="taxReceipt"
                      value="세금계산서 발행 신청"
                      checked={checkboxValues.taxReceipt}
                      onChange={(e) => {
                        handleCheckboxChange(e);
                      }}
                    >
                      신청합니다.
                    </PaymentCheckBox>
                  </PaymentBoxCheckboxWrapper>
                  <PretendardText
                    style={{
                      fontSize: 12,
                      color: "#939393",
                      fontWeight: 400,
                      lineHeight: "130%",
                      marginTop: 10,
                    }}
                  >
                    *주문 금액에 대한 세금계산서는 거래 주체인 전문가가 직접
                    발행합니다.
                    <br />
                    *수수료에 대한 세금계산서는 크몽이 발행하며, 결제 단계에서
                    별도로 신청하실 수 있습니다.
                    <br />
                    *세금계산서는 구매 확정일(거래 완료일)을 기준으로
                    발행됩니다.
                    <br />
                    *무통장입금 선택 시 신청한 '현금영수증'은 매입세액공제가
                    불가합니다. 매입세액공제는 세금계산서를 신청하여 처리해
                    주세요.
                  </PretendardText>
                </PaymentContentContainer>
              </PaymentInnerContainer>

              {/* 결제 영역 */}
              <PaymentBoxContainer>
                <PaymentBox>
                  <PaymentBoxWrapper
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: 10,
                      backgroundColor: "#fff",
                      padding: 20,
                    }}
                  >
                    <PaymentBoxTitle>결제정보</PaymentBoxTitle>
                    <PaymentBoxDivisionLine />
                    <PaymentBoxContentContainer>
                      <PaymentBoxContentWrapper>
                        <PaymentBoxContentText>주문금액</PaymentBoxContentText>
                        <PaymentBoxContentText>
                          {formatNumberWithCommas(serviceInfo.price * 0.85)}원
                        </PaymentBoxContentText>
                      </PaymentBoxContentWrapper>
                      <PaymentBoxContentWrapper>
                        <PaymentBoxContentText>수수료</PaymentBoxContentText>
                        <PaymentBoxContentText>
                          {formatNumberWithCommas(serviceInfo.price * 0.15)}원
                        </PaymentBoxContentText>
                      </PaymentBoxContentWrapper>
                    </PaymentBoxContentContainer>
                    <PaymentBoxDivisionLine />
                    <PaymentBoxContentWrapper>
                      <PaymentBoxContentText
                        style={{
                          fontWeight: 600,
                          color: "#383838",
                        }}
                      >
                        총 결제금액
                      </PaymentBoxContentText>
                      <PaymentBoxPrice>
                        {formatNumberWithCommas(
                          serviceInfo.price * 0.9 + serviceInfo.price * 0.1
                        )}
                        원
                      </PaymentBoxPrice>
                    </PaymentBoxContentWrapper>
                    <PaymentBoxCheckboxContainer>
                      <PaymentBoxCheckboxWrapper>
                        <PaymentCheckBox
                          $isBold={true}
                          id="allAgree"
                          value="약관 전체 동의함"
                          checked={checkboxValues.allAgree}
                          onChange={(e) =>
                            handleAllAgreeCheckboxChange(e.target.checked)
                          }
                        >
                          <CheckboxIcon
                            style={{ width: 16, height: 16 }}
                            checked={checkboxValues.allAgree}
                          />
                          약관 전체 동의
                        </PaymentCheckBox>
                      </PaymentBoxCheckboxWrapper>
                      <PaymentBoxCheckboxWrapper>
                        <PaymentCheckBox
                          $isRequired
                          id="orderAndPayAgree"
                          value="주문내용 확인 및 결제 동의함"
                          checked={checkboxValues.orderAndPayAgree}
                          onChange={(e) => {
                            handleCheckboxChange(e);
                          }}
                        >
                          <CheckboxIcon
                            style={{ width: 16, height: 16 }}
                            checked={checkboxValues.orderAndPayAgree}
                          />
                          주문내용 확인 및 결제 동의(필수)
                        </PaymentCheckBox>
                      </PaymentBoxCheckboxWrapper>
                      <PaymentBoxCheckboxWrapper>
                        <PaymentCheckBox
                          $isRequired
                          id="refundAndCancelAgree"
                          value="주문내용 확인 및 결제 동의함"
                          checked={checkboxValues.refundAndCancelAgree}
                          onChange={(e) => {
                            handleCheckboxChange(e);
                          }}
                        >
                          <CheckboxIcon
                            style={{ width: 16, height: 16 }}
                            checked={checkboxValues.refundAndCancelAgree}
                          />
                          {termsData?.title
                            ? `${termsData?.title} 동의(필수)`
                            : "환불/취소 관련 동의(필수)"}
                        </PaymentCheckBox>
                        <PretendardText
                          style={{
                            color: "#939393",
                            fontSize: 16,
                            fontWeight: 400,
                            lineHeight: "145%",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={openRefundAndCancelAgreeModal}
                        >
                          전체보기
                        </PretendardText>
                        {/* 개인정보 제 3자 제공 모달 영역 */}
                        {isRefundAndCancelAgreeOpen && (
                          <TermsModal
                            $termsId={3}
                            $isOpen={!isAnimating}
                            handleClose={closeRefundAndCancelAgreeModal}
                          />
                        )}
                      </PaymentBoxCheckboxWrapper>
                    </PaymentBoxCheckboxContainer>
                    <PrimaryBtn
                      type="button"
                      disabled={
                        !checkboxValues.orderAndPayAgree ||
                        !checkboxValues.refundAndCancelAgree ||
                        !selectedRadioValue
                      }
                      $isSmall
                      onClick={handlePayment}
                    >
                      결제하기
                    </PrimaryBtn>
                  </PaymentBoxWrapper>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 20,
                    }}
                  >
                    {accountInfo.authCancelFlag && (
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <PretendardText
                          style={{
                            width: 20,
                            textAlign: "center",
                            fontWeight: 600,
                            fontSize: 14,
                            lineHeight: "19.6px",
                            color: "#939393",
                          }}
                        >
                          •
                        </PretendardText>
                        <PretendardText
                          style={{
                            fontWeight: 600,
                            fontSize: 14,
                            lineHeight: "19.6px",
                            color: "#939393",
                          }}
                        >
                          무통장 입금 시 결제 완료 시점으로부터{" "}
                          {accountInfo.autoCancelHour}시간 이내 미입금 시<br />
                          자동으로 결제 취소 처리됩니다.
                        </PretendardText>
                      </div>
                    )}

                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <PretendardText
                        style={{
                          width: 20,
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: 14,
                          lineHeight: "19.6px",
                          color: "#939393",
                        }}
                      >
                        •
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: 600,
                          fontSize: 14,
                          lineHeight: "19.6px",
                          color: "#939393",
                        }}
                      >
                        무통장 입금 시 홈페이지 구매자의 이름으로 입금을
                        진행해야
                        <br />
                        정확한 확인이 가능합니다.
                      </PretendardText>
                    </div>
                  </div>
                </PaymentBox>
              </PaymentBoxContainer>
            </>
          )}

          {paymentSubmit && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 1200,
                height: 204,
                marginTop: 194,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: 430,
                  height: "100%",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 38,
                    fontWeight: 600,
                    lineHeight: "57px",
                    color: "#282828",
                  }}
                >
                  결제 완료
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: "23.2px",
                    color: "#383838",
                    textAlign: "center",
                    marginTop: 10,
                    marginBottom: 40,
                  }}
                >
                  결제 진행이 완료되었습니다!
                  <br />
                  주문금액을 입금해 주세요. 입금 완료 시 거래가 진행됩니다.
                </PretendardText>
                <PrimaryBtn
                  type="button"
                  onClick={() => navigate("/", { replace: true })}
                >
                  홈으로 돌아가기
                </PrimaryBtn>
              </div>
            </div>
          )}
        </PaymentContainer>

        {/* 알림창 영역 */}
        {alertIsOpen && (
          <Alert
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            onClick={handleAlertClose}
          >
            {alertMessage}
          </Alert>
        )}

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default Payment;

const PaymentContainer = styled.div`
  position: absolute;
  top: 165px;
  width: 1200px;
  display: flex;
  gap: 132px;
`;

const PaymentInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 688px;
`;

const PaymentTitleText = styled(PretendardText)`
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  color: #282828;
`;

const PaymentDivisionLine = styled.div`
  width: 628px;
  height: 1px;
  background-color: #e9e9e9;
  margin: 15px 0;
`;

const PaymentContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
`;

// 주문정보 영역
const PaymentProductContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const PaymentProductTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const TypeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const TypeText = styled(PretendardText)`
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  color: #383838;
`;

const ReviewGradeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ReviewGradeText = styled(PretendardText)`
  font-size: 16px;
  font-weight: 700;
  line-height: 145%;
  color: #282828;
`;

// 결제수단 라디오 버튼 영역
const PaymentRadioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

// 결제정보 영역
const PaymentBoxContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

const PaymentBox = styled.div`
  position: sticky;
  top: 140px;
  margin-top: 76px;
  display: flex;
  flex-direction: column;
`;

const PaymentBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  padding: 20px;
`;

const PaymentBoxTitle = styled(PretendardText)`
  font-size: 16px;
  font-weight: 700;
  line-height: 145%;
  color: #282828;
`;

const PaymentBoxDivisionLine = styled.div`
  width: 340px;
  height: 1px;
  background-color: #d4d4d4;
  margin: 10px 0;
`;

const PaymentBoxContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PaymentBoxContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PaymentBoxContentText = styled(PretendardText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: #6f6f6f;
`;

const PaymentBoxPrice = styled(PretendardText)`
  font-size: 20px;
  font-weight: 700;
  line-height: 145%;
  color: #282828;
`;

const PaymentBoxCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 30px 0;
`;

const PaymentBoxCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const PaymentRadioBoxLabel = styled.label`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: #282828;
  cursor: pointer;
`;

const PaymentRadioBoxInput = styled.input`
  display: none;
`;
