import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import PageContainer from "../../components/molecules/layout/PageContainer";
import BodyContainer from "../../components/molecules/layout/BodyContainer";
import Header from "../../components/organisms/Header";
import Footer from "../../components/organisms/Footer";
import TopBanner from "../../components/molecules/TopBanner";
import SearchBar from "../../components/molecules/SearchBar";
import sortingOptions from "../../constants/sortingOptions";
import SortedResultBoldText from "../../components/molecules/SortedResultBoldText";
import scrollToTop from "../../lib/utils/scrollToTop";
import useResizeHeight from "../../hooks/useResizeHeight";
import DownArrowIcon from "../../components/atoms/icons/DownArrowIcon";
import SelectList from "./../../components/molecules/SelectList";
import SelectOption from "../../components/atoms/SelectOption";
import DivisionLine from "../../components/atoms/DivisionLine";
import CompanyList from "./../../components/molecules/CompanyList";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { fileUrl, url } from "./../../lib/api/api";
import { useRecoilValue } from "recoil";
import { myInfoValueState } from "../../store/myInfo";
import route from "../../router/route";
import Pagination from "../../components/molecules/Pagination";
import { deleteStorage, getStorage } from "../../lib/utils/sessionStorage";
import axios from "axios";
import Alert from "./../../components/molecules/Alert";

const Around = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
  const [keyword, setKeyword] = useState("");
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //

  // 기업 회원일 경우 홈으로 이동
  useEffect(() => {
    if (myInfo?.profile) {
      return navigate("/", { replace: true });
    }

    getTokens();
  }, [myInfo]); // eslint-disable-line

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    if (token !== "") {
      setKeyword(
        searchParams.get("keyword") ? searchParams.get("keyword") : ""
      );
      setSortInputValue(
        searchParams.get("sort") ? searchParams.get("sort") : "id"
      );
      getServiceList();
    }
  }, [location, token]); // eslint-disable-line

  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 12, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  // ----------------------------------- //
  // ---------- 토큰 가져오기 ---------- //
  // --------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // 알림창 관련 상태
  const [alertOpen, setAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // ------------------------------------------- //
  // ---------- 서비스 데이터 가져오기 ---------- //
  // ----------------------------------------- //
  const [serviceList, setServiceList] = useState([]); // 서비스 목록
  const getServiceList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword")
          ? searchParams.get("keyword")
          : undefined,
        page: searchParams.get("page") ? searchParams.get("page") : 1,
        size: pagination.size,
        sort: searchParams.get("sort") ? searchParams.get("sort") : undefined,
        orderBy: "DESC",
      };
      const response = await axios.get(`${url}merches`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        params: data,
      });
      setServiceList(response.data.data.content);

      setPagination({
        ...pagination,
        totalCount: response.data.data.totalCount
          ? response.data.data.totalCount
          : response.data.data.content.length,
        totalPages: response.data.data.totalPage
          ? response.data.data.totalPage
          : Math.ceil(
              response.data.data.totalCount
                ? response.data.data.totalCount / 10
                : response.data.data.content.length / 10
            ),
      });
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // ---------------------------------- //
  // ---------- 스크랩 관련 ---------- //
  // -------------------------------- //
  const toggleScrap = async (merchId) => {
    try {
      await axios.post(`${url}member/scrap/${merchId}`, null, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      getServiceList();
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // ------------------------------------ //
  // ---------- 정렬 방식 관련 ---------- //
  // ---------------------------------- //
  const [isSortRotated, setIsSortRotated] = useState(false);

  const [sortInputValue, setSortInputValue] = useState("id");

  const sortTitle = useMemo(() => {
    return sortingOptions.find((item) => item.sort === sortInputValue)
      .sortTitle;
  }, [sortInputValue]);

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 850 + elementHeight,
      }}
    >
      <Header />
      <BodyContainer>
        <TopBanner
          $title="둘러보기"
          $text="알팅의 전문가를 만나보세요."
          src="/assets/imgs/home/around_bg.png"
        />
        <AroundContainer ref={elementRef}>
          <AroundHeader>
            <SearchBar
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  navigate(
                    `${route.around}?page=1&keyword=${keyword}&sort=${
                      searchParams.get("sort") ? searchParams.get("sort") : "id"
                    }`,
                    {
                      replace: true,
                    }
                  );
                }
              }}
              onClick={() => {
                navigate(
                  `${route.around}?page=1&keyword=${keyword}&sort=${
                    searchParams.get("sort") ? searchParams.get("sort") : "id"
                  }`,
                  {
                    replace: true,
                  }
                );
              }}
            />

            {/* 정렬 */}
            <InputWrapper style={{ width: 133 }}>
              <InputLabel htmlFor="sort">정렬 방식</InputLabel>
              <InputField
                type="text"
                id="sort"
                name="sort"
                readOnly
                $isRequired
                value={sortTitle}
                // onChange={handleInputValueChange}
                style={{ width: 133 }}
                onClick={() => {
                  setIsSortRotated((prevState) => !prevState);
                }}
              />
              <DownArrowIcon
                style={{
                  position: "absolute",
                  top: 12,
                  right: 16,
                  transform: isSortRotated ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsSortRotated((prevState) => !prevState);
                }}
              />

              <SelectList
                className="scrollbar"
                style={{
                  top: 51,
                  right: 0,
                  width: 133,
                  display: isSortRotated ? "block" : "none",
                }}
              >
                {sortingOptions.map((option, index) => (
                  <React.Fragment key={option.sort}>
                    <SelectOption
                      onClick={() => {
                        setIsSortRotated((prevState) => !prevState);
                        setSortInputValue(option.sort);
                        navigate(
                          `${route.around}?page=1&keyword=${
                            searchParams.get("keyword") === null
                              ? ""
                              : searchParams.get("keyword")
                          }&sort=${option.sort}`,
                          {
                            replace: true,
                          }
                        );
                      }}
                    >
                      {option.sortTitle}
                    </SelectOption>
                    {index < sortingOptions.length - 1 && (
                      <DivisionLine
                        style={{
                          margin: "12px 0",
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </SelectList>
            </InputWrapper>
          </AroundHeader>

          <SortedResultBoldText
            style={{ marginTop: 80, marginBottom: 40 }}
            $title="전체"
            $count={pagination.totalCount}
          />

          {/* 게시물 영역 */}
          <PostContainer>
            <>
              {serviceList.map((item, index) => {
                return (
                  <CompanyList
                    key={index}
                    path={`${route.serviceDetail}/${item.id}`}
                    pathHandler={() => {
                      scrollToTop();
                    }}
                    isShowBookmark={myInfo?.id !== 0}
                    bookmarkHandler={(e) => {
                      e.stopPropagation();
                      toggleScrap(item.id);
                    }}
                    src={`${fileUrl}/${item.profileServerFileName}`}
                    $isChecked={item.isScrap}
                    serviceName={item.title}
                    companyName={item.member?.profile.companyName}
                    marketingType={item.marketingType.title}
                    location={item.member?.profile.regionTitle}
                    reviewGrade={item.reviewAverage}
                  />
                );
              })}
              <Pagination
                route={route.around}
                queryString={`keyword=${
                  searchParams.get("keyword") === null
                    ? ""
                    : searchParams.get("keyword")
                }&sort=${
                  searchParams.get("sort") ? searchParams.get("sort") : "id"
                }`}
                currentPage={page}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
              />
            </>
          </PostContainer>

          {alertOpen && (
            <Alert
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setAlertOpen(false);
              }}
            >
              {responseMessage}
            </Alert>
          )}
        </AroundContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default Around;

const AroundContainer = styled.div`
  position: absolute;
  top: 475px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const AroundHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 15px;
  width: 323px;
`;

const InputLabel = styled.label`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip-path: inset(50%);
  border: 0;
  clip: rect(0, 0, 0, 0);
`;

const InputField = styled.input`
  width: 78px;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #282828;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  padding: 12.5px 0 12.5px 15px;
  outline: none;
  border-radius: 5px;
  &::placeholder {
    color: #c0c0c0;
  }
  &:focus {
    outline: 1px solid #2ec5f6;
  }
`;

const PostContainer = styled.div`
  display: flex;
  width: 1200px;
  gap: 29px;
  flex-wrap: wrap;
`;
