import React from "react";
import styled from "styled-components";

const MoreBtn = (props) => {
  return (
    <MoreBtnSvg
      width="5"
      height="22"
      viewBox="0 0 5 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="2.5" cy="2.5" r="2.5" fill="#939393" />
      <circle cx="2.5" cy="10.8335" r="2.5" fill="#939393" />
      <circle cx="2.5" cy="19.1665" r="2.5" fill="#939393" />
    </MoreBtnSvg>
  );
};

export default MoreBtn;

const MoreBtnSvg = styled.svg`
  cursor: pointer;
`;
