// 업종 리스트
export const sectorList = [
  { title: "업종을 선택해 주세요.", value: "" },
  { title: "음식점업", value: "RESTAURANT" },
  { title: "부동산업", value: "REAL_ESTATE" },
  { title: "전문, 과학 및 기술 서비스업", value: "TECHNOLOGY" },
  { title: "통신판매업", value: "ONLINE" },
  { title: "예술, 스포츠 및 여가 관련 서비스업", value: "ART" },
  { title: "교육 서비스업", value: "EDUCATION" },
  { title: "수리 및 개인 서비스업", value: "PERSONAL" },
];

// 예산 선택 옵션
export const budgetList = [
  { title: "예산을 선택해 주세요.", value: "" },
  { title: "50만 원 이하", value: "BELOW_500K" },
  { title: "50만 원 초과 ~ 100만 원 이하", value: "FROM_500K_TO_1M" },
  { title: "100만 원 초과 ~ 300만 원 이하", value: "FROM_1M_TO_3M" },
  { title: "300만 원 초과 ~ 500만 원 이하", value: "FROM_3M_TO_5M" },
  { title: "500만 원 초과", value: "ABOVE_5M" },
];

// 집행 기간 선택 옵션
export const periodList = [
  { title: "기간을 선택해 주세요.", value: "" },
  { title: "1개월 이하", value: "BELOW_1_MONTH" },
  { title: "1개월 초과 ~ 3개월 이하", value: "FROM_1_TO_3_MONTHS" },
  { title: "3개월 초과 ~ 6개월 이하", value: "FROM_3_TO_6_MONTHS" },
  { title: "6개월 초과", value: "ABOVE_6_MONTHS" },
];
