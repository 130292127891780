import React from "react";
import styled from "styled-components";
import CompanyIcon from "../atoms/icons/CompanyIcons";
import BookmarkIcon from "../atoms/icons/BookmarkIcon";
import PretendardText from "../atoms/PretendardText";
import PenIcon from "../atoms/icons/PenIcon";
import LocationIcon from "../atoms/icons/LocationIcon";
import ReviewGradeIcon from "../atoms/icons/ReviewGradeIcon";
import { Link } from "react-router-dom";

const CompanyList = (props) => {
  return (
    <CompanyListContainer $isSmall={props.$isSmall}>
      <Link to={props.path} onClick={props.pathHandler}>
        {/* 기업 사진 */}
        <CompanyIcon
          style={{
            width: props.$isSmall && 175,
            height: props.$isSmall && 175,
          }}
          $isSmall={props.$isSmall}
          src={props.src}
        />
      </Link>
      {/* 북마크 아이콘 */}
      {props.isShowBookmark && (
        <BookmarkIcon
          $isChecked={props.$isChecked}
          onClick={props.bookmarkHandler}
          style={{
            position: "absolute",
            top: props.$isSmall ? 12.59 : 20,
            right: props.$isSmall ? 13.22 : 21,
            width: props.$isSmall ? 15.11 : 24,
            height: props.$isSmall ? 15.11 : 24,
          }}
        />
      )}

      {/* 서비스명, 기업명 */}
      <CompanyListTextWrapper $isSmall={props.$isSmall}>
        <CompanyListServiceName $isSmall={props.$isSmall}>
          {props.serviceName}
        </CompanyListServiceName>
        <CompanyListCompanyName $isSmall={props.$isSmall}>
          {props.companyName}
        </CompanyListCompanyName>
      </CompanyListTextWrapper>

      {/* 마케팅 종류, 지역, 별점 */}
      <CompanyListBottomWrapper>
        {/* 마케팅 종류, 지역 */}
        <CompanyListBottomTextWrapper $isSmall={props.$isSmall}>
          {/* 마케팅 종류 */}
          <CompanyListBottomTextInnerWrapper $isSmall={props.$isSmall}>
            <PenIcon
              style={{
                width: props.$isSmall && 11.33,
                height: props.$isSmall && 11.33,
              }}
            />
            <CompanyListBottomText $isSmall={props.$isSmall}>
              {props.marketingType}
            </CompanyListBottomText>
          </CompanyListBottomTextInnerWrapper>
          {/* 지역 */}
          <CompanyListBottomTextInnerWrapper>
            <LocationIcon
              style={{
                width: props.$isSmall && 11.33,
                height: props.$isSmall && 11.33,
              }}
            />
            <CompanyListBottomText $isSmall={props.$isSmall}>
              {props.location}
            </CompanyListBottomText>
          </CompanyListBottomTextInnerWrapper>
        </CompanyListBottomTextWrapper>

        {/* 리뷰 평점 */}
        <CompanyListReviewGradeWrapper $isSmall={props.$isSmall}>
          <ReviewGradeIcon
            style={{
              width: props.$isSmall ? 11.33 : 18,
              height: props.$isSmall ? 11.33 : 18,
            }}
            $isChecked={true}
          />
          <CompanyListReviewGradeText $isSmall={props.$isSmall}>
            {props.reviewGrade === "NaN" ? "0" : props.reviewGrade}
          </CompanyListReviewGradeText>
        </CompanyListReviewGradeWrapper>
      </CompanyListBottomWrapper>
    </CompanyListContainer>
  );
};

export default CompanyList;

const CompanyListContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.$isSmall ? "175px" : "278px")};
`;

const CompanyListTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.$isSmall ? "6.98px" : "12px")};
  margin-bottom: ${(props) => (props.$isSmall ? "7.35px" : "12.5px")};
  gap: ${(props) => (props.$isSmall ? "3.15px" : "5px")};
`;

const CompanyListServiceName = styled(PretendardText)`
  font-size: ${(props) => (props.$isSmall ? "12px" : "16px")};
  font-weight: ${(props) => (props.$isSmall ? 600 : 700)};
  line-height: 145%;
  color: #282828;
`;

const CompanyListCompanyName = styled(PretendardText)`
  font-size: ${(props) => (props.$isSmall ? "12px" : "16px")};
  font-weight: 400;
  line-height: ${(props) => (props.$isSmall ? "130%" : "145%")};
  color: #6f6f6f;
`;

const CompanyListBottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CompanyListBottomTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.$isSmall ? "3.78px" : "6px")};
`;

const CompanyListBottomTextInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const CompanyListBottomText = styled(PretendardText)`
  font-weight: 600;
  font-size: ${(props) => (props.$isSmall ? "10px" : "14px")};
  line-height: ${(props) => (props.$isSmall ? "130%" : "140%")};
  color: #383838;
`;

const CompanyListReviewGradeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.$isSmall ? "3.15px" : "5px")};
  width: ${(props) => (props.$isSmall ? "36.51px" : "58px")};
`;

const CompanyListReviewGradeText = styled(PretendardText)`
  font-weight: 700;
  font-size: ${(props) => (props.$isSmall ? "10px" : "16px")};
  line-height: 145%;
  color: #282828;
`;
