import React from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const CompanyIcon = (props) => {
  return !props.src ? (
    <DefaultCompanyIconSvg
      width="278"
      height="278"
      viewBox="0 0 278 278"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="278" height="278" rx="10" fill="#E0EAF3" />
      <path
        d="M189.427 191.704H184.67V84.4135C184.67 81.2936 182.115 78.7666 178.961 78.7666H99.0357C95.8815 78.7666 93.3267 81.2936 93.3267 84.4135V191.704H88.5693C86.9922 191.704 85.7148 192.968 85.7148 194.528V199.233H192.282V194.528C192.282 192.968 191.004 191.704 189.427 191.704ZM116.162 96.6484C116.162 95.0884 117.44 93.8249 119.017 93.8249H128.532C130.109 93.8249 131.386 95.0884 131.386 96.6484V106.06C131.386 107.62 130.109 108.883 128.532 108.883H119.017C117.44 108.883 116.162 107.62 116.162 106.06V96.6484ZM116.162 119.236C116.162 117.676 117.44 116.412 119.017 116.412H128.532C130.109 116.412 131.386 117.676 131.386 119.236V128.647C131.386 130.207 130.109 131.471 128.532 131.471H119.017C117.44 131.471 116.162 130.207 116.162 128.647V119.236ZM128.532 154.058H119.017C117.44 154.058 116.162 152.795 116.162 151.235V141.823C116.162 140.263 117.44 139 119.017 139H128.532C130.109 139 131.386 140.263 131.386 141.823V151.235C131.386 152.795 130.109 154.058 128.532 154.058ZM146.61 191.704H131.386V171.94C131.386 170.38 132.664 169.117 134.241 169.117H143.756C145.333 169.117 146.61 170.38 146.61 171.94V191.704ZM161.834 151.235C161.834 152.795 160.557 154.058 158.979 154.058H149.465C147.887 154.058 146.61 152.795 146.61 151.235V141.823C146.61 140.263 147.887 139 149.465 139H158.979C160.557 139 161.834 140.263 161.834 141.823V151.235ZM161.834 128.647C161.834 130.207 160.557 131.471 158.979 131.471H149.465C147.887 131.471 146.61 130.207 146.61 128.647V119.236C146.61 117.676 147.887 116.412 149.465 116.412H158.979C160.557 116.412 161.834 117.676 161.834 119.236V128.647ZM161.834 106.06C161.834 107.62 160.557 108.883 158.979 108.883H149.465C147.887 108.883 146.61 107.62 146.61 106.06V96.6484C146.61 95.0884 147.887 93.8249 149.465 93.8249H158.979C160.557 93.8249 161.834 95.0884 161.834 96.6484V106.06Z"
        fill="#C4DBF0"
      />
    </DefaultCompanyIconSvg>
  ) : (
    <StyledCompanyIcon src={props.src} effect="blur" {...props} />
  );
};

export default CompanyIcon;

const DefaultCompanyIconSvg = styled.svg`
  box-shadow: ${(props) =>
    props.$shadowOn && "0px 5px 15px rgba(0, 0, 0, 0.15)"};
  border-radius: 10px;
`;

const StyledCompanyIcon = styled(LazyLoadImage)`
  /* background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  object-fit: cover;
  border-radius: 10px;
  box-shadow: ${(props) =>
    props.$shadowOn && "0px 5px 15px rgba(0, 0, 0, 0.15)"};
  width: ${(props) => (props.$isSmall ? "175px" : "278px")};
  height: ${(props) => (props.$isSmall ? "175px" : "278px")};
`;
