import React from "react";

const ReviewGradeIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        ...props.style,
      }}
      onClick={props.onClick}
    >
      <path
        d="M7.13589 0.983587C7.52162 0.32132 8.47838 0.321321 8.86411 0.983588L10.6878 4.11458C10.8291 4.35718 11.0658 4.52921 11.3402 4.58863L14.8815 5.35548C15.6306 5.51769 15.9262 6.42761 15.4156 6.99912L13.0013 9.70103C12.8143 9.91039 12.7238 10.1887 12.7521 10.4681L13.1171 14.073C13.1943 14.8355 12.4203 15.3979 11.719 15.0888L8.40325 13.6277C8.14634 13.5145 7.85366 13.5145 7.59675 13.6277L4.28104 15.0888C3.57971 15.3979 2.80568 14.8355 2.88288 14.073L3.24788 10.4681C3.27616 10.1887 3.18572 9.91039 2.99866 9.70103L0.584442 6.99912C0.0737871 6.42761 0.369441 5.51769 1.11849 5.35548L4.65978 4.58863C4.93417 4.52921 5.17095 4.35718 5.31225 4.11458L7.13589 0.983587Z"
        fill={props.$isChecked ? "#2ec5f6" : "#c0c0c0"}
      />
    </svg>
  );
};

export default ReviewGradeIcon;
