// 업종 리스트
const sectorOptions = {
  RESTAURANT: "음식점업",
  REAL_ESTATE: "부동산업",
  TECHNOLOGY: "전문, 과학 및 기술 서비스업",
  ONLINE: "통신판매업",
  ART: "예술, 스포츠 및 여가 관련 서비스업",
  EDUCATION: "교육 서비스업",
  PERSONAL: "수리 및 개인 서비스업",
};

export default sectorOptions;
