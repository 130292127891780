import React from "react";
import styled from "styled-components";

const SelectList = (props) => {
  return <StyledSelectList {...props}>{props.children}</StyledSelectList>;
};

export default SelectList;

const StyledSelectList = styled.div`
  position: absolute;
  margin-top: 10px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  background: #fff;
  z-index: 1;
  max-height: 278px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
`;
