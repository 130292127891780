import React from "react";
import styled from "styled-components";

const XBtnIcon = (props) => {
  return (
    <XBtnIconSvg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.83984 6.42857L25.0226 24.6113"
        stroke="#939393"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6113 6.42857L6.42858 24.6113"
        stroke="#939393"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </XBtnIconSvg>
  );
};

export default XBtnIcon;

const XBtnIconSvg = styled.svg`
  cursor: pointer;
`;
