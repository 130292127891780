import React from "react";
import styled from "styled-components";

const ProfileIcon = (props) => {
  return <StyledProfileIcon {...props} />;
};

export default ProfileIcon;

const StyledProfileIcon = styled.div`
  /* background-image: url("/assets/imgs/"); */
  background-color: #d9d9d9;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 999px;
`;
