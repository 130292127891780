import { RecoilRoot } from "recoil";
import Router from "./router/router";
import { ErrorBoundary } from "react-error-boundary";
import "./App.css";
import Alert from "./components/molecules/Alert";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  console.error(error);
  return (
    <Alert
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
      onClick={() => {
        resetErrorBoundary();
        window.location.reload();
      }}
    >
      <>
        에러가 발생하였습니다.
        <br />
        확인을 누르면 새로고침 됩니다.
      </>
    </Alert>
  );
};

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <RecoilRoot>
        <Router />
      </RecoilRoot>
    </ErrorBoundary>
  );
}

export default App;
