import React from "react";
import styled from "styled-components";
import DownArrowIcon from "../atoms/icons/DownArrowIcon";

const InputSelectBox = (props) => {
  return (
    <InputSelectBoxContainer {...props} onClick={props.onClick}>
      <InputSelectBoxWrapper>
        {!props.isNoLabel && (
          <InputSelectBoxLabel htmlFor={props.$uniqueKey}>
            {props.label}
            {props.$isRequired && (
              <span
                style={{
                  fontFamily: "Pretendard",
                  color: "#2ec5f6",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "145%",
                }}
              >
                *
              </span>
            )}
          </InputSelectBoxLabel>
        )}
        <InputSelectBoxField
          type="text"
          readOnly
          id={props.$uniqueKey}
          name={props.$uniqueKey}
          placeholder={props.placeholder}
          required={props.$isRequired}
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
        />
        <DownArrowIcon
          $isRotated={props.$isRotated}
          style={{
            position: "absolute",
            top: props.isNoLabel ? 13 : 45,
            right: 15,
          }}
        />
        {props.$isError && <ErrorMsg>{props.$errorMsg}</ErrorMsg>}
        {props.$isHelp && <HelpMsg>{props.$helpMsg}</HelpMsg>}
      </InputSelectBoxWrapper>
      {props.children}
    </InputSelectBoxContainer>
  );
};

export default InputSelectBox;

const InputSelectBoxContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 430px;
`;

const InputSelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputSelectBoxLabel = styled.label`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 145%;
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 9px;
`;

const InputSelectBoxField = styled.input`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #282828;
  width: 100%;
  height: 51px;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  padding-left: 17px;
  outline: none;
  margin-bottom: 3px;

  &::placeholder {
    color: #c0c0c0;
  }

  &:focus {
    outline: 1px solid #2ec5f6;
  }

  &:disabled {
    background-color: #e9e9e9;
    color: #c0c0c0;
    cursor: not-allowed;
  }
`;

const ErrorMsg = styled.p`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  color: #ff3124;
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;
  word-break: keep-all;
  white-space: nowrap;
`;

const HelpMsg = styled(ErrorMsg)`
  color: #1b84ff;
`;
