import React from "react";
import styled from "styled-components";

const WriteIconBtn = (props) => {
  return (
    <WriteIconBtnLabel {...props}>
      <input
        type="file"
        id="fileInput"
        accept={props.$onlyPngJpg ? props.$onlyPngJpg : "image/*"}
        style={{ display: "none" }}
        onChange={props.onChange}
      />
      <svg
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="17.5" cy="17.5" r="17" fill="white" stroke="#D9D9D9" />
        <path
          d="M26.3051 12.3317L23.7742 9.80078L11.5826 21.9924L14.1135 24.5233L26.3051 12.3317Z"
          fill="white"
          stroke="#939393"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.79883 26.3117L11.5922 21.9883L14.1222 24.5183L9.79883 26.3117Z"
          fill="white"
          stroke="#939393"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </WriteIconBtnLabel>
  );
};

export default WriteIconBtn;

const WriteIconBtnLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
