import React from "react";
import styled from "styled-components";

const FileBtn = (props) => {
  return (
    <label style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
      <input
        type="file"
        id="fileInput"
        accept={!props.$isAllAccept ? "image/*" : "*"}
        style={{ display: "none" }}
        onChange={props.onChange}
      />
      <FileBtnSvg
        width="40"
        height="42"
        viewBox="0 0 40 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect x="0.5" y="0.5" width="39" height="41" rx="9.5" fill="white" />
        <rect
          x="0.5"
          y="0.5"
          width="39"
          height="41"
          rx="9.5"
          stroke="#2EC5F6"
        />
        <path
          d="M17.6207 30.3722C21.4423 26.6151 24.4317 23.612 27.7568 20.3881C28.8547 19.2545 29.5499 18.1452 29.8426 17.06C30.355 15.012 29.5672 13.2386 28.196 11.8508C26.8785 10.5484 25.4699 9.9333 23.9696 10.0057C22.4693 10.0781 20.9874 10.8258 19.5236 12.2486L10.1923 21.5093L11.7292 22.9925L21.0602 13.7679C22.0177 12.8679 23.1571 12.0196 24.4634 12.1401C26.4537 12.4164 28.2112 14.8244 27.7568 16.5172C27.1806 18.019 26.3862 18.7041 25.2868 19.7909C21.9424 23.0887 19.3346 25.6389 16.0839 28.8527C14.8621 29.9564 14.0719 30.2552 12.937 29.2144C12.3515 28.6357 12.0954 28.069 12.1685 27.5142C12.2497 26.867 12.6202 26.4193 13.0466 25.9949L21.5726 17.5662C21.9621 17.1805 22.8493 16.4402 23.2192 16.7703C23.5032 17.4215 22.7719 18.043 22.4142 18.3982L14.5834 26.1396L16.0837 27.6589L23.951 19.9174C25.3061 18.5358 26.2797 16.8473 24.7561 15.287C23.0711 13.8572 21.2959 14.8118 20.0356 16.0467L11.5098 24.4756C10.6317 25.3437 10.1315 26.2844 10.0095 27.2973C9.91324 28.7172 10.5608 29.8572 11.4365 30.7339C12.2595 31.5431 13.149 31.9914 14.2542 32C15.5832 31.9474 16.8897 31.0821 17.6207 30.3722Z"
          fill="#2EC5F6"
        />
      </FileBtnSvg>
    </label>
  );
};

export default FileBtn;

const FileBtnSvg = styled.svg`
  cursor: pointer;
`;
