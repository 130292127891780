import React from "react";
import styled from "styled-components";
import PretendardText from "./PretendardText";

const SubTitle = (props) => {
  return <StyledSubTitle {...props}>{props.children}</StyledSubTitle>;
};

export default SubTitle;

const StyledSubTitle = styled(PretendardText)`
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #383838;
`;
