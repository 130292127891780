import React from "react";
import styled from "styled-components";

const ModalOverlay = (props) => {
  return (
    <StyledModalOverlay $isOpen={props.$isOpen} {...props}>
      {props.children}
    </StyledModalOverlay>
  );
};

export default ModalOverlay;

const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  z-index: 1000;
  animation: ${(props) => (props.$isOpen ? "fadeIn 0.3s" : "fadeOut 0.3s")};
  transition: background-color 0.3s;
`;
