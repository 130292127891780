import React from "react";
import styled from "styled-components";
import PrimaryMiniBtn from "./../atoms/buttons/PrimaryMiniBtn";
import PretendardText from "../atoms/PretendardText";

const Alert = ({ children, style, onClick }) => {
  return (
    <AlertContainer
      style={{
        ...style,
      }}
    >
      <AlertText>{children}</AlertText>
      <PrimaryMiniBtn
        style={{
          width: 68,
        }}
        type={"button"}
        onClick={onClick}
      >
        확인
      </PrimaryMiniBtn>
    </AlertContainer>
  );
};

export default Alert;

const AlertContainer = styled.div`
  padding: 40px 51px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const AlertText = styled(PretendardText)`
  width: 242px;
  height: fit-content;
  font-size: 16px;
  line-height: 145%;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #282828;
  text-align: center;
`;
