import React from "react";
import styled from "styled-components";
import PretendardText from "../atoms/PretendardText";
import DivisionLine from "./../atoms/DivisionLine";
import {
  FirstRankingBubble,
  SecondRankingBubble,
  ThirdRankingBubble,
} from "./RankingBubble";
import VCheckIcon from "./../atoms/icons/VCheckIcon";

const SearchCard = ({
  $isFirst,
  $isSecond,
  $isThird,
  $title,
  $content,
  $hashTag,
  src,
}) => {
  return (
    <StrategyWrapper $isFirst={$isFirst}>
      {$isFirst && (
        <FirstRankingBubble
          $isFirst={true}
          style={{
            position: "absolute",
            top: -35,
            left: 0,
            zIndex: 1,
          }}
        />
      )}
      {$isSecond && (
        <SecondRankingBubble
          style={{
            position: "absolute",
            top: -29,
            left: 0,
            zIndex: 1,
          }}
        />
      )}
      {$isThird && (
        <ThirdRankingBubble
          style={{
            position: "absolute",
            top: -29,
            left: 0,
            zIndex: 1,
          }}
        />
      )}
      <StrategyTextWrapper>
        <StrategyTextTitle>{$title}</StrategyTextTitle>
        <DivisionLine />
        <StrategyText>{$content}</StrategyText>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          {$hashTag.map((item, index) => (
            <StrategyCheckWrapper key={index}>
              <VCheckIcon checked />
              <StrategyCheckText>{item}</StrategyCheckText>
            </StrategyCheckWrapper>
          ))}
        </div>
      </StrategyTextWrapper>
      <PostImg
        style={{
          borderRadius: "0 0 10px 10px",
          width: "100%",
          height: 229,
        }}
        src={src}
      />
    </StrategyWrapper>
  );
};

export default SearchCard;

const StrategyWrapper = styled.div`
  width: 380px;
  height: 520px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  box-shadow: ${(props) =>
    props.$isFirst && "4px 4px 25px 2px rgba(74, 93, 100, 0.15)"};
  border-radius: 10px;
`;

const StrategyTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px;
`;

const StrategyTextTitle = styled(PretendardText)`
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  color: #282828;
  margin-bottom: 23px;
`;

const StrategyText = styled(PretendardText)`
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #383838;
  margin-top: 28px;
  margin-bottom: 26px;
`;

const StrategyCheckWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StrategyCheckText = styled(PretendardText)`
  font-size: 15px;
  font-weight: 600;
  line-height: 30px;
  color: #282828;
`;

const PostImg = styled.div`
  background-image: url(${(props) => props.src});
  background-color: #e9e9e9;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  border-radius: 5px;
  width: 278px;
  height: 278px;
`;
