import React, { forwardRef } from "react";
import styled from "styled-components";

const PretendardText = forwardRef((props, ref) => {
  return <PretendardTextWrapper ref={ref} {...props} />;
});

export default PretendardText;

const PretendardTextWrapper = styled.div`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  display: block;
  word-break: keep-all;
  white-space: pre-wrap;
  /* white-space: nowrap; */
`;
