import React, { useState } from "react";
import styled from "styled-components";
import ModalOverlay from "./../atoms/ModalOverlay";
import Title from "./../atoms/Title";
import PretendardText from "./../atoms/PretendardText";
import XBtnIcon from "./../atoms/icons/XBtnIcon";
import SecondaryMiniBtn from "./../atoms/buttons/SecondaryMiniBtn";
import PrimaryMiniBtn from "./../atoms/buttons/PrimaryMiniBtn";
import regex from "../../constants/regex";
import TextareaBox from "./TextareaBox";
import axios from "axios";
import { url } from "../../lib/api/api";
import Alert from "./Alert";
import InputWrapper from "./layout/InputWrapper";
import InputLabel from "./../atoms/InputLabel";
import ReviewGradeIcon from "../atoms/icons/ReviewGradeIcon";

const ReviewModal = (props) => {
  const [textareaInputValue, setTextareaInputValue] = useState("");
  const [textareaRegexTest, setTextareaRegexTest] = useState(true);
  const [reviewModalIsOpen, setReviewModalIsOpen] = useState(false);
  const [reviewRating, setReviewRating] = useState(0);
  const [modalMsg, setModalMsg] = useState("");

  // input 값 변경 핸들러
  const handleInputChange = (e) => {
    const value = e.target.value;

    setTextareaInputValue(value);

    const validateInput = (regex, value) => {
      return regex.test(value);
    };

    if (value !== "") {
      setTextareaRegexTest(validateInput(regex.text, value));
    } else {
      setTextareaRegexTest(true);
    }
  };

  // 신고 제출 핸들러
  const handleChat = async () => {
    const data = {
      rating: reviewRating,
      content: textareaInputValue,
      companyName: props.$companyName,
      payDate: props.$payDate,
    };
    try {
      await axios.post(`${url}member/review/${props.$merchId}`, data, {
        headers: {
          authorization: `Bearer ${props.$token}`,
        },
      });
      setReviewModalIsOpen(true);
      setModalMsg("작성 완료되었습니다.");
      setTextareaInputValue("");
      setReviewRating(0);
    } catch (error) {
      console.error(error);
      setReviewModalIsOpen(true);
      setModalMsg(
        <>
          오류가 발생했습니다.
          <br />
          다시 시도해 주세요.
        </>
      );
    }
  };

  return (
    <>
      <ModalOverlay $isOpen={props.$isOpen}>
        <ReviewModalContainer $isOpen={props.$isOpen}>
          <XBtnIcon
            style={{ position: "absolute", top: 30, right: 30 }}
            onClick={props.$handleClose}
          />
          <ReviewModalContentContainer>
            <ReviewModalTitleTextWrapper>
              <Title>리뷰 작성</Title>
              <ReviewModalText>서비스 후기를 작성해 주세요.</ReviewModalText>
            </ReviewModalTitleTextWrapper>
            <ReviewModalTextareaBoxWrapper>
              <InputWrapper
                style={{
                  gap: 10,
                  marginBottom: 0,
                }}
              >
                <InputLabel
                  style={{
                    marginBottom: 0,
                  }}
                >
                  별점
                  <span
                    style={{
                      fontFamily: "Pretendard",
                      color: "#2ec5f6",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "145%",
                    }}
                  >
                    *
                  </span>
                </InputLabel>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ReviewGradeIcon
                    style={{
                      width: 40,
                      height: 40,
                    }}
                    $isChecked={
                      String(reviewRating).startsWith("1") ||
                      String(reviewRating).startsWith("2") ||
                      String(reviewRating).startsWith("3") ||
                      String(reviewRating).startsWith("4") ||
                      String(reviewRating).startsWith("5")
                    }
                    onClick={() => {
                      setReviewRating(1);
                    }}
                  />
                  <ReviewGradeIcon
                    style={{
                      width: 40,
                      height: 40,
                    }}
                    $isChecked={
                      String(reviewRating).startsWith("2") ||
                      String(reviewRating).startsWith("3") ||
                      String(reviewRating).startsWith("4") ||
                      String(reviewRating).startsWith("5")
                    }
                    onClick={() => {
                      setReviewRating(2);
                    }}
                  />
                  <ReviewGradeIcon
                    style={{
                      width: 40,
                      height: 40,
                    }}
                    $isChecked={
                      String(reviewRating).startsWith("3") ||
                      String(reviewRating).startsWith("4") ||
                      String(reviewRating).startsWith("5")
                    }
                    onClick={() => {
                      setReviewRating(3);
                    }}
                  />
                  <ReviewGradeIcon
                    style={{
                      width: 40,
                      height: 40,
                    }}
                    $isChecked={
                      String(reviewRating).startsWith("4") ||
                      String(reviewRating).startsWith("5")
                    }
                    onClick={() => {
                      setReviewRating(4);
                    }}
                  />
                  <ReviewGradeIcon
                    style={{
                      width: 40,
                      height: 40,
                    }}
                    $isChecked={String(reviewRating).startsWith("5")}
                    onClick={() => {
                      setReviewRating(5);
                    }}
                  />
                </div>
              </InputWrapper>
              <TextareaBox
                $uniqueKey="textarea"
                label="내용"
                $isRequired={true}
                placeholder="내용을 입력해 주세요."
                value={textareaInputValue}
                onChange={handleInputChange}
                $isError={!textareaRegexTest}
                $errorMsg="두 글자 이상 입력해 주세요."
              />
            </ReviewModalTextareaBoxWrapper>
          </ReviewModalContentContainer>
          <ReviewModalBtnWrapper>
            <SecondaryMiniBtn
              onClick={() => {
                props.$handleClose();
              }}
            >
              취소
            </SecondaryMiniBtn>

            <PrimaryMiniBtn
              onClick={() => {
                handleChat();
              }}
              disabled={
                textareaInputValue === "" ||
                !textareaRegexTest ||
                reviewRating === 0
              }
            >
              확인
            </PrimaryMiniBtn>
          </ReviewModalBtnWrapper>
          {/* 알림창 영역 */}
          {reviewModalIsOpen && (
            <Alert
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              onClick={() => {
                setReviewModalIsOpen(false);
                setModalMsg("");
                props.$handleClose();
              }}
            >
              {modalMsg}
            </Alert>
          )}
        </ReviewModalContainer>
      </ModalOverlay>
    </>
  );
};

export default ReviewModal;

const ReviewModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 510px;
  min-height: 536px;
  padding: 40px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  animation: ${(props) =>
    props.$isOpen
      ? "fadeIn 0.3s ease-out forwards"
      : "fadeOut 0.3s ease-out forwards"};
`;

const ReviewModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 430px;
  min-height: 395px;
  gap: 30px;
`;

const ReviewModalTitleTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ReviewModalText = styled(PretendardText)`
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #6f6f6f;
`;

const ReviewModalTextareaBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ReviewModalBtnWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 20px;
  align-items: center;
  align-self: center;
`;
