import { atom } from "recoil";

export const initialLoginValues = {
  pw: "",
  currentPw: "",
  newPw: "",
  newPwCheck: "",
  phoneNumber: "",
  certNum: "",
};

export const loginValueState = atom({
  key: "loginValueState",
  default: initialLoginValues,
});
