import React, { useState } from "react";
import styled from "styled-components";
import ModalOverlay from "./../atoms/ModalOverlay";
import Title from "./../atoms/Title";
import PretendardText from "./../atoms/PretendardText";
import XBtnIcon from "./../atoms/icons/XBtnIcon";
import SecondaryMiniBtn from "./../atoms/buttons/SecondaryMiniBtn";
import PrimaryMiniBtn from "./../atoms/buttons/PrimaryMiniBtn";
import regex from "../../constants/regex";
import TextareaBox from "./TextareaBox";
import axios from "axios";
import { url } from "../../lib/api/api";
import Alert from "./Alert";

const ReportModal = (props) => {
  const [textareaInputValue, setTextareaInputValue] = useState("");
  const [textareaRegexTest, setTextareaRegexTest] = useState(true);
  const [reportModalIsOpen, setReportModalIsOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  // input 값 변경 핸들러
  const handleInputChange = (e) => {
    const value = e.target.value;

    setTextareaInputValue(value);

    const validateInput = (regex, value) => {
      return regex.test(value);
    };

    if (value !== "") {
      setTextareaRegexTest(validateInput(regex.text, value));
    } else {
      setTextareaRegexTest(true);
    }
  };

  // 신고 제출 핸들러
  const handleChat = async () => {
    try {
      await axios.post(
        `${url}member/report/${props.$reportedId}`,
        {
          content: textareaInputValue,
        },
        {
          headers: {
            authorization: `Bearer ${props.$token}`,
          },
        }
      );
      setReportModalIsOpen(true);
      setModalMsg("신고가 완료되었습니다.");
      setTextareaInputValue("");
    } catch (error) {
      console.error(error);
      setReportModalIsOpen(true);
      setModalMsg(
        <>
          오류가 발생했습니다.
          <br />
          다시 시도해 주세요.
        </>
      );
    }
  };

  return (
    <>
      <ModalOverlay $isOpen={props.$isOpen}>
        <ReportModalContainer $isOpen={props.$isOpen}>
          <XBtnIcon
            style={{ position: "absolute", top: 30, right: 30 }}
            onClick={props.handleClose}
          />
          <ReportModalContentContainer>
            <ReportModalTitleTextWrapper>
              <Title>신고하기</Title>
              <ReportModalText>신고 사유를 작성해 주세요.</ReportModalText>
            </ReportModalTitleTextWrapper>
            <TextareaBox
              $uniqueKey="textarea"
              label="내용"
              $isRequired={true}
              placeholder="내용을 입력해 주세요."
              value={textareaInputValue}
              onChange={handleInputChange}
              $isError={!textareaRegexTest}
              $errorMsg="두 글자 이상 입력해 주세요."
            />
          </ReportModalContentContainer>
          <ReportModalBtnWrapper>
            <SecondaryMiniBtn
              onClick={() => {
                props.handleClose();
              }}
            >
              취소
            </SecondaryMiniBtn>

            <PrimaryMiniBtn
              onClick={() => {
                handleChat();
              }}
              disabled={textareaInputValue === "" || !textareaRegexTest}
            >
              확인
            </PrimaryMiniBtn>
          </ReportModalBtnWrapper>
          {/* 알림창 영역 */}
          {reportModalIsOpen && (
            <Alert
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              onClick={() => {
                props.handleClose();
                setReportModalIsOpen(false);
                setModalMsg("");
              }}
            >
              {modalMsg}
            </Alert>
          )}
        </ReportModalContainer>
      </ModalOverlay>
    </>
  );
};

export default ReportModal;

const ReportModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 510px;
  min-height: 536px;
  padding: 40px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  animation: ${(props) =>
    props.$isOpen
      ? "fadeIn 0.3s ease-out forwards"
      : "fadeOut 0.3s ease-out forwards"};
`;

const ReportModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 430px;
  min-height: 395px;
  gap: 30px;
`;

const ReportModalTitleTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ReportModalText = styled(PretendardText)`
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #6f6f6f;
`;

const ReportModalBtnWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 20px;
  align-items: center;
  align-self: center;
`;
