import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import PageContainer from "../../components/molecules/layout/PageContainer";
import BodyContainer from "../../components/molecules/layout/BodyContainer";
import Header from "../../components/organisms/Header";
import Footer from "../../components/organisms/Footer";
import TopBanner from "../../components/molecules/TopBanner";
import SortedResultBoldText from "../../components/molecules/SortedResultBoldText";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import scrollToTop from "../../lib/utils/scrollToTop";
import useResizeHeight from "../../hooks/useResizeHeight";
import CompanyList from "../../components/molecules/CompanyList";
import PrimaryMiniBtn from "./../../components/atoms/buttons/PrimaryMiniBtn";
import { useRecoilValue } from "recoil";
import { myInfoValueState } from "../../store/myInfo";
import { deleteStorage, getStorage } from "../../lib/utils/sessionStorage";
import route from "./../../router/route";
import { fileUrl, url } from "./../../lib/api/api";
import Pagination from "./../../components/molecules/Pagination";
import axios from "axios";

const Service = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //

  // 기업 회원이 아닐 경우 홈으로 이동
  useEffect(() => {
    if (myInfo.id === 0 || !myInfo) {
      return;
    } else {
      if (myInfo.profile) {
        getTokens();
      } else {
        navigate("/");
      }
    }
  }, [myInfo]); // eslint-disable-line

  // 토근이 있어야지만 API 요청 진행
  useEffect(() => {
    if (token !== "") {
      getServiceList();
    }
  }, [location, token]); // eslint-disable-line

  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 4, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
  });

  // ----------------------------------- //
  // ---------- 토큰 가져오기 ---------- //
  // --------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // --------------------------------------------- //
  // ---------- 서비스 데이터 가져오기 ---------- //
  // ------------------------------------------- //
  const [serviceList, setServiceList] = useState([]); // 서비스 목록

  const getServiceList = async () => {
    try {
      const data = {
        page: searchParams.get("page") ? searchParams.get("page") : 1,
        size: pagination.size,
      };
      const response = await axios.get(`${url}member/merches`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        params: data,
      });

      setServiceList(response.data.data.content);

      setPagination({
        ...pagination,
        totalCount: response.data.data.totalCount
          ? response.data.data.totalCount
          : response.data.data.content.length,
        totalPages: response.data.data.totalPage
          ? response.data.data.totalPage
          : Math.ceil(
              response.data.data.totalCount
                ? response.data.data.totalCount / 4
                : response.data.data.content.length / 4
            ),
      });
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      }
    }
  };

  // -------------------------------- //

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 800 + elementHeight,
      }}
    >
      <Header />
      <BodyContainer>
        <TopBanner
          $title="서비스 등록"
          $text="알팅에 서비스를 등록하고 고객을 만나보세요."
          src="/assets/imgs/service/service_bg.jpg"
        />
        <ServiceContainer ref={elementRef}>
          <SortedResultBoldText
            style={{ marginBottom: 30 }}
            $title="전체"
            $count={pagination.totalCount}
          />
          <PostContainer>
            <>
              {serviceList.map((item, index) => (
                <CompanyList
                  key={index}
                  path={`${route.serviceDetail}/${item.id}`}
                  pathHandler={() => {
                    scrollToTop();
                  }}
                  src={`${fileUrl}/${item.profileServerFileName}`}
                  serviceName={item.title}
                  companyName={item.member.profile.companyName}
                  marketingType={item.marketingType.title}
                  location={item.member.profile.regionTitle}
                  reviewGrade={item.reviewAverage}
                />
              ))}
              <Pagination
                route={route.service}
                currentPage={page}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
              />
            </>
          </PostContainer>
          <div
            style={{
              marginTop: 40,
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <PrimaryMiniBtn
              onClick={(e) => {
                e.preventDefault();
                navigate("/addService");
                scrollToTop();
              }}
            >
              등록하기
            </PrimaryMiniBtn>
          </div>
        </ServiceContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default Service;

const ServiceContainer = styled.div`
  position: absolute;
  top: 475px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PostContainer = styled.div`
  display: flex;
  width: 1200px;
  gap: 29px;
  flex-wrap: wrap;
`;
