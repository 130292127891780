import React from "react";
import styled from "styled-components";

const DivisionLine = (props) => {
  return <StyledDivisionLine {...props} />;
};

export default DivisionLine;

const StyledDivisionLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e9e9e9;
`;
