import React from "react";
import styled from "styled-components";

const SearchIcon = (props) => {
  return (
    <SearchBtn type={props.type ? props.type : "button"} {...props}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: "pointer" }}
      >
        <circle
          cx="9.11428"
          cy="9.11401"
          r="5.47143"
          stroke="#5A5A5A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="13.2785"
          y1="13.2002"
          x2="18.8571"
          y2="18.7788"
          stroke="#5A5A5A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SearchBtn>
  );
};

export default SearchIcon;

const SearchBtn = styled.button`
  border: none;
  background: none;
  width: 22px;
  height: 22px;
  border-radius: 5px;
  &:focus {
    outline: 1px solid #2ec5f6;
  }
`;
