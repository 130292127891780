import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import DownArrowIcon from "./../atoms/icons/DownArrowIcon";
import styled from "styled-components";

// 아이템 목록과 한 페이지당 표시할 아이템 수를 받음
const PaginatedItems = (props) => {
  // 현재 페이지 번호를 상태로 관리
  const [pageNumber, setPageNumber] = useState(0);

  // 페이지 번호에 따라 표시할 아이템들을 계산
  const pagesVisited = pageNumber * props.$itemsPerPage;
  const displayItems = props.$items.slice(
    pagesVisited,
    pagesVisited + props.$itemsPerPage
  );

  // 전체 페이지 수를 계산
  const pageCount = Math.ceil(props.$items.length / props.$itemsPerPage);

  // 사용자가 페이지를 변경할 때 호출될 함수
  const handlePageClick = (event) => {
    setPageNumber(event.selected);
  };

  return (
    <PaginateContainer {...props}>
      {/* 표시할 아이템 렌더링 */}
      {!props.$isHorizontal && (
        <div>
          {displayItems.map((item, index) => (
            <React.Fragment key={index}>
              {props.children(item, pagesVisited + index)}
            </React.Fragment>
          ))}
        </div>
      )}
      {props.$isHorizontal && (
        <div
          style={{
            width: props.wrapperWidth ? props.wrapperWidth : 1200,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: props.wrapperGap ? props.wrapperGap : 29,
          }}
        >
          {displayItems.map((item, index) => (
            <React.Fragment key={index}>
              {props.children(item, pagesVisited + index)}
            </React.Fragment>
          ))}
        </div>
      )}
      {/* 페이지네이션 컴포넌트 */}
      <StyledPaginate
        previousLabel={
          <DownArrowIcon
            style={{
              transform: "rotate(90deg)",
            }}
          />
        }
        nextLabel={
          <DownArrowIcon
            style={{
              transform: "rotate(-90deg)",
            }}
          />
        }
        pageCount={pageCount}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        marginPagesDisplayed={1}
        containerClassName={"paginationBtns"}
        previousLinkClassName={"previousBtn"}
        nextLinkClassName={"nextBtn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />
    </PaginateContainer>
  );
};

export default PaginatedItems;

const PaginateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
`;

const StyledPaginate = styled(ReactPaginate).attrs({
  activeClassName: "paginationActive",
  disabledClassName: "paginationDisabled",
})`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #6f6f6f;
  line-height: 145%;

  .paginationBtns {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
  }

  .previousBtn,
  .nextBtn {
    cursor: pointer;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .paginationActive {
    color: #282828;
    background-color: #e9e9e9;
    border-radius: 8px;
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;
