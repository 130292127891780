import React from "react";
import styled from "styled-components";
import PrimaryMiniBtn from "../atoms/buttons/PrimaryMiniBtn";

const InputBox = ({
  style,
  isNoLabel,
  $uniqueKey,
  label,
  $isRequired,
  type,
  placeholder,
  value,
  onChange,
  disabled,
  readOnly,
  $isError,
  $errorMsg,
  $isHelp,
  $helpMsg,
  $isShowBtn,
  $btnDisabled,
  $btnHandler,
  $btnText,
}) => {
  return (
    <InputWrapper
      style={{
        ...style,
      }}
    >
      {!isNoLabel && (
        <InputLabel htmlFor={$uniqueKey}>
          {label}

          {/* 필수 입력값일 경우 */}
          {$isRequired && (
            <span
              style={{
                fontFamily: "Pretendard",
                color: "#2ec5f6",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "145%",
              }}
            >
              *
            </span>
          )}
        </InputLabel>
      )}

      {/* 입력 필드 */}
      <InputField
        type={type}
        id={$uniqueKey}
        name={$uniqueKey}
        placeholder={placeholder}
        required={$isRequired}
        value={value}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        autoComplete={type === "password" ? "current" : "off"}
      />

      {/* 에러 메시지 */}
      {$isError && (
        <ErrorMsg
          style={{
            top: isNoLabel ? 63 : 86,
          }}
        >
          {$errorMsg}
        </ErrorMsg>
      )}

      {/* 헬프 메시지 */}
      {$isHelp && (
        <HelpMsg
          style={{
            top: isNoLabel ? 53 : 86,
          }}
        >
          {$helpMsg}
        </HelpMsg>
      )}

      {/* 버튼 */}
      {$isShowBtn && (
        <PrimaryMiniBtn
          type="button"
          $isSmall
          style={{
            position: "absolute",
            top: 42,
            right: 15,
          }}
          disabled={$btnDisabled}
          onClick={$btnHandler}
        >
          {$btnText}
        </PrimaryMiniBtn>
      )}
    </InputWrapper>
  );
};

export default InputBox;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 430px;
`;

const InputLabel = styled.label`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 145%;
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 9px;
`;

const InputField = styled.input`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #282828;
  width: 100%;
  height: 51px;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  padding-left: 17px;
  outline: none;
  margin-bottom: 3px;

  &::placeholder {
    color: #c0c0c0;
  }

  &:focus {
    outline: 1px solid #2ec5f6;
  }

  &:disabled {
    background-color: #e9e9e9;
    color: #c0c0c0;
    cursor: not-allowed;
  }
`;

const ErrorMsg = styled.p`
  position: absolute;
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  color: #ff3124;
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;
  word-break: keep-all;
  white-space: nowrap;
`;

const HelpMsg = styled(ErrorMsg)`
  color: #1b84ff;
`;
