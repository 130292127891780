import React from "react";
import styled from "styled-components";

const SecondaryBtn = (props) => {
  return <StyledSecondaryBtn {...props}>{props.children}</StyledSecondaryBtn>;
};

export default SecondaryBtn;

const StyledSecondaryBtn = styled.button`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 145%;
  width: 340px;
  height: 51px;
  background-color: transparent;
  border: 1px solid #2ec5f6;
  color: #2ec5f6;
  border-radius: 5px;
  cursor: pointer;
  transition: border 0.3s color 0.3s;

  &:hover {
    border: 1px solid #29abd6;
    color: #29abd6;
  }

  &:disabled {
    border: 1px solid #939393;
    color: #939393;
    cursor: not-allowed;
  }
`;
