import React from "react";

const DetailPageFileAddIcon = (props) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5455 3H4V17H16V8L10.5455 3Z"
        stroke="#939393"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0004 8H10.5459V3L16.0004 8Z"
        stroke="#939393"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DetailPageFileAddIcon;
