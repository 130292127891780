import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PageContainer from "../../components/molecules/layout/PageContainer";
import BodyContainer from "../../components/molecules/layout/BodyContainer";
import Header from "../../components/organisms/Header";
import Footer from "../../components/organisms/Footer";
import Title from "./../../components/atoms/Title";
import PretendardText from "../../components/atoms/PretendardText";
import SortedResultBoldText from "./../../components/molecules/SortedResultBoldText";
import useResizeHeight from "../../hooks/useResizeHeight";
import DownArrowIcon from "../../components/atoms/icons/DownArrowIcon";
import PaginatedItems from "../../components/molecules/PaginatedItems";
import axios from "axios";
import { url } from "../../lib/api/api";

const QnA = () => {
  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    getQnAList();
  }, []);

  // ------------------------------------------ //
  // ---------- QnA 데이터 가져오기 ---------- //
  // ---------------------------------------- //
  const [qnaList, setQnaList] = useState([]);
  const [qnaCount, setQnaCount] = useState(0);

  const getQnAList = async () => {
    try {
      const response = await axios.get(`${url}faqs`, {
        params: {
          page: 1,
          size: 0,
        },
      });
      setQnaCount(response.data.data.totalCount);
      setQnaList(response.data.data.content);
    } catch (error) {
      console.error(error);
    }
  };

  // 각 게시물의 DownArrow 상태 배열로 관리
  const [rotatedItems, setRotatedItems] = useState([]);

  useEffect(() => {
    setRotatedItems(
      new Array(qnaCount ? qnaCount : qnaList.length).fill(false) // qnaList의 길이만큼 배열 생성 후 채우지 않음
    );
  }, [qnaList]); // eslint-disable-line react-hooks/exhaustive-deps

  // 각 게시물의 DownArrow 상태 토글 함수
  const toggleItem = (index) => {
    setRotatedItems((prev) =>
      prev.map((item, i) => (i === index ? !item : item))
    );
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer style={{ minHeight: 500 + elementHeight }}>
      <Header />
      <BodyContainer>
        <QnAContainer ref={elementRef}>
          <Title style={{ marginBottom: 50 }}>자주 묻는 질문</Title>
          <SortedResultBoldText
            $title="전체 게시글"
            $count={qnaCount ? qnaCount : qnaList.length}
          />
          <QnATitleText>제목</QnATitleText>
          <QnAContentContainer>
            <PaginatedItems $items={qnaList} $itemsPerPage={10}>
              {(item, index) => (
                <div
                  style={{
                    width: 1140,
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={() => toggleItem(index)}
                >
                  <QnAContentTitle>
                    {item.title}
                    <DownArrowIcon $isRotated={rotatedItems[index]} />
                  </QnAContentTitle>
                  <QnAContentText
                    style={{
                      display: rotatedItems[index] ? "block" : "none",
                    }}
                  >
                    {item.content}
                  </QnAContentText>
                </div>
              )}
            </PaginatedItems>
          </QnAContentContainer>
        </QnAContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default QnA;

const QnAContainer = styled.div`
  position: absolute;
  top: 165px;
  width: 1140px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const QnATitleText = styled(PretendardText)`
  width: 100%;
  background-color: #f4f4f4;
  font-size: 16px;
  font-weight: 600;
  line-height: 145%;
  color: #282828;
  padding: 20px 32px;
  margin-top: 22px;
`;

const QnAContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
`;

const QnAContentTitle = styled(PretendardText)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1140px;
  font-size: 16px;
  font-weight: 600;
  line-height: 145%;
  max-height: 65px;
  padding: 20px 30px;
  color: #282828;
  border-bottom: 1px solid #e9e9e9;
`;

const QnAContentText = styled(PretendardText)`
  width: 100%;
  font-size: 16px;
  max-width: 1140px;
  background-color: #f8f9fa;
  font-weight: 400;
  border-bottom: 1px solid #e9e9e9;
  color: #282828;
  height: auto;
  max-height: 115px;
  margin-top: 0;
  padding: 20px 184px 20px 30px;
  overflow-y: auto;
  white-space: normal;
  word-break: break-word;
  line-height: 25px;
`;
