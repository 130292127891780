import React from "react";
import styled from "styled-components";
import PretendardText from "./PretendardText";

const ModalTextContent = (props) => {
  return (
    <StyledModalTextContent {...props}>{props.children}</StyledModalTextContent>
  );
};

export default ModalTextContent;

const StyledModalTextContent = styled(PretendardText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #555b58;
`;
