import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Title from "../../../../components/atoms/Title";
import BodyContainer from "../../../../components/molecules/layout/BodyContainer";
import Header from "../../../../components/organisms/Header";
import useResizeHeight from "../../../../hooks/useResizeHeight";
import Footer from "../../../../components/organisms/Footer";
import { useRecoilValue } from "recoil";
import {
  deleteStorage,
  getStorage,
} from "../../../../lib/utils/sessionStorage";
import axios from "axios";
import { fileUrl, url } from "../../../../lib/api/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { myInfoValueState } from "../../../../store/myInfo";
import PageContainer from "../../../../components/molecules/layout/PageContainer";
import scrollToTop from "../../../../lib/utils/scrollToTop";
import Alert from "../../../../components/molecules/Alert";
import CommonSideBar from "../_components/CommonSideBar";
import SortedResultBoldText from "./../../../../components/molecules/SortedResultBoldText";
import CompanyList from "./../../../../components/molecules/CompanyList";
import route from "./../../../../router/route";
import Pagination from "./../../../../components/molecules/Pagination";

const CommonScrap = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    if (myInfo.id === 0 || !myInfo) {
      return;
    } else {
      getTokens();
    }
  }, [myInfo]);

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    if (token !== "") {
      getScrapList();
    }
  }, [token, location]); // eslint-disable-line

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // 알림창 관련 상태
  const [alertOpen, setAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // ---------------------------------- //
  // ---------- 스크랩 영역 ---------- //
  // -------------------------------- //
  const [scrapList, setScrapList] = useState([]);

  // 페이지네이션 관련 상태
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 6, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const getScrapList = async () => {
    try {
      const data = {
        page: searchParams.get("page") ? searchParams.get("page") : 1,
        size: pagination.size,
        orderBy: "DESC",
      };
      const response = await axios.get(`${url}member/scraps`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        params: data,
      });
      setScrapList(response.data.data.content);

      setPagination({
        ...pagination,
        totalCount: response.data.data.totalCount
          ? response.data.data.totalCount
          : response.data.data.content.length,
        totalPages: response.data.data.totalPage
          ? response.data.data.totalPage
          : Math.ceil(
              response.data.data.totalCount
                ? response.data.data.totalCount / 6
                : response.data.data.content.length / 6
            ),
      });
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  const toggleScrap = async (merchId) => {
    try {
      await axios.post(`${url}member/scrap/${merchId}`, null, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      getScrapList();
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 550 + elementHeight,
      }}
    >
      <Header $isBgWhite={true} />
      <BodyContainer
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        <MyPageContainer ref={elementRef}>
          <Title>마이페이지</Title>
          <MyPageInnerContainerWrapper>
            <CommonSideBar />

            <MyPageContentsContainer>
              <SortedResultTextWrapper>
                <SortedResultBoldText
                  fontSize={20}
                  $title="스크랩한 기업"
                  $count={pagination.totalCount}
                />
              </SortedResultTextWrapper>
              <PostContainer>
                <>
                  {scrapList.map((item, index) => (
                    <CompanyList
                      key={index}
                      path={`${route.serviceDetail}/${item.merch.id}`}
                      pathHandler={() => {
                        scrollToTop();
                      }}
                      isShowBookmark={true}
                      bookmarkHandler={(e) => {
                        e.stopPropagation();
                        toggleScrap(item.merch.id);
                      }}
                      src={`${fileUrl}/${item.merch.profileServerFileName}`}
                      $isChecked={scrapList.some(
                        (scrap) => scrap.merch.id === item.merch.id
                      )}
                      serviceName={item.merch.title}
                      companyName={item.merch.member.profile.companyName}
                      marketingType={item.merch.marketingType.title}
                      location={item.merch.member.profile.regionTitle}
                      reviewGrade={item.merch.reviewAverage}
                    />
                  ))}
                  <Pagination
                    route={route.myPage_common_scrap}
                    currentPage={page}
                    totalPages={pagination.totalPages}
                    rangeSize={pagination.rangeSize}
                  />
                </>
              </PostContainer>

              {alertOpen && (
                <Alert
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAlertOpen(false);
                  }}
                >
                  {responseMessage}
                </Alert>
              )}
            </MyPageContentsContainer>
          </MyPageInnerContainerWrapper>
        </MyPageContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default CommonScrap;

const MyPageContainer = styled.div`
  position: absolute;
  top: 165px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MyPageInnerContainerWrapper = styled.div`
  display: flex;
  gap: 31px;
  width: 100%;
  margin-top: 30px;
`;

const MyPageContentsContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PostContainer = styled.div`
  display: flex;
  width: 892px;
  gap: 29px;
  flex-wrap: wrap;
`;

const SortedResultTextWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
`;
