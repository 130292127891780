import React from "react";
import styled from "styled-components";

const LocationIcon = (props) => {
  return (
    <LocationIconSvg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/LocationIconSvg"
      {...props}
    >
      <path
        d="M9 0.5C12.8733 0.5 16 3.75506 16 7.78745C16 10.7996 13.7911 13.9575 9.46667 17.3421C9.18667 17.5526 8.81333 17.5526 8.53333 17.3421C4.20889 13.9575 2 10.7996 2 7.78745C2 3.75506 5.12667 0.5 9 0.5ZM9 5.3583C7.70889 5.3583 6.66667 6.44332 6.66667 7.78745C6.66667 9.13158 7.70889 10.2166 9 10.2166C10.2911 10.2166 11.3333 9.13158 11.3333 7.78745C11.3333 6.44332 10.2911 5.3583 9 5.3583Z"
        fill="#C0C0C0"
      />
    </LocationIconSvg>
  );
};

export default LocationIcon;

const LocationIconSvg = styled.svg``;
