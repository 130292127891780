import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../components/organisms/Header";
import useResizeHeight from "../hooks/useResizeHeight";
import Footer from "./../components/organisms/Footer";
import BodyContainer from "./../components/molecules/layout/BodyContainer";
import PageContainer from "./../components/molecules/layout/PageContainer";
import callChatGPT from "./../lib/api/chatGPT";
import Address from "./../components/organisms/Adress";
import ModalOverlay from "./../components/atoms/ModalOverlay";
import axios from "axios";
import socket from "../lib/utils/socket";

const TestPage = () => {
  // ------------------------------- //
  // ---------- 채팅 관련 ---------- //
  // ----------------------------- //

  // 채팅참가: 'enter' : { room: id }
  // 메세지 전송: 'send_message' : { room: id, message: string, senderId : id }
  // 메시지 수신 : receive_message 구독
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [roomId, setRoomId] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    // 메시지 수신
    socket.on("receive_message", (newMessage) => {
      console.log(">>> New message received:", newMessage);
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });

    return () => {
      socket.off("receive_message");
      console.log(">>> Socket off");
    };
  }, []);

  const joinRoom = () => {
    socket.emit("enter", { room: roomId });
    console.log(">>> Room joined:", roomId);
  };

  const sendMessage = () => {
    const newMessage = {
      room: roomId,
      message: message,
      senderId: userId,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);

    socket.emit("send_message", newMessage);
    console.log(">>> Message sent:", message);
    setMessage("");
  };

  // ---------------------------------- //
  // ---------- chatGPT 관련 ---------- //
  // --------------------------------- //

  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState("");

  const handleSubmit = async () => {
    const result = await callChatGPT(prompt);
    setResponse(result.choices[0].message.content);
    console.log(JSON.parse(result.choices[0].message.content));
  };

  // ------------------------------- //
  // ---------- 주소 관련 ---------- //
  // ----------------------------- //
  const [addressValue, setAddressValue] = useState({
    address: "",
  });

  const [popup, setPopup] = useState(false);

  const handleInput = (e) => {
    setAddressValue({
      ...addressValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleComplete = (data) => {
    setPopup(!popup);
  };

  const closePopup = () => {
    setPopup(false);
  };

  useEffect(() => {
    const getKakaoAddress = async () => {
      const data = {
        query: "서울특별시 강남구 역삼동 736-1",
      };
      const response = await axios.get(
        `https://dapi.kakao.com/v2/local/search/address.json`,
        {
          headers: {
            Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_REST_API_KEY}`,
          },
          params: data,
        }
      );
      console.log(response.data.documents[0].y);
      console.log(response.data.documents[0].x);
    };

    getKakaoAddress();
  }, []);

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer style={{ minHeight: 650 + elementHeight }}>
      <Header />
      <BodyContainer>
        <TestContainer ref={elementRef}>
          {/* Chat GPT */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              marginBottom: 40,
            }}
          >
            <h1>Chat GPT</h1>
            <div>
              <textarea
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="내용을 입력하세요."
              />
              <button onClick={handleSubmit}>제출</button>
              <div>
                <h3>Response:</h3>
                <p>{response}</p>
              </div>
            </div>
          </div>

          {/* 채팅 */}
          <div>
            <h2>Enter Room</h2>
            <input
              type="text"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              placeholder="User ID"
            />
            <input
              type="text"
              value={roomId}
              onChange={(e) => setRoomId(e.target.value)}
              placeholder="Room ID"
            />
            <button onClick={joinRoom}>Join Room</button>
          </div>

          <div>
            <h2>Chat</h2>
            <div>
              {messages.map((msg, index) => (
                <div key={index}>
                  <strong>{msg.senderId}: </strong>
                  {msg.message}
                </div>
              ))}
            </div>
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button onClick={sendMessage}>Send</button>
          </div>

          {/* 주소 */}
          <div>
            <h2>address</h2>
            <input
              placeholder="주소"
              type="text"
              required={true}
              name="address"
              onChange={handleInput}
              value={addressValue.address}
            />
            <button onClick={handleComplete}>우편번호 찾기</button>
            {popup && (
              <ModalOverlay $isOpen={popup}>
                <Address
                  address={addressValue}
                  setAddress={setAddressValue}
                  onClose={closePopup}
                />
              </ModalOverlay>
            )}
          </div>
        </TestContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default TestPage;

const TestContainer = styled.div`
  position: absolute;
  top: 105px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
