import React from "react";
import styled from "styled-components";

const VCheckIcon = (props) => {
  return (
    <VCheckIconSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.76172 11.6679L10.1297 16.3202L18.2417 7.68018"
        stroke={props.checked ? "#2ec5f6" : "#C0C0C0"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </VCheckIconSvg>
  );
};

export default VCheckIcon;

const VCheckIconSvg = styled.svg`
  cursor: pointer;
`;
