import { atom } from "recoil";

export const initialMyInfoValues = {
  createdAt: "",
  updatedAt: "",
  id: 0,
  email: "",
  name: "",
  phoneNumber: "",
  age: 0,
  sector: "",
  sectorTitle: "",
  address: "",
  agreement: true,
  privacy: true,
  providePrivacy: true,
  advertisement: false,
  joinType: "NORMAL",
  joinTypeTitle: "일반",
  activation: true,
  approval: true,
  profileServerFileName: "",
  profileOriginFileName: "",
};

export const myInfoValueState = atom({
  key: "myInfoValueState",
  default: initialMyInfoValues,
});
