import React from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ProfileIcon = (props) => {
  return !props.src ? (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="40" cy="40" r="40" fill="#E9E9E9" />
      <circle cx="40.0013" cy="26.6668" r="13.3333" fill="#C0C0C0" />
      <mask
        id="mask0_270_1539"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="80"
        height="80"
      >
        <circle cx="40" cy="40" r="40" fill="#E9E9E9" />
      </mask>
      <g mask="url(#mask0_270_1539)">
        <rect
          x="6.66797"
          y="44.4443"
          width="64.4444"
          height="55.5556"
          rx="27.7778"
          fill="#C0C0C0"
        />
      </g>
    </svg>
  ) : (
    <StyledProfileIcon src={props.src} {...props} />
  );
};

export default ProfileIcon;

const StyledProfileIcon = styled(LazyLoadImage)`
  /* background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 999px;
`;
