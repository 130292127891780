import React from "react";
import styled from "styled-components";

const HomeIcon = (props) => {
  return (
    <HomeIconSvg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.93333 1.81995C9.38111 1.39595 8.61889 1.39595 8.06667 1.81995L2.62222 6.01995C2.23333 6.32395 2 6.79595 2 7.29995V14.9C2 15.78 2.7 16.5 3.55556 16.5H6.58889C7.06333 16.5 7.44444 16.108 7.44444 15.62V11.7C7.44444 10.82 8.14444 10.1 9 10.1C9.85556 10.1 10.5556 10.82 10.5556 11.7V15.62C10.5556 16.108 10.9367 16.5 11.4111 16.5H14.4444C15.3 16.5 16 15.78 16 14.9V7.29995C16 6.79595 15.7667 6.32395 15.3778 6.01995L9.93333 1.81995Z"
        fill="#C0C0C0"
      />
    </HomeIconSvg>
  );
};

export default HomeIcon;

const HomeIconSvg = styled.svg``;
