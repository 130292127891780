import styled from "styled-components";
import Title from "../../../../components/atoms/Title";
import BodyContainer from "../../../../components/molecules/layout/BodyContainer";
import Header from "../../../../components/organisms/Header";
import useResizeHeight from "../../../../hooks/useResizeHeight";
import Footer from "../../../../components/organisms/Footer";
import PretendardText from "../../../../components/atoms/PretendardText";
import { useRecoilValue } from "recoil";
import { useEffect, useMemo, useState } from "react";
import {
  deleteStorage,
  getStorage,
} from "../../../../lib/utils/sessionStorage";
import axios from "axios";
import { fileUrl, url } from "../../../../lib/api/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { myInfoValueState } from "../../../../store/myInfo";
import PageContainer from "../../../../components/molecules/layout/PageContainer";
import scrollToTop from "../../../../lib/utils/scrollToTop";
import ServiceApi from "../../../../lib/api/service.api";
import SortedResultBoldText from "../../../../components/molecules/SortedResultBoldText";
import CompanyIcon from "../../../../components/atoms/icons/CompanyIcons";
import route from "../../../../router/route";
import PenIcon from "../../../../components/atoms/icons/PenIcon";
import DivisionLine from "../../../../components/atoms/DivisionLine";
import { formatDate } from "../../../../lib/utils/formatDate";
import Alert from "../../../../components/molecules/Alert";
import CommonSideBar from "../_components/CommonSideBar";
import LocationIcon from "./../../../../components/atoms/icons/LocationIcon";
import ReviewGradeIcon from "./../../../../components/atoms/icons/ReviewGradeIcon";
import { formatRatingNumber } from "../../../../lib/utils/formatNumber";
import Pagination from "./../../../../components/molecules/Pagination";

const CommonChat = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
  const [token, setToken] = useState("");
  const myInfo = useRecoilValue(myInfoValueState);

  // -------------------------------- //
  // ---------- 마운트 시 ---------- //
  // ------------------------------ //
  useEffect(() => {
    if (myInfo.id === 0 || !myInfo || myInfo.profile) {
      return;
    } else {
      if (myInfo.id !== 0) {
        getTokens();
      } else return;
    }
  }, [myInfo]); // eslint-disable-line

  // 토큰이 있어야지만 API 요청 진행
  useEffect(() => {
    scrollToTop();
    if (token !== "") {
      getChatList();
    }
  }, [token, location]); // eslint-disable-line

  // ------------------------------------ //
  // ---------- 토큰 가져오기 ---------- //
  // ---------------------------------- //
  const getTokens = async () => {
    try {
      const response = await getStorage("token");
      setToken(response);
    } catch (error) {
      console.error(error);
    }
  };

  // 알림창 관련 상태
  const [alertOpen, setAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // -------------------------------- //
  // ---------- 채팅 영역 ---------- //
  // ------------------------------ //
  const [chatList, setChatList] = useState([]);

  // 페이지네이션 관련 상태
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 5, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const getChatList = async () => {
    try {
      const data = {
        page: searchParams.get("page") ? searchParams.get("page") : 1,
        size: pagination.size,
        orderBy: "DESC",
      };
      const response = await axios.get(`${url}member/chats`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        params: data,
      });

      const merchResponse = await Promise.all(
        response.data.data.content.map(async (item) => {
          const merchResponse = await ServiceApi.getById(item.merchId);
          const merchData = merchResponse.data.data.content;
          return {
            ...item,
            merch: {
              ...merchData,
            },
          };
        })
      );
      setChatList(merchResponse);

      setPagination({
        ...pagination,
        totalCount: response.data.data.totalCount
          ? response.data.data.totalCount
          : response.data.data.content.length,
        totalPages: response.data.data.totalPage
          ? response.data.data.totalPage
          : Math.ceil(
              response.data.data.totalCount
                ? response.data.data.totalCount / 5
                : response.data.data.content.length / 5
            ),
      });
    } catch (error) {
      console.error(error);
      if (error.response.statusText === "Unauthorized") {
        deleteStorage("token");
        navigate(route.login + "?refresh=true");
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
      } else {
        setAlertOpen(true);
        setResponseMessage("잠시 후 다시 시도해 주세요.");
      }
    }
  };

  // ResizeObserver를 이용한 컨테이너 높이 계산
  const { elementRef, elementHeight } = useResizeHeight();

  return (
    <PageContainer
      style={{
        minHeight: 550 + elementHeight,
      }}
    >
      <Header $isBgWhite={true} />
      <BodyContainer
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        <MyPageContainer ref={elementRef}>
          <Title>마이페이지</Title>
          <MyPageInnerContainerWrapper>
            <CommonSideBar />

            <MyPageContentsContainer>
              <>
                <SortedResultTextWrapper>
                  <SortedResultBoldText
                    fontSize={20}
                    $title="채팅"
                    $count={pagination.totalCount}
                  />
                </SortedResultTextWrapper>
                <ChatContainer>
                  <>
                    {chatList.map((item, index) => (
                      <ChatBoxWrapper
                        onClick={() => {
                          navigate(`${route.chat}/${item.id}`);
                          scrollToTop();
                        }}
                        key={index}
                      >
                        <ChatBoxInnerTopWrapper>
                          <CompanyIcon
                            src={
                              item.companyMemberName.profileServerFileName !==
                              ""
                                ? `${fileUrl}/${item.companyMemberName.profileServerFileName}`
                                : ""
                            }
                            style={{
                              width: 60,
                              height: 60,
                              backgroundColor: "#d9d9d9",
                              borderRadius: 5,
                              boxShadow: "none",
                            }}
                          />
                          <ChatCompanyInfoWrapper>
                            <ChatCompanyNameText>
                              {item.merch.member.profile.companyName}
                            </ChatCompanyNameText>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 25,
                              }}
                            >
                              <TypeWrapper>
                                <PenIcon />
                                <TypeText
                                  style={{
                                    marginLeft: -3,
                                  }}
                                >
                                  {item.merch.marketingType.title}
                                </TypeText>
                                <LocationIcon />
                                <TypeText
                                  style={{
                                    marginLeft: -3,
                                  }}
                                >
                                  {item.merch.member.profile.regionTitle}
                                </TypeText>
                              </TypeWrapper>
                              <ReviewGradeWrapper>
                                <ReviewGradeIcon
                                  $isChecked
                                  style={{ width: 18, height: 18 }}
                                />
                                <ReviewGradeText>
                                  {formatRatingNumber(item.merch.reviewAverage)}
                                </ReviewGradeText>
                              </ReviewGradeWrapper>
                            </div>
                          </ChatCompanyInfoWrapper>
                        </ChatBoxInnerTopWrapper>
                        <DivisionLine
                          style={{
                            position: "static",
                            width: "100%",
                            borderBottom: "1px solid #E9E9E9",
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <ChatContent>
                            {item.lastMessage === null
                              ? "메시지가 없습니다."
                              : item.lastMessage.startsWith("경로:")
                              ? "(첨부파일)"
                              : item.lastMessage}
                          </ChatContent>
                          <ChatContentLastMsgTime>
                            {item.lastMessageDate !== null
                              ? formatDate(item.lastMessageDate)
                              : ""}
                          </ChatContentLastMsgTime>
                        </div>
                      </ChatBoxWrapper>
                    ))}
                    <Pagination
                      route={route.myPage_common_chat}
                      currentPage={page}
                      totalPages={pagination.totalPages}
                      rangeSize={pagination.rangeSize}
                    />
                  </>
                </ChatContainer>

                {alertOpen && (
                  <Alert
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setAlertOpen(false);
                    }}
                  >
                    {responseMessage}
                  </Alert>
                )}
              </>
            </MyPageContentsContainer>
          </MyPageInnerContainerWrapper>
        </MyPageContainer>

        {/* 푸터 영역 */}
        <Footer />
      </BodyContainer>
    </PageContainer>
  );
};

export default CommonChat;

const MyPageContainer = styled.div`
  position: absolute;
  top: 165px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MyPageInnerContainerWrapper = styled.div`
  display: flex;
  gap: 31px;
  width: 100%;
  margin-top: 30px;
`;

const MyPageContentsContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SortedResultTextWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const ChatBoxWrapper = styled.div`
  width: 892px;
  height: 155px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
`;

const ChatBoxInnerTopWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const ChatCompanyInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ChatCompanyNameText = styled(PretendardText)`
  font-size: 18px;
  font-weight: 600;
  color: #282828;
  line-height: 28px;
`;

const ChatContent = styled(PretendardText)`
  width: 687px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ChatContentLastMsgTime = styled(PretendardText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #959595;
`;

const TypeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const TypeText = styled(PretendardText)`
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  color: #939393;
`;

const ReviewGradeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ReviewGradeText = styled(PretendardText)`
  font-size: 16px;
  font-weight: 800;
  line-height: 25px;
  color: #565656;
`;
