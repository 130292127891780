import React from "react";
import styled from "styled-components";

const DownArrowIcon = (props) => {
  return (
    <DownArrowIconSvg
      width={props.$isSmall ? "20" : "24"}
      height={props.$isSmall ? "20" : "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      $isRotated={props.$isRotated}
      style={{
        ...props.style,
      }}
      {...props}
    >
      <path
        d="M7.5 10L13 16L18.5 10"
        stroke="#939393"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </DownArrowIconSvg>
  );
};

export default DownArrowIcon;

const DownArrowIconSvg = styled.svg`
  transform: ${(props) =>
    props.$isRotated ? "rotate(180deg)" : "rotate(0deg)"};
  transition: transform 0.3s ease;
  cursor: pointer;
`;
