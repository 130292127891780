import React from "react";
import styled from "styled-components";
import PretendardText from "./PretendardText";

const Title = (props) => {
  return <StyledTitle {...props}>{props.children}</StyledTitle>;
};

export default Title;

const StyledTitle = styled(PretendardText)`
  font-weight: 600;
  font-size: 38px;
  line-height: 150%;
  color: #282828;
`;
