import React from "react";
import styled from "styled-components";

const LeftArrowIcon = (props) => {
  return (
    <LeftArrowIconSvg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.5 10L2.5 10"
        stroke="#939393"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 5L2 10L7 15"
        stroke="#939393"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </LeftArrowIconSvg>
  );
};

export default LeftArrowIcon;

const LeftArrowIconSvg = styled.svg`
  cursor: pointer;
`;
