import React from "react";
import styled from "styled-components";

const HashTagBox = (props) => {
  return <StyledHashTagBox>#{props.children}</StyledHashTagBox>;
};

export default HashTagBox;

const StyledHashTagBox = styled.div`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 20px;
  border: 1px solid #2ec5f6;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 700;
  line-height: 145%;
  color: #2ec5f6;
  background-color: #fff;
`;
