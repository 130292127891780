import React from "react";
import styled from "styled-components";

const CheckboxIcon = (props) => {
  return (
    <CheckboxIconSvg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="17"
        height="17"
        rx="2.5"
        fill={props.checked ? "#2EC5F6" : "#E9E9E9"}
        stroke={props.checked ? "#2EC5F6" : "#E9E9E9"}
      />
      <path
        d="M5 8.76923L7.8 12L13 6"
        stroke={props.checked ? "#fff" : "#C0C0C0"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </CheckboxIconSvg>
  );
};

export default CheckboxIcon;

const CheckboxIconSvg = styled.svg`
  cursor: pointer;
`;
