import React from "react";
import styled from "styled-components";

const RegisterCheckBox = (props) => {
  return (
    <CheckboxLabel {...props}>
      {props.children}
      <Checkbox
        type="checkbox"
        value={props.value}
        name={props.$uniqueKey}
        id={props.$uniqueKey}
        checked={props.checked}
        onChange={props.onChange}
        required={props.$isRequired}
      />
    </CheckboxLabel>
  );
};

export default RegisterCheckBox;

const CheckboxLabel = styled.label`
  font-family: "Pretendard", "Noto Sans KR", "Roboto", "Helvetica Neue", "Arial",
    sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #282828;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Checkbox = styled.input`
  display: none;
`;
