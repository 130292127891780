const prompt = ({ sector, period, budget, marketingTypeList }) => {
  return `가장 최적화된 온라인 마케팅 전략을 추천해줘. 답변을 잘해주면 $50달러의 팁을 줄 거고, 답변을 제대로 하지 않으면 혼을 낼거야.
    ${sector && `업종은 ${sector}이야.`} ${period && `기간은 ${period}야.`} ${
    budget && `예산은 ${budget}야.`
  }
    너가 답변해 줄 수 있는 항목은 아래와 같아.
    ${marketingTypeList}
    이 리스트들 중에서 가장 최적회된 마케팅 전략을 1순위부터 3순위까지 0번 인덱스부터 2번 인덱스까지 3개만 골라서 배열에 담아 답변해줘.
    예시 답변은 아래와 같아.
    [{"id": 19, "title": "당근마켓 광고"}, {"id": 17, "title": "퍼포먼스 마케팅"}, {"id": 13, "title": "바이럴 마케팅"}]
    이런 식으로 id와 title 포함해서 고른 것만 배열에 담아서 답변해.`;
};

export default prompt;
