import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import styled from "styled-components";
import PretendardText from "../atoms/PretendardText";
import { getStorage, setStorage } from "./../../lib/utils/localStorage";
import Alert from "./Alert";
import { url } from "../../lib/api/api";
import axios from "axios";
import { fileUrl } from "./../../lib/api/api";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Popup = () => {
  // ------------------------------------- //
  // ---------- 마운트 시 실행 ---------- //
  // ----------------------------------- //
  useEffect(() => {
    getPopupList();
    checkStorage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // 알림창 관련 상태
  const [alertOpen, setAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // -------------------------------------- //
  // ---------- 데이터 가져오기 ---------- //
  // ------------------------------------ //

  // new Date 형식 날짜로 변환 후 KST 시간으로 변환하는 함수
  const kstOffSetter = (date) => {
    const kstOffset = 9 * 60 * 60 * 1000;
    const dateConversion = new Date(date);
    return new Date(dateConversion.getTime() - kstOffset);
  };

  const [popupList, setPopupList] = useState([]);

  const getPopupList = async () => {
    try {
      const response = await axios.get(`${url}popups`);

      // 팝업 리스트 중 게시 기간에 해당하는 것과 게시 활성화 된 아이템만 필터링
      const filteredPopupList = response.data.data.content.filter((popup) => {
        const startDate = kstOffSetter(popup.startDate);
        const endDate = new Date(
          kstOffSetter(popup.endDate).getTime() + 1 * 24 * 60 * 60 * 1000
        ); // 1일 더하지 않으면 당일에 게시 중단됨
        const today = new Date();
        return (
          today >= startDate && today <= endDate && popup.activation === true
        );
      });

      setPopupList(filteredPopupList);
      if (filteredPopupList.length === 0) {
        handlePopUpClose();
      }
    } catch (error) {
      console.error(error);
      setAlertOpen(true);
      setResponseMessage("잠시 후 다시 시도해 주세요.");
    }
  };

  // ------------------------------------------ //
  // ---------- 팝업 노출 여부 관련 ---------- //
  // ---------------------------------------- //
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);

  const handlePopUpOpen = () => {
    setPopUpIsOpen(true);
  };

  const handlePopUpClose = () => {
    setPopUpIsOpen(false);
  };

  // 오늘 날짜를 가져오는 함수
  const getToday = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = ("0" + (today.getMonth() + 1)).slice(-2);
    const day = ("0" + today.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  // 스토리지에 저장된 값이 오늘 날짜와 같은지 확인하는 함수
  const checkStorage = async () => {
    const today = getToday();
    const doNotWatchToday = await getStorage("doNotWatchToday");
    if (doNotWatchToday && doNotWatchToday === today) {
      handlePopUpClose();
    } else {
      handlePopUpOpen();
    }
  };

  // 오늘 하루 보지 않기 버튼 클릭 시 실행되는 함수
  const doNotWatchToday = () => {
    const today = getToday();
    setStorage("doNotWatchToday", today);
    handlePopUpClose();
  };

  return (
    <PopupContainer isOpen={popUpIsOpen}>
      <Swiper pagination={true} modules={[Pagination]}>
        {popupList.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <PopupImage
                effect="blur"
                src={`${fileUrl}/${item.imageServerFileName}`}
                alt={item.imageOriginFileName}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div
        style={{
          width: 450,
          height: 62,
          display: "flex",
          alignItems: "center",
          backgroundColor: "#fff",
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        <PretendardText
          style={{
            width: 225,
            height: 62,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRight: "1px solid #e9e9e9",
            fontWeight: 600,
            fontSize: 16,
            lineHeight: "145%",
            color: "#383838",
            cursor: "pointer",
          }}
          onClick={doNotWatchToday}
        >
          오늘 하루 보지 않기
        </PretendardText>
        <PretendardText
          style={{
            width: 225,
            height: 62,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: 600,
            fontSize: 16,
            lineHeight: "145%",
            color: "#383838",
            cursor: "pointer",
          }}
          onClick={handlePopUpClose}
        >
          닫기
        </PretendardText>
      </div>
      {alertOpen && (
        <Alert
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setAlertOpen(false);
          }}
        >
          {responseMessage}
        </Alert>
      )}
    </PopupContainer>
  );
};

export default Popup;

const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  width: 450px;
  height: 450px;
  /* background-color: #fff; */
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 8px 8px 35px rgba(0, 0, 0, 0.45);
  animation: ${(props) =>
    props.isOpen
      ? "fadeIn 0.3s ease-out forwards"
      : "fadeOut 0.3s ease-out forwards"};
  z-index: 999;

  .swiper {
    width: 450px;
    height: 388px;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
    border-radius: 10px 10px 0 0;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 388px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
  }

  .swiper-pagination-bullet {
    background: rgba(219, 219, 219, 0.5);
    opacity: 1;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
  }

  .swiper-pagination-bullet-active {
    background-color: #fff;
  }
`;

const PopupImage = styled(LazyLoadImage)``;
